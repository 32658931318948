import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent,
]);

interface CircleChartProps {
  data: { value: number; name: string }[];
  height?: number;
}

const CircleChart: React.FC<CircleChartProps> = ({ data, height = 200 }) => {
  const getOptions = () => ({
    color: ['#1CCB31', '#E21B49', '#1B27E2'],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: '#f8f9fa',
      borderColor: '#ced4da',
      textStyle: { color: 'dark' },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: (params: any) =>
        `<strong>${params.data.name}:</strong> ${params.data.value}`,
    },

    legend: { show: false },
    series: [
      {
        type: 'pie',
        radius: ['100%', '65%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderWidth: 2,
          borderColor: '#f8f9fa',
        },
        label: {
          show: false,
        },
        emphasis: {
          scale: false,
        },
        data,
      },
    ],
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions()}
      style={{ height }}
    />
  );
};

export default CircleChart;
