import ICoupon, { ICouponFormSend } from '@/interfaces/Coupon';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const couponsApi = api.injectEndpoints({
  endpoints: build => ({
    getCoupons: build.query<IPaginateResponse<ICoupon>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/coupons',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Coupons' as const,
                id,
              })),
              { type: 'Coupons', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getCoupon: build.query<ICoupon, string>({
      query: id => `/coupons/${id}`,
      providesTags: (_coupon, _err) => [{ type: 'Coupons', id: 'LIST' }],
    }),
    storeCoupon: build.mutation<ICoupon, ICouponFormSend>({
      query: data => ({
        url: '/coupons',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Coupons', id: 'LIST' }],
    }),
    updateCoupon: build.mutation<ICoupon, [string, Partial<ICouponFormSend>]>({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        console.log(data);
        return {
          url: `/coupons/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Coupons', id: 'LIST' }],
    }),
    destroyCoupon: build.mutation<void, string>({
      query: id => ({
        url: `/coupons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Coupons', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCouponQuery,
  useGetCouponsQuery,
  useLazyGetCouponsQuery,
  useStoreCouponMutation,
  useUpdateCouponMutation,
  useDestroyCouponMutation,
} = couponsApi;
