import Flex from '@/components/common/Flex';
import IGooglePlace from '@/interfaces/GooglePlace';
import {
  faClock,
  faHashtag,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { FC, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

interface IProps {
  stops: IGooglePlace[];
}

const RequestStopsCard: FC<IProps> = ({ stops }) => {
  if (stops.length === 0) {
    return <div>No stops available for this request.</div>;
  }

  return (
    <Fragment>
      {stops.map(({ id, address, createdAt }, index) => (
        <div
          key={id}
          className={classNames(
            'bg-white dark__bg-1100 p-x1 rounded-3 shadow-sm',
            {
              'mt-3': index !== 0,
            }
          )}
        >
          <Row className="flex-between-center">
            <Col xs={12} lg={8}>
              <Flex className="gap-1 h6" alignItems="center">
                <FontAwesomeIcon icon={faHashtag} transform="shrink-3 up-1" />
                <span className="text-600 lb-anywhere">{id}</span>
              </Flex>
              <Flex className="gap-1 h6 mb-0" alignItems="center">
                <FontAwesomeIcon icon={faMapPin} transform="shrink-3 up-1" />
                <span className="text-600">{address}</span>
              </Flex>
            </Col>
            <Col xs={12} lg={'auto'}>
              <div className="border-bottom border-lg-0 border-0 my-lg-0 my-3"></div>
              <Flex direction="column">
                <h6 className="d-flex align-items-center gap-1 mb-0 mt-2">
                  <FontAwesomeIcon icon={faClock} transform="shrink-3 up-1" />
                  <span className="text-600">
                    {DateTime.fromISO(createdAt)
                      .setLocale('fr')
                      .toFormat('dd LLL yyyy')}
                  </span>
                </h6>
              </Flex>
            </Col>
          </Row>
        </div>
      ))}
    </Fragment>
  );
};

export default RequestStopsCard;
