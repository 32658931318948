import errors from "@/constants/errors";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import Flex from "./Flex";
import IconButton from "./IconButton";
import { useNavigate } from "react-router-dom";

interface IProps {
  error: FetchBaseQueryError | SerializedError;
  callback?: (data: FetchBaseQueryError | SerializedError) => void;
  absolute?: boolean;
  returnButton?: boolean;
  className?: string;
}

const ErrorComponent: FC<IProps> = ({
  error,
  absolute = false,
  returnButton = false,
  callback,
  className,
}) => {
  const navigation = useNavigate();
  const [status, setStatus] = useState<number>(400);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (
      "status" in error &&
      typeof error.status === "number" &&
      !!errors[error.status]
    ) {
      setStatus(error.status);
      setTitle(errors[error.status].title);
      setMessage(errors[error.status].message);
    } else {
      setStatus(400);
      setTitle(errors[400].title);
      setMessage(errors[400].message);
    }
    callback?.(error);
  }, [error]);

  return (
    <Flex
      className={classNames(
        `text-center`,
        {
          "position-absolute top-0 start-0 end-0 bottom-0": absolute,
        },
        className
      )}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <div className="display-1 text-300 fs-error">{status}</div>
      <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">{title}</p>
      <hr />
      <p>{message}</p>
      {returnButton && (
        <IconButton
          className="btn btn-primary btn-sm mt-3"
          icon={faChevronLeft}
          iconAlign="left"
          onClick={() => navigation(-1)}
        >
          Retour
        </IconButton>
      )}
    </Flex>
  );
};

export default ErrorComponent;
