import StatsNumberLive from '@/components/cards/home/Stats/StatsNumberLive';
import StatsSortables from '@/components/cards/home/Stats/StatsSortables';
import Revenue from '@/components/cards/home/TabsCharts/TabsCharts';
import GreetingsInfoRides from '@/components/cards/home/greetings/GreetingsInfoRides';
import { RideMapCard } from '@/components/common/map/RideMapCard/RideMapCard';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

const HomeView: FC = () => {
  return (
    <Row className="g-3 mb-3 mt-2">
      <Col lg={12} xl={6}>
        <Row className="g-4 flex">
          <Col xs={12}>
            <GreetingsInfoRides />
          </Col>
          <Col xs={12} className="ml-3">
            <StatsSortables />
          </Col>
          <Col xs={12} className="ml-3">
            <Revenue />
          </Col>
        </Row>
      </Col>
      <Col lg={12} xl={6}>
        <Row className="g-3 flex">
          <Col xs={12}>
            <StatsNumberLive />
          </Col>
          <Col xs={12} className="flex-1 md-h-100">
            <RideMapCard className="h-100" style={{ minHeight: 600 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeView;
