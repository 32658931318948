import UnknownBadge from '@/components/common/UnknownBadge';
import { useGetRequestOfferPaymentsQuery } from '@/services/requests/payments';
import { FC } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';

const RequestPaymentCard: FC<{ id: string; offerId: string }> = ({
  id,
  offerId,
}) => {
  const { data, isLoading, error } = useGetRequestOfferPaymentsQuery([
    id,
    offerId,
  ]);

  return (
    <Row className="g-3 mt-3">
      <Col xs={12}>
        <Card className="font-sans-serif">
          <Card.Body>
            {data && data.length > 0 ? (
              data?.map((payment, index) => (
                <Row className="gx-3" key={index}>
                  <Col xs={12} md={6} className="mb-3 mb-md-0">
                    <Table borderless className="fs--1 fw-medium mb-0">
                      <tbody>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Payment Wallet Type :
                          </td>
                          <td className="p-1 text-600">
                            {payment?.paymentWalletType ? (
                              payment.paymentWalletType
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Payment Category:
                          </td>
                          <td className="p-1 text-600">
                            {payment?.paymentCategory ? (
                              payment.paymentCategory
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            receipt URL:
                          </td>
                          <td className="p-1 text-600">
                            {payment?.receiptUrl ? (
                              payment.receiptUrl
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Payment Intent:
                          </td>
                          <td className="p-1 text-600">
                            {payment?.paymentIntent ? (
                              payment.paymentIntent
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs={12} md={6} className="mb-3 mb-sm-0">
                    <Table borderless className="fs--1 fw-medium mb-0">
                      <tbody>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Payment Card Brand :
                          </td>
                          <td className="p-1">
                            {payment?.paymentCardBrand ? (
                              payment.paymentCardBrand
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Payment Status :
                          </td>
                          <td className="p-1">
                            {payment?.paymentStatus ? (
                              payment.paymentStatus
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Authorized Amount :
                          </td>
                          <td className="p-1">
                            {payment?.authorizedAmount ? (
                              payment.authorizedAmount
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1" style={{ width: '35%' }}>
                            Captured Amount :
                          </td>
                          <td className="p-1">
                            {payment?.capturedAmount ? (
                              payment.capturedAmount
                            ) : (
                              <UnknownBadge />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ))
            ) : (
              <p>No payments found for this offer.</p>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default RequestPaymentCard;
