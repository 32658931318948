import Modal from '@/components/common/Modal';
import { IModalMethods } from '@/interfaces/Modal';
import { IPartnershipForm } from '@/interfaces/Partnership/Partnership';
import { useStorePartnershipMutation } from '@/services/partnerships/partnerships';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StorePartnershipModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IPartnershipForm>();

  const [storePartnershipAsync] = useStorePartnershipMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IPartnershipForm> = ({ ...data }) => {
    toast.promise(
      storePartnershipAsync({
        ...data,
      }).unwrap(),
      {
        pending: 'Ajout du partnership en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le partnership à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IPartnershipForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un partnership"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Name <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : AXA"
              type="text"
              {...register('name', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Category <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Insurance"
              type="text"
              {...register('category', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.category}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            as="textarea"
            type="text"
            {...register('description', {
              minLength: {
                value: 3,
                message: 'Ce champ doit contenir au moins 3 caractères.',
              },
              maxLength: {
                value: 2000,
                message: 'Ce champ doit contenir au plus 255 caractères.',
              },
            })}
            style={{ minHeight: 100 }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StorePartnershipModal);
