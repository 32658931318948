import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import UpdatableCell from '@/components/common/table/UpdatableCell';
import AllDayFactorHeader from '@/components/table-headers/dayAndTimeFactor/AllDayFactorHeader';
import { INVALID_FORM_MESSAGE } from '@/constants/validatorMessages';
import IDayPriceController, {
  IDayPriceControllerForm,
} from '@/interfaces/DayPriceController';
import { IUserFilters } from '@/interfaces/User';
import {
  useGetDayPriceControllersQuery,
  useUpdateDayPriceControllersMutation,
} from '@/services/dayPriceControllers';
import { useUpdateTimePriceControllersMutation } from '@/services/timePriceControllers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { toast, ToastContentProps } from 'react-toastify';

const DayFactorTable: FC = () => {
  const [updateDayPriceController] = useUpdateDayPriceControllersMutation();
  const [state, setState] = useState<Partial<TableState> & IUserFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetDayPriceControllersQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const { control, handleSubmit, reset } = useForm<IDayPriceControllerForm>();

  const { append, fields } = useFieldArray({
    control,
    name: 'data',
  });

  const onUpdate = (id: string, _: string, factor: unknown) =>
    append({
      id,
      factor: factor as number,
    });

  const onSubmit: SubmitHandler<IDayPriceControllerForm> = async data => {
    try {
      await toast.promise(updateDayPriceController(data).unwrap, {
        pending: 'Modification de la colonne en cours...',
        success: {
          render() {
            reset();
            return <p>Le day factor à bien été modifié 🤩</p>;
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      });
    } catch (error) {
      toast.error('Une erreur est survenue');
    }
  };

  const onError: SubmitErrorHandler<IDayPriceControllerForm> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  const columns = useRef<ColumnDef<IDayPriceController>[]>([
    {
      header: 'Day',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ day }) => (
        <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          {day}
        </span>
      ),
      id: 'day',
      cell: info => info.getValue(),
    },
    {
      header: 'Factor',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ factor }) => factor.toFixed(2),
      id: 'factor',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Fragment>
      <Table
        HeaderComponent={AllDayFactorHeader}
        columns={columns}
        state={state}
        tableName="timeFactor"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
      {fields.length > 0 && (
        <Button className="mt-3" onClick={handleSubmit(onSubmit, onError)}>
          Save Changes
        </Button>
      )}
    </Fragment>
  );
};

export default DayFactorTable;
