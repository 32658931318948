import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import { UUID_REGEX } from '@/constants/regexs';
import ArticleHeader from '@/components/card-headers/articles/ArticleHeader';
import { IArticle } from '@/interfaces/Article';
import ArticleDetailsCard from '@/components/cards/articles/ArticleDetailsCard';

const ArticleView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<IArticle>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {article && <ArticleHeader id={id} />}
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <ArticleDetailsCard id={id} card callBack={setArticle} />
        </Col>
      </Row>
    </div>
  );
};

export default ArticleView;
