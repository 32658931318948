import { ILog } from "@/components/interfaces/Log";
import { createFormDataRequest } from "@/helpers/utils";
import { IPaginateResponse } from "@/interfaces/Fetch";
import { LogFilters } from "@/interfaces/Log";
import api from "@/services/api";

export const userLogsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserLogs: build.query<IPaginateResponse<ILog>, LogFilters>({
      query: (data) => {
        const params = createFormDataRequest(data);
        return {
          url: `/logs`,
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
            ...result.data.map(({ id }) => ({ type: "UserLogs" as const, id })),
            { type: "UserLogs", id: "LIST" },
          ]
          : [],
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserLogsQuery } = userLogsApi;
