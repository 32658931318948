import AuthCardLayout from "@/components/layouts/AuthCardLayout";
import { EMAIL_REGEX } from "@/constants/regexs";
import {
  INVALID_EMAIL_MESSAGE,
  INVALID_EMAIL_REQUIRED_MESSAGE,
  INVALID_FORM_MESSAGE,
} from "@/constants/validatorMessages";
import { IAskEmailOTP } from "@/interfaces/Auth";
import { useSendEmailOTPMutation } from "@/services/auth";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";
import { Button, Form } from "react-bootstrap";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastContentProps, toast } from "react-toastify";

const AuthView: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAskEmailOTP>();
  const [mutateAsync] = useSendEmailOTPMutation();
  const navigation = useNavigate();

  const onSubmit: SubmitHandler<IAskEmailOTP> = async (loginData) => {
    await toast.promise(mutateAsync(loginData).unwrap(), {
      pending: "Envoie du code à 4 chiffres en cours...",
      success: {
        render({ data }) {
          navigation(`/auth/login/${loginData.email}`);

          return (
            <p style={{ marginBottom: 0, textAlign: "center" }}>
              Le code à bien été envoyé
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 429) {
            return "Vous devez attendre au moins une minute avant de pouvoir renvoyer un code.";
          }

          return "Une erreur est survenue";
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IAskEmailOTP> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Vous n&apos;avez pas de compte ?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/auth/register"
          >
            Faire une demande
          </Link>
        </p>
      }
    >
      <h3>Connexion</h3>
      <Form onSubmit={handleSubmit(onSubmit, onError)} className="mt-4">
        <Form.Group className="mb-3">
          <Form.Label>
            Adresse e-mail <span className="text-danger">*</span> :
          </Form.Label>
          <Form.Control
            {...register("email", {
              required: INVALID_EMAIL_REQUIRED_MESSAGE,
              pattern: {
                value: EMAIL_REGEX,
                message: INVALID_EMAIL_MESSAGE,
              },
            })}
            isInvalid={!!errors.email}
            type="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" variant="primary" className="mt-3 w-100">
          Se connecter
        </Button>
      </Form>
    </AuthCardLayout>
  );
};

export default AuthView;
