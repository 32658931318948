import IOffer from '@/interfaces/Offer';
import Vehicle from '@/interfaces/Vehicle';

export const getOfferIdFromString = (offer: string, offers: IOffer[]) => {
  return offers.find(offer_ => offer_.name === offer)?.id;
};

export const getAllAvailableOffers = (offers: IOffer[]) => {
  const activeOffers = offers
    .filter(offer => offer.isActive)
    .map(offer => offer.name)
    .filter(offer => !!offer);
  return activeOffers;
};

export const getCorrespondingOffersFromVehicle = async (
  vehicle: Vehicle,
  offers: IOffer[]
) => {
  const valuesNeeded = ['eligibleForBerline', 'eligibleForVan'] as const;

  const availableVehicleOffers = await Promise.all(
    Object.entries(vehicle)
      .filter(
        ([key, value]) =>
          valuesNeeded.includes(key as (typeof valuesNeeded)[number]) && !!value
      )
      .map(async ([key]) => {
        const reelName = getReelNameVehicle(
          key as keyof Pick<Vehicle, 'eligibleForBerline' | 'eligibleForVan'>
        );
        const availableOffers = getAllAvailableOffers(offers);
        return (
          availableOffers.find(offer => offer.includes(reelName)) ?? 'COMIN'
        );
      })
  ).then(availableVehicleOffers => availableVehicleOffers.concat('COMIN'));

  const availableVehicleOffersIds = await Promise.all(
    availableVehicleOffers
      .filter(offer => !!offer)
      .map(async offer => await getOfferIdFromString(offer, offers))
  );
  return availableVehicleOffersIds.filter(offer => offer != undefined);
};

const getReelNameVehicle = (
  vehicle: keyof Pick<Vehicle, 'eligibleForBerline' | 'eligibleForVan'>
) => {
  switch (vehicle) {
    case 'eligibleForBerline':
      return 'Berline';
    case 'eligibleForVan':
      return 'Van';
    default:
      return 'COMIN';
  }
};
