import ErrorComponent from '@/components/common/ErrorComponent';
import IconButton from '@/components/common/IconButton';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import UpdateStatusDocumentDriverModal from '@/components/modals/driver/UpdateDocumentDriverModal';
import AllDocumentsHeader from '@/components/table-headers/documents/AllDocumentsHeader';
import { getReelTypeNameOfDocument } from '@/helpers/DriverDocumentsHelpers';
import { getColorFromDocumentsStatus } from '@/helpers/DriverVehiculeDocumentsHelpers';
import { splitText } from '@/helpers/utils';
import { DriverDocument } from '@/interfaces/Driver/Document';
import { IRideFilters } from '@/interfaces/Ride';
import {
  useDeleteDocumentMutation,
  useGetDocumentsQuery,
} from '@/services/drivers/documents';
import {
  faEdit,
  faEye,
  faToggleOn,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

type DriverDocumentsTableProps = {
  id: string;
};

const DriverDocumentsTable: FC<DriverDocumentsTableProps> = ({ id }) => {
  const [deleteDocumentDriverAsync] = useDeleteDocumentMutation();
  const [state, setState] = useState<Partial<TableState> & IRideFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });
  const ModalRef =
    useRef<ElementRef<typeof UpdateStatusDocumentDriverModal>>(null);
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const { data, isLoading, isError, error } = useGetDocumentsQuery([
    id,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const onDelete = (idDocument: string) => {
    toast.promise(deleteDocumentDriverAsync([id, idDocument]).unwrap(), {
      pending: 'Suppression du document en cours...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              Le document à bien été retiré du chauffeur 🚙🚙
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  const columns = useRef<ColumnDef<DriverDocument>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: doc => (
        <div className="gap-2 d-flex">
          <IconButton
            variant="nolina-default"
            size="sm"
            icon={faToggleOn}
            onClick={() => {
              ModalRef.current?.showOrHiddenModal(doc);
            }}
            transform="shrink-3"
            iconAlign="middle"
          />
          <IconButton
            variant="nolina-danger"
            size="sm"
            icon={faTrash}
            onClick={() => {
              DeleteModalRef.current?.showOrHiddenModal(doc.id);
            }}
            transform="shrink-3"
            iconAlign="middle"
          />
        </div>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Type',
      footer: props => props.column.id,
      accessorFn: ({ type }) => getReelTypeNameOfDocument(type),
      id: 'type',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ status }) => (
        <SoftBadge
          bg={getColorFromDocumentsStatus(status)}
          className={'text-xl-center'}
        >
          {status}
        </SoftBadge>
      ),
      id: 'status',
      cell: info => info.getValue(),
    },
    {
      header: 'Exp. Date',
      footer: props => props.column.id,
      accessorFn: ({ file }) =>
        DateTime.fromISO(file.linkExpiredAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'exp-dat',
      cell: info => info.getValue(),
    },
    {
      header: 'File',
      footer: props => props.column.id,
      accessorFn: ({ file }) => (
        <Link to={file.link} target="_blank">
          {splitText(file.link)}
        </Link>
      ),
      id: 'file verso',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'Created At',
      cell: info => info.getValue(),
    },
    {
      header: 'Updated At',
      footer: props => props.column.id,
      accessorFn: ({ updatedAt }) =>
        DateTime.fromISO(updatedAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'Updated At',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment retirer cet document ?"
      />
      <UpdateStatusDocumentDriverModal idDriver={id} ref={ModalRef} />
      <Table
        HeaderComponent={AllDocumentsHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default DriverDocumentsTable;
