import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC} from 'react';
import { useParams } from 'react-router-dom';
import UpdateDrivingPreferenceCard from '@/components/updates/drivingPreferences/UpdateDrivingPreferenceCard';
import DrivingPreferenceHeader from '@/components/card-headers/drivingPreferences/DrivingPreferenceHeader';

const DrivingPreferenceEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <DrivingPreferenceHeader id={id} isEdit />
      <UpdateDrivingPreferenceCard id={id} />
    </div>
  );
};

export default DrivingPreferenceEditView;
