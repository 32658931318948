import Flex from "@/components/common/Flex";
import IconButton from "@/components/common/IconButton";
import { IDefaultCardProps } from "@/components/interfaces/Card";
import {
  faArrowLeft,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import { Card as C } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
const Card: any = C

const EarnPointHeader: FC<IDefaultCardProps> = ({ id, isEdit}) => {
    const navigation = useNavigate()

      return (
        <Card className="mb-3">
            <Card.Header className="d-flex flex-between-center">
                <IconButton
                onClick={() => navigation(-1)}
                variant="nolina-default"
                size="sm"
                icon={faArrowLeft}
                />
                <Flex>
                    <div
                        className="bg-300 mx-3 d-xl-none"
                        style={{ width: "1px", height: "29px" }}
                    ></div>
                    {!isEdit && (
                    <IconButton
                    href={`/earnPoints/${id}/edit`}
                    variant="nolina-default"
                    size="sm"
                    icon={faEdit}
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                    >
                    <span className="d-none d-xl-inline-block ms-1">Modifier</span>
                    </IconButton>
                )}
                </Flex>
            </Card.Header>
        </Card>
      );
}
export default EarnPointHeader;