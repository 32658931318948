import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import Loader from '@/components/common/Loader';
import Select from '@/components/common/Select';
import CardHeader from '@/components/card-headers/CardHeader';
import {
  useUpdateEarnPointMutation,
  useGetEarnPointQuery,
} from '@/services/earnPoints';
import {
  EARNPOINT_TYPES,
  IEarnPointForm,
  USER_TYPES,
} from '@/interfaces/EarnPoint';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromIsArchived,
  getReelArchivedName,
} from '@/helpers/UserHelpers';

interface UpdateEarnPointCardProps {
  id: string;
}

const UpdateEarnPointCard: FC<UpdateEarnPointCardProps> = ({ id }) => {
  const { data, isLoading } = useGetEarnPointQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IEarnPointForm>>();

  const [editEarnPointMutation] = useUpdateEarnPointMutation();

  const navigation = useNavigate();

  const [showValueField, setShowValueField] = useState(false);
  const [showUserTypeField, setShowUserTypeField] = useState(false);
  const [criteriaTypeLabel, setCriteriaTypeLabel] = useState('Value');

  const [isArchived, setIsArchived] = useState<boolean>(
    data?.isArchived || false
  );

  const onSubmit: SubmitHandler<Partial<IEarnPointForm>> = ({
    userType,
    criteria,
    ...data
  }) => {
    toast.promise(
      editEarnPointMutation([
        id,
        {
          ...data,
          userType: userType?.value,
          criteria: {
            ...criteria,
            userType: criteria?.userType?.value,
            type: criteria?.type?.value!,
          },
        },
      ]).unwrap(),
      {
        pending: `Modification du earn points en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le earn points à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IEarnPointForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        userType: USER_TYPES.find(element => element.value === data.userType),
        criteria: {
          type: EARNPOINT_TYPES.find(
            element => element.value === data.earnPointCriteria?.type
          ),
          value: data.earnPointCriteria?.value,
          userType: USER_TYPES.find(
            element => element.value === data.earnPointCriteria?.userType
          ),
        },
      });
      const criteriaTypeValue = data.earnPointCriteria?.type || '';

      setShowValueField(
        criteriaTypeValue === 'referral' ||
          criteriaTypeValue === 'completed_rides'
      );
      setShowUserTypeField(criteriaTypeValue === 'referral');
      if (criteriaTypeValue === 'referral') {
        setCriteriaTypeLabel('Points for Referred');
      }
    }
  }, [data]);

  const handleArchiveToggle = async () => {
    try {
      setIsArchived(prevIsArchived => !prevIsArchived);
      await editEarnPointMutation([id, { isArchived: !isArchived }]);
      navigation(-1);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification du earn points" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Status :</Form.Label>
                <SoftBadge
                  bg={getColorFromIsArchived(data?.isArchived || false)}
                  className={'m-3 text-xl-center'}
                >
                  {getReelArchivedName(data?.isArchived || false)}
                </SoftBadge>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Get 10% discount"
                  type="text"
                  {...register('title', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  User Type <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="userType"
                  render={({ field }) => (
                    <Select
                      options={USER_TYPES}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userType?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Points for Referrer <span className="text-danger">*</span> :
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="ex : 10"
                    type="number"
                    {...register('points', {
                      required: 'Ce champ est requis.',
                      minLength: {
                        value: 2,
                        message:
                          'Le discount amount doit contenir au moins 2 caractères.',
                      },
                      maxLength: {
                        value: 255,
                        message:
                          'Le discount amount doit contenir au maximum 255 caractères.',
                      },
                    })}
                    isInvalid={!!errors.points}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.points?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Limit :</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="ex : 10"
                    type="number"
                    {...register('limit', {
                      minLength: {
                        value: 2,
                        message:
                          'Le Points doit contenir au moins 1 caractères.',
                      },
                      maxLength: {
                        value: 255,
                        message:
                          'Le Points doit contenir au maximum 255 caractères.',
                      },
                    })}
                    isInvalid={!!errors.limit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.limit?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Description Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Enjoy your reward"
                  type="text"
                  {...register('descriptionTitle', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.descriptionTitle}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.descriptionTitle?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('description', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>

            {/* EarnPointCriteriaSection */}
            <>
              <Col className="mb-3 fw-bold mt-3" lg={12}>
                Earn Points Criteria
              </Col>
            </>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Criteria type <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="criteria.type"
                  render={({ field }) => (
                    <Select
                      options={EARNPOINT_TYPES}
                      value={field.value}
                      onChange={value => {
                        field.onChange(value);
                        setShowValueField(
                          value?.value === 'referral' ||
                            value?.value === 'completed_rides'
                        );
                        setShowUserTypeField(value?.value === 'referral');
                        if (value?.value === 'referral') {
                          setCriteriaTypeLabel('Points for Referred');
                        } else {
                          setCriteriaTypeLabel('Value');
                        }
                      }}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.criteria?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {showValueField && (
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {criteriaTypeLabel} <span className="text-danger">*</span> :
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="ex : 5"
                      type="number"
                      {...register('criteria.value', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 1,
                          message:
                            'Le value doit contenir au moins 1 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Le value doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.criteria?.value}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.criteria?.value?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            )}

            {showUserTypeField && (
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    User Type <span className="text-danger">*</span> :
                  </Form.Label>
                  <Controller
                    control={control}
                    name="criteria.userType"
                    render={({ field }) => (
                      <Select
                        options={USER_TYPES}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.criteria?.userType?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

            {/* END OF EarnPointCriteriaSection */}
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3 me-2"
          >
            Confirmer
          </Button>
          <Button
            variant={isArchived ? 'info' : 'danger'}
            disabled={isLoading}
            onClick={handleArchiveToggle}
            className="mt-3"
          >
            {isArchived ? 'Unarchive' : 'Archive'}
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateEarnPointCard;
