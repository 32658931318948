import { IFilters, IPaginateResponse } from "@/interfaces/Fetch";
import api from "./api";
import { createFormDataRequest } from "@/helpers/utils";
import { IAskPhoneOTP, PhoneOTP } from "@/interfaces/PhoneOTP";

export const phoneOtpsApi = api.injectEndpoints({
    endpoints: build => ({
        getPhoneOtps: build.query<IPaginateResponse<PhoneOTP>, IFilters>({
            query: data => {
                const params = createFormDataRequest(data);
                return {
                    url: '/phoneOtps',
                    params,
                };
            },
            providesTags: (result, _error, _arg) =>
                result
                    ? [
                        ...result.data.map(({ id }) => ({
                            type: 'PhoneOtps' as const,
                            id,
                        })),
                        { type: 'PhoneOtps', id: 'LIST' },
                    ]
                    : [],
        }),
        createPhoneOtp: build.mutation<PhoneOTP, IAskPhoneOTP>({
            query: phone => ({
                url: '/phoneOtps',
                method: 'POST',
                body: { phone },
            }),
            invalidatesTags: [{ type: 'PhoneOtps', id: 'LIST' }],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetPhoneOtpsQuery,
    useCreatePhoneOtpMutation,
} = phoneOtpsApi;