import Loader from '@/components/common/Loader';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { ASSOCIATION_STATUSES_SELECT } from '@/helpers/AssociationHelper';
import {
  CompanyBusinessTypeSelect,
  CompanyCountrySelect,
  CompanyCurrencySelect,
  CompanyStatusSelect,
} from '@/helpers/CompanyHelper';
import getDifferenceBetweenTwoObjects from '@/helpers/FormHelpers';
import useLoadOptions from '@/hooks/useLoadOptions';
import { IAssociationForm } from '@/interfaces/Association';
import { CompanyFormUpdate } from '@/interfaces/Company';
import {
  useGetAssociationQuery,
  useUpdateAssociationMutation,
} from '@/services/associations';
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from '@/services/companies';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

interface UpdateAssociationCardProps {
  id: string;
}

const UpdateAssociationCard: FC<UpdateAssociationCardProps> = ({ id }) => {
  const { data, isLoading } = useGetAssociationQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IAssociationForm>>();

  const [updateAssociationMutation] = useUpdateAssociationMutation();
  const navigation = useNavigate();

  const onSubmit: SubmitHandler<Partial<IAssociationForm>> = ({
    ...newData
  }) => {
    toast.promise(
      updateAssociationMutation([
        id,
        {
          ...newData,
          status: newData.status?.value,
        },
      ]).unwrap(),
      {
        pending: `Modification de l'association en cours...`,
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`L'association à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IAssociationForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        status: ASSOCIATION_STATUSES_SELECT.find(
          sts => sts.value === data.status
        ),
        illustrationBanner: null,
        illustration: null,
      });
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Modifier l'association {data?.name}</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Association 1"
                  type="text"
                  {...register('name', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : La paix est véridique."
                  type="text"
                  {...register('title', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Illustration (Banner):</Form.Label>
                <Form.Control
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  {...register('illustrationBanner')}
                  isInvalid={!!errors.illustrationBanner}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.illustrationBanner?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Illustration du lien:</Form.Label>
                <Form.Control
                  accept=".jpeg, .jpg, .png"
                  type="file"
                  {...register('illustration')}
                  isInvalid={!!errors.illustration}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.illustration?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Description de mon association."
                  type="text"
                  as="textarea"
                  rows={5}
                  {...register('description', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 2000,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Donation goal <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 1000"
                  type="number"
                  {...register('donationGoal', {
                    required: 'Ce champ est requis.',
                    min: {
                      value: 0,
                      message: 'Ce champ doit contenir au minimum 0.',
                    },
                    max: {
                      value: 1000000,
                      message: 'Ce champ doit contenir au maximum 1000000.',
                    },
                  })}
                  isInvalid={!!errors.donationGoal}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.donationGoal?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Lien <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Lien de mon association."
                  type="text"
                  {...register('link', {
                    required: 'Ce champ est requis.',
                    validate: {
                      url: value => {
                        try {
                          new URL(value ?? '');
                          return true;
                        } catch {
                          return 'Ce champ doit contenir une url valide.';
                        }
                      },
                    },
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.link}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.link?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Category <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Categorie de mon association."
                  type="text"
                  {...register('category', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.category}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.category?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Status <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Select
                      options={ASSOCIATION_STATUSES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateAssociationCard;
