import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import IGovernance from '@/interfaces/Governance';
import { useGetGovernanceQuery } from '@/services/governances';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const GovernanceInfoCard: FC<ICardDetailsProps<IGovernance>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetGovernanceQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-2">
      <Col xs={12}>
        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <Col md={6} lg={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Governance Info</h5>
                <p className="mb-0 fs--1">
                  <strong>
                    Start at :{' '}
                    {DateTime.fromISO(data?.startAt).toFormat(
                      "dd LLL yyyy 'at' HH:mm"
                    )}
                  </strong>
                </p>
                <p className="mb-0 fs--1">
                  <strong>
                    End at :{' '}
                    {DateTime.fromISO(data?.endAt).toFormat(
                      "dd LLL yyyy 'at' HH:mm"
                    )}
                  </strong>
                </p>
              </Col>
              <Col md={6} lg={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Course Counts</h5>
                <h6 className="mb-2">
                  Driver Course Count : {data.driverCourseCount}
                </h6>
                <h6 className="mb-2">
                  Customer Course Count : {data.customerCourseCount}
                </h6>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default GovernanceInfoCard;
