import dayjs from "dayjs";
import { EChartsOption } from "echarts-for-react";
import { CallbackDataParams, TooltipOption } from "echarts/types/dist/shared";

/* Get Past Dates */
export const getPastDates = (duration: 'week' | 'month' | 'year') => {
    let days;

    switch (duration) {
        case 'week':
            days = 7;
            break;
        case 'month':
            days = 30;
            break;
        case 'year':
            days = 365;
            break;

        default:
            days = duration;
    }

    const date = new Date();
    const endDate = date;
    const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
    return getDates(startDate, endDate);
};

export const getDates = (
    startDate: Date,
    endDate: Date,
    interval = 1000 * 60 * 60 * 24
) => {
    const duration = endDate.getTime() - startDate.getTime();
    const steps = duration / interval;
    return Array.from(
        { length: steps + 1 },
        (v, i) => new Date(startDate.valueOf() + interval * i)
    );
};

const tooltipFormatter: (
    params: (CallbackDataParams & { axisValue: string })[]
  ) => string = params => {
    return `<div class="card">
                <div class="card-header bg-light py-2">
          <h6 class="text-600 mb-0">${params[0]?.axisValue}</h6>
                </div>
              <div class="card-body py-2">
                <h6 class="text-600 fw-normal">
                  <span class="dot me-1 d-inline-block bg-primary"></span>
                  Revenue: 
                  <span class="fw-medium">$${params[0].data}</span>
                </h6>
                <h6 class="text-600 mb-0 fw-normal"> 
                  <span class="dot me-1 d-inline-block bg-warning"></span>
                  Revenue Goal: 
                  <span class="fw-medium">$${params[1].data}</span>
                </h6>
              </div>
            </div>`;
  };

export const getOptions: (
    data1: number[],
    data2: number[]
  ) => Omit<EChartsOption, 'tooltip'> & {
    tooltip: {
      formatter: (
        params: (CallbackDataParams & { axisValue: string })[]
      ) => string;
    } & Omit<TooltipOption, 'formatter'>;
  } = (data1, data2) => ({
    color: '#09b6f6',
    tooltip: {
      trigger: 'axis',
      padding: 0,
      backgroundColor: 'transparent',
      borderWidth: 0,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
      formatter: tooltipFormatter,
    },
    xAxis: {
      type: 'category',
      data: getPastDates('week').map(date => dayjs(date).format('DD MMM, YYYY')),
      axisLabel: {
        color: '#000000',
        formatter: (value: string) => dayjs(value).format('MMM DD YYYY'),
        align: 'center',
        fontSize: 11,
  
        showMaxLabel: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      boundaryGap: true,
    },
    yAxis: {
      position: 'right',
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        name: 'Revenue',
        data: data1,
        lineStyle: {
          color: '#f2f2f2',
        },
        itemStyle: {
          borderRadius: [8, 8, 0, 0],
          borderColor: '#55e7ff',
          borderWidth: 1,
        },
        emphasis: {
          itemStyle: {
            color: '#55e7ff',
          },
        },
      },
      {
        type: 'line',
        name: 'Revenue Goal',
        data: data2,
        symbol: 'circle',
        symbolSize: 6,
        animation: false,
        itemStyle: {
          color: '#233e57',
        },
        lineStyle: {
          type: 'dashed',
          width: 2,
          color: '#233e57',
        },
      },
    ],
    grid: { right: 5, left: 5, bottom: '8%', top: '5%' },
  });