import VehicleHeader from "@/components/card-headers/vehicles/VehicleHeader";
import ErrorComponent from "@/components/common/ErrorComponent";
import UpdateVehicleCard from "@/components/updates/UpdateVehicleCard";
import { UUID_REGEX } from "@/constants/regexs";
import { FC } from "react";
import { useParams } from "react-router-dom";

const VehicleEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <VehicleHeader id={id} isEdit />
      <UpdateVehicleCard id={id} />
    </div>
  );
};

export default VehicleEditView;
