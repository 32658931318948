import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import AllCouponsHeader from '@/components/table-headers/coupons/AllCouponsHeader';
import ICoupon from '@/interfaces/Coupon';
import { IFilters } from '@/interfaces/Fetch';
import {
  useGetCouponsQuery,
  useUpdateCouponMutation,
} from '@/services/coupons';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { truncate } from '@/helpers/utils';
import classNames from 'classnames';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromIsArchived,
  getReelArchivedName,
} from '@/helpers/UserHelpers';

const CouponsView: FC = () => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetCouponsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [updateCoupon, { isLoading: isUpdateCouponLoading }] =
    useUpdateCouponMutation();

  const handleArchiveToggle = async (couponId: string, isArchived: boolean) => {
    try {
      await updateCoupon([couponId, { isArchived: !isArchived }] as [
        string,
        Omit<Partial<ICoupon>, 'userId'>
      ]);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  const columns = useRef<ColumnDef<ICoupon>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id, isArchived }) => (
        <>
          <Link to={`/coupons/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link to={`/coupons/${id}/edit`} className="btn btn-info btn-sm ms-2">
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant={isArchived ? 'danger' : 'success'}
            size="sm"
            className="ms-2"
            onClick={() => handleArchiveToggle(id, isArchived)}
            disabled={isUpdateCouponLoading}
          >
            <FontAwesomeIcon icon={faArchive} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Name',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ name, id }) => (
        <Link to={`/coupons/${id}`}>
          <p className="mb-0">{name}</p>
        </Link>
      ),
      id: 'name',
      cell: info => info.getValue(),
    },
    {
      header: 'Code',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ code }) => <h6>{code}</h6>,
      id: 'code',
      cell: info => info.getValue(),
    },
    {
      header: 'Start At\nEnd At',
      footer: props => props.column.id,
      accessorFn: ({ startAt, endAt }) => (
        <>
          {DateTime.fromISO(startAt).toFormat("dd/MM/yyyy 'at' HH:mm")}
          <p className="mb-0 text-500">
            {DateTime.fromISO(endAt).toFormat("dd/MM/yyyy 'at' HH:mm")}
          </p>
        </>
      ),
      id: 'startAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ isArchived }) => (
        <SoftBadge
          bg={getColorFromIsArchived(isArchived)}
          className={'my-2 text-xl-center'}
        >
          {getReelArchivedName(isArchived)}
        </SoftBadge>
      ),
      id: 'archived',
      cell: info => info.getValue(),
    },
    {
      header: 'Discount Amount',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ discountAmount, discountType }) =>
        `${discountAmount}${discountType === 'fixed' ? '€' : '%'}`,
      id: 'discountAmount',
      cell: info => info.getValue(),
    },
    {
      header: 'Max Discount Amount(€)',
      footer: props => props.column.id,
      accessorFn: ({ maxDiscountAmount }) => `${maxDiscountAmount}€`,
      id: 'maxDiscountAmount',
      cell: info => info.getValue(),
    },
    {
      header: 'Min Purchase Amount(€)',
      footer: props => props.column.id,
      accessorFn: ({ minPurchaseAmount }) => `${minPurchaseAmount}€`,
      id: 'minPurchaseAmount',
      cell: info => info.getValue(),
    },
    {
      header: 'Limit Per User',
      footer: props => props.column.id,
      accessorFn: ({ limitPerUser }) => limitPerUser,
      id: 'limitPerUser',
      cell: info => info.getValue(),
    },
    {
      header: 'Total Limit',
      footer: props => props.column.id,
      accessorFn: ({ totalLimit }) => totalLimit,
      id: 'totalLimit',
      cell: info => info.getValue(),
    },
    {
      header: 'Min Ride Completed',
      footer: props => props.column.id,
      accessorFn: ({ minRideCompleted }) => minRideCompleted,
      id: 'minRideCompleted',
      cell: info => info.getValue(),
    },
    {
      header: '# of Uses',
      footer: props => props.column.id,
      accessorFn: ({ numberOfUses }) => numberOfUses,
      id: 'numberOfUses',
      cell: info => info.getValue(),
    },
    {
      header: '# of Adds',
      footer: props => props.column.id,
      accessorFn: ({ numberOfAdds }) => numberOfAdds,
      id: 'numberOfAdds',
      cell: info => info.getValue(),
    },
    {
      header: 'Excluded Offers',
      enableSorting: false,
      accessorFn: ({ excludeOffers }) => (
        <div>
          {excludeOffers?.map((offer, index) => (
            <span
              key={offer.id}
              className={classNames(
                'badge border link-secondary text-decoration-none',
                {
                  'ms-1': index !== 0,
                }
              )}
            >
              {offer.name}
            </span>
          ))}
        </div>
      ),
      id: 'excludeOffers',
      cell: info => info.getValue(),
    },
    {
      enableSorting: false,
      header: 'Description',
      footer: props => props.column.id,
      accessorFn: ({ description }) => {
        truncate(description ? description : '', 100);
      },
      id: 'description',
      cell: info => info.getValue(),
    },
    {
      header: 'Coupon ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <Link to={`/coupons/${id}`}>{truncate(id ? id : '', 10)}</Link>
      ),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <Table
        HeaderComponent={AllCouponsHeader}
        columns={columns}
        state={state}
        tableName="coupons"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default CouponsView;
