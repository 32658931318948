import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import Loader from '@/components/common/Loader';
import Select from '@/components/common/Select';
import CardHeader from '@/components/card-headers/CardHeader';
import {
  useGetArticleQuery,
  useUpdateArticleMutation,
} from '@/services/articles';
import { IArticleForm } from '@/interfaces/Article';
import { USER_TYPES } from '@/interfaces/EarnPoint';
import classNames from 'classnames';
import { UserTypeSelect } from '@/helpers/UserHelpers';
import Editor from '@/components/common/Editor';

interface UpdateArticleCardProps {
  id: string;
}

const UpdateArticleCard: FC<UpdateArticleCardProps> = ({ id }) => {
  const { data, isLoading } = useGetArticleQuery(id);
  const {
    control,
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IArticleForm>>();

  const [editArticleMutation] = useUpdateArticleMutation();

  const navigation = useNavigate();

  const onSubmit: SubmitHandler<Partial<IArticleForm>> = ({
    userType,
    ...data
  }) => {
    toast.promise(
      editArticleMutation([
        id,
        {
          ...data,
          userType: userType?.value,
          content: JSON.stringify(data.content),
        },
      ]).unwrap(),
      {
        pending: `Modification du article en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`L'article à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IArticleForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        userType: USER_TYPES.find(element => element.value === data.userType),
        illustration: undefined,
      });
    }
  }, [data]);

  const articleId = getValues('id');

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification du article" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Mon titre"
                  type="text"
                  {...register('title', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Link <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : www.wearecomin.com"
                  type="text"
                  {...register('link', {
                    required: 'Ce champ est requis..',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères',
                    },
                  })}
                  isInvalid={!!errors.link}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.link?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Illustration <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="file"
                  {...register('illustration', {
                    validate: {
                      isFile: value => {
                        const files = value as unknown as FileList;
                        if (!!articleId) return true;
                        return (
                          !!files.length &&
                          (files[0].type === 'image/jpeg' ||
                            files[0].type === 'image/png')
                        );
                      },
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.illustration?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Type d'utilisateur :</Form.Label>
                <Controller
                  control={control}
                  name="userType"
                  render={({ field }) => (
                    <Select
                      options={UserTypeSelect}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': !!errors.userType,
                  })}
                >
                  {errors.userType?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {articleId && (
              <Form.Group className="mb-3">
                <Form.Label>
                  Contenu <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: 'Contenu requis',
                  }}
                  render={({ field }) => (
                    <Editor
                      field={field as FieldValues['content']}
                      articleId={articleId}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': errors.content,
                  })}
                >
                  {errors.content?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateArticleCard;
