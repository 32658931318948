import { createFormDataRequest } from "@/helpers/utils";
import { IPaginateResponse } from "@/interfaces/Fetch";
import UserCoupon, { UserCouponFilters } from "@/interfaces/User/Coupon";
import api from "@/services/api";

export const userCouponsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserCoupons: build.query<
      IPaginateResponse<UserCoupon>,
      UserCouponFilters
    >({
      query: ({ userId, ...data }) => {
        const params = createFormDataRequest(data);
        return {
          url: `/users/${userId}/coupons`,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserCouponsQuery } = userCouponsApi;
