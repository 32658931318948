import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../../helpers/utils';
import api from '../api';
import IPartnership, {
  IPartnershipForm,
} from '@/interfaces/Partnership/Partnership';

export const partnershipsApi = api.injectEndpoints({
  endpoints: build => ({
    getPartnerships: build.query<IPaginateResponse<IPartnership>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/partnerships',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Partnerships' as const,
                id,
              })),
              { type: 'Partnerships', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getPartnership: build.query<IPartnership, string>({
      query: id => `/partnerships/${id}`,
      providesTags: (partnership, _err) => [
        { type: 'Partnerships', id: 'LIST' },
      ],
    }),
    storePartnership: build.mutation<IPartnership, IPartnershipForm>({
      query: data => ({
        url: '/partnerships',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Partnerships', id: 'LIST' }],
    }),
    updatePartnership: build.mutation<
      IPartnership,
      [string, Partial<IPartnershipForm>]
    >({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/partnerships/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Partnerships', id: 'LIST' }],
    }),
    destroyPartnership: build.mutation<void, string>({
      query: id => ({
        url: `/partnerships/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Partnerships', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPartnershipQuery,
  useGetPartnershipsQuery,
  useStorePartnershipMutation,
  useUpdatePartnershipMutation,
  useDestroyPartnershipMutation,
  useLazyGetPartnershipsQuery,
} = partnershipsApi;
