import React from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSpring, animated } from '@react-spring/web';
import { Card } from 'react-bootstrap';
import Flex from '../../Flex';

function LegendMapCard() {
  const [pressed, setPressed] = React.useState(false);
  const springs = useSpring({
    y: pressed ? 0 : 100,
    opacity: pressed ? 1 : 0,
    delay: 100,
  });

  return (
    <Flex className="position-absolute bottom-0 end-0 m-2 w-100 px-2 align-items-end">
      <Card
        as={animated.div}
        className="p-2 px-4 w-100"
        style={
          {
            marginLeft: 22,
            marginRight: 10,
            ...springs,
          } as unknown as React.CSSProperties
        }
      >
        <div className="d-flex">
          <aside className="flex-1 flex-column py-1 gap-1 justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <span
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: '#7fff00',
                  transform: 'rotateZ(45deg)',
                }}
                className="me-2"
              />
              <h6 className="text-black m-0">Driver online</h6>
            </div>
            <div className="d-flex align-items-center">
              <span
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: '#d2691e',
                  transform: 'rotateZ(45deg)',
                }}
                className="me-2"
              />
              <h6 className="text-black m-0">Driver dropoff</h6>
            </div>
            <div className="d-flex align-items-center">
              <span
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: '#6495ed',
                  transform: 'rotateZ(45deg)',
                }}
                className="me-2"
              />
              <h6 className="text-black m-0">Driver pickup</h6>
            </div>
          </aside>
          <aside className="flex-1 flex-column py-1 justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <span
                style={{
                  width: 15,
                  height: 15,
                  backgroundColor: '#dc143c',
                  borderRadius: '100%',
                }}
                className="me-2"
              />
              <h6 className="text-black m-0">User online</h6>
            </div>
            <div className="d-flex align-items-center">
              <span
                style={{
                  width: 15,
                  height: 15,
                  backgroundColor: '#a9a9a9',
                  borderRadius: '100%',
                }}
                className="me-2"
              />
              <h6 className="text-black m-0">User requesting</h6>
            </div>
            <div className="d-flex align-items-center">
              <span
                style={{
                  width: 15,
                  height: 15,
                  backgroundColor: '#8b008b',
                  borderRadius: '100%',
                }}
                className="me-2"
              />
              <h6 className="text-black m-0">User riding</h6>
            </div>
          </aside>
        </div>
      </Card>
      <div className="flex-1 flex self-end">
        <button
          style={{
            width: 30,
            height: 30,
          }}
          onClick={() => setPressed(!pressed)}
          className=" bg-white rounded-circle border-0 d-flex align-items-center justify-content-center cursor-pointer hover:bg-primary-100"
        >
          <FontAwesomeIcon icon={faInfo} className="text-primary" />
        </button>
      </div>
    </Flex>
  );
}

export default LegendMapCard;
