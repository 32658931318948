import { createFormDataRequest } from '@/helpers/utils';
import {
  DriverDocument,
  DriverDocumentFilters,
} from '@/interfaces/Driver/Document';
import DriverVehicle, {
  DriverVehicleFormSend,
  DriverVehicleFormSendCSV,
  DriverVehicleFormUpdateSend,
} from '@/interfaces/DriverVehicle';
import { IPaginateResponse } from '@/interfaces/Fetch';
import api from '@/services/api';

export const driverVehiclesApi = api.injectEndpoints({
  endpoints: build => ({
    getDriverVehicles: build.query<IPaginateResponse<DriverVehicle>, string>({
      query: id => {
        return {
          url: `/drivers/${id}/vehicles`,
        };
      },
      providesTags: (result, _error, id) => [{ type: 'DriverVehicles', id }],
    }),
    addDriverVehicle: build.mutation<
      DriverVehicle,
      [string, Partial<DriverVehicleFormSend>]
    >({
      query: ([id, body]) => {
        return {
          url: `/drivers/${id}/vehicles`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, _error, [id]) => [
        { type: 'DriverVehicles', id },
      ],
    }),
    storeDriverVehiclesForCSV: build.mutation<
      DriverVehicle,
      [string, DriverVehicleFormSendCSV]
    >({
      query: ([driverId, body]) => ({
        url: `/drivers/${driverId}/vehicles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _err, [driverId]) => [
        { type: 'DriverVehicles', id: 'LIST' },
        { type: 'Drivers', id: driverId },
      ],
    }),
    getDriverVehicle: build.query<DriverVehicle, [string, string]>({
      query: ([id, idVehicule]) => {
        return {
          url: `/drivers/${id}/vehicles/${idVehicule}`,
        };
      },
      providesTags: (result, _error, [id, idVehicule]) => [
        { type: 'DriverVehicles', id },
      ],
    }),

    destroyDriverVehicle: build.mutation<void, [string, string]>({
      query: ([id, idVehicule]) => {
        return {
          url: `/drivers/${id}/vehicles/${idVehicule}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, _error, [id, idVehicule]) => [
        { type: 'DriverVehicles', id },
      ],
    }),

    getDriverVehicleDocuments: build.query<
      IPaginateResponse<DriverDocument>,
      DriverDocumentFilters
    >({
      query: ({ driverId, vehicleId, ...data }) => {
        const params = createFormDataRequest(data);
        return {
          url: `/drivers/${driverId}/vehicles/${vehicleId}/documents`,
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'DriversVehiclesDocuments' as const,
                id,
              })),
              { type: 'DriversVehiclesDocuments', id: 'LIST', ...filters },
            ]
          : [],
    }),
    storeDriverVehicleDocument: build.mutation<
      DriverDocument,
      [string, string, Partial<DriverDocument>]
    >({
      query: ([driverId, vehicleId, params]) => {
        const body = createFormDataRequest(params);
        return {
          url: `/drivers/${driverId}/vehicles/${vehicleId}/documents`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, _error, [driverId, vehicleId]) => [
        { type: 'DriversVehiclesDocuments', id: 'LIST' },
        { type: 'DriversVehiclesDocuments', id: `${driverId}-${vehicleId}` },
      ],
    }),
    updateDriverVehicle: build.mutation<
      DriverVehicleFormUpdateSend,
      [string, string, Partial<DriverVehicleFormUpdateSend>]
    >({
      query: ([driverId, vehicleId, body]) => ({
        url: `/drivers/${driverId}/vehicles/${vehicleId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, _error, [driverId, vehicleId]) => [
        { type: 'DriverVehicles', id: driverId },
        { type: 'DriversVehiclesDocuments', id: 'LIST' },
        { type: 'DriversVehiclesDocuments', id: `${driverId}-${vehicleId}` },
      ],
    }),
    updateDriverVehicleDocument: build.mutation<
      DriverDocument,
      [string, string, string, Partial<DriverDocument>]
    >({
      query: ([driverId, documentId, vehicleId, body]) => ({
        url: `/drivers/${driverId}/vehicles/${vehicleId}/documents/${documentId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, _error, [driverId, vehicleId]) => [
        { type: 'DriversVehiclesDocuments', id: 'LIST' },
        { type: 'DriverVehicles', driverId },
        { type: 'DriversVehiclesDocuments', id: `${driverId}-${vehicleId}` },
      ],
    }),
    deleteDriverVehicleDocument: build.mutation<void, [string, string, string]>(
      {
        query: ([driverId, documentId, vehicleId]) => ({
          url: `/drivers/${driverId}/vehicles/${vehicleId}/documents/${documentId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, _error, [driverId, vehicleId]) => [
          { type: 'DriversVehiclesDocuments', id: 'LIST' },
          { type: 'DriversVehiclesDocuments', id: `${driverId}-${vehicleId}` },
        ],
      }
    ),
    removeDriverVehiclesForCSV: build.mutation<void, [string, string]>({
      query: ([driverId, driverVehicleId]) => ({
        url: `/drivers/${driverId}/vehicles/${driverVehicleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _err, [driverId]) => [
        { type: 'DriverVehicles', id: 'LIST' },
        { type: 'Drivers', id: driverId },
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDriverVehiclesQuery,
  useGetDriverVehicleQuery,
  useDestroyDriverVehicleMutation,
  useGetDriverVehicleDocumentsQuery,
  useAddDriverVehicleMutation,
  useStoreDriverVehicleDocumentMutation,
  useStoreDriverVehiclesForCSVMutation,
  useDeleteDriverVehicleDocumentMutation,
  useUpdateDriverVehicleDocumentMutation,
  useUpdateDriverVehicleMutation,
  useRemoveDriverVehiclesForCSVMutation,
  useLazyGetDriverVehiclesQuery,
} = driverVehiclesApi;
