import PaginateCard from '@/components/cards/PaginateCard';
import Coupon from '@/components/common/Coupon';
import { UserCouponFilters } from '@/interfaces/User/Coupon';
import { useGetUserCouponsQuery } from '@/services/users/coupons';
import { FC, useState } from 'react';

const UserCouponsCard: FC<{ id: string }> = ({ id }) => {
  const [filters, setFilters] = useState<UserCouponFilters>({
    pageIndex: 0,
    pageSize: 3,
    userId: id,
  });

  const { data, isLoading, error } = useGetUserCouponsQuery(filters);

  return (
    <PaginateCard
      filters={filters}
      setFilters={setFilters}
      isLoading={isLoading}
      error={error}
      card
      meta={data?.meta}
      title="User Coupons"
    >
      {data?.data.map(coupon => (
        <Coupon key={coupon.id} {...coupon} />
      ))}
    </PaginateCard>
  );
};

export default UserCouponsCard;
