import routes from '@/constants/routes';
import { capitalize } from '@/helpers/utils';
import { IRoute, RouteChildren } from '@/interfaces/Route';
import { selectCurrentUser } from '@/services/slices/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC, Fragment, useCallback, useContext, useState } from 'react';
import { Col, Collapse, Nav, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Flex from '../common/Flex';
import SoftBadge from '../common/SoftBadge';
import { TemplateContext } from '@/hooks/useTemplate';
import { checkIfUserHasRoles } from '@/helpers/UserHelpers';

const CollapseItems: FC<{ route: RouteChildren }> = ({ route }) => {
  const { pathname } = useLocation();

  const openCollapse = (childrens: RouteChildren[]) => {
    const checkLink = (children: RouteChildren) => {
      if (children.href === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children?.some(checkLink)
      );
    };

    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children!));

  const onClick = () => setOpen(old => !old);

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={onClick}
        className={`dropdown-indicator cursor-pointer`}
        aria-expanded={open}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      {route.children && (
        <Collapse in={open}>
          <Nav className="flex-column nav" as="ul">
            <NavbarVerticalChildren routes={route.children} />
          </Nav>
        </Collapse>
      )}
    </Nav.Item>
  );
};

const NavbarLabel: FC<{ label: string }> = ({ label }) => {
  return (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {capitalize(label)}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );
};

const NavbarVerticalMenuItem: FC<{
  route: RouteChildren;
}> = ({ route }) => {
  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.badge && (
        <SoftBadge bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

const NavbarVerticalChildren: FC<{ routes: RouteChildren[] }> = ({
  routes,
}) => {
  const user = useSelector(selectCurrentUser);
  const { pathname } = useLocation();
  const { disabledBurgerMenu } = useContext(TemplateContext);

  const onClick = () => disabledBurgerMenu();

  const ReturnChildren = useCallback(
    ({ route }: { route: RouteChildren }) => {
      if (!route.children) {
        return (
          <Nav.Item as="li" key={route.name}>
            <Nav.Link
              as={Link}
              to={route.href}
              target={route.target}
              onClick={onClick}
              disabled={route.disabled}
              className={classNames('nav-link', {
                active: pathname === route.href && route.href !== '#!',
                'fw-lighter': route.disabled,
              })}
            >
              <NavbarVerticalMenuItem route={route} />
            </Nav.Link>
          </Nav.Item>
        );
      }
      return <CollapseItems route={route} />;
    },
    [pathname]
  );

  return (
    <>
      {routes.map(route => {
        return route.roles ? (
          user && checkIfUserHasRoles(user, route.roles) ? (
            <ReturnChildren route={route} key={route.name} />
          ) : null
        ) : (
          <ReturnChildren route={route} key={route.name} />
        );
      })}
    </>
  );
};

const NavbarVerticalMenu: FC = () => {
  const user = useSelector(selectCurrentUser);

  const ReturnMenu = useCallback(
    ({ route }: { route: IRoute }) => (
      <>
        {!route.labelDisable && <NavbarLabel label={route.label} />}
        <NavbarVerticalChildren routes={route.children} />
      </>
    ),
    []
  );

  return (
    <div
      className="navbar-vertical-menu-container"
      style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
    >
      <Nav className="flex-column" as="ul" style={{ overflowY: 'scroll' }}>
        {routes.map(route => (
          <Fragment key={route.label}>
            {route.roles ? (
              user && checkIfUserHasRoles(user, route.roles) ? (
                <ReturnMenu route={route} />
              ) : null
            ) : (
              <ReturnMenu route={route} />
            )}
          </Fragment>
        ))}
      </Nav>
    </div>
  );
};

export default NavbarVerticalMenu;
