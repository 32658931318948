import StatisticsCard from '@/components/common/StatisticsCard';
import StoreDriverPointTransactionModal from '@/components/modals/driver/pointsTransactions/StoreDriverPointTransactionModal';
import StoreUserPointTransactionModal from '@/components/modals/user/pointsTransactions/StoreUserPointTransactionModal';
import Driver from '@/interfaces/Driver';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import React, { ElementRef, FC, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

type DriverStatisticCardsProps = {
  driver: Driver;
};

const DriverStatisticCards: FC<DriverStatisticCardsProps> = ({ driver }) => {
  const userConfig = useSelector(selectCurrentUserConfig);

  const StoreUserPointTransactionModalModalRef =
    useRef<ElementRef<typeof StoreUserPointTransactionModal>>(null);
  const openCreateModal = () =>
    StoreUserPointTransactionModalModalRef.current?.showOrHiddenModal();
  return (
    <>
      {userConfig.isAdminOrSuperAdmin && (
        <StoreDriverPointTransactionModal
          id={driver.id}
          ref={StoreUserPointTransactionModalModalRef}
        />
      )}
      <Row className="g-2">
        <Col md={6} sm={4} xl={4}>
          <StatisticsCard
            title="Points"
            value={driver.points}
            prefix="⚡"
            badgeBg="success"
            linkText="Add transaction"
            onClick={openCreateModal}
          />
        </Col>
        <Col md={6} sm={4} xl={4}>
          <StatisticsCard
            title="Gov. Votes"
            //TODO: change this value
            // data!.governanceVotesCount
            value={0}
            prefix="🗳️ "
            badgeBg="warning"
            linkText="See all"
            link={`/users/${driver.id}/points`}
          />
        </Col>
        <Col md={6} sm={4} xl={4}>
          <StatisticsCard
            title="Rides"
            value={driver.completedRidesCount}
            prefix="🚗 "
            badgeBg="info"
            linkText="All rides"
            link={`/users/${driver.id}/points`}
          />
        </Col>
      </Row>
    </>
  );
};

export default DriverStatisticCards;
