import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import AllDriversClubHeader from '@/components/table-headers/driversClub/AllDriversClubHeader';
import { DriverClub } from '@/interfaces/DriverClub';
import { IFilters } from '@/interfaces/Fetch';
import { useGetDriverClubsQuery } from '@/services/driverClubs';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const DriverClubsView: FC = () => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetDriverClubsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const columns = useRef<ColumnDef<DriverClub>[]>([
    {
      header: '#',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ number }) => number,
      id: 'number',
      cell: info => info.getValue(),
    },
    {
      header: 'First Name\nLast Name',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ driver, driverId }) => (
        <Link to={`/drivers/${driverId}`}>
          <Flex alignItems="center">
            {driver.avatar && (
              <Avatar
                src={driver.avatar.link}
                alt={`${driver.firstname} Avatar`}
                className="me-2"
                size="2xl"
              />
            )}
            <p className="mb-0">
              <span>{driver.firstname}</span>
              <br />
              <span>{driver.lastname}</span>
            </p>
          </Flex>
        </Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      header: 'Total Referrals',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ totalReferrals }) => totalReferrals,
      id: 'totalReferrals',
      cell: info => info.getValue(),
    },
    {
      header: 'Total Referrals\nCompleted Rides',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ totalCompletedRidesReferredUser }) =>
        totalCompletedRidesReferredUser,
      id: 'totalCompletedRidesReferredUser',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }
  return (
    <div>
      <Table
        HeaderComponent={AllDriversClubHeader}
        columns={columns}
        state={state}
        tableName="driverClubs"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default DriverClubsView;
