import { Card, Nav, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import Loader from '@/components/common/Loader';
import ErrorComponent from '@/components/common/ErrorComponent';
import { useGetDriverVehiclesQuery } from '@/services/drivers/vehicles';
import DriverVehicleInfoCard from './vehicles/DriverVehicleInfoCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SoftBadge from '@/components/common/SoftBadge';
import IconButton from '@/components/common/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import { ElementRef, useRef } from 'react';
import AddVehicleToDriverModal from '@/components/modals/driver/vehicle/AddVehicleToDriverModal';

type DriverVehicleCardProps = {
  id: string;
};

const DriverVehiclesCard: React.FC<DriverVehicleCardProps> = ({ id }) => {
  const { data, isLoading, isError, error } = useGetDriverVehiclesQuery(id);
  const userConfig = useSelector(selectCurrentUserConfig);
  const AddVehicleToDriverModalRef =
    useRef<ElementRef<typeof AddVehicleToDriverModal>>(null);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  const openCreateModal = () => {
    AddVehicleToDriverModalRef.current?.showOrHiddenModal();
  };

  return (
    <Card>
      {userConfig.isAdminOrSuperAdmin && (
        <AddVehicleToDriverModal
          idDriver={id!}
          ref={AddVehicleToDriverModalRef}
        />
      )}
      {data.data.length === 0 ? (
        <Card.Body className="text-center gap-2 d-flex align-items-center justify-content-between">
          <h5 className="mb-0">Aucun véhicule</h5>
          <IconButton
            variant="nolina-default"
            size="sm"
            icon={faPlus}
            onClick={openCreateModal}
            transform="shrink-3"
            iconAlign="middle"
          >
            <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
              Ajouter un nouveau
            </span>
          </IconButton>
        </Card.Body>
      ) : (
        <Tab.Container defaultActiveKey={data.data[0].id}>
          <SimpleBar>
            <Card.Header className="py-0 border-bottom d-flex align-items-center justify-content-between">
              <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                {data.data
                  .slice()
                  .sort((a, b) => Number(b.default) - Number(a.default))
                  .map((item, i) => (
                    <Nav.Item key={item.id}>
                      <Nav.Link
                        eventKey={item.id}
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <h6 className="text-600 mb-0 ms-1">
                          {`${item.vehicle.brand} ${item.licensePlate}  `}
                          {item.default && (
                            <SoftBadge bg="success" className="mr-3">
                              <FontAwesomeIcon
                                icon={faCheck}
                                transform="shrink-3 up-1"
                              />
                            </SoftBadge>
                          )}
                        </h6>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faPlus}
                onClick={openCreateModal}
                transform="shrink-3"
                iconAlign="middle"
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  Nouveau
                </span>
              </IconButton>
            </Card.Header>
          </SimpleBar>

          <Card.Body className="bg-light">
            <Tab.Content>
              {data.data.map((item, i) => (
                <Tab.Pane key={item.id} eventKey={item.id}>
                  <DriverVehicleInfoCard driverId={id} idVehicle={item.id} />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      )}
    </Card>
  );
};

export default DriverVehiclesCard;
