import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import CircleChart from '@/components/common/chart/CircleChart';
import { ICardDetailsProps } from '@/interfaces/Card';
import IGovernance from '@/interfaces/Governance';
import IVote from '@/interfaces/Governance/Vote';
import { useGetGovernanceVotesQuery } from '@/services/governances/votes';
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faCircle,
  faClock,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';

const GovernanceVotesChartCard: FC<ICardDetailsProps<IGovernance>> = ({
  id,
}) => {
  const {
    data: votesData,
    isLoading,
    error,
    isError,
  } = useGetGovernanceVotesQuery([id, {}]);

  const [selectedTimeRange, setSelectedTimeRange] = useState('Last 7 days');

  useEffect(() => {
    if (!votesData || !votesData.data) return;

    console.log('Original votes data:', votesData.data);

    const filteredVotes = votesData.data.filter((vote: IVote) => {
      const voteDate = new Date(vote.createdAt);
      const currentDate = new Date();

      switch (selectedTimeRange) {
        case 'Last 7 days':
          console.log('Filtering by Last 7 days');
          return (
            currentDate.getTime() - voteDate.getTime() <=
            7 * 24 * 60 * 60 * 1000
          );

        case 'Last Month':
          console.log('Filtering by Last Month');
          return currentDate.getMonth() === voteDate.getMonth();

        case 'Last Year':
          console.log('Filtering by Last Year');
          return currentDate.getFullYear() === voteDate.getFullYear();

        default:
          console.log('No valid selection, returning all votes');
          return true;
      }
    });

    const yesVotes = filteredVotes.filter(
      (vote: IVote) => vote.vote === 'yes'
    ).length;
    const noVotes = filteredVotes.filter(
      (vote: IVote) => vote.vote === 'no'
    ).length;
    const pendingVotes = filteredVotes.length - yesVotes - noVotes;

    setChartData([
      { value: yesVotes, name: 'Yes' },
      { value: noVotes, name: 'No' },
      { value: pendingVotes, name: 'Pending' },
    ]);
  }, [votesData, selectedTimeRange]);

  const initialChartData = Array.from({ length: 3 }, () => ({
    value: 0,
    name: '',
  }));
  const [chartData, setChartData] = useState(initialChartData);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!votesData) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  // Calculate percentages
  const totalVotes = votesData.data.length;
  const yesPercentage =
    chartData.length > 0 ? (chartData[0].value / totalVotes) * 100 : 0;
  const noPercentage =
    chartData.length > 1 ? (chartData[1].value / totalVotes) * 100 : 0;
  const pendingPercentage =
    chartData.length > 2 ? (chartData[2].value / totalVotes) * 100 : 0;

  return (
    <Card className="h-100">
      <Card.Header>
        <h5>Votes</h5>
      </Card.Header>
      <hr></hr>
      <Card.Body
        as={Flex}
        direction="column"
        justifyContent="between"
        className="py-0"
      >
        <div className="my-auto py-5 py-md-5">
          <CircleChart data={chartData} height={200} />
        </div>
        <div className="border-top">
          <Table size="sm" className="mb-0">
            <tbody>
              <tr>
                <td className="py-3">
                  <Flex alignItems="center">
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                    <h6 className="text-600 mb-0 ms-2">Yes</h6>
                  </Flex>
                </td>
                <td className="py-3">
                  <Flex alignItems="center" justifyContent="center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-success fs--2 me-2"
                    />
                    <h6 className="text-700 fw-normal mb-0">
                      {chartData[0].value}
                    </h6>
                  </Flex>
                </td>
                <td className="py-3">
                  <Flex alignItems="center" justifyContent="end">
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      className="text-success"
                    />
                    <h6 className="fs--2 text-700 mb-0 ms-2">
                      {yesPercentage.toFixed(2)}%
                    </h6>
                  </Flex>
                </td>
              </tr>
              <tr>
                <td className="py-3">
                  <Flex alignItems="center">
                    <FontAwesomeIcon icon={faX} className="text-danger" />
                    <h6 className="text-600 mb-0 ms-2">No</h6>
                  </Flex>
                </td>
                <td className="py-3">
                  <Flex alignItems="center" justifyContent="center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-danger fs--2 me-2"
                    />
                    <h6 className="text-700 fw-normal mb-0">
                      {chartData[1].value}
                    </h6>
                  </Flex>
                </td>
                <td className="py-3">
                  <Flex alignItems="center" justifyContent="end">
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="text-danger"
                    />
                    <h6 className="fs--2 text-700 mb-0 ms-2">
                      {noPercentage.toFixed(2)}%
                    </h6>
                  </Flex>
                </td>
              </tr>
              <tr>
                <td className="py-3">
                  <Flex alignItems="center">
                    <FontAwesomeIcon icon={faClock} className="text-info" />
                    <h6 className="text-600 mb-0 ms-2">Pending</h6>
                  </Flex>
                </td>
                <td className="py-3">
                  <Flex alignItems="center" justifyContent="center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-info fs--2 me-2"
                    />
                    <h6 className="text-700 fw-normal mb-0">
                      {chartData[2].value}
                    </h6>
                  </Flex>
                </td>
                <td className="py-3">
                  <Flex alignItems="center" justifyContent="end">
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      className="text-success"
                    />
                    <h6 className="fs--2 text-700 mb-0 ms-2">
                      {pendingPercentage.toFixed(2)}%
                    </h6>
                  </Flex>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card.Body>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select
              size="sm"
              className="me-2"
              title="Select time range"
              value={selectedTimeRange}
              onChange={e => setSelectedTimeRange(e.target.value)}
            >
              <option>Last 7 days</option>
              <option>Last Month</option>
              <option>Last Year</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default GovernanceVotesChartCard;
