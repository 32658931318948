import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import { UUID_REGEX } from '@/constants/regexs';
import AssociationDetailsCard from '@/components/cards/association/AssociationDetailsCard';
import IAssociation from '@/interfaces/Association';
import AssociationHeader from '@/components/card-headers/associations/AssociationHeader';

const AssociationView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [association, setAssociation] = useState<IAssociation>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {association && <AssociationHeader id={id} />}
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <AssociationDetailsCard id={id} card callBack={setAssociation} />
        </Col>
      </Row>
    </div>
  );
};

export default AssociationView;
