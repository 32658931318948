import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import IGovernance, { IGovernanceFormSend } from '@/interfaces/Governance';
import api from '@/services/api';
import { createFormDataRequest } from '@/helpers/utils';

export const governancesApi = api.injectEndpoints({
  endpoints: build => ({
    getGovernances: build.query<IPaginateResponse<IGovernance>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/governances',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Governances' as const,
                id,
              })),
              { type: 'Governances', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getGovernance: build.query<IGovernance, string>({
      query: id => `/governances/${id}`,
      providesTags: (_governance, _err) => [
        { type: 'Governances', id: 'LIST' },
      ],
    }),
    updateGovernance: build.mutation<
      IGovernance,
      [string, Partial<IGovernanceFormSend>]
    >({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/governances/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Governances', id: 'LIST' }],
    }),
    storeGovernance: build.mutation<IGovernance, IGovernanceFormSend>({
      query: data => ({
        url: '/governances',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Governances', id: 'LIST' }],
    }),
    destroyGovernance: build.mutation<void, string>({
      query: id => ({
        url: `/governances/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Governances', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetGovernancesQuery,
  useStoreGovernanceMutation,
  useGetGovernanceQuery,
  useUpdateGovernanceMutation,
  useDestroyGovernanceMutation,
} = governancesApi;
