import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllArticlesHeader from '@/components/table-headers/articles/AllArticlesHeader';
import { truncate } from '@/helpers/utils';
import { IArticle } from '@/interfaces/Article';
import { IFilters } from '@/interfaces/Fetch';
import {
  useDestroyArticleMutation,
  useGetArticlesQuery,
} from '@/services/articles';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';

const ArticlesView: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const [destroyArticleAsync] = useDestroyArticleMutation();

  const { data, isLoading, isError, error } = useGetArticlesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const columns = useRef<ColumnDef<IArticle>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <>
          <Link to={`/articles/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link
            to={`/articles/${id}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Illustration',
      footer: props => props.column.id,
      accessorFn: ({ illustration }) =>
      illustration ? (
          <ImageOverview images={[illustration.link]}>
            {setImgIndex => (
              <Avatar
                size="xl"
                src={illustration.link}
                name={illustration.name}
                onClick={() => setImgIndex(0)}
              />
            )}
          </ImageOverview>
        ) : (
          <UnknownBadge />
        ),
      id: 'avatar',
      cell: info => info.getValue(),
    },
    {
      header: 'Title',
      footer: props => props.column.id,
      accessorFn: ({ title }) => title,
      id: 'title',
      cell: info => info.getValue(),
    },
    {
      header: 'Link',
      footer: props => props.column.id,
      accessorFn: ({ link }) => link,
      id: 'link',
      cell: info => info.getValue(),
    },
    {
      header: 'Article ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => truncate(id, 10),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  const onDelete = useCallback((id: string) => {
    toast.promise(destroyArticleAsync(id).unwrap(), {
      pending: 'Suppression du article en cours ...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p className="mb-0 text-center">L'article à bien été supprimé 🤩</p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'Une erreur est survenue';
        },
      },
    });
  }, []);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé l'article qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllArticlesHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default ArticlesView;
