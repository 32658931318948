import api from './api';

export const userToUserReferralApi = api.injectEndpoints({
  endpoints: build => ({
    getUserToUserReferral: build.query({
      query: () => ({
        url: '/userReferralConfiguration',
      }),
      providesTags: ['UserToUserReferral'],
    }),
    updateuserReferralConfiguration: build.mutation({
      query: ([data, id]) => ({
        url: `/userReferralConfiguration/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['UserToUserReferral'],
    }),
  }),
});

export const {
  useGetUserToUserReferralQuery,
  useUpdateuserReferralConfigurationMutation,
} = userToUserReferralApi;
