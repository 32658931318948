export type NotificationsGreetings = {
    id: number;
    title: string;
    linkTo: string;
    linkText: string;
    type?: string;
};

type GetGreetingNotificationsFuncParams = {
    nbrRidesCompleted?: number;
    nbrRidesCancelled?: number;
    nbrRidesRequested?: number;
    nbrDriversValidation?: number
}

export type FunctionHandler<Entry, Out> = (arg: Entry) => Out

export const getGreetingNotifications: FunctionHandler<GetGreetingNotificationsFuncParams, Array<NotificationsGreetings>> = ({
    nbrRidesCompleted = 0,
    nbrRidesCancelled = 0,
    nbrRidesRequested = 0,
    nbrDriversValidation = 0
}) => {
    return [
        {
            id: 1,
            title: `<strong>${nbrRidesCompleted} Rides</strong> Completed`,
            linkTo: 'rides?status=completed',
            linkText: 'Show completed rides',
            type: 'success',
        },
        {
            id: 2,
            title: `<strong>${nbrRidesCancelled} Rides</strong> Cancelled`,
            linkTo: 'rides?status=cancelled',
            linkText: 'Show cancelled rides',
            type: 'danger',
        },
        {
            id: 3,
            title: `<strong>${nbrRidesRequested} Rides</strong> Requested`,
            tag: 'Rides',
            linkText: 'Show requests',
            linkTo: 'rides?status=requested',
        },
        {
            id: 4,
            title: `<strong>${nbrDriversValidation} Drivers</strong> require validation`,
            linkText: 'Validate drivers',
            linkTo: 'drivers?status=',
            type: 'warning',
        },
    ];
};