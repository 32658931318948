import { IFilters, IPaginateResponse } from "@/interfaces/Fetch";
import api from "./api";
import { EmailOTP } from "@/interfaces/EmailOTP";
import { createFormDataRequest } from "@/helpers/utils";
import { IAskEmailOTP } from "@/interfaces/Auth";

export const emailOtpsApi = api.injectEndpoints({
    endpoints: build => ({
        getEmailOtps: build.query<IPaginateResponse<EmailOTP>, IFilters>({
            query: data => {
                const params = createFormDataRequest(data);
                return {
                    url: '/emailOtps',
                    params,
                };
            },
            providesTags: (result, _error, _arg) =>
                result
                    ? [
                        ...result.data.map(({ id }) => ({
                            type: 'EmailOtps' as const,
                            id,
                        })),
                        { type: 'EmailOtps', id: 'LIST' },
                    ]
                    : [],
        }),
        createEmailOtp: build.mutation<EmailOTP, IAskEmailOTP>({
            query: email => ({
                url: '/emailOtps',
                method: 'POST',
                body: { email },
            }),
            invalidatesTags: [{ type: 'EmailOtps', id: 'LIST' }],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetEmailOtpsQuery,
    useCreateEmailOtpMutation,
} = emailOtpsApi;