import { useRef, useCallback } from 'react';

function useThrottle<T extends (...args: any[]) => any>(callback: T, limit: number): T {
    const lastCall = useRef<number>(0);

    const throttledFunction = useCallback((...args: Parameters<T>): void => {
        const now = Date.now();
        if (now - lastCall.current >= limit) {
            lastCall.current = now;
            callback(...args);
        }
    }, [callback, limit]);

    return throttledFunction as T;
}

export default useThrottle;