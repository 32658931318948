import { EarnPointCriteriaType } from '@/interfaces/EarnPoint';
import { GovernanceStatus } from '@/interfaces/Governance';
import { RewardType } from '@/interfaces/Reward';
import { ISelectOption } from '@/interfaces/Select';
import IUser, {
  GenderType,
  IUserFormCSV,
  UserLoginType,
  UserRoles,
  UserType,
  UserTypeConst_,
} from '@/interfaces/User';
import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, replaceLastQueryParameterUrl } from './utils';
import { IRideStatus, RideCancellationReasons } from '@/interfaces/Ride';
import { DynamicData } from './TableHelper';

export const getColorFromRole = (role: UserRoles) => {
  if (role === 'superadmin') return 'primary';
  else if (role === 'admin') return 'warning';
  else if (role === 'user') return 'info';
  else return 'danger';
};

export const UserTypeSelect: ISelectOption<UserType>[] = UserTypeConst_.map(
  type => ({
    value: type,
    label: capitalize(type)!,
  })
);

export const getReelRoleName = (role: UserRoles) => {
  if (role === 'superadmin') return 'Super Administrateur';
  else if (role === 'admin') return 'Administrateur';
  else if (role === 'user') return 'Utilisateur';
  else return 'Inconnu';
};

export const getColorFromUserType = (userType: UserType) => {
  if (userType === 'driver') return 'info';
  else if (userType === 'user') return '#FF6F91';
};

export const getReelUserTypeName = (userType: UserType) => {
  if (userType === 'driver') return 'driver';
  else if (userType === 'user') return 'user';
};

export const getColorFromCriteriaType = (
  criteriaType: EarnPointCriteriaType
) => {
  if (criteriaType === 'completed_ride') return 'primary';
  else if (criteriaType === 'completed_rides') return 'warning';
  else if (criteriaType === 'rated_ride') return 'info';
  else if (criteriaType === 'referral') return 'danger';
};

export const getReelCriteriaType = (criteriaType: EarnPointCriteriaType) => {
  if (criteriaType === 'completed_ride') return 'Completed Ride';
  else if (criteriaType === 'completed_rides') return 'Completed Rides';
  else if (criteriaType === 'rated_ride') return 'Rated Ride';
  else if (criteriaType === 'referral') return 'Referral';
};

export const getColorFromRideStatus = (status: IRideStatus) => {
  if (status === 'completed') return 'success';
  else if (status === 'cancelled') return 'danger';
  else if (status === 'no_drivers_available') return 'warning';
  else if (status === 'pending') return 'primary';
  else if (status === 'internal_error') return 'grey';
  else if (status === 'driver_cancelled') return '#FF4500';
  else return 'info';
};

export const getReelRideStatus = (status: IRideStatus) => {
  if (status === 'completed') return 'Completed';
  else if (status === 'cancelled') return 'Cancelled';
  else if (status === 'no_drivers_available') return 'No Drivers Available';
  else if (status === 'pending') return 'Pending';
  else if (status === 'started') return 'Started';
  else if (status === 'requested') return 'Requested';
  else if (status === 'accepted') return 'Accepted';
  else if (status === 'driver_arrived') return 'Driver Arrived';
  else if (status === 'driver_cancelled') return 'Driver Cancelled';
  else return 'Internal Error';
};

export const getColorFromRewardType = (rewardType: RewardType) => {
  if (rewardType === 'discount') return 'primary';
  else return 'info';
};

export const getReelRewardTypeName = (rewardType: RewardType) => {
  if (rewardType === 'discount') return 'discount';
  else return 'partnership';
};

export const getColorFromCancellationReason = (
  cancellationReason: RideCancellationReasons
) => {
  if (cancellationReason === 'accident') return 'danger';
  if (cancellationReason === 'waiting_too_long') return 'warning';
  if (cancellationReason === 'wrong_pickup_location') return 'info';
  if (cancellationReason === 'wrong_vehicle_type') return 'success';
  if (cancellationReason === 'wrong_destination') return 'primary';
  if (cancellationReason === 'other') return 'info';
  return 'grey';
};

export const getReelCancellationReasonName = (
  cancellationReason: RideCancellationReasons
) => {
  if (cancellationReason === 'accident') return 'Accident';
  if (cancellationReason === 'waiting_too_long') return 'Waiting too long';
  if (cancellationReason === 'wrong_pickup_location')
    return 'Wrong pickup location';
  if (cancellationReason === 'wrong_vehicle_type') return 'Wrong vehicle type';
  if (cancellationReason === 'wrong_destination') return 'Wrong destination';
  if (cancellationReason === 'other') return 'Other';
  else return 'N/A';
};

export const getColorFromIsArchived = (archived: boolean) => {
  return archived ? 'danger' : 'success';
};

export const getReelArchivedName = (archived: boolean) => {
  return archived ? 'Archived' : 'Active';
};

export const getColorFromIsExpired = (isExpired: boolean) => {
  return isExpired ? 'danger' : 'success';
};

export const getReelExpiredName = (isExpired: boolean) => {
  return isExpired ? 'Not Expired' : 'Expired';
};

export const getColorFromIsActive = (isActive: boolean) => {
  return isActive ? 'success' : 'danger';
};

export const getReelIsActiveName = (isActive: boolean) => {
  return isActive ? 'YES' : 'NO';
};

export const getColorFromGovStatusType = (
  governanceStatus: GovernanceStatus
) => {
  if (governanceStatus === 'voting_period') return 'primary';
  else if (governanceStatus === 'passed') return 'success';
  else if (governanceStatus === 'rejected') return 'warning';
  else if (governanceStatus === 'waiting') return 'info';
};

export const getReelGovStatusName = (governanceStatus: GovernanceStatus) => {
  if (governanceStatus === 'voting_period') return 'voting_period';
  else if (governanceStatus === 'passed') return 'passed';
  else if (governanceStatus === 'rejected') return 'rejected';
  else if (governanceStatus === 'waiting') return 'waiting';
};

export const getColorFromLoginType = (loginType: UserLoginType) => {
  if (loginType === 'apple') return 'primary';
  else if (loginType === 'google') return 'warning';
  else if (loginType === 'email') return 'success';
  else if (loginType === 'phone') return 'info';
  else return 'danger';
};

export const getGenderAlias = (gender: GenderType) => {
  if (gender === 'female') return 'Mme.';
  return 'M.';
};

export const getReelLoginTypeName = (loginType: UserLoginType) => {
  if (loginType === 'apple')
    return (
      <>
        <FontAwesomeIcon icon={faApple} /> Apple
      </>
    );
  else if (loginType === 'google')
    return (
      <>
        <FontAwesomeIcon icon={faGoogle} className="me-1" /> Google
      </>
    );
  else if (loginType === 'email')
    return (
      <>
        <FontAwesomeIcon icon={faEnvelope} className="me-1" /> Email
      </>
    );
  else
    return (
      <>
        <FontAwesomeIcon icon={faPhone} className="me-1" /> Phone
      </>
    );
};

export const checkIfUserHasRoles = (
  user: IUser,
  roles: UserRoles[]
): boolean => {
  return roles.some(role => user.role === role);
};

export const getColorFromUserCouponUsed = (usedAt: string | null) => {
  if (usedAt) return 'success';
  else return 'danger';
};

export const getReelUserCouponUsed = (usedAt: string | null) => {
  if (usedAt) return 'Used';
  else return 'Not Used';
};

export const getReelGender: (arg: string) => GenderType = (gender: string) => {
  switch (gender) {
    case 'Homme':
      return 'male';
    case 'Femme':
      return 'female';
    default:
      return 'male';
  }
};

export const formCsvArrayToUser = (d: DynamicData) => {
  return {
    avatar: d.Photo as string,
    referralCode: d.Referral_Code as string,
    firstname: d.firstname_formatted,
    lastname: d.lastname_formatted,
    fullname: `${d.firstname_formatted} ${d.lastname_formatted}`,
    gender: getReelGender(d.Sexe as string),
    email: d.lowercased_email as string,
    phone: d.Phone_Number as string,
    birthdate: d.company_owner_birthdate as string,
    role: 'user',
  } as IUserFormCSV;
};

export const formCSVToRegisteredUser = (d: DynamicData) => {
  return {
    firstname: d.firstname_formatted,
    lastname: d.lastname_formatted,
    fullname: `${d.firstname} ${d.lastname}`,
    email: d.email_formatted as string,
    phone: d.phone_formatted as string,
    role: 'user',
  } as IUserFormCSV;
};

export const normalizeUrl = (url: string): string => {
  let normalizedUrl = replaceLastQueryParameterUrl(url, 'dl=1');

  normalizedUrl = normalizedUrl.replace(/\.JPG(?=\?|$)/i, '.jpg');
  normalizedUrl = normalizedUrl.replace(/\.JPEG(?=\?|$)/i, '.jpeg');
  normalizedUrl = normalizedUrl.replace(/\.PDF(?=\?|$)/i, '.pdf');
  normalizedUrl = normalizedUrl.replace(/\.PNG(?=\?|$)/i, '.png');

  return normalizedUrl;
};
