import { faReorder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";

interface IProps {
  row: Row<unknown>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
}

const DraggableRow: FC<IProps> = ({ row, reorderRow }) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow: Row<unknown>) => reorderRow(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  return (
    <tr
      ref={previewRef} //previewRef could go here
      className="btn-reveal-trigger align-middle"
      role="row"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {row.getVisibleCells().map((cell, index) => {
        // write custom props here
        return (
          <td
            key={cell.id}
            ref={index === 0 ? dropRef : undefined}
            role="cell"
            {...cell.getContext().cell.column.columnDef.meta?.cellProps}
            className={classNames(
              "white-space-nowrap pe-6 py-3",
              cell.getContext().cell.column.columnDef.meta?.cellProps?.className
            )}
          >
            {index === 0 && (
              <Button
                ref={dragRef}
                variant="link"
                className="btn-reveal-primary p-0"
              >
                <FontAwesomeIcon icon={faReorder} />
              </Button>
            )}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

export default DraggableRow;
