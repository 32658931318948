import { TemplateContext } from "@/hooks/useTemplate";
import { FC, useContext } from "react";
import { Button, OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";

const OverlayTooltip: FC<TooltipProps> = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Ouvrir ou fermé le menu
    </Tooltip>
  );
};

const ToogleButton: FC = () => {
  const { triggerVerticalNavbar } = useContext(TemplateContext);

  return (
    <OverlayTrigger placement="right" overlay={OverlayTooltip}>
      <div className="toogle-icon-wrapper me-2">
        <Button
          variant="link"
          className="navbar-toggler-humburger-icon navbar-vertical-toggle"
          id="toggleNavigationTooltip"
          onClick={triggerVerticalNavbar}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default ToogleButton;
