import { createFormDataRequest } from '@/helpers/utils';
import { IDriverReferrals } from '@/interfaces/DriverReferrals';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import IDriverNotification from '@/interfaces/Ride/DriverNotification';
import api from '@/services/api';

export const driverNotificationsApi = api.injectEndpoints({
  endpoints: build => ({
    getDriverNotifcations: build.query<
      IPaginateResponse<IDriverNotification>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/rides/${id}/notifiedDrivers`,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetDriverNotifcationsQuery } = driverNotificationsApi;
