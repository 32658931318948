import Modal from '@/components/common/Modal';
import { SelectPaginateAsync } from '@/components/common/Select';
import useLoadOptions from '@/hooks/useLoadOptions';
import { AssignCouponForm } from '@/interfaces/Coupon';
import { IFilters } from '@/interfaces/Fetch';
import { IModalMethods } from '@/interfaces/Modal';
import { useInsertCouponToUserListMutation } from '@/services/userList';
import { useStoreCouponMutation } from '@/services/coupons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const AssignCouponModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AssignCouponForm>();
  const { loadUserList, loadCominCoupon } = useLoadOptions();
  const defaultAdditionnal = useRef<IFilters>({
    pageIndex: 0,
    pageSize: 15,
  }).current;

  const [insertCouponAsync] = useInsertCouponToUserListMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<AssignCouponForm> = data => {
    const { coupon, userList } = data;

    if (!coupon || !userList) {
      toast.error('The fields you have filled in seem to be incorrect.');
    }

    toast.promise(
      insertCouponAsync([userList?.map(list => list.id), coupon?.id]).unwrap(),
      {
        pending: 'Assigning coupons...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                The coupon has been successfully added 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'The fields you have filled in seem to be incorrect.';
            }
            return 'An error occurred';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<AssignCouponForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      size="lg"
      title="Assign a Coupon"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Coupon <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="coupon"
                render={({ field }) => (
                  <SelectPaginateAsync
                    loadOptions={loadCominCoupon}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={field.value}
                    onChange={field.onChange}
                    additional={defaultAdditionnal}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.userList?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Select a User List <span className="text-danger">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="userList"
                render={({ field }) => (
                  <SelectPaginateAsync
                    loadOptions={loadUserList}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={field.value}
                    isMulti
                    onChange={field.onChange}
                    additional={defaultAdditionnal}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.coupon?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default forwardRef(AssignCouponModal);
