import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import IOffer, { IOfferForm } from '@/interfaces/Offer';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const offersApi = api.injectEndpoints({
  endpoints: build => ({
    getOffers: build.query<IPaginateResponse<IOffer>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/offers',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Offers' as const,
                id,
              })),
              { type: 'Offers', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getOffer: build.query<IOffer, string>({
      query: id => `/offers/${id}`,
      providesTags: (_offer, _err) => [{ type: 'Offers', id: 'LIST' }],
    }),
    storeOffer: build.mutation<IOffer, IOfferForm>({
      query: data => {
        const body = createFormDataRequest(data);
        return {
          url: '/offers',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Offers', id: 'LIST' }],
    }),
    updateOffer: build.mutation<IOffer, [string, Partial<IOfferForm>]>({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/offers/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Offers', id: 'LIST' }],
    }),
    destroyOffer: build.mutation<void, string>({
      query: id => ({
        url: `/offers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Offers', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetOffersQuery,
  useStoreOfferMutation,
  useLazyGetOffersQuery,
  useDestroyOfferMutation,
  useGetOfferQuery,
  useUpdateOfferMutation,
} = offersApi;
