import { FC } from "react";
import SoftBadge from "./SoftBadge";
import classNames from "classnames";

const UnknownBadge: FC<{ className?: string }> = ({ className }) => {
  return (
    <h6 className={classNames("mb-0 text-700 fs--1", className)}>
      <SoftBadge bg="warning">Unknown</SoftBadge>
    </h6>
  );
};

export default UnknownBadge;
