import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllGovernancesHeader from '@/components/table-headers/governances/AllGovernancesHeader';
import {
  getColorFromGovStatusType,
  getReelGovStatusName,
} from '@/helpers/UserHelpers';
import IGovernance from '@/interfaces/Governance';
import { IUserFilters } from '@/interfaces/User';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';
import { truncate } from '@/helpers/utils';
import {
  useDestroyGovernanceMutation,
  useGetGovernancesQuery,
} from '@/services/governances';

const GovernancesView: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [state, setState] = useState<Partial<TableState> & IUserFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const [destroyGovernanceAsync] = useDestroyGovernanceMutation();

  const { data, isLoading, isError, error } = useGetGovernancesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const columns = useRef<ColumnDef<IGovernance>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <>
          <Link to={`/governances/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link
            to={`/governances/${id}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ status }) => (
        <SoftBadge
          bg={getColorFromGovStatusType(status)}
          className={'my-2 text-xl-center'}
        >
          {getReelGovStatusName(status)}
        </SoftBadge>
      ),
      id: 'status',
      cell: info => info.getValue(),
    },
    {
      header: 'Title',
      footer: props => props.column.id,
      accessorFn: ({ title, id }) => (
        <Link to={`/governances/${id}`}>
          <p className="mb-0">{title}</p>
        </Link>
      ),
      id: 'title',
      cell: info => info.getValue(),
    },
    {
      header: 'Start At\nEnd At',
      footer: props => props.column.id,
      accessorFn: ({ startAt, endAt }) => (
        <>
          {DateTime.fromISO(startAt).toFormat("dd/MM/yyyy 'at' HH:mm")}
          <p className="mb-0 text-500">
            {DateTime.fromISO(endAt).toFormat("dd/MM/yyyy 'at' HH:mm")}
          </p>
        </>
      ),
      id: 'startAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Driver Ride Count',
      footer: props => props.column.id,
      accessorFn: ({ driverCourseCount }) => `${driverCourseCount}`,
      id: 'driverCourseCount',
      cell: info => info.getValue(),
    },
    {
      header: 'User Ride Count',
      footer: props => props.column.id,
      accessorFn: ({ customerCourseCount }) => `${customerCourseCount}`,
      id: 'customerCourseCount',
      cell: info => info.getValue(),
    },
    {
      header: 'Description',
      footer: props => props.column.id,
      accessorFn: ({ description }) => truncate(description, 100),
      id: 'description',
      cell: info => info.getValue(),
    },
    {
      header: 'Context',
      footer: props => props.column.id,
      accessorFn: ({ context }) => truncate(context, 100),
      id: 'context',
      cell: info => info.getValue(),
    },
    {
      header: 'id',
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <Link to={`/governances/${id}`}>{truncate(id, 10)}</Link>
      ),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  const onDelete = useCallback((id: string) => {
    toast.promise(destroyGovernanceAsync(id).unwrap(), {
      pending: 'Suppression du governance en cours ...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p className="mb-0 text-center">
              Le Governance à bien été supprimé 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'Une erreur est survenue';
        },
      },
    });
  }, []);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le governance qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllGovernancesHeader}
        columns={columns}
        state={state}
        tableName="governances"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default GovernancesView;
