import bgShape from "@/assets/img/illustrations/bg-shape.png";
import halfCircle from "@/assets/img/illustrations/half-circle.png";
import shape1 from "@/assets/img/illustrations/shape-1.png";
import LogoWhite from "@/assets/img/logo-white.png";
import Background from "@/components/common/Background";
import Section from "@/components/common/Section";
import React, { FC } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IProps {
  leftSideContent: React.ReactNode;
  children: React.ReactNode;
  footer?: boolean;
}

const AuthCardLayout: FC<IProps> = ({
  leftSideContent,
  children,
  footer = true,
}) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={6} className="py-3 position-relative">
          <Image
            className="bg-auth-circle-shape"
            src={bgShape}
            alt="Circle 1"
            width="250"
          />
          <Image
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt="Circle 2"
            width="150"
          />
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="g-0">
                <Col
                  md={5}
                  className="bg-card-gradient text-center flex-column d-flex justify-content-center position-relative"
                >
                  <Background
                    image={halfCircle}
                    className="bg-auth-card-shape"
                  />
                  <div className="p-4 pt-md-5 pb-md-7">
                    <div className="z-index-1 position-relative light">
                      <Link
                        className="link-light mb-4 font-sans-serif fw-bolder fs-4 d-inline-block"
                        to="/"
                      >
                        <img
                          className="ms-1 me-2"
                          src={LogoWhite}
                          alt="Logo"
                          width={"80%"}
                        />
                      </Link>
                      <p className="opacity-75 text-white">
                        Bienvenue sur la page d'authentification de COMIN !
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 light">
                    {leftSideContent}
                  </div>
                </Col>
                <Col
                  md={7}
                  className="p-4 p-md-5 flex-grow-1 flex-column d-flex justify-content-center"
                >
                  {children}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthCardLayout;
