import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import { IDefaultCardProps } from '@/components/interfaces/Card';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { Card as C } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const Card: any = C;

const ActivityAreaPickHeader: FC<IDefaultCardProps> = ({ id, isEdit }) => {
  const navigation = useNavigate();

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigation(-1)}
          variant="nolina-default"
          size="sm"
          icon={faArrowLeft}
        />

        {/* <h5 className="mb-0">Zone d'inactivité</h5> */}
        <Flex>
          {!isEdit && (
            <IconButton
              href={`/activityAreaPick/new`}
              variant="nolina-default"
              size="sm"
              icon={faPen}
              transform="shrink-2"
              iconAlign="middle"
              className="me-2"
            >
              <span className="d-none d-xl-inline-block ms-1">Create</span>
            </IconButton>
          )}
        </Flex>
      </Card.Header>
    </Card>
  );
};
export default ActivityAreaPickHeader;
