import React, { useCallback, useEffect, useState } from "react";
import { APP_VERSION } from "@/constants";

export const TemplateContext = React.createContext({
  isNavbarVerticalCollapsed: false,
  triggerVerticalNavbar: () => {},
  isDark: false,
  switchTheme: () => {},
  showBurgerMenu: false,
  triggerBurgerMenu: () => {},
  disabledBurgerMenu: () => {},
});

export const TemplateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] =
    useState<boolean>(false);

  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);

  const [isDark, setIsDark] = useState<boolean>(false);

  const triggerBurgerMenu = () => setShowBurgerMenu((old) => !old);

  const disabledBurgerMenu = () => setShowBurgerMenu(false);

  const switchTheme = () =>
    setIsDark((old) => {
      if (document) {
        localStorage.setItem("dark", (!old).toString());
        document.documentElement.classList[!old ? "add" : "remove"]("dark");
        return !old;
      }
      return old;
    });

  useEffect(() => {
    const app_version = localStorage.getItem("app_version");
    if (app_version !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem("app_version", APP_VERSION);
    }
  }, []);

  useEffect(() => {
    setIsDark((old) => {
      const isDark = localStorage.getItem("dark");
      if (isDark) {
        document.documentElement.classList[
          isDark === "true" ? "add" : "remove"
        ]("dark");
        return isDark === "true";
      }

      return old;
    });
    setIsNavbarVerticalCollapsed(() => {
      const isNavbarVerticalCollapsed = localStorage.getItem(
        "navbar-vertical-collapsed"
      );
      if (isNavbarVerticalCollapsed === "true") {
        document
          .getElementsByTagName("html")[0]
          .classList.toggle("navbar-vertical-collapsed");
        return true;
      }

      return false;
    });
  }, []);

  const triggerVerticalNavbar = useCallback(() => {
    setIsNavbarVerticalCollapsed((old) => {
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("navbar-vertical-collapsed");
      localStorage.setItem("navbar-vertical-collapsed", (!old).toString());
      return !old;
    });
  }, []);

  const contextValue = {
    isNavbarVerticalCollapsed,
    triggerVerticalNavbar,
    isDark,
    switchTheme,
    triggerBurgerMenu,
    showBurgerMenu,
    disabledBurgerMenu,
  };

  return (
    <TemplateContext.Provider value={contextValue}>
      {children}
    </TemplateContext.Provider>
  );
};
