import GoogleMap from '@/components/common/map/GoogleMaps';
import { FC } from 'react';
import LegendMapCard from './LegendMapCard';

type RideMapCardProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const RideMapCard: FC<RideMapCardProps> = ({
  className = '',
  style,
}) => {
  return (
    <div
      style={{
        borderRadius: 10,
        overflow: 'hidden',
        height: '100%',
        minHeight: '30vh',
        ...style,
      }}
      className={`${className} position-relative`}
    >
      <GoogleMap
        initialCenter={{ lat: 48.8583736, lng: 2.2922926 }}
        options={{
          zoom: 15,
          disableDefaultUI: true,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
      />
      <LegendMapCard />
    </div>
  );
};
