import Flex from "@/components/common/Flex";
import IconButton from "@/components/common/IconButton";
import { IDefaultCardProps } from "@/components/interfaces/Card";
import AreYouSurToContinueModal from "@/components/modals/AreYouSurToContinueModal";
import {
  selectCurrentUser,
  selectCurrentUserConfig,
} from "@/services/slices/authSlice";
import {
  faArrowLeft,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ElementRef, FC, useRef } from "react";
import { Card as C } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContentProps, toast } from "react-toastify";
import { useDestroyCouponMutation } from "@/services/coupons";
const Card: any = C

const CouponHeader: FC<IDefaultCardProps> = ({ id, isEdit}) => {
    const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
    const user = useSelector(selectCurrentUser);
    const userConfig = useSelector(selectCurrentUserConfig)
    const navigation = useNavigate()
    const [deleteCouponAsync] = useDestroyCouponMutation();

    const onDelete = (id: string) => {
        toast.promise(deleteCouponAsync(id).unwrap(), {
          pending: "Suppression du coupon en cours...",
          success: {
            render() {
              DeleteModalRef.current?.showOrHiddenModal();
              navigation(-1);
              return (
                <p style={{ marginBottom: 0, textAlign: "center" }}>
                  Le coupon à bien été supprimée 🚙🚙
                </p>
              );
            },
          },
          error: {
            render({ data }: ToastContentProps<FetchBaseQueryError>) {
              if (data?.status === 422) {
                return "Les champs que vous avez remplis semblent être incorrects.";
              }
    
              return "Une erreur est survenue";
            },
          },
        });
      };

      return (
        <Card className="mb-3">
            <AreYouSurToContinueModal
            ref={DeleteModalRef}
            onContinue={onDelete}
            title="Souhaitez-vous vraiment supprimé le coupon qui a pour id : {{id}} ?"
            />
            <Card.Header className="d-flex flex-between-center">
                <IconButton
                onClick={() => navigation(-1)}
                variant="nolina-default"
                size="sm"
                icon={faArrowLeft}
                />
                <Flex>
                    <div
                        className="bg-300 mx-3 d-xl-none"
                        style={{ width: "1px", height: "29px" }}
                    ></div>
                    {!isEdit && (
                    <IconButton
                    href={`/coupons/${id}/edit`}
                    variant="nolina-default"
                    size="sm"
                    icon={faEdit}
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                    >
                    <span className="d-none d-xl-inline-block ms-1">Modifier</span>
                    </IconButton>
                )}
                {userConfig.isAdmin && user?.id !== id && (
                    <IconButton
                    onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
                    variant="nolina-default"
                    size="sm"
                    icon={faTrash}
                    iconAlign="middle"
                    className="d-none d-sm-block me-2"
                    >
                    <span className="d-none d-xl-inline-block ms-1">Supprimer</span>
                    </IconButton>
                )}
                </Flex>
            </Card.Header>
        </Card>
      );
}
export default CouponHeader;