import { IDriverVehicleStatus } from '@/interfaces/DriverVehicle';
import { DynamicData } from './TableHelper';

export const formCSVToDriverVehicle = (d: DynamicData) => {
  const brandModelArray = (d.brand_model as string).split(' ');
  const brand = brandModelArray.shift();
  const model = brandModelArray.join(' ');

  return {
    licensePlate: d.Plate_number_formatted as string,
    vehicle: {
      brand: brand as string,
      model: model as string,
    },
    colorLabel: d.Color as string,
    color: d.Color as string,
    year: d.Car_year as number,
    default: true,
    status: 'active' as IDriverVehicleStatus,
  };
};
