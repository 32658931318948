import React, { FC, useContext, useEffect, useState } from 'react';

import {
  GoogleMap as ReactGoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api';
import googleMapStyles from '@/helpers/googleMapStyles';
import { TemplateContext } from '@/hooks/useTemplate';
import { GoogleMapStyle } from '@/interfaces/GoogleMaps';
import useThrottle from '@/hooks/useThrottle';

type GoogleMapProps = {
  mapStyle?: GoogleMapStyle;
  darkStyle?: GoogleMapStyle;
  className?: string;
  children?: React.ReactNode;
  options?: google.maps.MapOptions;
  positions?: (isLoaded: boolean) => JSX.Element[];
  onDragEnd?: (arg: { lat: number; lng: number }) => void;
  initialCenter?: {
    lat: number;
    lng: number;
  };
};

const GoogleMap: FC<GoogleMapProps & GoogleMapProps> = ({
  mapStyle = 'Default',
  initialCenter = {
    lat: 48.8583736,
    lng: 2.2922926,
  },
  onDragEnd,
  positions = () => [],
  darkStyle = 'Midnight',
  className,
  children,
  ...rest
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  const { isDark } = useContext(TemplateContext);
  const [mapStyles, setMapStyles] = useState(mapStyle);

  useEffect(() => {
    if (darkStyle && isDark) setMapStyles(darkStyle);
    else setMapStyles(mapStyle);
  }, [isDark]);

  useEffect(() => {}, []);

  const options: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    styles: googleMapStyles[mapStyles],
    ...rest.options,
  };

  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onDrag = useThrottle(() => {
    if (map) {
      const center = map.getCenter();
      if (center) {
        const lat = center.lat();
        const lng = center.lng();
        if (onDragEnd) {
          onDragEnd({ lat, lng });
        }
      }
    }
  }, 1000);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className={`h-100 ${className}`} {...rest}>
      <ReactGoogleMap
        zoom={12}
        options={options}
        center={initialCenter}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        onLoad={onLoad}
        onDragEnd={onDrag}
        {...rest}
      >
        {positions
          ? positions(isLoaded).map((position, index) => position)
          : null}
        {/* <DirectionsService
          options={{
            origin: origin,
            destination: destination,
            travelMode: 'DRIVING' as google.maps.TravelMode,
            avoidFerries: true,
          }}
          onLoad={directionsService => {}}
          callback={(result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (result) setDirections(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }}
        /> */}
      </ReactGoogleMap>
    </div>
  );
};

export default GoogleMap;
