import { IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';
import IRide, { IRideFilters } from '@/interfaces/Ride';

export const ridesApi = api.injectEndpoints({
  endpoints: build => ({
    getRides: build.query<IPaginateResponse<IRide>, IRideFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/rides',
          params,
        };
      },
    }),
    getRide: build.query<IRide, string>({
      query: id => `/rides/${id}`,
    }),
    updateRideStatus: build.mutation<
      void,
      {
        id: string;
        status: string;
        cancellationReason?: string;
        cancellationReasonMessage?: string;
      }
    >({
      query: ({
        id,
        status,
        cancellationReason,
        cancellationReasonMessage,
      }) => ({
        url: `/rides/${id}/status`,
        method: 'PATCH',
        body: { status, cancellationReason, cancellationReasonMessage },
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetRidesQuery,
  useGetRideQuery,
  useUpdateRideStatusMutation,
} = ridesApi;
