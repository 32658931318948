import { Colors } from "@/interfaces/Theme";
import bg1 from "@/assets/img/icons/spot-illustrations/corner-1.png";
import bg2 from "@/assets/img/icons/spot-illustrations/corner-2.png";
import bg3 from "@/assets/img/icons/spot-illustrations/corner-3.png";

export const getBackgroundFromColor = (color: Colors) => {
  switch (color) {
    case "warning":
      return bg1;
    case "danger":
      return bg1;
    case "info":
      return bg2;
    case "success":
      return bg3;
    default:
      return bg2;
  }
};
