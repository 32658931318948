import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import IReward, { IRewardFormSend } from '@/interfaces/Reward';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const rewardsApi = api.injectEndpoints({
  endpoints: build => ({
    getRewards: build.query<IPaginateResponse<IReward>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/rewards',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Rewards' as const,
                id,
              })),
              { type: 'Rewards', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getReward: build.query<IReward, string>({
      query: id => `/rewards/${id}`,
      providesTags: (_reward, _err) => [{ type: 'Rewards', id: 'LIST' }],
    }),
    storeReward: build.mutation<IReward, IRewardFormSend>({
      query: data => ({
        url: '/rewards',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Rewards', id: 'LIST' }],
    }),
    editReward: build.mutation<IReward, [string, Partial<IRewardFormSend>]>({
      query: ([id, body]) => {
        return {
          url: `/rewards/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Rewards', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetRewardsQuery,
  useStoreRewardMutation,
  useGetRewardQuery,
  useEditRewardMutation,
} = rewardsApi;
