import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import {
  DriverDocumentFormStore,
  DriverDocumentTypeSelect,
} from '@/interfaces/Driver/Document';
import { DriverDocumentVehicleTypeSelect } from '@/interfaces/DriverVehicleDocument';
import { IDrivingPreferencesForm } from '@/interfaces/DrivingPreferences';
import { IModalMethods } from '@/interfaces/Modal';
import { IUserForm } from '@/interfaces/User';
import { useCreateDocumentMutation } from '@/services/drivers/documents';
import { useCreateDrivingPreferenceMutation } from '@/services/drivingPreferences';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

type StoreDrivingPreferencesModalProps = {};

const StoreDrivingPreferencesModal: ForwardRefRenderFunction<
  IModalMethods,
  StoreDrivingPreferencesModalProps
> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDrivingPreferencesForm>();
  const [createDrivingPreference] = useCreateDrivingPreferenceMutation();

  const showOrHiddenModal = () => {
    ModalRef.current?.showOrHiddenModal();
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IDrivingPreferencesForm> = data => {
    toast.promise(createDrivingPreference(data).unwrap(), {
      pending: 'Ajout de la preference en cours...',
      success: {
        render() {
          reset();
          showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              La préférence a bien été ajouté.
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IDrivingPreferencesForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter une nouvelle préférence de conduite."
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Group className="mb-3">
              <Form.Label>
                Name <span className="text-danger">*</span> :{' '}
              </Form.Label>
              <Form.Control
                placeholder="ex : John"
                type="text"
                {...register('name', {
                  required: `Merci d'indiquer un nom.`,
                  minLength: {
                    value: 2,
                    message: 'Le nom doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Le nm doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Text color <span className="text-danger">*</span> :{' '}
              </Form.Label>
              <Form.Control
                placeholder="ex : John"
                type="text"
                {...register('textColor', {
                  required: `Merci d'indiquer une couleur.`,
                  minLength: {
                    value: 2,
                    message: 'La couleur doit au moins 2 caractères.',
                  },
                  validate: {
                    isValidHex: value => {
                      if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                        return true;
                      }
                      return "La couleur n'est pas valide.";
                    },
                  },
                  maxLength: {
                    value: 10,
                    message:
                      'La couleur doit contenir au maximum 10 caractères.',
                  },
                })}
                isInvalid={!!errors.textColor}
              />
              <Form.Control.Feedback type="invalid">
                {errors.textColor?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Background color <span className="text-danger">*</span> :{' '}
            </Form.Label>
            <Form.Control
              placeholder="ex : John"
              type="text"
              {...register('backgroundColor', {
                required: `Merci d'indiquer une couleur.`,
                minLength: {
                  value: 2,
                  message: 'La couleur doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 50,
                  message: 'Le couleur doit contenir au maximum 50 caractères.',
                },
              })}
              isInvalid={!!errors.backgroundColor}
            />
            <Form.Control.Feedback type="invalid">
              {errors.backgroundColor?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreDrivingPreferencesModal);
