import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { EMAIL_REGEX } from '@/constants/regexs';
import {
  INVALID_EMAIL_MESSAGE,
  INVALID_EMAIL_REQUIRED_MESSAGE,
  INVALID_PHONE_MESSAGE,
} from '@/constants/validatorMessages';
import { IModalMethods } from '@/interfaces/Modal';
import { GENDERS, IUserForm, USER_ROLES } from '@/interfaces/User';
import { useStoreUserMutation } from '@/services/users';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreUserModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IUserForm>();
  const [storeUserAsync] = useStoreUserMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IUserForm> = ({
    role,
    phone,
    gender,
    ...data
  }) => {
    toast.promise(
      storeUserAsync({
        ...data,
        phone: parsePhoneNumber(phone, 'FR').number,
        role: role.value,
        gender: gender.value,
      }).unwrap(),
      {
        pending: "Ajout de l'utilisateur en cours...",
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                L'utilisateur à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IUserForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un utilisateur"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Prénom <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : John"
              type="text"
              {...register('firstname', {
                required: 'Le prénom est requis.',
                minLength: {
                  value: 2,
                  message: 'Le prénom doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Le prénom doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.firstname}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstname?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Nom de famille <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : DOE"
              type="text"
              {...register('lastname', {
                required: 'Le nom de famille est requis.',
                minLength: {
                  value: 2,
                  message:
                    'Le nom de famille doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message:
                    'Le nom de famille doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastname?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Email <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : hello@comin-app.com"
              type="email"
              {...register('email', {
                required: INVALID_EMAIL_REQUIRED_MESSAGE,
                pattern: {
                  value: EMAIL_REGEX,
                  message: INVALID_EMAIL_MESSAGE,
                },
              })}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Numéro de téléphone : <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              placeholder="ex : 0634835532"
              type="tel"
              {...register('phone', {
                required: 'Le numéro de téléphone est requis.',
                validate: value => {
                  if (value && !isValidPhoneNumber(value, 'FR')) {
                    return INVALID_PHONE_MESSAGE;
                  }
                },
              })}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Genre :</Form.Label>
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <Select
                  options={GENDERS}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.gender,
              })}
            >
              {errors.gender?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Role <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="role"
              rules={{
                required: 'Le rôle ou les rôles sont requis.',
              }}
              render={({ field }) => (
                <Select
                  options={USER_ROLES}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.role,
              })}
            >
              {errors.role?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreUserModal);
