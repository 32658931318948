import ActivityAreaPickHeader from '@/components/card-headers/activityAreaPick/ActivityAreaPickHeader';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import CreateActivityAreaPick from '@/components/updates/activityAreaPick/CreateActivityAreaPick';
import { useDestroyArticleMutation } from '@/services/articles';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ElementRef, FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';

const ActivityAreaPickNew: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const navigation = useNavigate();
  const [deleteArticleAsync] = useDestroyArticleMutation();

  const onDelete = (id: string) => {
    toast.promise(deleteArticleAsync(id).unwrap(), {
      pending: `Suppression de l'article en cours...`,
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          navigation(-1);
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              Article retiré
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  return (
    <div className="flex-1 md-h-100 ">
      <ActivityAreaPickHeader isEdit id="" />
      <CreateActivityAreaPick />
    </div>
  );
};

export default ActivityAreaPickNew;
