import CardHeader from '@/components/card-headers/CardHeader';
import Background from '@/components/common/Background';
import { ICardDetailsProps } from '@/interfaces/Card';
import classNames from 'classnames';
import { ElementRef, FC, useEffect, useRef } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CornerBg from '@/assets/img/illustrations/corner-2.png';
import Flex from '@/components/common/Flex';
import SoftBadge from '@/components/common/SoftBadge';
import IconButton from '@/components/common/IconButton';
import { faEye, faPhone, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Loader from '@/components/common/Loader';
import ErrorComponent from '@/components/common/ErrorComponent';
import UnknownBadge from '@/components/common/UnknownBadge';
import { IRequest } from '@/interfaces/Request';
import { useGetRequestQuery } from '@/services/requests';

interface IProps extends ICardDetailsProps<IRequest> {}

const RequestDetailsCard: FC<IProps> = ({
  id,
  card,
  showDetailsButton,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetRequestQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute={!!card} />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute={!!card} returnButton />;
  }

  return (
    <div
      className={classNames({
        card: card,
      })}
    >
      {card && <CardHeader title="Informations de la requête" />}
      <div
        className={classNames('bg-light', {
          'card-body': card,
        })}
      >
        <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
          <Background
            image={CornerBg}
            className="bg-card d-none d-sm-block d-xl-none"
          />
          <Card.Body className="row g-0 flex-column flex-sm-row flex-xl-column z-index-1 align-items-center">
            <Col sm={8} md={6} lg={4} xl={12} className="ps-sm-1 ps-xl-0">
              {data?.isSimulation && (
                <Flex alignItems="xl-center" direction="column">
                  <SoftBadge bg={'primary'} className="my-2 text-xl-center">
                    Simulation
                  </SoftBadge>
                </Flex>
              )}
              {showDetailsButton && (
                <Flex justifyContent="center" className="gap-2">
                  <IconButton
                    variant="primary"
                    icon={faEye}
                    iconAlign="left"
                    href={`/companies/${data!.id}`}
                    transform="shrink-3 down-1"
                    size="sm"
                    className="w-50 px-2 text-nowrap"
                  >
                    <span className="fs--2">Détails</span>
                  </IconButton>
                </Flex>
              )}
            </Col>
          </Card.Body>
        </Card>
        <Row className="border rounded-3 p-x1 mt-3 bg-white dark__bg-1000 mx-0 g-0">
          <Col md={6} xl={12} className="pe-md-4 pe-xl-0">
            <div className="mb-2">
              <Form.Label>#</Form.Label>
              <p className="mb-0 text-700 fs--1">
                {data?.id ?? <UnknownBadge />}
              </p>
            </div>
            <div className="mb-2">
              <Form.Label>Prix moyen estimé</Form.Label>
              <p className="mb-0 text-700 fs--1">
                {data?.averagePrice ? (
                  `${data?.averagePrice} €`
                ) : (
                  <UnknownBadge />
                )}
              </p>
            </div>
            <div className="mb-2">
              <Form.Label>Durée estimée du trajet</Form.Label>
              <p className="mb-0 text-700 fs--1">
                {data?.initialEta ? (
                  `${data?.initialEta} minutes`
                ) : (
                  <UnknownBadge />
                )}
              </p>
            </div>
            <div className="mb-2">
              <Form.Label>Distance estimée du trajet</Form.Label>
              <p className="mb-0 text-700 fs--1">
                {data?.initialDistance ? (
                  `${data.initialDistance} km`
                ) : (
                  <UnknownBadge />
                )}
              </p>
            </div>
            <div className="mb-2">
              <Form.Label>Adresse de départ</Form.Label>
              <p className="mb-0 text-700 fs--1">
                {data?.origin ? (
                  <Link to={data.origin.link} target="_blank">
                    {data.origin.address}
                  </Link>
                ) : (
                  <UnknownBadge />
                )}
              </p>
            </div>
            <div className="mb-2">
              <Form.Label>Adresse de d'arrivée</Form.Label>
              <p className="mb-0 text-700 fs--1">
                {data?.destination ? (
                  <Link to={data.destination.link} target="_blank">
                    {data.destination.address}
                  </Link>
                ) : (
                  <UnknownBadge />
                )}
              </p>
            </div>
          </Col>
          <Col md={6} xl={12} className="ps-md-4 ps-xl-0"></Col>
        </Row>
      </div>
    </div>
  );
};

export default RequestDetailsCard;
