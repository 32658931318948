import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import { IDefaultCardProps } from '@/components/interfaces/Card';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { Card as C } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const Card: any = C

const RequestHeader: FC<IDefaultCardProps> = ({ id, isEdit }) => {
  const navigation = useNavigate();

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigation(-1)}
          variant="nolina-default"
          size="sm"
          icon={faArrowLeft}
        />
      </Card.Header>
    </Card>
  );
};
export default RequestHeader;
