import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { getStatusName } from '@/helpers/DriverHelpers';
import Driver from '@/interfaces/Driver';
import {
  DriverBlockedStatusSelect,
  DriverBlockedTypeSelect,
  IDriverBlockForm,
  IDriverUpdateStatusForm,
} from '@/interfaces/DriverBlocked';
import { IModalMethods } from '@/interfaces/Modal';
import { IUserForm } from '@/interfaces/User';
import {
  useBlockDriverMutation,
  useChangeDriverStatusMutation,
  useGetDriverQuery,
} from '@/services/drivers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

type UpdateStatusDriverModalProps = {
  idDriver: string;
  blockedProfile?: Driver['blockedProfile'];
};

const UpdateStatusDriverModal: ForwardRefRenderFunction<
  IModalMethods,
  UpdateStatusDriverModalProps
> = ({ idDriver, blockedProfile }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDriverUpdateStatusForm>();
  const [changeDriverStatus] = useChangeDriverStatusMutation();
  const { data, isLoading, isError, error } = useGetDriverQuery(idDriver);

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();
  useEffect(() => {
    if (data) {
      reset({
        status: DriverBlockedStatusSelect.find(
          v => v.value === data?.blockedProfile?.status
        ),
      });
    }
  }, [data]);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IDriverUpdateStatusForm> = ({ status }) => {
    toast.promise(
      changeDriverStatus([
        idDriver,
        blockedProfile?.id ?? '',
        { status: status.value },
      ]).unwrap(),
      {
        pending: 'Changement du status du chauffeur...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le status du chauffeur à été remplacé par {status.label} 🚙🚙
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IUserForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Change a driver status"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Status <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="status"
              rules={{
                required: `Merci d'indiquer le futur status.`,
              }}
              render={({ field }) => (
                <Select
                  options={DriverBlockedStatusSelect}
                  value={field.value}
                  onChange={field.onChange}
                  required
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.status,
              })}
            >
              {errors.status?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(UpdateStatusDriverModal);
