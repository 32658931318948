import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AllRequestsHeader from '@/components/table-headers/requests/AllRequestsHeader';
import AllRidesHeader from '@/components/table-headers/rides/AllRidesHeader';
import {
  getColorFromRideStatus,
  getReelRideStatus,
} from '@/helpers/UserHelpers';
import IRide, { IRideFilters, ISearchRide } from '@/interfaces/Ride';
import { useGetRidesQuery } from '@/services/rides';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const DriverRidesTable: FC<ISearchRide> = filters => {
  const [driverRidesData, setDriverRidesData] = useState<IRide[]>([]);
  const [state, setState] = useState<Partial<TableState> & IRideFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error, refetch } = useGetRidesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
    ...filters,
  });

  useEffect(() => {
    if (data) {
      setDriverRidesData(data.data || []);
    }
  }, [data]);

  const columns = useRef<ColumnDef<IRide>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <Link to={`/rides/${id}`} className="btn btn-primary btn-sm">
          <FontAwesomeIcon icon={faEye} />
        </Link>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Date\nTime',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) => (
        <>
          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(createdAt).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'DateTime',
      cell: info => info.getValue(),
    },
    {
      header: 'Start Address\nDestination Address',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({
        offer: {
          request: { origin, destination },
        },
      }) => (
        <>
          {origin.address}
          <p className="mb-0 text-500">{destination.address}</p>
        </>
      ),
      id: 'origin',
      cell: info => info.getValue(),
    },
    {
      header: 'Start Time\nEnd Time',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ driverArrivedAt, completedAt }) => (
        <>
          {driverArrivedAt ? (
            DateTime.fromISO(driverArrivedAt).toFormat('dd/MM/yyyy')
          ) : (
            <UnknownBadge />
          )}
          {completedAt ? (
            <p className="mb-0 text-500">
              {DateTime.fromISO(completedAt).toFormat('HH:mm')}
            </p>
          ) : (
            <UnknownBadge />
          )}
        </>
      ),
      id: 'driverArrivedAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Last full location',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({
        driverRequest,
      }) => (
        <>
          {driverRequest?.driverService?.driverProfile?.lastFullLocation ? (
            driverRequest?.driverService?.driverProfile?.lastFullLocation
          ) : (
            <UnknownBadge />
          )}
        </>
      ),
      id: 'driverLastLocation',
      cell: info => info.getValue(),
    },
    {
      header: 'Ride Status\nPayment Status',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ status }) => (
        <SoftBadge bg={getColorFromRideStatus(status)}>
          {getReelRideStatus(status)}
        </SoftBadge>
      ),
      id: 'status',
      cell: info => info.getValue(),
    },
    {
      header: 'Final Price\nBasePrice',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ offer: { price, originalPrice } }) => (
        <>
          {`${price}€`}
          <p className="mb-0 text-500">{`${originalPrice}€`}</p>
        </>
      ),
      id: 'offer.price',
      cell: info => info.getValue(),
    },
    {
      header: 'Promo\nName',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ offer }) => {
        return (
          <>
            {offer.userCoupon?.coupon?.name ? (
              <>
                {`${offer.couponDiscountAmount}€`}
                <p className="mb-0 text-500">{offer.userCoupon.coupon.name}</p>
              </>
            ) : (
              'No promo used'
            )}
          </>
        );
      },
      id: 'offer.couponDiscountAmount',
      cell: info => {
        return info.getValue();
      },
    },

    {
      header: 'Initial Distance\nFinal Distance',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ offer }) => <>{`${offer.request.initialDistance}km`}</>,
      id: 'distance',
      cell: info => info.getValue(),
    },
    {
      header: 'Crée le',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Ride ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => id,
      id: 'id',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Table
      HeaderComponent={AllRidesHeader}
      columns={columns}
      state={state}
      onStateChange={setState}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default DriverRidesTable;
