import Modal from '@/components/common/Modal';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import { IDriverVehicleStatusTypeSelect } from '@/helpers/DriverVehiculeHelpers';
import useLoadOptions from '@/hooks/useLoadOptions';
import DriverVehicle, {
  DriverVehicleFormUpdate,
} from '@/interfaces/DriverVehicle';
import { useUpdateDriverVehicleMutation } from '@/services/drivers/vehicles';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import InputColor from '@/components/common/InputColor';
import { ToastContentProps, toast } from 'react-toastify';

type UpdateStatusDriverModalProps = {
  idDriver: string;
};

type IModalMethodsParams = {
  vehicle: DriverVehicle;
};

type IModalMethods = {
  showOrHiddenModal: (arg: IModalMethodsParams) => void;
};

const UpdateDriverVehicleModal: ForwardRefRenderFunction<
  IModalMethods,
  UpdateStatusDriverModalProps
> = ({ idDriver }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<DriverVehicleFormUpdate>();
  const [updateDriverVehicle] = useUpdateDriverVehicleMutation();
  const [args, setArgs] = useState<IModalMethodsParams | undefined>();
  const { loadVehicles, loadOffersOptions } = useLoadOptions();

  const showOrHiddenModal = (doc?: IModalMethodsParams) => {
    if (doc) setArgs(doc);
    ModalRef.current?.showOrHiddenModal();
  };

  useEffect(() => {
    if (args) {
      reset({
        status: IDriverVehicleStatusTypeSelect.find(
          v => v.value === args.vehicle.status
        ),
        color: args.vehicle.color,
        licensePlate: args.vehicle.licensePlate,
        year: args.vehicle.year,
        colorLabel: args.vehicle.colorLabel,
        default: args.vehicle.default,
        availableOfferIds:
          args.vehicle.offers?.map(offer => ({
            id: offer.id,
            name: offer.name,
          })) ?? [],
        vehicleId: {
          id: args.vehicle.vehicle.id,
          brand: args.vehicle.vehicle.brand,
          model: args.vehicle.vehicle.model,
        },
      });
    }
  }, [args, reset]);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<DriverVehicleFormUpdate> = ({ ...props }) => {
    if (args === undefined) return;

    const payload = {
      ...props,
      availableOfferIds: props.availableOfferIds?.map(offer => offer.id) ?? [],
      vehicleId: props.vehicleId?.id,
      status: props.status.value,
    };

    toast.promise(
      updateDriverVehicle([idDriver, args.vehicle.id, payload]).unwrap(),
      {
        pending: 'Changement du status du chauffeur...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le status du chauffeur à été remplacé par {props.status.label}{' '}
                🚙🚙
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<DriverVehicleFormUpdate> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Modifier le vehicle"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Status <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="status"
              rules={{
                required: `Merci d'indiquer le futur status.`,
              }}
              render={({ field }) => (
                <Select
                  options={IDriverVehicleStatusTypeSelect}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.status,
              })}
            >
              {errors.status?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Default Vehicle"
              {...register('default')}
              checked={watch('default')}
              onChange={e => setValue('default', e.target.checked)}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr></hr>
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Vehicle <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="vehicleId"
              rules={{ required: 'Ce champ est requis.' }}
              render={({ field }) => (
                <SelectPaginateAsync
                  loadOptions={loadVehicles}
                  getOptionLabel={option => `${option.brand} ${option.model}`}
                  getOptionValue={option => option.id}
                  value={field.value}
                  required
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': errors.vehicleId?.message,
              })}
            >
              {errors.vehicleId?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Color <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="color"
              rules={{
                required: 'Ce champ est requis.',
              }}
              render={({ field }) => <InputColor {...field} />}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': errors.color?.message,
              })}
            >
              {errors.color?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Available offers <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="availableOfferIds"
              rules={{
                required: 'Ce champ est requis.',
                validate: value => value.length > 0 || 'Ce champ est requis.',
              }}
              render={({ field }) => (
                <SelectPaginateAsync
                  loadOptions={loadOffersOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  isMulti
                  value={field.value}
                  required
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': errors.availableOfferIds?.message,
              })}
            >
              {errors.availableOfferIds?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Color label <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Bleu sky"
              type="text"
              {...register('colorLabel', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 50,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.colorLabel}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': errors.colorLabel?.message,
              })}
            >
              {errors.colorLabel?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              License Plate <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : EK-366-AS"
              type="text"
              {...register('licensePlate', {
                required: 'Ce champ est requis.',
                pattern: /^[A-Z]{2} ?- ?\d{3} ?- ?[A-Z]{2}$/,
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 10,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.licensePlate}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': errors.licensePlate?.message,
              })}
            >
              {errors.licensePlate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Year <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 2014"
              type="text"
              {...register('year', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 4,
                  message: 'Ce champ doit contenir au moins 4 caractères.',
                },
                maxLength: {
                  value: 50,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.year}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': errors.year?.message,
              })}
            >
              {errors.year?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(UpdateDriverVehicleModal);
