import CardHeader from '@/components/card-headers/CardHeader';
import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import useLoadOptions from '@/hooks/useLoadOptions';
import Driver, {
  ComissionRateOptions,
  DRIVER_EXPERIENCE_YEARS_TYPES_SELECT,
  DRIVER_PRIMARY_SHIFT_TYPES_SELECT,
  IDriverFormUpdate,
  WEEKLY_DRIVING_HOURS_TYPES_SELECT,
} from '@/interfaces/Driver';
import { useGetDriverQuery, useUpdateDriverMutation } from '@/services/drivers';
import { useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useMemo } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

type UpdateUserCardProps = {
  id: string;
  callBack?: (driver: Driver) => void;
};

const UpdateDriverCard: FC<UpdateUserCardProps> = ({ id, callBack }) => {
  const { data, isLoading, isError, error } = useGetDriverQuery(id);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IDriverFormUpdate>({defaultValues: {
    commissionRate: {value: 10, label: '10%'},
  }});
  const navigation = useNavigate();
  const [updateDriverAsync] = useUpdateDriverMutation();
  const { loadCompaniesOptions } = useLoadOptions();

  useMemo(() => {
    if (callBack && data) {
      callBack(data);
    }
  }, [data]);

  useEffect(() => {
    data &&
      reset({
        ...data,
        driverExperienceYears: DRIVER_EXPERIENCE_YEARS_TYPES_SELECT.find(
          option => option.value === data.driverExperienceYears
        ),
        weeklyDrivingHours: WEEKLY_DRIVING_HOURS_TYPES_SELECT.find(
          option => option.value === data.weeklyDrivingHours
        ),
        primaryShift: DRIVER_PRIMARY_SHIFT_TYPES_SELECT.find(
          option => option.value === data.primaryShift
        ),
        commissionRate: ComissionRateOptions.find(
          option => option.value === data.commissionRate
        ),
      });
  }, [data]);

  const onSubmit: SubmitHandler<IDriverFormUpdate> = ({ ...data }) => {
    toast.promise(
      updateDriverAsync([
        id,
        {
          companyId: data.company?.id,
          driverExperienceYears: data.driverExperienceYears?.value,
          weeklyDrivingHours: data.weeklyDrivingHours?.value,
          primaryShift: data.primaryShift?.value,
          referralCode: data.referralCode,
          commissionRate: data.commissionRate?.value as any,
        },
      ]).unwrap(),
      {
        pending: 'Modification du chauffeur en cours...',
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                La modification à bien été éffectuée
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IDriverFormUpdate> = () => {};

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification du chauffeur" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Driver experience years <span className="text-danger">*</span>{' '}
                  :
                </Form.Label>
                <Controller
                  control={control}
                  name="driverExperienceYears"
                  render={({ field }) => (
                    <Select
                      options={DRIVER_EXPERIENCE_YEARS_TYPES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.driverExperienceYears?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Weekly driving hours <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="weeklyDrivingHours"
                  render={({ field }) => (
                    <Select
                      options={WEEKLY_DRIVING_HOURS_TYPES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.weeklyDrivingHours?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Primary shift <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="primaryShift"
                  render={({ field }) => (
                    <Select
                      options={DRIVER_PRIMARY_SHIFT_TYPES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.primaryShift?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Company <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="company"
                  render={({ field }) => (
                    <SelectPaginateAsync
                      loadOptions={loadCompaniesOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.company?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Referral Code <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : DANY9679"
                  type="text"
                  {...register('referralCode', {
                    minLength: {
                      value: 6,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 12,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.referralCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.referralCode?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Commission Rate <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="commissionRate"
                  render={({ field }) => (
                    <Select
                      options={ComissionRateOptions}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.commissionRate?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateDriverCard;
