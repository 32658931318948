import Table from '@/components/common/table/Table';
import UpdatableCell from '@/components/common/table/UpdatableCell';
import { UserToUserReferralHeader } from '@/components/table-headers/userToUserReferral/UserToUserReferralHeader';
import { INVALID_FORM_MESSAGE } from '@/constants/validatorMessages';
import {
  IUserToUserReferral,
  IUserToUserReferralForm,
} from '@/interfaces/UserToUserReferral';
import {
  useGetUserToUserReferralQuery,
  useUpdateuserReferralConfigurationMutation,
} from '@/services/userToUserReferral';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { toast, ToastContentProps } from 'react-toastify';

export const UserToUserReferral = () => {
  const [state, setState] = useState<Partial<TableState>>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data } = useGetUserToUserReferralQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [updateUserReferralAsync] =
    useUpdateuserReferralConfigurationMutation();

  const { control, handleSubmit, reset } = useForm<IUserToUserReferralForm>();

  const onSubmit: SubmitHandler<IUserToUserReferralForm> = async (
    updatedData: IUserToUserReferralForm
  ) => {
    const dataToSubmit = { ...data.data[0], ...updatedData.data[0] };

    toast.promise(updateUserReferralAsync([dataToSubmit, dataToSubmit.id]), {
      pending: 'Modifying the column...',
      success: {
        render() {
          reset();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              The user to user configuration has been successfully modified 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'An error occurred';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IUserToUserReferralForm> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  const { append, fields } = useFieldArray({
    control,
    name: 'data',
  });

  const onUpdate = (id: string, columnId: string, value: any) => {
    append([
      {
        id,
        [columnId]: parseInt(value),
      } as IUserToUserReferralForm['data'][0],
    ]);
  };

  const columns = useRef<ColumnDef<IUserToUserReferral>[]>([
    {
      header: 'Referred User promo amount',
      accessorKey: 'referred_user_promo_amount',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
    {
      header: 'Referred User coupon validity',
      accessorKey: 'referred_user_coupon_validity',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
    {
      header: 'Required ride for referred',
      accessorKey: 'required_rides_for_sponsor_coupon',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
    {
      header: 'Referrer coupon amount',
      accessorKey: 'sponsor_coupon_amount',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
    {
      header: 'Referrer coupon validity',
      accessorKey: 'sponsor_coupon_validity',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
  ]).current;


  return (
    <>
      <Table
        HeaderComponent={UserToUserReferralHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
      {fields.length > 0 && (
        <Button className="mt-3" onClick={handleSubmit(onSubmit, onError)}>
          Save Changes
        </Button>
      )}
    </>
  );
};
