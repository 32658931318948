import { getReelTypeNameOfDocument } from '@/helpers/DriverDocumentsHelpers';
import { DriverVehicleDocumentStatus } from '../DriverVehicleDocument';
import { IFilters } from '../Fetch';
import IFile from '../IFile';
import { ISelectOption } from '../Select';

export const DriverDocumentStatusType = [
  'identityDocumentVerification',
  'legalChauffeurTransportVehicleDrivingAuthorization',
  'legalRoadVehicleDrivingAuthorization',
  'officialFrenchBusinessExistenceCertificate',
  'professionalLiabilityInsurance',
  'vtcRegistrationCertificate',
];

export const DriverDocumentTypeSelect: ISelectOption<DriverDocumentType>[] =
  DriverDocumentStatusType.map(type => ({
    value: type,
    label: getReelTypeNameOfDocument(type),
  }));

export type DriverDocumentType = (typeof DriverDocumentStatusType)[number];

export interface DriverDocument {
  id: string;
  status: DriverVehicleDocumentStatus;
  driverProfileId: string;
  fileId: string;
  file: IFile;
  type: DriverDocumentType;
  createdAt: string;
  updatedAt: string;
  comment: null | string;
  link: string;
  linkExpiredAt: null | string;
}

export interface DriverDocumentForm {
  status: ISelectOption<DriverVehicleDocumentStatus>;
  file: IFile;
  type: ISelectOption<DriverDocumentType>;
  comment: null | string;
  name: string;
}

export interface DriverDocumentFormUpdate
  extends Pick<DriverDocumentForm, 'status'> {}

export interface DriverDocumentFormStore
  extends Omit<DriverDocumentForm, 'status'> {}

export interface DriverDocumentFormSend
  extends Omit<DriverDocumentForm, 'status' | 'type'> {
  status: DriverVehicleDocumentStatus;
  type: DriverDocumentType;
}

export interface DriverDocumentFilters extends IFilters {
  driverId: string;
  vehicleId: string;
}
