import ErrorComponent from "@/components/common/ErrorComponent";
import { UUID_REGEX } from "@/constants/regexs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import EarnPointHeader from "@/components/card-headers/earnPoints/EarnPointHeader";
import UpdateEarnPointCard from "@/components/updates/earnPoints/UpdateEarnPointCard";

const EarnPointEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
        <EarnPointHeader id={id} isEdit />
        <UpdateEarnPointCard id={id} />
    </div>
  );
};

export default EarnPointEditView;