import { IModalMethods } from '@/interfaces/Modal';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Modal from '@/components/common/Modal';
import { EARNPOINT_TYPES, USER_TYPES } from '@/interfaces/EarnPoint';
import { ToastContentProps, toast } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Select from '@/components/common/Select';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { IEarnPointForm } from '@/interfaces/EarnPoint';
import { useStoreEarnPointMutation } from '@/services/earnPoints';

const StoreEarnPointsModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IEarnPointForm>({
    defaultValues: {
      userType: USER_TYPES[0],
      criteria: {
        type: EARNPOINT_TYPES[0],
        userType: USER_TYPES[0],
      },
    },
  });
  const [storeEarnPointsAsync] = useStoreEarnPointMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  const [showValueField, setShowValueField] = useState(false);
  const [showUserTypeField, setShowUserTypeField] = useState(false);

  const [isArchived, setIsArchived] = useState(false);

  const [valueLabel, setValueLabel] = useState('Value');

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IEarnPointForm> = ({
    userType,
    criteria,
    ...data
  }) => {
    toast.promise(
      storeEarnPointsAsync({
        ...data,
        userType: userType.value,
        isArchived,
        criteria: {
          ...criteria,
          userType: criteria.userType?.value,
          type: criteria.type.value,
        },
      }).unwrap(),
      {
        pending: 'Ajout du earnPoints en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le earnPoints à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IEarnPointForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un earnPoints"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Get 10% discount"
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              User Type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="userType"
              render={({ field }) => (
                <Select
                  options={USER_TYPES}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.userType?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Points for Referrer <span className="text-danger">*</span> :
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="ex : 10"
                type="number"
                {...register('points', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Le Points doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le Points doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.points}
              />
              <Form.Control.Feedback type="invalid">
                {errors.points?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Limit :</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="ex : 10"
                type="number"
                {...register('limit', {
                  minLength: {
                    value: 2,
                    message: 'Le Points doit contenir au moins 1 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le Points doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.limit}
              />
              <Form.Control.Feedback type="invalid">
                {errors.limit?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Description Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Enjoy your reward"
              type="text"
              {...register('descriptionTitle', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.descriptionTitle}
            />
            <Form.Control.Feedback type="invalid">
              {errors.descriptionTitle?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            as="textarea"
            type="text"
            {...register('description', {
              required: 'Ce champ est requis.',
              minLength: {
                value: 3,
                message: 'Ce champ doit contenir au moins 3 caractères.',
              },
              maxLength: {
                value: 255,
                message: 'Ce champ doit contenir au plus 255 caractères.',
              },
            })}
            style={{ minHeight: 100 }}
          />
        </Col>

        {/* EarnPointCriteriaSection */}
        <>
          <Col className="mb-3 fw-bold mt-3" lg={12}>
            Earn Points Criteria
          </Col>
        </>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Criteria type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="criteria.type"
              render={({ field }) => (
                <Select
                  options={EARNPOINT_TYPES}
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    setShowValueField(
                      value?.value === 'referral' ||
                        value?.value === 'completed_rides'
                    );
                    setShowUserTypeField(value?.value === 'referral');
                    if (value?.value === 'referral') {
                      setValueLabel('Points for Referred');
                    } else {
                      setValueLabel('Value');
                    }
                  }}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.criteria?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {showValueField && (
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                {valueLabel} <span className="text-danger">*</span> :
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="ex : 5"
                  type="number"
                  {...register('criteria.value', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Le value doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Le value doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.criteria?.value}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.criteria?.value?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        )}

        {showUserTypeField && (
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                User Type <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="criteria.userType"
                render={({ field }) => (
                  <Select
                    options={USER_TYPES}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.criteria?.userType?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}

        {/* END OF EarnPointCriteriaSection */}
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreEarnPointsModal);
