import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AllDriversBlocksHeader from '@/components/table-headers/drivers/blocks/AllDriversBlocksHeader';
import { getColorFromStatus } from '@/helpers/DriverHelpers';
import { getBackgroundFromColor } from '@/helpers/StatisticHelpers';
import { getGenderAlias } from '@/helpers/UserHelpers';
import { splitText } from '@/helpers/utils';
import IDriverBlocked, {
  DriverBlockedTypeSelect,
  IDriverBlocksFilters,
} from '@/interfaces/DriverBlocked';
import { useGetDriverBlocksQuery } from '@/services/drivers/blocks';
import { useGetRidesQuery } from '@/services/rides';
import { selectCurrentUser } from '@/services/slices/authSlice';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

type DriverBlocksTableProps = {
  id: string;
};

const DriverBlocksTable: FC<DriverBlocksTableProps> = ({ id }) => {
  const [state, setState] = useState<
    Partial<TableState> & IDriverBlocksFilters
  >({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });
  const user = useSelector(selectCurrentUser);
  const { data, isLoading, isError, error } = useGetDriverBlocksQuery([
    id,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const columns = useRef<ColumnDef<IDriverBlocked>[]>([
    {
      header: 'Block ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => splitText(id, 25),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Message',
      footer: props => props.column.id,
      accessorFn: ({ message }) => message ?? <UnknownBadge />,
      id: 'message',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ status }) => (
        <SoftBadge bg={getColorFromStatus(status)}>{status}</SoftBadge>
      ),
      id: 'status',
      cell: info => info.getValue(),
    },
    {
      header: 'Type',
      footer: props => props.column.id,
      accessorFn: ({ type }) => (
        <span
          className={classNames(
            'badge border link-secondary text-decoration-none'
          )}
        >
          {DriverBlockedTypeSelect.find(v => v.value === type)?.label}
        </span>
      ),
      id: 'type',
      cell: info => info.getValue(),
    },
    {
      header: 'Blocked By',
      footer: props => props.column.id,
      accessorFn: ({ blockedBy }) => (
        <>
          {blockedBy ? (
            <Link to={`/users/${blockedBy.id}`}>
              <Flex alignItems="center">
                {blockedBy.avatar && (
                  <Avatar
                    src={blockedBy.avatar.link}
                    alt={`${blockedBy.firstname} Avatar`}
                    className="me-2"
                    size="2xl"
                  />
                )}
                <p className="mb-0">
                  {blockedBy.gender && (
                    <span>{getGenderAlias(blockedBy.gender)}</span>
                  )}{' '}
                  <span>{blockedBy.firstname}</span>
                  <br />
                  <span>{blockedBy.lastname}</span>
                </p>
                {user?.id === id && <b className="ms-2">(moi)</b>}
              </Flex>
            </Link>
          ) : (
            <UnknownBadge />
          )}
        </>
      ),
      id: 'blockedBy',
      cell: info => info.getValue(),
    },
    {
      header: 'UnBlocked By',
      footer: props => props.column.id,
      accessorFn: ({ unblockedBy }) => (
        <>
          {unblockedBy ? (
            <Link to={`/users/${unblockedBy.id}`}>
              <Flex alignItems="center">
                {unblockedBy.avatar && (
                  <Avatar
                    src={unblockedBy.avatar.link}
                    alt={`${unblockedBy.firstname} Avatar`}
                    className="me-2"
                    size="2xl"
                  />
                )}
                <p className="mb-0">
                  {unblockedBy.gender && (
                    <span>{getGenderAlias(unblockedBy.gender)}</span>
                  )}{' '}
                  <span>{unblockedBy.firstname}</span>
                  <br />
                  <span>{unblockedBy.lastname}</span>
                </p>
                {user?.id === id && <b className="ms-2">(moi)</b>}
              </Flex>
            </Link>
          ) : (
            <UnknownBadge />
          )}
        </>
      ),
      id: 'unlockedBy',
      cell: info => info.getValue(),
    },

    {
      header: 'Created At\nTime',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) => (
        <>
          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(createdAt).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'CreatedAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Update At\nTime',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ updatedAt }) => (
        <>
          {DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(updatedAt).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'UpdatedAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Table
      HeaderComponent={AllDriversBlocksHeader}
      columns={columns}
      state={state}
      onStateChange={setState}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default DriverBlocksTable;
