import { addDays, format } from 'date-fns';
import { DynamicData } from './TableHelper';
import {
  CompanyBusinessType,
  CompanyCountry,
  CompanyFormCreateSendForImport,
} from '@/interfaces/Company';
import { getReelGender } from './UserHelpers';

export const formCsvArrayToCompany = (d: DynamicData) => {
  return {
    addressId: d.company_address as string,
    postalCode: d.company_postal_code as string,
    supportAddressId: d.company_address as string,
    bankAccountCountry: d.bank_account_country as CompanyCountry,
    country: d.company_country as CompanyCountry,
    bankAccountCurrency: 'EUR',
    bankAccountHolderName: d.account_holder_name as string,
    bankAccountHolderType: d.company_business_type as CompanyBusinessType,
    bankAccountNumber: d.iban_formatted as string,
    businessType: (
      d.company_business_type as CompanyBusinessType
    ).toLowerCase(),
    chargesEnabled: false,
    defaultCurrency: 'EUR',
    detailsSubmitted: false,
    email: d.lowercased_email as string,
    name: d.company_name as string,
    payoutsEnabled: false,
    phone: d.Phone_Number as string,
    status: 'pending',
    taxId: d.Company_SIREN as string,
    vatId: d.TVA_Number as string,
    owner: {
      gender: getReelGender(d.Sexe as string),
      firstname: d.company_owner_firstname as string,
      lastname: d.Company_Owner_Lastname as string,
      email: d.lowercased_email as string,
      phone: d.Phone_Number as string,
      addressId: d.company_address as string,
      role: 'owner',
      title: d.Company_owner_title,
      nationality: d.Company_owner_nationality as string | undefined,
      percentOwnership: 100,
      birthdate: (d.company_owner_birthdate as string | undefined)
        ? format(d.company_owner_birthdate as string, 'dd-MM-yyyy')
        : undefined,
    },
  } as CompanyFormCreateSendForImport;
};
