import ErrorComponent from '@/components/common/ErrorComponent';
import IconButton from '@/components/common/IconButton';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllCompaniesHeader from '@/components/table-headers/companies/AllCompaniesHeader';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import { splitText } from '@/helpers/utils';
import Company from '@/interfaces/Company';
import { IFilters } from '@/interfaces/Fetch';
import {
  useDestroyCompanyMutation,
  useGetCompaniesQuery,
} from '@/services/companies';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';

import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { ElementRef, FC, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const CompaniesView: FC = () => {
  const userConfig = useSelector(selectCurrentUserConfig);

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [removeCompanyAsync] = useDestroyCompanyMutation();

  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetCompaniesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const totalCompanies = data?.meta?.total;

  const columns: ColumnDef<Company>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <div className="gap-2 d-flex">
            <Link to={`/companies/${id}`} className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link to={`/companies/${id}/edit`}>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faEdit}
                transform="shrink-3"
                iconAlign="middle"
              />
            </Link>
            {userConfig.isAdminOrSuperAdmin && (
              <IconButton
                variant="outline-danger"
                size="sm"
                icon={faTrash}
                onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
                transform="shrink-3"
                iconAlign="middle"
              />
            )}
          </div>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        id: 'number',
        cell: info => {
          if (totalCompanies === undefined) {
            return 'Loading...';
          }
          const pageIndex = info.table.getState().pagination.pageIndex;
          const pageSize = info.table.getState().pagination.pageSize;
          const rowIndex = info.row.index;
          const recordNumber =
            totalCompanies - (pageIndex * pageSize + rowIndex);
          return recordNumber;
        },
      },
      {
        header: 'Nom',
        accessorFn: ({ name }) => name,
        id: 'name',
      },
      {
        header: 'Adresse',
        accessorFn: ({ address }) => (
          <Link to={`${GOOGLEMAPSWEBLINK}${address.placeId}`}>
            {splitText(address.address, 23)}
          </Link>
        ),
        id: 'addresse',
        cell: info => info.getValue(),
      },
      {
        header: 'Téléphone',
        accessorFn: ({ phone }) => phone,
        id: 'phone',
      },
      {
        header: 'Email',
        footer: props => props.column.id,
        accessorFn: ({ email }) => <Link to={`mailto:${email}`}>{email}</Link>,
        id: 'email',
        cell: info => info.getValue(),
      },
      {
        header: 'Default Currency',
        accessorFn: ({ defaultCurrency }) => (
          <SoftBadge bg="info">{defaultCurrency}</SoftBadge>
        ),
        id: 'Default currency',
        cell: info => info.getValue(),
      },
      {
        header: 'Tax Id',
        accessorFn: ({ taxId }) => taxId,
        id: 'Tax ID',
      },
    ],
    [totalCompanies, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  const onDelete = (id: string) => {
    toast.promise(removeCompanyAsync(id).unwrap(), {
      pending: 'Suppression du de la compagnie en cours...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              La compagnie à bien été retirée.
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé la compagnie : {{id}} ?"
      />
      <Table
        HeaderComponent={AllCompaniesHeader}
        columns={columns}
        state={state}
        tableName="Companies"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default CompaniesView;
