import { IModalMethods, IModalProps } from "@/interfaces/Modal";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Form, Modal as RBModal } from "react-bootstrap";

const Modal: ForwardRefRenderFunction<IModalMethods, IModalProps> = (
  {
    onSubmit,
    children,
    beforeFooter,
    title,
    dangerDescription,
    onHide,
    size = "xl",
    continueTitle = "Continuer",
    requiredLabel = false,
  },
  ref
) => {
  const [show, setShow] = useState<boolean>(false);

  const showOrHiddenModal = () =>
    setShow((old) => {
      if (old && onHide) onHide();
      return !old;
    });

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  return (
    <RBModal
      size={size}
      show={show}
      aria-labelledby="contained-modal-title-center"
      onHide={showOrHiddenModal}
      centered
    >
      {onSubmit ? (
        <Form onSubmit={onSubmit}>
          <RBModal.Header closeButton>
            <RBModal.Title
              id="contained-RBModal-title-vcenter"
              className="d-flex flex-column"
            >
              {title}
              {requiredLabel && (
                <span className="mt-2 h6">
                  <span className="text-danger">*</span> champ(s) obligatoire(s)
                </span>
              )}
              {dangerDescription && (
                <span className="mt-2 h6">
                  <span className="text-danger">{dangerDescription}</span>
                </span>
              )}
            </RBModal.Title>
          </RBModal.Header>
          <RBModal.Body>{children}</RBModal.Body>
          <RBModal.Footer>
            {beforeFooter}
            <Button type="submit">{continueTitle}</Button>
            <Button onClick={() => showOrHiddenModal()} variant="danger">
              Fermer
            </Button>
          </RBModal.Footer>
        </Form>
      ) : (
        <>
          <RBModal.Header closeButton>
            <RBModal.Title
              id="contained-RBModal-title-vcenter"
              className="d-flex flex-column"
            >
              {title}
              {dangerDescription && (
                <span className="mt-2 text-danger">{dangerDescription}</span>
              )}
            </RBModal.Title>
          </RBModal.Header>
          <RBModal.Body>{children}</RBModal.Body>
          <RBModal.Footer>
            {beforeFooter}
            <Button onClick={showOrHiddenModal} variant="danger">
              Fermer
            </Button>
          </RBModal.Footer>
        </>
      )}
    </RBModal>
  );
};

export default forwardRef(Modal);
