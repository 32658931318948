import { IFilters, IPaginateResponse } from "@/interfaces/Fetch";
import { createFormDataRequest } from "../helpers/utils";
import api from "./api";
import { IDrivingPreferences, IDrivingPreferencesForm } from "@/interfaces/DrivingPreferences";

export const drivingPreferencesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDrivingPreferences: build.query<IPaginateResponse<IDrivingPreferences>, IFilters>({
      query: (data) => {
        const params = createFormDataRequest(data);
        return {
          url: "/drivingPreferences",
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.data.map(({ id }) => ({
              type: "DrivingPreferences" as const,
              id,
            })),
            { type: "DrivingPreferences", id: "LIST", ...filters },
          ]
          : [],
    }),
    getDrivingPreference: build.query<IDrivingPreferences, string>({
      query: id => `/drivingPreferences/${id}`,
      providesTags: (_coupon, _err) => [{ type: 'DrivingPreferences', id: 'LIST' }],
    }),
    createDrivingPreference: build.mutation<IDrivingPreferences, IDrivingPreferencesForm>({
      query: company => ({
        url: '/drivingPreferences',
        method: 'POST',
        body: company,
      }),
      invalidatesTags: [{ type: 'DrivingPreferences', id: 'LIST' }],
    }),
    removeDrivingPreference: build.mutation<void, string>({
      query: id => ({
        url: `/drivingPreferences/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'DrivingPreferences', id: 'LIST' }],
    }),
    editDrivingPreference: build.mutation<IDrivingPreferences, [string, Partial<IDrivingPreferencesForm>]>({
      query: ([id, drivingPreference]) => ({
        url: `/drivingPreferences/${id}`,
        method: 'PUT',
        body: drivingPreference,
      }),
      invalidatesTags: (result, _error, [id]) => [{ type: 'DrivingPreferences', id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDrivingPreferencesQuery,
  useLazyGetDrivingPreferencesQuery,
  useGetDrivingPreferenceQuery,
  useEditDrivingPreferenceMutation,
  useCreateDrivingPreferenceMutation,
  useRemoveDrivingPreferenceMutation
} = drivingPreferencesApi;
