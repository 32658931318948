import Driver, {
  IDriverFormSend,
  IDriverFormSendUpdate,
} from '@/interfaces/Driver';
import IDriverBlocked, {
  IDriverBlockFormSend,
} from '@/interfaces/DriverBlocked';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../../helpers/utils';
import api from '../api';

export const driversApi = api.injectEndpoints({
  endpoints: build => ({
    getDrivers: build.query<IPaginateResponse<Driver>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/drivers',
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Drivers' as const,
                id,
              })),
              { type: 'Drivers', id: 'LIST' },
            ]
          : [],
    }),
    getDriver: build.query<Driver, string>({
      query: id => `/drivers/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Drivers', id }],
    }),
    updateDriver: build.mutation<Driver, [string, IDriverFormSendUpdate]>({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/drivers/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, _error, [id]) => [
        { type: 'Drivers', id },
        { type: 'Users', id: result?.userId },
      ],
    }),
    blockDriver: build.mutation<
      IDriverBlocked,
      [string, Partial<IDriverBlockFormSend>]
    >({
      query: ([id, body]) => ({
        url: `/drivers/${id}/blocks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Drivers'],
    }),
    changeDriverStatus: build.mutation<
      IDriverBlocked,
      [string, string, Partial<IDriverBlocked>]
    >({
      query: ([idDriver, idBlock, body]) => ({
        url: `/drivers/${idDriver}/blocks/${idBlock}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, _error, [idDriver]) => [
        { type: 'Drivers', id: idDriver },
      ],
    }),
    destroyDriver: build.mutation<void, string>({
      query: id => ({
        url: `/drivers/${id}`,
        method: 'DELETE',
      }),
    }),
    storeDriver: build.mutation<Driver, IDriverFormSend>({
      query: body => ({
        url: '/drivers',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, _err, _body) => [
        { type: 'Drivers', id: 'LIST' },
        { type: 'Users', id: result?.userId },
      ],
    }),
    importDrivers: build.mutation<any, FormData>({
      query: formData => ({
        url: '/drivers/import',
        method: 'POST',
        body: formData,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDriversQuery,
  useGetDriverQuery,
  useDestroyDriverMutation,
  useUpdateDriverMutation,
  useBlockDriverMutation,
  useChangeDriverStatusMutation,
  useImportDriversMutation,
  useStoreDriverMutation,
} = driversApi;
