import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import PageHeader from '@/components/common/header/PageHeader';
import { ICardDetailsProps } from '@/interfaces/Card';
import IPartnership from '@/interfaces/Partnership/Partnership';
import { useGetPartnershipQuery } from '@/services/partnerships/partnerships';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

const PartnershipPageHeaderCard: FC<ICardDetailsProps<IPartnership>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetPartnershipQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-3">
      <Col xs={12}>
        {/* Page header */}
        <PageHeader title={`Partnership Details: ${data.name}`}>
          <p className="fs--1 mt-1">
            {DateTime.fromISO(data?.createdAt).toFormat(
              "dd LLL yyyy 'at' HH:mm"
            )}
          </p>
        </PageHeader>
        {/* end of page header */}
      </Col>
    </Row>
  );
};

export default PartnershipPageHeaderCard;
