import GoogleMap from '@/components/common/map/GoogleMaps';
import { FC, useState } from 'react';
import PinDriverVehicle from '@/assets/img/map-view-illustration/pin-driver-vehicle.svg';
import { useLiveActivityDatasQuery } from '@/services/liveActivity';
import { Marker } from '@react-google-maps/api';

export const ActivityAreaPickMap: FC = () => {
  const initialCenter = {
    lat: 48.8608685,
    lng: 2.34,
  };
  return (
    <div
      style={{
        borderRadius: 10,
        overflow: 'hidden',
        height: '100%',
        minHeight: '30vh',
      }}
      className={`position-relative`}
    >
      <GoogleMap
        initialCenter={initialCenter}
        options={{
          zoomControlOptions: {
            position: 0,
          },
          disableDefaultUI: true,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
};
