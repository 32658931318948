import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import AllReferralCodesHeader from '@/components/table-headers/partnerships/referralCodes/AllReferralCodesHeader';
import IReferralCode, {
  IReferralCodeFilters,
} from '@/interfaces/Partnership/ReferralCode';
import {
  useGetPartnershipRefferalCodesQuery,
  useDestroyPartnershipReferralCodeMutation,
} from '@/services/partnerships/referralCodes';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { ElementRef, FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnDef, TableState } from '@tanstack/react-table';
import Table from '@/components/common/table/Table';
import { Button } from 'react-bootstrap';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import { toast, ToastContentProps } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { truncate } from '@/helpers/utils';

const PartnershipReferralCodesTable: FC<{
  partnershipId: string;
}> = ({ partnershipId }) => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const [state, setState] = useState<
    Partial<TableState> & IReferralCodeFilters
  >({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });

  const [destroyReferralCodeAsync] =
    useDestroyPartnershipReferralCodeMutation();

  const { data, isLoading, isError, error } =
    useGetPartnershipRefferalCodesQuery({ partnershipId });

  const columns = useRef<ColumnDef<IReferralCode>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id: referralCodeId, partnershipId }) => (
        <>
          <Link
            to={`/partnerships/${partnershipId}/referralCodes/${referralCodeId}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() =>
              DeleteModalRef.current?.showOrHiddenModal(referralCodeId)
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Code',
      footer: props => props.column.id,
      accessorFn: ({ code }) => code,
      id: 'code',
      cell: info => info.getValue(),
    },
    {
      header: 'ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => truncate(id, 10),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Partnership ID',
      footer: props => props.column.id,
      accessorFn: ({ partnershipId }) => truncate(partnershipId, 10),
      id: 'partnershipId',
      cell: info => info.getValue(),
    },
    {
      header: 'Created at',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Updated at',
      footer: props => props.column.id,
      accessorFn: ({ updatedAt }) =>
        DateTime.fromISO(updatedAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'updatedAt',
      cell: info => info.getValue(),
    },
  ]).current;

  const onDelete = (referralCodeId: string) => {
    toast.promise(
      destroyReferralCodeAsync({ partnershipId, referralCodeId }).unwrap(),
      {
        pending: 'Suppression du partnership referral code en cours ...',
        success: {
          render() {
            DeleteModalRef.current?.showOrHiddenModal();
            return (
              <p className="mb-0 text-center">
                Le referral code a bien été supprimé 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={(referralCodeId: string) => onDelete(referralCodeId)}
        title="Souhaitez-vous vraiment supprimer le referral code qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllReferralCodesHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default PartnershipReferralCodesTable;
