import Delimiter from '@editorjs/delimiter';
import { ToolConstructable, ToolSettings } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import List from "@editorjs/list";
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';
import UnderLine from '@editorjs/underline';
import ColorPlugin from 'editorjs-text-color-plugin';
import FontSizeTool from 'editorjs-inline-font-size-tool';
import AnyButton from 'editorjs-button';
import { useCallback } from 'react';
import { useAddArticleFileMutation } from '@/services/articles';
import { IArticleForm } from '@/interfaces/Article';

type IPropsParams = {
    articleId?: IArticleForm['id']
}

type IEditorJsProps = () => { [toolName: string]: ToolConstructable | ToolSettings }

export const useEditorJsTools = ({ articleId }: IPropsParams) => {
    const [addArticleFile] = useAddArticleFileMutation()

    return useCallback(() => {
        return {
            paragraph: {
                class: Paragraph,
                inlineToolbar: true,
            },
            AnyButton: {
                class: AnyButton,
                inlineToolbar: true,
                config: {
                    css: {
                        "btnColor": "btn-primary",
                        "btn": "btn",
                    }
                }
            },
            marker: {
                class: ColorPlugin,
                shortcut: 'CMD+SHIFT+M',
                config: {
                    defaultColor: '#FFBF00',
                    type: 'marker',
                    icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`
                }
            },
            Color: {
                class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
                config: {
                    colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
                    defaultColor: '#000000',
                    type: 'text',
                    customPicker: true // add a button to allow selecting any colour  
                }
            },
            list: List,
            fontSize: {
                class: FontSizeTool,
                config: {
                    defaultSize: '18px',
                    shortcut: 'CMD+SHIFT+F',
                    allowCustomSize: true,
                }
            },
            linkTool: LinkTool,
            image: {
                class: ImageTool,
                config: {
                    uploader: {
                        async uploadByFile(file: File) {
                            if (articleId) {
                                const result = await addArticleFile([
                                    articleId,
                                    file
                                ]).unwrap()
                                return {
                                    success: 1,
                                    file: {
                                        url: result.link
                                    }
                                };
                            }
                        }
                    }
                }
            },
            header: Header,
            delimiter: Delimiter,
            quote: Quote,
            underline: UnderLine
        }
    }, [articleId, addArticleFile]) as IEditorJsProps
}