import {
  getReelNameFromDocumentsStatus,
  getReelNameFromDocumentsType,
} from '@/helpers/DriverVehiculeDocumentsHelpers';
import IFile from './IFile';
import { ISelectOption } from './Select';

export const DriverVehicleDocumentStatus_ = [
  'pending',
  'accepted',
  'rejected',
  'expired',
];

export const DriverVehicleDocumentType_ = [
  'greenCard',
  'insurance',
  'registrationCardInfo',
];

export const DriverDocumentVehicleStatusSelect: ISelectOption<DriverVehicleDocumentStatus>[] =
  DriverVehicleDocumentStatus_.map(status => ({
    value: status,
    label: getReelNameFromDocumentsStatus(status),
  }));

export const DriverDocumentVehicleTypeSelect: ISelectOption<DriverVehicleDocumentType>[] =
  DriverVehicleDocumentType_.map(type => ({
    value: type,
    label: getReelNameFromDocumentsType(type),
  }));

export type DriverVehicleDocumentStatus =
  (typeof DriverVehicleDocumentStatus_)[number];

export type DriverVehicleDocumentType =
  (typeof DriverVehicleDocumentType_)[number];

interface IDriverVehicleDocument {
  status: DriverVehicleDocumentStatus;
  id: string;
  driverVehicleId: string;
  fileId: string;
  type: DriverVehicleDocumentType;
  comment: string | null;
  createdAt: string;
  updatedAt: string;
  file: IFile;
}

export interface DriverDocumentFormUpdate
  extends Pick<IDriverVehicleDocument, 'status'> {}

export interface DriverDocumentFormStore
  extends Omit<IDriverVehicleDocument, 'status'> {}

export interface DriverDocumentFormSend
  extends Omit<IDriverVehicleDocument, 'status' | 'type'> {
  status: DriverVehicleDocumentStatus;
  type: DriverVehicleDocumentType;
}

export default IDriverVehicleDocument;
