import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect } from 'react';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import { useGetRideQuery } from '@/services/rides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import SoftBadge from '../../common/SoftBadge';
import { DateTime } from 'luxon';
import IRide from '@/interfaces/Ride';
import {
  calculateAcceptanceTime,
  calculateFinalETA,
  calculatePercentageChange,
  calculateDistance,
  calculatePickupDetails,
} from '@/helpers/utils';
import {
  getColorFromRideStatus,
  getReelRideStatus,
} from '@/helpers/UserHelpers';
import UnknownBadge from '@/components/common/UnknownBadge';

const RideDetailsCard: FC<ICardDetailsProps<IRide>> = ({ id, callBack }) => {
  const { data, isLoading, isError, error } = useGetRideQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  // Calculate percentage change
  const basePrice = data.offer.price;
  const finalPrice = data.offer.driverPrice;
  const percentageChange = calculatePercentageChange(basePrice, finalPrice);

  //Calculate Final ETA
  const driverArrivalTime = data.driverArrivedAt
    ? new Date(data.driverArrivedAt).getTime()
    : 0;
  const rideCompletionTime = data.completedAt
    ? new Date(data.completedAt).getTime()
    : 0;
  const finalETA = calculateFinalETA(
    new Date(driverArrivalTime),
    new Date(rideCompletionTime),
    data.offer.initialDriverEta
  );

  //Calculate acceptance time
  const acceptanceTime = calculateAcceptanceTime(
    new Date(data.createdAt),
    new Date(data.updatedAt)
  );

  //Calculate Final Distance
  const finalLatitude = data.offer.request.destination.latitude;
  const finalLongitude = data.offer.request.destination.longitude;
  const initialLatitude = data.offer.request.origin.latitude;
  const initialLongitude = data.offer.request.origin.longitude;

  const finalDistance = calculateDistance(
    initialLatitude,
    initialLongitude,
    finalLatitude,
    finalLongitude
  );

  const difference = finalDistance - data.offer.request.initialDistance;

  //Calculate pick up details
  const pickupDetails = calculatePickupDetails(
    data.offer.request.initialEta,
    data.offer.request.initialDistance
  );

  return (
    <Card className="font-sans-serif ">
      <Card.Body>
        <h4>Ride details</h4>
        <Row>
          <Col xs={12} md={6} sm={12} xl={6} lg={6} className="mb-3 mb-sm-0">
            <Table borderless className="fs--1 fw-medium mb-0">
              <tbody>
                <tr>
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="130"
                  >
                    <strong> Ride ID</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="250">
                    {data.id}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="130"
                  >
                    <strong>Ride Status</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="130">
                    <SoftBadge bg={getColorFromRideStatus(data.status)}>
                      {getReelRideStatus(data.status)}
                    </SoftBadge>
                    <span className="ml-2 fs--2">
                      {data.driverRequest &&
                        data.driverRequest.cancellationReason !== null && (
                          <>{data.driverRequest.cancellationReason} </>
                        )}
                    </span>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="130"
                  >
                    <strong>Payment Status</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="130">
                    <SoftBadge bg={'danger'} className={'text-xl-center'}>
                      Failed
                    </SoftBadge>
                    <span className="ml-2 fs--2"> No sufficient funds</span>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Start Address</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    <Link to={`${GOOGLEMAPSWEBLINK}`}>
                      {data.offer.request.origin.address}
                    </Link>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Destination Ad </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    <Link to={`${GOOGLEMAPSWEBLINK}`}>
                      {data.offer.request.destination.address}
                    </Link>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Req Date & Time</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    {data?.createdAt
                      ? DateTime.fromISO(data.createdAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )
                      : 'N/A'}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Start Date & Time </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    {data?.startedAt
                      ? DateTime.fromISO(data.startedAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )
                      : 'N/A'}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Ride last full location</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    {data?.driverRequest?.driverService?.driverProfile
                      ?.lastFullLocation ? (
                      data?.driverRequest?.driverService?.driverProfile
                        ?.lastFullLocation
                    ) : (
                      <UnknownBadge />
                    )}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>End Date & Time </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    {data?.completedAt
                      ? DateTime.fromISO(data.completedAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )
                      : 'N/A'}
                  </td>
                </tr>

                <tr>
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="130"
                  >
                    <strong> Ride Type </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="130">
                    <Badge bg="200" className="text-secondary fs--2">
                      Immediate
                    </Badge>{' '}
                  </td>
                </tr>

                <tr>
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong> COMIN Commission </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="130">
                    <Badge bg="info" className="text-light fs--2">
                      10%{' '}
                      <strong className="mr-2">
                        {' '}
                        ({(data.offer.driverPrice * 0.1).toFixed(2)}€)
                      </strong>
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={12} md={6} sm={12} xl={6} lg={6} className="mb-3 mb-sm-0">
            <Table borderless className="fs--1 fw-medium mb-0">
              <tbody>
                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="80%"
                  >
                    <strong>Category </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    <SoftBadge bg={'info'} className={'text-xl-center'}>
                      {data.offer.offer.name}
                    </SoftBadge>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="60%"
                  >
                    <strong>Base / Final Price </strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="100">
                    <td className="p-0 d-flex align-items-center">
                      {`${data.offer.price}€ / ${data.offer.driverPrice}€`}
                      <Badge
                        pill
                        bg={percentageChange < 0 ? 'danger' : 'success'}
                        className={`fs--2 mx-1 ${
                          percentageChange < 0 ? 'text-white' : ''
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={percentageChange < 0 ? faCaretDown : faCaretUp}
                          className="me-1"
                          style={{ marginBottom: -1.5 }}
                        />
                        {Math.abs(percentageChange).toFixed(1)}%
                      </Badge>
                    </td>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="80%"
                  >
                    <strong>Coupon / Amount</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    {data.offer.userCoupon && (
                      <Badge bg="300" className="text-secondary fs--2 mx-1">
                        {data.offer.userCoupon.coupon.code}
                      </Badge>
                    )}
                    {data.offer.couponDiscountAmount !== null
                      ? data.offer.couponDiscountAmount + '€'
                      : 'No coupon'}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="120"
                  >
                    <strong>Initial / Final ETA</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1">
                    {data.offer.initialDriverEta}m /{finalETA}m
                    {finalETA !== data.offer.initialDriverEta && (
                      <Badge pill bg="200" className="text-warning fs--2 mx-1">
                        {finalETA > data.offer.initialDriverEta ? (
                          <>
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              className="me-1"
                              style={{ marginBottom: -1.5 }}
                            />
                            + {finalETA - data.offer.initialDriverEta}m
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              className="me-1"
                              style={{ marginBottom: -1.5 }}
                            />
                            {finalETA - data.offer.initialDriverEta}m
                          </>
                        )}
                      </Badge>
                    )}
                  </td>
                </tr>

                <tr className="m-2">
                  <td className="p-0 d-flex items-center pl-2 p-0 my-1">
                    <div className="column">
                      <strong>Initial / Final Distance </strong>
                    </div>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="250">
                    {data.offer.request.initialDistance.toFixed(3)}km /{' '}
                    {finalDistance.toFixed(3)}km
                    <Badge
                      pill
                      bg={difference < 0 ? 'danger' : 'success'}
                      className={`fs--2 mx-1 ${
                        difference < 0 ? 'text-white' : ''
                      }`}
                    >
                      {difference > 0 ? (
                        <>
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className="me-1"
                            style={{ marginBottom: -1.5 }}
                          />
                          +{difference.toFixed(1)}km
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="me-1"
                            style={{ marginBottom: -1.5 }}
                          />
                          {difference.toFixed(1)}km
                        </>
                      )}
                    </Badge>
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="120"
                  >
                    <strong>Acceptance time</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1">{acceptanceTime}</td>
                </tr>
                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="120"
                  >
                    <strong>Pickup details</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1">
                    {data.driverRequest &&
                    data.driverRequest.initialDuration &&
                    data.driverRequest.initialDistance
                      ? `${data.driverRequest.initialDuration} / ${data.driverRequest.initialDistance}`
                      : 'N/A'}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="120"
                  >
                    <strong>Ride details</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1">
                    {data.offer.request.initialDistance &&
                    data.offer.request.initialEta
                      ? `${data.offer.request.initialEta} mins / ${data.offer.request.initialDistance} km`
                      : 'N/A'}
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="60%"
                  >
                    <strong>Referral</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    <Badge bg="200" className="text-secondary fs--2 mx-1">
                      Yes
                    </Badge>{' '}
                    0.5€
                  </td>
                </tr>

                <tr className="m-2">
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="60%"
                  >
                    <strong>Price Review</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="150">
                    <Badge bg="200" className="text-secondary fs--2 mx-1">
                      Yes
                    </Badge>{' '}
                    20.51€
                  </td>
                </tr>
                <tr>
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Delay fees</strong>
                  </td>
                  <td className="p-0 text-600 w-200 py-1" width="130">
                    Unknown
                  </td>
                </tr>

                <tr>
                  <td
                    className="p-0 d-flex items-center pl-2 p-0 my-1"
                    width="150"
                  >
                    <strong>Stop</strong>
                  </td>
                  {data.offer.request.stops &&
                  data.offer.request.stops.length > 0 ? (
                    <td className="p-0 text-600 w-200 py-1" width="130">
                      <Link to={`${GOOGLEMAPSWEBLINK}`}>
                        {data.offer.request.stops.map((stop, index) => (
                          <span key={index}>YES / {stop.address}</span>
                        ))}
                      </Link>
                    </td>
                  ) : (
                    <td className="p-0 text-600 w-200 py-1" width="130">
                      No stops
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RideDetailsCard;
