import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import RewardHeader from '@/components/card-headers/rewards/RewardHeader';
import RewardDetailsCard from '@/components/cards/rewards/RewardsDetailsCard';

const RewardView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <RewardHeader id={id} />
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <RewardDetailsCard id={id} card />
        </Col>
      </Row>
    </div>
  );
};

export default RewardView;
