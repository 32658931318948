import { IPaginateResponse, IFilters } from '@/interfaces/Fetch';
import api from './api';
import { createFormDataRequest } from '@/helpers/utils';
import IDayPriceController, {
  IDayPriceControllerForm,
} from '@/interfaces/DayPriceController';

export const dayFactorApi = api.injectEndpoints({
  endpoints: build => ({
    getDayPriceControllers: build.query<
      IPaginateResponse<IDayPriceController>,
      IFilters
    >({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/dayPriceControllers',
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'DayPriceControllers' as const,
                id,
              })),
              { type: 'DayPriceControllers', id: 'LIST' },
            ]
          : [],
    }),
    updateDayPriceControllers: build.mutation<
      IDayPriceController,
      IDayPriceControllerForm
    >({
      query: body => ({
        url: `/dayPriceControllers`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'DayPriceControllers', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDayPriceControllersQuery,
  useUpdateDayPriceControllersMutation,
} = dayFactorApi;
