import { IPaginateResponse } from "@/interfaces/Fetch";
import { IDrivingPreferences } from "@/interfaces/DrivingPreferences";
import api from "../api";
import { DriverPreferenceFormSend } from "@/interfaces/Driver/Preference";
import Driver from "@/interfaces/Driver";

export const driverPreferencesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDriverPreferences: build.query<IPaginateResponse<IDrivingPreferences>, string>({
      query: (idDriver) => {
        return {
          url: `/drivers/${idDriver}/preferences`,
        };
      },
      providesTags: (result, _error, _) =>
        result
          ? [
            ...result.data.map(({ id }) => ({
              type: "DrivingPreferences" as const,
              id,
            })),
            { type: "DrivingPreferences", id: "LIST" },
          ]
          : [],
    }),
    updateDriverPreferences: build.mutation<Driver, [string, DriverPreferenceFormSend]>({
      query: ([idDriver, data]) => ({
        url: `/drivers/${idDriver}/preferences`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, _error, data) => [{ type: 'DrivingPreferences', id: "LIST" }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDriverPreferencesQuery,
  useUpdateDriverPreferencesMutation
} = driverPreferencesApi;