import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllUsersListHeader from '@/components/table-headers/users/AllUsersListHeader';
import { IUserList } from '@/interfaces/User/UserList';
import {
  useDeleteUserListMutation,
  useGetUserListQuery,
} from '@/services/userList';
import { faFileExport, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';

export const UserList = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [state, setState] = useState<Partial<TableState>>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data } = useGetUserListQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });
  const [deleteAsync] = useDeleteUserListMutation();

  const columns = useRef<ColumnDef<IUserList>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <>
          <Link to={`${process.env.REACT_APP_API_URI}/export/userLists/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faFileExport} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'List name',
      accessorKey: 'name',
    },
    {
      header: 'Users/Drivers count',
      accessorKey: 'user_count',
    },
    {
      header: 'Created date',
      accessorKey: 'created_at',
      accessorFn: ({ created_at }) => (
        <>
          {DateTime.fromISO(created_at?.toString()).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(created_at?.toString()).toFormat('HH:mm')}
          </p>
        </>
      ),
      cell: info => info.getValue(),
    },
  ]).current;

  const onDelete = useCallback(
    (id: string) => {
      toast.promise(deleteAsync(id).unwrap(), {
        pending: 'Suppression du statut en cours ...',
        success: {
          render() {
            DeleteModalRef.current?.showOrHiddenModal();
            return (
              <p className="mb-0 text-center">
                The user list has been deleted 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            return 'Something went wrong';
          },
        },
      });
    },
    [deleteAsync]
  );

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Would you really delete the user list with id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllUsersListHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};
