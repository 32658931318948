import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { GOVERNANCE_STATUS, IGovernanceForm } from '@/interfaces/Governance';
import { IModalMethods } from '@/interfaces/Modal';
import { useStoreGovernanceMutation } from '@/services/governances';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreGovernanceModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IGovernanceForm>({
    defaultValues: {
      status: GOVERNANCE_STATUS[0],
    },
  });

  const [storeGovernanceAsync] = useStoreGovernanceMutation();

  const showOrHiddenModal = () => {
    ModalRef.current?.showOrHiddenModal();
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IGovernanceForm> = ({ status, ...data }) => {
    toast.promise(
      storeGovernanceAsync({
        status: status.value,
        ...data,
      }).unwrap(),
      {
        pending: 'Ajout du governance en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le governance à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IGovernanceForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un governance"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Status <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select
                  options={GOVERNANCE_STATUS}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.status?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Would you like a new rating system?"
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Start At <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('startAt', {
                validate: value => {
                  if (DateTime.fromISO(value) <= DateTime.now()) {
                    return 'La date de début doit être supérieure à la date actuelle';
                  }
                },
                required: 'La date de début est obligatoire',
              })}
              isInvalid={!!errors.startAt?.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.startAt?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              End At <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('endAt', {
                validate: value => {
                  if (DateTime.fromISO(value) < DateTime.now()) {
                    return 'La date de fin doit être supérieure à la date actuelle';
                  }
                },
                required: 'La date de fin est obligatoire',
              })}
              isInvalid={!!errors.endAt?.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.endAt?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Driver Ride Count <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('driverCourseCount', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.driverCourseCount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.driverCourseCount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              User Ride Count <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('customerCourseCount', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.customerCourseCount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.customerCourseCount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="mt-2">
          <Form.Label>Context</Form.Label>
          <Form.Control
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            as="textarea"
            type="text"
            {...register('context', {
              minLength: {
                value: 3,
                message: 'Ce champ doit contenir au moins 3 caractères.',
              },
              maxLength: {
                value: 2000,
                message: 'Ce champ doit contenir au plus 255 caractères.',
              },
            })}
            style={{ minHeight: 100 }}
          />
        </Col>
        <Col className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            as="textarea"
            type="text"
            {...register('description', {
              minLength: {
                value: 3,
                message: 'Ce champ doit contenir au moins 3 caractères.',
              },
              maxLength: {
                value: 2000,
                message: 'Ce champ doit contenir au plus 255 caractères.',
              },
            })}
            style={{ minHeight: 100 }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreGovernanceModal);
