import { FC, ReactNode } from 'react';
import { MapContainer, TileLayer, MapContainerProps } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Define the props for LeafletMap, including children and MapContainerProps
interface LeafletMapProps extends MapContainerProps {
  children?: ReactNode; // Children components (e.g. FeatureGroup, EditControl)
}

const LeafletMap: FC<LeafletMapProps> = ({ children, ...props }) => {
  return (
    <MapContainer
      attributionControl={false}
      {...props}
      //@ts-ignore
      center={[48.8566, 2.3522]}
      zoom={13}
      scrollWheelZoom
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {children}
    </MapContainer>
  );
};

export default LeafletMap;
