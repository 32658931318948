import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import {
  DriverBlockedTypeSelect,
  IDriverBlockForm,
} from '@/interfaces/DriverBlocked';
import { IModalMethods } from '@/interfaces/Modal';
import { IUserForm } from '@/interfaces/User';
import { useBlockDriverMutation } from '@/services/drivers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

type BlockDriverModalProps = {
  idDriver: string;
};

const BlockDriverModal: ForwardRefRenderFunction<
  IModalMethods,
  BlockDriverModalProps
> = ({ idDriver }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IDriverBlockForm>();
  const [blockDriverMutation] = useBlockDriverMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IDriverBlockForm> = ({ message, type }) => {
    toast.promise(
      blockDriverMutation([
        idDriver,
        {
          message,
          type: type.value,
        },
      ]).unwrap(),
      {
        pending: 'Blocking driver in progress...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                The driver has been blocked 🔐
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IUserForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Block a driver"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Raison <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="type"
              rules={{
                required: 'La raison est requise.',
              }}
              render={({ field }) => (
                <Select
                  options={DriverBlockedTypeSelect}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.type,
              })}
            >
              {errors.type?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Message <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : John"
              type="text"
              as="textarea"
              rows={3}
              {...register('message', {
                required: `Un message d'explication est requis.`,
                minLength: {
                  value: 2,
                  message: 'Le message doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message:
                    'Le message doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.message?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(BlockDriverModal);
