import { FC } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getOptions } from '@/helpers/RevenueChartHelper';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  BarChart,
]);

type RevenueChartProps = {
  data: number[][];
};

const RevenueChart: FC<RevenueChartProps> = ({ data }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data[0], data[1])}
      style={{ height: '20rem', padding: 0 }}
    />
  );
};

export default RevenueChart;
