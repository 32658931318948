import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import Loader from '@/components/common/Loader';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ElementRef, FC, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import { useSelector } from 'react-redux';
import UpdateDriverPreferenceModal from '@/components/modals/driver/preferences/UpdateDriverPreferenceModal';
import { useGetDriverPreferencesQuery } from '@/services/drivers/preferences';
import { useGetDrivingPreferencesQuery } from '@/services/drivingPreferences';

type DriverPreferencesProps = {
  id: string;
};

export const DriverPreferencesCard: FC<DriverPreferencesProps> = ({ id }) => {
  const { data, isLoading, error } = useGetDriverPreferencesQuery(id);
  const {
    data: dataDrivingPreferences,
    isLoading: isLoadingDrivingPreferences,
    error: errorDrivingPreferences,
  } = useGetDrivingPreferencesQuery({});

  const userConfig = useSelector(selectCurrentUserConfig);

  const UpdateDriverPreferenceModalModalRef =
    useRef<ElementRef<typeof UpdateDriverPreferenceModal>>(null);
    
  if (isLoading || isLoadingDrivingPreferences) {
    <Loader />;
  } else if (error) {
    <ErrorComponent error={error} />;
  } else if (errorDrivingPreferences) {
    <ErrorComponent error={errorDrivingPreferences} />;
  }

  return (
    <>
      {userConfig.isAdminOrSuperAdmin && (
        <UpdateDriverPreferenceModal
          idDriver={id}
          ref={UpdateDriverPreferenceModalModalRef}
        />
      )}
      <Card>
        <Card.Header className="mt-0 mb-0 pb-0">
          <Flex className="justify-content-between">
            <h1 className="fs-1 fw-bold mb-0 pb-0">Préférences</h1>
            <IconButton
              onClick={() => {
                UpdateDriverPreferenceModalModalRef.current?.showOrHiddenModal({
                  preferences: data?.data ?? [],
                });
              }}
              variant="nolina-default"
              size="sm"
              icon={faPlus}
              transform="shrink-2"
              iconAlign="middle"
              className="me-2"
            >
              <span className="d-none d-xl-inline-block ms-1">
                Attribuer une préférence
              </span>
            </IconButton>
          </Flex>
        </Card.Header>

        <Card.Body>
          <Row xs={2} md={2} className="px-3">
            {dataDrivingPreferences?.data.map((drvgPrf, i) => (
              <Col
                key={drvgPrf.id}
                xs={12}
                md={6}
                className="offset-mb-2 border border-200"
              >
                <Row className=" rounded-3 py-2">
                  <Col xs={6} md={8}>
                    <span className="text-bold fs--1">{drvgPrf.name}:</span>
                  </Col>
                  <Col className="px-3 justify-content-end align-items-center d-flex">
                    {(data?.data.filter(prf => drvgPrf.id === prf.id).length ??
                      0) > 0 && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="1x"
                        color="green"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
