import { createFormDataRequest } from '@/helpers/utils';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import PointTransaction, { IPointTransactionFormSend } from '@/interfaces/User/PointTransaction';
import api from '@/services/api';

export const pointsTransactionsApi = api.injectEndpoints({
  endpoints: build => ({
    getUserPointsTransactions: build.query<
      IPaginateResponse<PointTransaction>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/users/${id}/pointsTransactions`,
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
            ...result.data.map(({ id }) => ({ type: "UserPointsTransactions" as const, id })),
            { type: "UserPointsTransactions", id: "LIST" },
          ]
          : [],
    }),
    storeUserPointsTransactions: build.mutation<PointTransaction, [string, IPointTransactionFormSend]>({
      query: ([userId, body]) => ({
        url: `/users/${userId}/pointsTransactions`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_user, _err, [id]) => [
        { type: "UserPointsTransactions", id: "LIST" },
        { type: "Users", id },
        { type: "UserLogs", id: "LIST" }
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserPointsTransactionsQuery,
  useStoreUserPointsTransactionsMutation
} = pointsTransactionsApi;
