import { ISelectOption } from './Select';
import { MultiValue, PropsValue } from 'react-select';
import IOffer from './Offer';
import { IUserList } from './User/UserList';

export type DiscountType = 'percent' | 'fixed';

export const DISCOUNT_TYPES: ISelectOption<DiscountType>[] = [
  {
    label: '%',
    value: 'percent',
  },
  {
    label: '€',
    value: 'fixed',
  },
];

interface ICoupon {
  id: string;
  userId: string | null;
  name: string;
  description: string;
  code: string;
  discountAmount: number;
  discountType: DiscountType;
  maxDiscountAmount: number;
  minPurchaseAmount: number;
  limitPerUser: number;
  totalLimit: number;
  numberOfUses: number;
  numberOfAdds: number;
  minRideCompleted: number;
  startAt: string;
  endAt: string;
  isArchived: boolean;
  excludeOffers?: IOffer[];
  createdAt: string;
  updatedAt: string;
}

export interface ICouponForm {
  name: string;
  userId?: string;
  description?: string;
  discountAmount: number;
  discountType: ISelectOption<DiscountType>;
  maxDiscountAmount: number;
  minPurchaseAmount: number;
  limitPerUser: number;
  totalLimit: number;
  minRideCompleted: number;
  startAt: string;
  endAt: string;
  excludeOffers?: MultiValue<IOffer>;
}

export interface ICouponFormSend
  extends Omit<ICouponForm, 'discountType' | 'excludeOffers'> {
  discountType: DiscountType;
  excludeOfferIds?: string[];
  isArchived: boolean;
}

export interface AssignCouponForm {
  userList: MultiValue<IUserList>;
  coupon: ICoupon;
}

export default ICoupon;
