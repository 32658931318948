import { AreYouSureModalProps, IModalMethods } from "@/interfaces/Modal";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";

const AreYouSurToContinueModal: ForwardRefRenderFunction<
  IModalMethods,
  AreYouSureModalProps
> = ({ onContinue, title }, ref) => {
  const [id, setId] = useState<string | undefined>();

  const showOrHiddenModal = (id?: string) => setId(id);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  return (
    <Modal
      ref={ref}
      size="xl"
      show={!!id}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={showOrHiddenModal}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex flex-column"
        >
          Êtes-vous sûr de vouloir continuer ?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {title.replace("{{id}}", id ? id.toString() : "")}
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="success" onClick={() => onContinue(id!)}>
          Continuer
        </Button>
        <Button onClick={() => showOrHiddenModal()}>Fermer</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(AreYouSurToContinueModal);
