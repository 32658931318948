import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllAssociationsHeader from '@/components/table-headers/associations/AllAssociationsHeader';
import { getColorFromAssociationsStatus } from '@/helpers/AssociationHelper';
import { splitText, truncate } from '@/helpers/utils';
import IAssociation from '@/interfaces/Association';
import { IFilters } from '@/interfaces/Fetch';
import {
  useDestroyAssociationMutation,
  useGetAssociationsQuery,
} from '@/services/associations';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const AssociationsView: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const [destroyAssociationAsync] = useDestroyAssociationMutation();

  const { data, isLoading, isError, error } = useGetAssociationsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const onDelete = useCallback((id: string) => {
    toast.promise(destroyAssociationAsync(id).unwrap(), {
      pending: 'Suppression du statut en cours ...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p className="mb-0 text-center">Le statut à bien été supprimé 🤩</p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'Une erreur est survenue';
        },
      },
    });
  }, []);

  const columns = useRef<ColumnDef<IAssociation>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <>
          <Link to={`/associations/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link
            to={`/associations/${id}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Association ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => splitText(id, 19),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ status }) => (
        <SoftBadge bg={getColorFromAssociationsStatus(status)}>
          {status}
        </SoftBadge>
      ),
      id: 'status',
      cell: info => info.getValue(),
    },
    {
      header: 'Lien',
      footer: props => props.column.id,
      accessorFn: ({ link }) => <Link to={link}>{splitText(link, 18)}</Link>,
      id: 'link',
      cell: info => info.getValue(),
    },
    {
      header: 'Donation goal',
      footer: props => props.column.id,
      accessorFn: ({ donationGoal }) => (
        <Badge bg="200" className="text-info fs--1">
          {donationGoal}
        </Badge>
      ),
      id: 'points',
      cell: info => info.getValue(),
    },
    {
      header: 'Category',
      footer: props => props.column.id,
      accessorFn: ({ category }) => (
        <span
          className={classNames(
            'badge border link-secondary text-decoration-none'
          )}
        >
          {category}
        </span>
      ),
      id: 'category',
      cell: info => info.getValue(),
    },
    {
      header: 'Name',
      footer: props => props.column.id,
      accessorFn: ({ name }) => name,
      id: 'name',
      cell: info => info.getValue(),
    },
    {
      header: 'Title',
      footer: props => props.column.id,
      accessorFn: ({ title }) => truncate(title, 100),
      id: 'title',
      cell: info => info.getValue(),
    },
    {
      header: 'Description',
      footer: props => props.column.id,
      accessorFn: ({ description }) => (
        <p className="mb-0 text-500 ">{truncate(description, 100)}</p>
      ),
      id: 'description',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) => (
        <>
          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(createdAt).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le reward qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllAssociationsHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default AssociationsView;
