import React from 'react';

function StatsNumberLiveItem() {
  return (
    <div className="p-3 border-end border-lg-bottom-0 border-bottom">
      <h5 className="text-800 mb-2">Rides On Going</h5>
      <h6 className="pb-1 text-500">Nombres de nouveaux utilisateurs</h6>
      <h2>25</h2>
    </div>
  );
}

export default StatsNumberLiveItem;
