import IAssociation, { IAssociationForm, IAssociationFormSend } from "@/interfaces/Association";
import { IFilters, IPaginateResponse } from "@/interfaces/Fetch";
import { createFormDataRequest } from "../helpers/utils";
import api from "./api";

export const associationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssociations: build.query<IPaginateResponse<IAssociation>, IFilters>({
      query: (data) => {
        const params = createFormDataRequest(data);
        return {
          url: "/associations",
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.data.map(({ id }) => ({
              type: "Associations" as const,
              id,
            })),
            { type: "Associations", id: "LIST", ...filters },
          ]
          : [],
    }),
    getAssociation: build.query<IAssociation, string>({
      query: id => `/associations/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Associations', id }],
    }),
    createAssociation: build.mutation<IAssociation, IAssociationForm>({

      query: association => {
        const body = createFormDataRequest(association)
        return {
          url: '/associations',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, _err, _body) => [
        { type: 'Associations', id: result?.id },
      ],
    }),
    destroyAssociation: build.mutation<IAssociation, string>({
      query: id => ({
        url: `/associations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _err, body) => [
        { type: 'Associations', id: body },
      ],
    }),
    updateAssociation: build.mutation<IAssociation, [string, Partial<IAssociationFormSend>]>({
      query: ([id, association]) => {
        const body = createFormDataRequest(association)
        return {
          url: `/associations/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (_result, _error, [id]) => [{ type: 'Associations', id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAssociationsQuery,
  useGetAssociationQuery,
  useUpdateAssociationMutation,
  useCreateAssociationMutation,
  useDestroyAssociationMutation
} = associationsApi;
