import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useMemo } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

import {
  useEditDrivingPreferenceMutation,
  useGetDrivingPreferenceQuery,
} from '@/services/drivingPreferences';
import { IDrivingPreferencesForm } from '@/interfaces/DrivingPreferences';
import Loader from '@/components/common/Loader';
import CardHeader from '../../card-headers/CardHeader';

interface UpdateDrivingPreferenceCardProps {
  id: string;
}

const UpdateDrivingPreferenceCard: FC<UpdateDrivingPreferenceCardProps> = ({
  id,
}) => {
  const { data, isLoading } = useGetDrivingPreferenceQuery(id);
  const navigation = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IDrivingPreferencesForm>>();
  const [editDrivingPreference] = useEditDrivingPreferenceMutation();

  useEffect(() => {
    reset(data);
  }, [data]);

  const onSubmit: SubmitHandler<Partial<IDrivingPreferencesForm>> = data => {
    toast.promise(editDrivingPreference([id, data]).unwrap(), {
      pending: `Modification de la préférence en cours...`,
      success: {
        render() {
          navigation(-1);
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              {`La préférence à bien été modifié 🤩`}
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<Partial<IDrivingPreferencesForm>> = error =>
    console.log(error);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification d'une préférence" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name <span className="text-danger">*</span> :{' '}
                  </Form.Label>
                  <Form.Control
                    placeholder="ex : John"
                    type="text"
                    {...register('name', {
                      required: `Merci d'indiquer un nom.`,
                      minLength: {
                        value: 2,
                        message: 'Le nom doit contenir au moins 2 caractères.',
                      },
                      maxLength: {
                        value: 255,
                        message:
                          'Le nm doit contenir au maximum 255 caractères.',
                      },
                    })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Text color <span className="text-danger">*</span> :{' '}
                  </Form.Label>
                  <Form.Control
                    placeholder="ex : John"
                    type="text"
                    {...register('textColor', {
                      required: `Merci d'indiquer une couleur.`,
                      minLength: {
                        value: 2,
                        message: 'La couleur doit au moins 2 caractères.',
                      },
                      validate: {
                        isValidHex: value => {
                          if (
                            value &&
                            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)
                          ) {
                            return true;
                          }
                          return "La couleur n'est pas valide.";
                        },
                      },
                      maxLength: {
                        value: 10,
                        message:
                          'La couleur doit contenir au maximum 10 caractères.',
                      },
                    })}
                    isInvalid={!!errors.textColor}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.textColor?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Background color <span className="text-danger">*</span> :{' '}
                </Form.Label>
                <Form.Control
                  placeholder="ex : John"
                  type="text"
                  {...register('backgroundColor', {
                    required: `Merci d'indiquer une couleur.`,
                    minLength: {
                      value: 2,
                      message:
                        'La couleur doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 50,
                      message:
                        'Le couleur doit contenir au maximum 50 caractères.',
                    },
                  })}
                  isInvalid={!!errors.backgroundColor}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.backgroundColor?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateDrivingPreferenceCard;
