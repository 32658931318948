import { IDriverVehicleStatus, IDriverVehicleStatus_ } from "@/interfaces/DriverVehicle";
import { ISelectOption } from "@/interfaces/Select";
export const getColorFromVehicleStatus = (status?: IDriverVehicleStatus) => {
  if (status === "maintenance") return "warning";
  else if (status === "active") return "success";
  else if (["archived", "not_approved", "inactive"].indexOf(status!) >= -1) return "success";
  else return "danger";
};

export const getReelVehiculeStatusName = (status?: IDriverVehicleStatus) => {
  if (status === "maintenance") return "Pending Review";
  if (status === "active") return "Valid";
  if (status === "archived") return "Archived";
  if (status === "inactive") return "Expired";
  if (status === "not_approved") return "Not Approved";
};

export const IDriverVehicleStatusTypeSelect: ISelectOption<IDriverVehicleStatus>[] = IDriverVehicleStatus_.map((type) => ({
  value: type,
  label: getReelVehiculeStatusName(type)!,
}));