import { createFormDataRequest } from '@/helpers/utils';
import { IPaginateResponse, IFilters } from '@/interfaces/Fetch';
import IVote from '@/interfaces/Governance/Vote';
import api from '@/services/api';

export const votesApi = api.injectEndpoints({
  endpoints: build => ({
    getGovernanceVotes: build.query<
      IPaginateResponse<IVote>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/governances/${id}/votes`,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetGovernanceVotesQuery } = votesApi;
