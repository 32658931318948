import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import AllPhoneOtpsHeader from '@/components/table-headers/phoneOtps/AllPhoneOtpsHeader';
import { IFilters } from '@/interfaces/Fetch';
import { PhoneOTP } from '@/interfaces/PhoneOTP';
import { useGetPhoneOtpsQuery } from '@/services/phoneOtps';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';

const PhoneOtpsView: FC = () => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetPhoneOtpsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const columns = useRef<ColumnDef<PhoneOTP>[]>([
    {
      header: 'Phone',
      footer: props => props.column.id,
      accessorFn: ({ phone }) => phone,
      id: 'phone',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'Created At',
      cell: info => info.getValue(),
    },
    {
      header: 'Updated At',
      footer: props => props.column.id,
      accessorFn: ({ updatedAt }) =>
        DateTime.fromISO(updatedAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'Updated At',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Table
      HeaderComponent={AllPhoneOtpsHeader}
      columns={columns}
      state={state}
      tableName="PhoneOtps"
      onStateChange={setState}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default PhoneOtpsView;
