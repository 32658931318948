import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import { IDefaultCardProps } from '@/components/interfaces/Card';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import { useDestroyPartnershipReferralCodeMutation } from '@/services/partnerships/referralCodes';
import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ElementRef, FC, useRef } from 'react';
import { Card as C } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';
const Card: any = C

const ReferralCodesHeader: FC<IDefaultCardProps> = ({ id }) => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const navigation = useNavigate();
  const [deleteReferralCodeAsync] = useDestroyPartnershipReferralCodeMutation();
  const { referralCodesId } = useParams<{
    referralCodesId: string;
  }>();

  const onDelete = (id: string) => {
    toast.promise(
      deleteReferralCodeAsync({
        partnershipId: id,
        referralCodeId: referralCodesId || '',
      }).unwrap(),
      {
        pending: 'Suppression du referral code en cours...',
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le referral code a bien été supprimé 🎉`}
              </p>
            );
          },
        },
        error: {
          render() {
            // Handle error
            return 'Une erreur est survenue lors de la suppression du referral code.';
          },
        },
      }
    );
  };

  return (
    <Card className="mb-3">
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le partnership qui a pour id : {{id}} ?"
      />
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigation(-1)}
          variant="nolina-default"
          size="sm"
          icon={faArrowLeft}
        />
        <Flex>
          <div
            className="bg-300 mx-3 d-xl-none"
            style={{ width: '1px', height: '29px' }}
          ></div>
          <IconButton
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
            variant="nolina-default"
            size="sm"
            icon={faTrash}
            iconAlign="middle"
            className="d-none d-sm-block me-2"
          >
            <span className="d-none d-xl-inline-block ms-1">Supprimer</span>
          </IconButton>
        </Flex>
      </Card.Header>
    </Card>
  );
};
export default ReferralCodesHeader;
