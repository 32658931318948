import Flex from '@/components/common/Flex';
import Modal from '@/components/common/Modal';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import { IManyCardProps } from '@/interfaces/Card';
import { IFilters } from '@/interfaces/Fetch';
import { USER_ADDRESS_TYPES } from '@/interfaces/User/Address';
import {
  useDeleteUserAddressMutation,
  useGetUserAddressesQuery,
} from '@/services/users/addresses';
import {
  faClock,
  faEllipsisVertical,
  faHashtag,
  faMapLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { ElementRef, FC, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import PaginateCard from '../../PaginateCard';

const UserAddressesCard: FC<IManyCardProps & { id: string }> = ({
  onGetTotal,
  onDelete: onDeleteProp,
  id: userId,
  ...props
}) => {
  const DeleteModalRef = useRef<ElementRef<typeof Modal>>(null);
  const [filters, setFilters] = useState<IFilters>({
    pageIndex: 0,
    pageSize: 10,
    ...props,
  });
  const [deleteUserAddressAsync] = useDeleteUserAddressMutation();
  const { data, isLoading, error } = useGetUserAddressesQuery([
    userId,
    filters,
  ]);

  useEffect(() => {
    data?.meta && onGetTotal?.(data.meta.total);
  }, [data?.meta]);

  const onDeleteClick = (id: string) => {
    onDeleteProp
      ? onDeleteProp(id)
      : DeleteModalRef.current?.showOrHiddenModal(id);
  };

  const onDelete = (id: string) => {
    toast.promise(deleteUserAddressAsync([userId, id]).unwrap(), {
      pending: "Suppression de le l'adresse en cours...",
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              L'adresse été supprimée 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  return (
    <PaginateCard<IFilters>
      meta={data?.meta}
      filters={filters}
      isLoading={isLoading}
      error={error}
      card
      setFilters={setFilters}
      title="User Addresses"
    >
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé l'adresse qui a pour id : {{id}} ?"
      />
      {data?.data.map(({ id, userId, googlePlace, type, createdAt }) => (
        <div
          key={id}
          className="notification d-block rounded-0 border-x-0 border-300 border-bottom-0"
        >
          <Row className="flex-between-center">
            <Col xs={10}>
              <h5 className="d-flex align-items-center fw-semi-bold text-primary">
                {USER_ADDRESS_TYPES[type]}
              </h5>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end">
              <Dropdown
                align="end"
                className="btn-reveal-trigger d-inline-block"
              >
                <Dropdown.Toggle split variant="nolina-default" size="sm">
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="fs--2"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() => onDeleteClick(id.toString())}
                    >
                      Supprimer
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <div className="border-bottom border-lg-0 border-0 my-3"></div>
          <Row className="flex-between-center">
            <Col xs={12} lg={8}>
              <div className="d-flex align-items-center gap-1 h6">
                <FontAwesomeIcon icon={faHashtag} transform="shrink-3 up-1" />
                <span className="text-600 lb-anywhere">
                  {googlePlace.placeId}
                </span>
              </div>
              <Link
                to={googlePlace.link}
                className="d-flex align-items-center gap-1 h6"
              >
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  transform="shrink-3 up-1"
                />
                <span className="text-600 lb-anywhere">
                  {googlePlace.address}
                </span>
              </Link>
            </Col>
            <Col xs={12} lg={'auto'}>
              <div className="border-bottom border-lg-0 border-0 my-lg-0 my-3"></div>
              <Flex direction="column">
                <h6 className="d-flex align-items-center gap-1 mb-0 mt-2">
                  <FontAwesomeIcon icon={faClock} transform="shrink-3 up-1" />
                  <span className="text-600">
                    {DateTime.fromISO(createdAt)
                      .setLocale('fr')
                      .toFormat('dd LLL yyyy à HH:mm')}
                  </span>
                </h6>
              </Flex>
            </Col>
          </Row>
        </div>
      ))}
    </PaginateCard>
  );
};

export default UserAddressesCard;
