import { ISelectOption } from './Select';
import { UserType } from './User';

export type EarnPointCriteriaType =
  | 'completed_ride'
  | 'referral'
  | 'rated_ride'
  | 'completed_rides';

export const EARNPOINT_TYPES: ISelectOption<EarnPointCriteriaType>[] = [
  {
    label: 'Completed Ride',
    value: 'completed_ride',
  },
  {
    label: 'Referral',
    value: 'referral',
  },
  {
    label: 'Rated Ride',
    value: 'rated_ride',
  },
  {
    label: 'Completed Rides',
    value: 'completed_rides',
  },
];

export const USER_TYPES: ISelectOption<UserType>[] = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Driver',
    value: 'driver',
  },
];

interface IEarnPointCriteria {
  id: string;
  earnPointId: string;
  type: EarnPointCriteriaType;
  userType: UserType | null;
  value: number | null;
  createdAt: string;
  updatedAt: string;
}

interface IEarnPoint {
  isArchived: boolean;
  id: string;
  userType: UserType;
  earnPointCriteria: IEarnPointCriteria;
  title: string;
  points: number;
  limit: number | null;
  descriptionTitle: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface IEarnPointForm {
  isArchived: boolean;
  userType: ISelectOption<UserType>;
  criteria: {
    type: ISelectOption<EarnPointCriteriaType>;
    value: number | null;
    userType: ISelectOption<UserType> | null;
  };
  title: string;
  points: number;
  limit: number | null;
  descriptionTitle: string;
  description: string;
}

export interface IEarnPointFormSend
  extends Omit<IEarnPointForm, 'userType' | 'criteria'> {
  userType: UserType;
  isArchived: boolean;
  criteria: {
    type: EarnPointCriteriaType;
    value?: number | null;
    userType?: UserType | null;
  };
}

export default IEarnPoint;
