import { TemplateContext } from "@/hooks/useTemplate";
import { FC, useContext, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import ToogleButton from "../navbar/ToogleButton";
import Logo from "@/components/common/Logo";
import NavbarVerticalMenu from "../navbar/NavbarVerticalMenu";

const NavbarVertical: FC = () => {
  const { isNavbarVerticalCollapsed, showBurgerMenu } =
    useContext(TemplateContext);
  const timoutRef = useRef<NodeJS.Timeout>();

  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed && document) {
      timoutRef.current = setTimeout(() => {
        document.documentElement.classList.add(
          "navbar-vertical-collapsed-hover"
        );
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    if (timoutRef.current && document) {
      clearTimeout(timoutRef.current);
      timoutRef.current = undefined;
      document.documentElement.classList.remove(
        "navbar-vertical-collapsed-hover"
      );
    }
  };

  return (
    <Navbar expand="xl" variant="light" className="navbar-vertical">
      <div className="d-flex align-items-center">
        <ToogleButton />
        <Logo />
      </div>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="navbar-vertical-content scrollbar">
          <NavbarVerticalMenu />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
