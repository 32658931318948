import { CompanyCountry } from '@/interfaces/Company';

export const getReelNameCountryBasedOnSplit = (country: CompanyCountry) => {
  switch (country) {
    case 'FR':
      return 'France';
    case 'BE':
      return 'Belgium';
    case 'LU':
      return 'Luxembourg';
    case 'DE':
      return 'Germany';
    case 'ES':
      return 'Spain';
    case 'IT':
      return 'Italy';
    case 'PT':
      return 'Portugal';
    case 'UK':
      return 'United Kingdom';
    case 'US':
      return 'United States';
    case 'IE':
      return 'Ireland';
    case 'AM':
      return 'Armenia';
    default:
      return '';
  }
};
