import Loader from '@/components/common/Loader';
import { IPartnershipForm } from '@/interfaces/Partnership/Partnership';
import {
  useGetPartnershipQuery,
  useUpdatePartnershipMutation,
} from '@/services/partnerships/partnerships';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

interface UpdatePartnershipCardProps {
  id: string;
}

const UpdatePartnershipCard: FC<UpdatePartnershipCardProps> = ({ id }) => {
  const { data, isLoading } = useGetPartnershipQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IPartnershipForm>>({});

  const [updatePartnershipMutation] = useUpdatePartnershipMutation();

  const navigation = useNavigate();

  const onSubmit: SubmitHandler<Partial<IPartnershipForm>> = ({ ...data }) => {
    toast.promise(
      updatePartnershipMutation([
        id,
        {
          ...data,
        },
      ]).unwrap(),
      {
        pending: `Modification du partnership en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le partnership à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IPartnershipForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Edit a Partnership</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : AXA"
                  type="text"
                  {...register('name', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Category <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Insurance"
                  type="text"
                  {...register('category', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.category}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.category?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('description', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 2000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdatePartnershipCard;
