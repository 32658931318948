import IUser from "@/interfaces/User";
import { RootState } from "@/store";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  user: IUser | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isAdminOrSuperAdmin: boolean;
  token: string | null;
};

const initialState: AuthState = {
  user: null,
  isAdmin: false,
  isSuperAdmin: false,
  isAdminOrSuperAdmin: false,
  token: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload,
      }: PayloadAction<{ user: IUser; token: string }>
    ) => {
      state.user = payload.user;
      state.token = payload.token;
      state.isAdmin = payload.user.role === "admin";
      state.isSuperAdmin = payload.user.role === "superadmin";
      state.isAdminOrSuperAdmin = state.isAdmin || state.isSuperAdmin;
    },
    resetCredentials: () => initialState,
  },
});

export const { setCredentials, resetCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export const selectCurrentUserConfig = (state: RootState) => ({
  isAdmin: state.auth.isAdmin,
  isSuperAdmin: state.auth.isSuperAdmin,
  isAdminOrSuperAdmin: state.auth.isAdminOrSuperAdmin,
});
