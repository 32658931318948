import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect } from 'react';
import { Card, Col, Nav, Row, Tab, Table } from 'react-bootstrap';
import IEarnPoint from '@/interfaces/EarnPoint';
import { useGetEarnPointQuery } from '@/services/earnPoints';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromCriteriaType,
  getColorFromIsArchived,
  getColorFromUserType,
  getReelArchivedName,
  getReelCriteriaType,
  getReelUserTypeName,
} from '@/helpers/UserHelpers';
import PageHeader from '@/components/common/header/PageHeader';
import { DateTime } from 'luxon';

const EarnPointsDetailsCard: FC<ICardDetailsProps<IEarnPoint>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetEarnPointQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-2">
      <Col xs={12}>
        {/* Page header */}
        <PageHeader title={`EarnPoints Details: ${data.title}`}>
          <p className="fs--1 mt-1">
            {DateTime.fromISO(data?.createdAt).toFormat(
              "dd LLL yyyy 'at' HH:mm"
            )}
          </p>
          <div>
            <strong className="me-2">Status : </strong>
            <SoftBadge
              bg={getColorFromIsArchived(data.isArchived)}
              className="fs--2"
            >
              {getReelArchivedName(data.isArchived)}
            </SoftBadge>
          </div>
        </PageHeader>
        {/* end of page header */}

        {/* EarnPoint Info */}

        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <Col md={6} lg={6} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Types</h5>
                <h6 className="mb-2">
                  User Type :
                  <SoftBadge
                    bg={getColorFromUserType(data.userType)}
                    className={'text-xl-center ms-2'}
                  >
                    {getReelUserTypeName(data.userType)}
                  </SoftBadge>
                </h6>
              </Col>
              <Col md={6} lg={6} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Limits</h5>
                <h6 className="mb-2">Limit : {data.limit} </h6>
                <h6 className="mb-2">Points : {data.points} </h6>
              </Col>
            </Row>
            <Row className="gx-3 gy-3 mt-1">
              <Col className="mb-3 mb-sm-0">
                <div className="overflow-hidden">
                  <Tab.Container defaultActiveKey="description">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="descriptionTitle"
                          className="ps-0 cursor-pointer outline-none"
                        >
                          Description Title
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="description"
                          className="px-2 px-md-3 cursor-pointer outline-none"
                        >
                          Description
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="descriptionTitle">
                        <div className="mt-3">{data.descriptionTitle}</div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="description">
                        <div className="mt-3">{data.description}</div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* End of EarnPoint info */}
      </Col>
    </Row>
  );
};

export default EarnPointsDetailsCard;
