import IconButton from '@/components/common/IconButton';
import Table from '@/components/common/table/Table';
import UpdatableCell from '@/components/common/table/UpdatableCell';
import { DriverClubConfigurationHeader } from '@/components/table-headers/driverClubConfigurations/DriverClubConfigurationHeader';
import { INVALID_FORM_MESSAGE } from '@/constants/validatorMessages';
import {
  IDriverClubConfigurations,
  IDriverClubConfigurationsForm,
} from '@/interfaces/DriverClubConfigurations';
import {
  useGetDriverClubConfigurationsQuery,
  useUpdateDriverClubConfigurationsMutation,
} from '@/services/driverClubConfiguration';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';

export const DriverClubEditView = () => {
  const [state, setState] = useState<Partial<TableState>>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const navigation = useNavigate();
  const [updateDriverClubConfiguration] =
    useUpdateDriverClubConfigurationsMutation();

  const { data } = useGetDriverClubConfigurationsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const { control, handleSubmit, reset } =
    useForm<IDriverClubConfigurationsForm>();

  const onSubmit: SubmitHandler<IDriverClubConfigurationsForm> = async (
    updatedData: IDriverClubConfigurationsForm
  ) => {
    const dataToSubmit = { ...data, ...updatedData.data[0] };

    toast.promise(updateDriverClubConfiguration(dataToSubmit), {
      pending: 'Modifying the column...',
      success: {
        render() {
          reset();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              The driver club configuration has been successfully modified 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'An error occurred';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IDriverClubConfigurationsForm> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  const { append, fields } = useFieldArray({
    control,
    name: 'data',
  });

  const onUpdate = (id: string, columnId: string, value: any) => {
    append([
      {
        id,
        [columnId]: parseInt(value),
      } as IDriverClubConfigurationsForm['data'][0],
    ]);
  };

  const columns = useRef<ColumnDef<IDriverClubConfigurations>[]>([
    {
      header: 'Amount per ride',
      accessorKey: 'amount_per_ride',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
    {
      header: 'User rides count',
      accessorKey: 'user_nb_of_rides_completed',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
    {
      header: 'Driver rides count',
      accessorKey: 'driver_nb_of_rides_completed',
      cell: cell => (
        <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
      ),
    },
  ]).current;

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigation('/driverClubs')}
            variant="nolina-default"
            size="sm"
            icon={faArrowLeft}
          />
        </Card.Header>
      </Card>

      <Table
        HeaderComponent={DriverClubConfigurationHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
      {fields.length > 0 && (
        <Button className="mt-3" onClick={handleSubmit(onSubmit, onError)}>
          Save Changes
        </Button>
      )}
    </>
  );
};
