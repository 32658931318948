import { useBreakpoints } from "@/hooks/useBreakpoints";
import { ICardHeaderProps } from "@/interfaces/Card";
import classNames from "classnames";
import { FC } from "react";
import { Card } from "react-bootstrap";

const CardHeader: FC<ICardHeaderProps> = ({
  title,
  className,
  titleClassName,
}) => {
  const { breakpoints } = useBreakpoints();

  return (
    <Card.Header className={classNames("pb-2", className)}>
      <h6
        className={classNames(
          {
            "mb-0": !breakpoints,
            [`mb-${breakpoints}-0`]: !!breakpoints,
          },
          titleClassName
        )}
      >
        {title}
      </h6>
    </Card.Header>
  );
};

export default CardHeader;
