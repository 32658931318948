import Loader from '@/components/common/Loader';
import { IReferralCodeForm } from '@/interfaces/Partnership/ReferralCode';
import {
  useGetPartnershipReferralCodeQuery,
  useUpdatePartnershipReferralCodeMutation,
} from '@/services/partnerships/referralCodes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

interface UpdatePartnershipReferralCodeCardProps {
  referralCodeId: string;
  partnershipId: string;
}

const UpdateReferralCodeCard: FC<UpdatePartnershipReferralCodeCardProps> = ({
  referralCodeId,
  partnershipId,
}) => {
  const { data, isLoading } = useGetPartnershipReferralCodeQuery([
    referralCodeId,
    partnershipId,
  ]);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IReferralCodeForm>>({});

  const [updatePartnershipReferralCodeMutation] =
    useUpdatePartnershipReferralCodeMutation();

  const navigation = useNavigate();

  const onSubmit: SubmitHandler<Partial<IReferralCodeForm>> = ({ ...data }) => {
    toast.promise(
      updatePartnershipReferralCodeMutation({
        partnershipId: partnershipId,
        referralCodeId: referralCodeId,
        data: { ...data },
      }).unwrap(),
      {
        pending: `Modification du referral code en cours...`,
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le referral code à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IReferralCodeForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Edit a Referral Code</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Code <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : ABCD"
                  type="text"
                  {...register('code', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 10,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.code}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.code?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateReferralCodeCard;
