import IconButton from '@/components/common/IconButton';
import { ITableHeaderComponentProps } from '@/interfaces/Table';
import {
  faFileExport,
  faFilter,
  faSearch,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const AllDriversClubHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {
  const navigate = useNavigate();

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Drivers Club</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filtres</span>
            </IconButton>
            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </>
        )}
      </div>

      <div id="orders-actions">
        <IconButton
          onClick={() => navigate('/driverClubs/edit')}
          variant="nolina-info"
          size="sm"
          icon={faGear}
          transform="shrink-3"
          className="me-2 hover:text-black hover:no-underline"
        >
          Configure
        </IconButton>
        <a
          href={`${process.env.REACT_APP_API_URI}/export/driverClubs`}
          rel="noreferrer"
        >
          <IconButton
            variant="nolina-default"
            size="sm"
            icon={faFileExport}
            transform="shrink-3"
            style={{ marginLeft: '10px', marginRight: '5px' }}
          >
            <span className="d-none d-sm-inline-block ms-1">Export</span>
          </IconButton>
        </a>
      </div>
    </div>
  );
};

export default AllDriversClubHeader;
