import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import api from './api';
import { IUserList } from '@/interfaces/User/UserList';

export const userListApi = api.injectEndpoints({
  endpoints: build => ({
    createUserListCriterion: build.mutation({
      query: body => ({
        url: '/user-lists/builder',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserLists'],
    }),
    getUserList: build.query<IPaginateResponse<IUserList>, IFilters>({
      query: () => ({
        url: '/user-lists/builder',
      }),
      providesTags: ['UserLists'],
    }),
    deleteUserList: build.mutation<string, string>({
      query: id => ({
        url: `/user-lists/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserLists'],
    }),
    insertCouponToUserList: build.mutation({
      query: ([userListIds, couponId]) => ({
        url: `/user-lists/insertCoupon`,
        method: 'POST',
        body: {
          user_list_ids: userListIds,
          coupon_id: couponId,
        },
      }),
      invalidatesTags: ['UserLists'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateUserListCriterionMutation,
  useGetUserListQuery,
  useLazyGetUserListQuery,
  useDeleteUserListMutation,
  useInsertCouponToUserListMutation
} = userListApi;
