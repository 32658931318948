import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllOffersHeader from '@/components/table-headers/offers/AllOffersHeader';
import {
  getColorFromIsActive,
  getReelIsActiveName,
} from '@/helpers/UserHelpers';
import { truncate } from '@/helpers/utils';
import { IFilters } from '@/interfaces/Fetch';
import IOffer, { IOfferForm } from '@/interfaces/Offer';
import {
  useDestroyOfferMutation,
  useGetOffersQuery,
  useUpdateOfferMutation,
} from '@/services/offers';
import {
  faArchive,
  faCheck,
  faEdit,
  faEye,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';

const OffersView: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const [destroyOfferAsync] = useDestroyOfferMutation();

  const { data, isLoading, isError, error } = useGetOffersQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [updateOffer, { isLoading: isUpdateOfferLoading }] =
    useUpdateOfferMutation();

  const handleActiveToggle = async (offerId: string, isActive: boolean) => {
    try {
      const offerToUpdate: Partial<IOfferForm> = {
        isActive: !isActive,
      };
      await updateOffer([offerId, offerToUpdate]);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  const columns = useRef<ColumnDef<IOffer>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id, isActive }) => (
        <>
          <Link to={`/offers/${id}/edit`} className="btn btn-info btn-sm ms-2">
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            variant={isActive ? 'primary' : 'info'}
            size="sm"
            className="ms-2"
            onClick={() => handleActiveToggle(id, isActive)}
            disabled={isUpdateOfferLoading}
          >
            {isActive ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Is Active',
      footer: props => props.column.id,
      accessorFn: ({ isActive }) => (
        <SoftBadge
          bg={getColorFromIsActive(isActive)}
          className={'my-2 text-xl-center'}
        >
          {getReelIsActiveName(isActive)}
        </SoftBadge>
      ),
      id: 'isActive',
      cell: info => info.getValue(),
    },
    {
      header: 'Avatar',
      footer: props => props.column.id,
      accessorFn: ({ avatar }) =>
        avatar ? (
          <ImageOverview images={[avatar.link]}>
            {setImgIndex => (
              <Avatar
                size="xl"
                src={avatar.link}
                name={avatar.name}
                onClick={() => setImgIndex(0)}
              />
            )}
          </ImageOverview>
        ) : (
          <UnknownBadge />
        ),
      id: 'avatar',
      cell: info => info.getValue(),
    },
    {
      header: 'Name',
      footer: props => props.column.id,
      accessorFn: ({ name }) => name,
      id: 'name',
      cell: info => info.getValue(),
    },
    {
      header: 'Number Of Seats',
      footer: props => props.column.id,
      accessorFn: ({ numberOfSeats }) => numberOfSeats,
      id: 'numberOfSeats',
      cell: info => info.getValue(),
    },
    {
      header: 'Offer ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => truncate(id, 10),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  const onDelete = useCallback((id: string) => {
    toast.promise(destroyOfferAsync(id).unwrap(), {
      pending: 'Suppression du offer en cours ...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p className="mb-0 text-center">Le offer à bien été supprimé 🤩</p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'Une erreur est survenue';
        },
      },
    });
  }, []);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le offer qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllOffersHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default OffersView;
