import Loader from "@/components/common/Loader";
import NavbarTop from "@/components/layouts/NavbarTop";
import NavbarVertical from "@/components/layouts/NavbarVertical";
import { useLazyGetMyProfileQuery } from "@/services/auth";
import { selectCurrentUser } from "@/services/slices/authSlice";
import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { DndProvider } from "react-dnd";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";

const AuthMiddleware: FC = () => {
  const user = useSelector(selectCurrentUser);
  const [cookies, _, removeCookie] = useCookies(["te_co"]);
  const [getMyProfileAsync] = useLazyGetMyProfileQuery();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          const token = cookies.te_co;
          if (!token) {
            throw new Error("No token");
          }
          await getMyProfileAsync(token).unwrap();
        }
      } catch (e) {
        removeCookie("te_co", { path: "/" });
        setHasError(true);
      }
    })();
  }, []);

  return user ? (
    <DndProvider backend={HTML5Backend}>
      <Container className="px-5" fluid>
        <NavbarVertical />
        <div className="content">
          <NavbarTop />
          <Outlet />
        </div>
      </Container>
    </DndProvider>
  ) : hasError ? (
    <Navigate to="/auth" replace />
  ) : (
    <Loader className="min-vh-100" />
  );
};

export default AuthMiddleware;
