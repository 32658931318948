import ErrorComponent from "@/components/common/ErrorComponent";
import AuthCardLayout from "@/components/layouts/AuthCardLayout";
import { EMAIL_REGEX } from "@/constants/regexs";
import {
  INVALID_EMAIL_MESSAGE,
  INVALID_EMAIL_REQUIRED_MESSAGE,
  INVALID_FORM_MESSAGE,
} from "@/constants/validatorMessages";
import { ICheckEmailOTP } from "@/interfaces/Auth";
import { useCheckEmailOTPMutation } from "@/services/auth";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";
import { Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContentProps, toast } from "react-toastify";

const LoginView: FC = () => {
  const { email } = useParams<{ email: string }>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICheckEmailOTP>({
    defaultValues: {
      email,
    },
  });
  const [mutateAsync] = useCheckEmailOTPMutation();
  const [_, setCookie] = useCookies(["te_co"]);

  const onSubmit: SubmitHandler<ICheckEmailOTP> = async (loginData) => {
    await toast.promise(mutateAsync(loginData).unwrap(), {
      pending: "Authentification en cours...",
      success: {
        render({ data }) {
          console.log("AFTER CHECK EMAIL", loginData, data)
          setCookie("te_co", data?.token, { path: "/" });
          return (
            <p style={{ marginBottom: 0, textAlign: "center" }}>
              C&apos;est un plaisir de vous revoir{" "}
              <b>{data?.user.firstname} 🤩</b>
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 409) {
            return "Votre code est expiré ou invalide.";
          } else if (data?.status === 403) {
            navigate("/auth/login");
            return "Vous n'êtes pas autorisé à vous connecter.";
          }

          return "Une erreur est survenue";
        },
      },
    });
  };

  const onError: SubmitErrorHandler<ICheckEmailOTP> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  if (!email || !EMAIL_REGEX.test(email)) {
    return (
      <ErrorComponent
        error={{ status: 422, data: {} }}
        returnButton
        className="min-vh-100"
      />
    );
  }

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Vous n&apos;avez pas de compte ?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/auth/register"
          >
            Faire une demande
          </Link>
        </p>
      }
    >
      <h3>Connexion</h3>
      <Form onSubmit={handleSubmit(onSubmit, onError)} className="mt-4">
        <Form.Group className="mb-3">
          <Form.Label>
            Adresse e-mail <span className="text-danger">*</span> :
          </Form.Label>
          <Form.Control
            {...register("email", {
              required: INVALID_EMAIL_REQUIRED_MESSAGE,
              pattern: {
                value: EMAIL_REGEX,
                message: INVALID_EMAIL_MESSAGE,
              },
            })}
            isInvalid={!!errors.email}
            type="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Code à 4 chiffres <span className="text-danger">*</span> :
          </Form.Label>
          <Form.Control
            placeholder="ex : 1234"
            type="number"
            {...register("otp", {
              required: "Le code OTP est obligatoire",
              minLength: {
                value: 4,
                message: "Le code OTP doit contenir 4 chiffres",
              },
              maxLength: {
                value: 4,
                message: "Le code OTP doit contenir 4 chiffres",
              },
            })}
            isInvalid={!!errors.otp}
          />
          <Form.Control.Feedback type="invalid">
            {errors.otp?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" variant="primary" className="my-3 w-100">
          Se connecter
        </Button>
        <Link className="fs--1 text-600" to="/auth">
          Retour à la page de connexion
          <span className="d-inline-block ms-1"> &rarr;</span>
        </Link>
      </Form>
    </AuthCardLayout>
  );
};

export default LoginView;
