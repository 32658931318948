import DriverHeader from '@/components/card-headers/drivers/DriverHeader';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import Driver from '@/interfaces/Driver';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import UpdateDriverCard from '@/components/updates/drivers/UpdateDriverCard';

const DriverEditView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [driver, setDriver] = useState<Driver>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {driver && (
        <DriverHeader
          id={driver.id}
          bloquedProfile={driver.blockedProfile}
          isEdit
          showBlockActionBtn
        />
      )}
      <UpdateDriverCard id={id} callBack={(driver_)=>{
        setDriver(driver_);
      }} />
    </div>
  );
};

export default DriverEditView;
