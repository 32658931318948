import { FC, useContext } from "react";
import NormalLogo from "@/assets/img/logo.png";
import WhiteLogo from "@/assets/img/logo-white.png";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { TemplateContext } from "@/hooks/useTemplate";

interface IProps {
  at?: "navbar-vertical" | "navbar-top" | "auth";
  className?: string;
}

const Logo: FC<IProps> = ({ at = "navbar-vertical", className }) => {
  const { isDark } = useContext(TemplateContext);
  return (
    <Link
      to="/"
      className="navbar-brand text-left text-decoration-none text-black"
    >
      <div
        className={classNames(
          "d-flex align-items-center",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center fw-bolder fs-5 mb-4": at === "auth",
          },
          className
        )}
      >
        {isDark ? (
          <img className="ms-1 me-2" src={WhiteLogo} alt="Logo" width={200} />
        ) : (
          <img className="ms-1 me-2" src={NormalLogo} alt="Logo" width={200} />
        )}
      </div>
    </Link>
  );
};

export default Logo;
