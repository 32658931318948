import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { IModalMethods } from '@/interfaces/Modal';
import {
  IVehicleForm,
  VEHICULES_FUEL_TYPES,
  VEHICULES_TYPES,
} from '@/interfaces/Vehicle';
import { useStoreVehiculesMutation } from '@/services/vehicles';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreVehiculesModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IVehicleForm>({
    defaultValues: {
      fuelType: VEHICULES_FUEL_TYPES[0],
      vehicleType: VEHICULES_TYPES[0],
    },
  });
  const [storeVehiculeAsync] = useStoreVehiculesMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IVehicleForm> = ({
    vehicleType,
    fuelType,
    ...data
  }) => {
    toast.promise(
      storeVehiculeAsync({
        vehicleType: vehicleType.value,
        fuelType: fuelType.value,
        ...data,
      }).unwrap(),
      {
        pending: `Ajout du vehicule en cours...`,
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le véhicule à bien été ajouté 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IVehicleForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title={'Ajouter un véhicule'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Brand <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : BMW"
              type="text"
              {...register('brand', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.brand}
            />
            <Form.Control.Feedback type="invalid">
              {errors.brand?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Model <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : M3"
              type="text"
              {...register('model', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.model}
            />
            <Form.Control.Feedback type="invalid">
              {errors.model?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Seats <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 5"
              type="number"
              {...register('numberOfSeats', {
                required: 'Ce champ est requis.',
                min: {
                  value: 1,
                  message: 'Ce champ doit être supérieur à 0.',
                },
                max: {
                  value: 10,
                  message: 'Ce champ doit être inférieur ou égale à 6.',
                },
              })}
              isInvalid={!!errors.numberOfSeats}
            />
            <Form.Control.Feedback type="invalid">
              {errors.numberOfSeats?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Fuel Type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="fuelType"
              render={({ field }) => (
                <Select
                  options={VEHICULES_FUEL_TYPES}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fuelType?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Vehicule Type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="vehicleType"
              render={({ field }) => (
                <Select
                  options={VEHICULES_TYPES}
                  {...register('vehicleType', {
                    required: 'Ce champ est requis.',
                  })}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.vehicleType?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Avatar <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control type="file" {...register('avatar')} />
            <Form.Control.Feedback type="invalid">
              {errors.avatar?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Eligible Van ? <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Check
              type="checkbox"
              {...register('eligibleForVan')}
              isInvalid={!!errors.eligibleForVan}
            />
            <Form.Control.Feedback type="invalid">
              {errors.eligibleForVan?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Eligible Berlin ? <span className="text-danger">*</span>
            </Form.Label>
            <Form.Check
              type="checkbox"
              {...register('eligibleForBerline')}
              isInvalid={!!errors.eligibleForBerline?.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.eligibleForBerline?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreVehiculesModal);
