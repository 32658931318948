import { LiveActivityDataQuery, LiveActivityResult, LiveActivityStats } from '@/interfaces/LiveActivity';
import api from './api';
import { objectToQueryString } from '@/helpers/utils';


export const liveActivityApi = api.injectEndpoints({
  endpoints: build => ({
    liveActivityDatas: build.query<LiveActivityResult, LiveActivityDataQuery>({
      query: liveActivityQuery => {
        const params = objectToQueryString(liveActivityQuery);
        return {
          url: `/liveActivity?${params}`,
        };
      },
    }),
    liveActivityStats: build.query<LiveActivityStats, void>({
      query: () => `/liveActivity/stats`,
    })
  }),
});

export const {
  useLiveActivityDatasQuery,
  useLiveActivityStatsQuery
} = liveActivityApi;
