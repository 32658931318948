import { ITableHeaderComponentProps } from '@/interfaces/Table';
import { ElementRef, FC, useRef } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faFilter,
  faList,
  faPlus,
  faRefresh,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@/components/common/IconButton';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import StoreUserModal from '@/components/modals/user/StoreUserModal';
import { useGetUsersQuery } from '@/services/users';
import UserListModal from '@/components/modals/user/UserListModal';
import { useNavigate } from 'react-router-dom';

const AllUsersHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {
  const userConfig = useSelector(selectCurrentUserConfig);
  const navigate = useNavigate();
  const StoreUserModalRef = useRef<ElementRef<typeof StoreUserModal>>(null);
  const UserListModalRef = useRef<ElementRef<typeof UserListModal>>(null);

  const { refetch } = useGetUsersQuery({
    pageIndex: 0,
    pageSize: 150,
    query: '',
  });

  const openCreateModal = () => StoreUserModalRef.current?.showOrHiddenModal();
  const openUserListModal = () => UserListModalRef.current?.showOrHiddenModal();

  return (
    <div className="d-lg-flex justify-content-between">
      {userConfig.isAdminOrSuperAdmin && (
        <>
          <StoreUserModal ref={StoreUserModalRef} />
          <UserListModal ref={UserListModalRef} />
        </>
      )}
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Utilisateurs</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filtres</span>
            </IconButton>
            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </>
        )}

        <div id="orders-actions">
          {userConfig?.isAdminOrSuperAdmin && (
            <>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faPlus}
                onClick={openCreateModal}
                transform="shrink-3"
                iconAlign="middle"
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  Nouveau
                </span>
              </IconButton>

              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faPlus}
                onClick={openUserListModal}
                transform="shrink-3"
                iconAlign="middle"
                style={{ marginLeft: '10px' }}
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  Create list
                </span>
              </IconButton>

              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faList}
                onClick={() => navigate('/users/list')}
                transform="shrink-3"
                iconAlign="middle"
                style={{ marginLeft: '10px' }}
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  View lists
                </span>
              </IconButton>

              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faRefresh}
                transform="shrink-3"
                style={{ marginLeft: '10px' }}
                onClick={refetch}
              >
                <span className="d-none d-sm-inline-block ms-1">Refresh</span>
              </IconButton>

              <a
                href={`${process.env.REACT_APP_API_URI}/export/users`}
                rel="noreferrer"
              >
                <IconButton
                  variant="nolina-default"
                  size="sm"
                  icon={faFileExport}
                  transform="shrink-3"
                  style={{ marginLeft: '10px' }}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllUsersHeader;
