import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ColumnDef, TableState } from '@tanstack/react-table';
import Loader from '@/components/common/Loader';
import ErrorComponent from '@/components/common/ErrorComponent';
import Table from '@/components/common/table/Table';
import AllDriverNotificationHeader from '@/components/table-headers/rides/AllDriverNotificationHeader';
import Avatar from '@/components/common/Avatar';
import IDriverNotification, {
  IDriverNotificationFilters,
} from '@/interfaces/Ride/DriverNotification';
import { useGetDriverNotifcationsQuery } from '@/services/requests/driverNotificatons';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromDriverNotificationStatusColor,
  getDriverNotificationStatusName,
} from '@/helpers/DriverHelpers';
import { GOOGLEMAPS_COORD_LINK } from '@/constants/places';
import dayjs from 'dayjs';

type DriverNotificationTableProps = {
  id: string;
};

const DriverNotifcationCard: React.FC<DriverNotificationTableProps> = ({
  id,
}) => {
  const [state, setState] = useState<
    Partial<TableState> & IDriverNotificationFilters
  >({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isError, error } = useGetDriverNotifcationsQuery([
    id,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const columns = useRef<ColumnDef<IDriverNotification>[]>([
    {
      header: 'Phase',
      footer: props => props.column.id,
      accessorFn: ({ phase }) => phase,
      id: 'phase',
      cell: info => info.getValue(),
    },
    {
      header: 'Driver',
      footer: props => props.column.id,
      accessorFn: ({ driverName, driverId }) => (
        <Link to={`/drivers/${driverId}`}>{driverName}</Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      header: 'Last Location',
      footer: props => props.column.id,
      accessorFn: ({ coordsDriver }) => {
        if (!coordsDriver?.latitude || !coordsDriver.longitude) return 'N/A';
        return (
          <Link
            to={GOOGLEMAPS_COORD_LINK(
              coordsDriver?.latitude,
              coordsDriver.longitude
            )}
          >
            localisation google
          </Link>
        );
      },
      id: 'services.lastLocation',
      cell: info => info.getValue(),
    },
    {
      header: 'Last Location Time',
      footer: props => props.column.id,
      accessorFn: ({ coordsDriver }) => {
        if (!coordsDriver?.latitude || !coordsDriver.longitude) return 'N/A';
        return (<div>{dayjs(coordsDriver.updatedAt).format('DD/MM/YYYY HH:mm')}</div>)
      },
      id: 'services.lastLocationTime',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ status }) => (
        <SoftBadge bg={getColorFromDriverNotificationStatusColor(status)}>
          {getDriverNotificationStatusName(status)}
        </SoftBadge>
      ),
      id: 'status',
      cell: info => info.getValue(),
    },
    {
      header: 'Time & Distance to Pickup',
      footer: props => props.column.id,
      accessorFn: ({ timeToPickup, distanceToPickup }) => (
        <>
          <div>
            {timeToPickup} - {distanceToPickup}
          </div>
        </>
      ),
      id: 'timeAndDistanceToPU',
      cell: info => info.getValue(),
    },
    {
      header: 'Car Brand\nCar plate',
      enableSorting: false,
      footer: (props: any) => props.column.id,
      accessorFn: ({ carAvatar, carBrand, carModel, carPlate, driverId }) => {
        return (
          <Link to={`/drivers/${driverId}/vehicles/${id}`}>
            <div className="d-flex align-items-center">
              {carAvatar && (
                <div className="me-2">
                  <Avatar
                    src={carAvatar.link}
                    alt={`${carBrand} Avatar`}
                    className="me-2"
                    size="2xl"
                  />
                </div>
              )}

              <div>
                <div>
                  <span>
                    {carBrand} {carModel}
                  </span>
                </div>
                <div>
                  <span>{carPlate}</span>
                </div>
              </div>
            </div>
          </Link>
        );
      },
      id: 'CarBrand',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <>
      <Table
        HeaderComponent={AllDriverNotificationHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default DriverNotifcationCard;
