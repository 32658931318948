import {  DriverDocumentType } from "@/interfaces/Driver/Document";


export const getReelTypeNameOfDocument = (status?: DriverDocumentType) => {
  if (status === "identityDocumentVerification") return "ID Card";
  else if (status === "legalChauffeurTransportVehicleDrivingAuthorization") return "Carte VTC";
  else if (status === "legalRoadVehicleDrivingAuthorization") return "Permis de conduire";
  else if (status === "officialFrenchBusinessExistenceCertificate") return "Extrait KBIS ou Relevé SIREN";
  else if (status === "professionalLiabilityInsurance") return "RC Pro";
  else if (status === "vtcRegistrationCertificate") return "Certificat Registre VTC";
  else return "Attestation de passage";
};