import React from 'react';
import StatsNumberLiveItem from './StatsNumberLiveItem';
import { Card, Col, Row } from 'react-bootstrap';

function StatsNumberLive() {
  const datas = Array.from({ length: 3 }, (_, i) => i + 1);
  return (
    <Card>
      <Row className="px-3 rounded-2 overflow-hidden">
        {datas.map(data => (
          <Col xs={6} lg={4} key={data} className="p-0">
            <StatsNumberLiveItem />
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default StatsNumberLive;
