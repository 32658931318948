import DriverHeader from '@/components/card-headers/drivers/DriverHeader';
import DriverVehicleDocumentsCard from '@/components/cards/drivers/vehicles/DriverVehicleDocumentCard';
import DriverVehicleInfoCard from '@/components/cards/drivers/vehicles/DriverVehicleInfoCard';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DriverVehiculeView: FC = () => {
  const { id, vehicleId } = useParams<{ id: string; vehicleId: string }>();

  if (
    !id ||
    !UUID_REGEX.test(id) ||
    !vehicleId ||
    !UUID_REGEX.test(vehicleId)
  ) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <>
      <DriverHeader id={id} isEdit />
      <Row className="g-3 mb-3">
        <Col xl={4} lg={5} md={5} sm={12}>
          <DriverVehicleInfoCard
            idVehicle={vehicleId}
            driverId={id}
            isLink={false}
          />
        </Col>
        {/* <Col xl={8} lg={7} md={7} sm={12}>
          <DriverVehicleDocumentsCard idVehicle={vehicleId} driverId={id} />
        </Col> */}
      </Row>
    </>
  );
};

export default DriverVehiculeView;
