import {
  PlaceAutocompleteParams,
  PlaceAutocompleteResult,
} from "@/interfaces/PlaceAutocomplete";
import api from "../api";

export const placeAutocompleteApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlacesAutocomplete: build.query<
      PlaceAutocompleteResult[],
      PlaceAutocompleteParams
    >({
      query: (params) => ({
        url: "/googlePlaceAutocomplete",
        params,
      }),
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ place_id: id }) => ({
                type: "Places" as const,
                id,
              })),
              { type: "Places", id: "LIST" },
            ]
          : [],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPlacesAutocompleteQuery,
  useLazyGetPlacesAutocompleteQuery,
} = placeAutocompleteApi;
