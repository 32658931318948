import UserCoupon from "@/interfaces/User/Coupon";
import classNames from "classnames";
import { DateTime } from "luxon";
import { FC } from "react";
import SoftBadge from "./SoftBadge";
import {
  getColorFromUserCouponUsed,
  getReelUserCouponUsed,
} from "@/helpers/UserHelpers";

interface IProps extends UserCoupon {
  className?: string;
  flush?: boolean;
}

const Coupon: FC<IProps> = ({
  className,
  coupon: { name, startAt, endAt, code },
  usedAt,
  createdAt,
  flush,
}) => (
  <div
    className={classNames(
      "notification rounded-0 border-x-0 border-300 border-bottom-0",
      { "notification-flush": flush },
      className
    )}
  >
    <div className="notification-body">
      <div className="d-flex align-items-center mb-1">
        <p className="mb-0">
          {name} - {code} -{" "}
        </p>
        <SoftBadge bg={getColorFromUserCouponUsed(usedAt)} className="ms-1">
          {getReelUserCouponUsed(usedAt)}
        </SoftBadge>
      </div>
      <p className="notification-time mb-1">
        {DateTime.fromISO(startAt).toFormat("dd/MM/yyyy")} -{" "}
        {DateTime.fromISO(endAt).toFormat("dd/MM/yyyy")}
      </p>
      <p className="notification-time">
        Add {DateTime.fromISO(startAt).toFormat("dd/MM/yyyy")}
      </p>
    </div>
  </div>
);

export default Coupon;
