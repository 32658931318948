import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import api from './api';
import { createFormDataRequest } from '@/helpers/utils';
import { IDriverClubConfigurations } from '@/interfaces/DriverClubConfigurations';

export const driverClubsConfigurationApi = api.injectEndpoints({
  endpoints: build => ({
    getDriverClubConfigurations: build.query<IPaginateResponse<IDriverClubConfigurations>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/driverClubConfigurations',
          params,
        };
      },
    }),
    updateDriverClubConfigurations: build.mutation<IDriverClubConfigurations, IDriverClubConfigurations>({
      query: data => {
        return {
          url: `/driverClubConfigurations`,
          method: 'PUT',
          body: data,
        };
      },
    }),
  }),
});

export const { useGetDriverClubConfigurationsQuery, useUpdateDriverClubConfigurationsMutation } = driverClubsConfigurationApi;
