export type Sizes = "s" | "m" | "l" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";

export type Colors =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "white"
  | "black";

export const ColorsArray: string[] = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
  "white",
  "black",
];
