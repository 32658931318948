import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import CouponHeader from '@/components/card-headers/coupons/CouponHeader';
import { Col, Row } from 'react-bootstrap';
import CouponDetailsCard from '@/components/cards/coupons/CouponDetailsCard';

const CouponView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <CouponHeader id={id} />
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <CouponDetailsCard id={id} card />
        </Col>
      </Row>
    </div>
  );
};

export default CouponView;
