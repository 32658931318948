import Modal from '@/components/common/Modal';
import { IModalMethods } from '@/interfaces/Modal';
import { IReferralCodeForm } from '@/interfaces/Partnership/ReferralCode';
import { useStorePartnershipReferralCodeMutation } from '@/services/partnerships/referralCodes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const StoreReferralCodesModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IReferralCodeForm>();

  const [storePartnershipReferralCodeAsync] =
    useStorePartnershipReferralCodeMutation();

  const { id: partnershipId } = useParams<{
    id: string;
  }>();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IReferralCodeForm> = async ({ code }) => {
    if (partnershipId) {
      toast.promise(
        storePartnershipReferralCodeAsync({
          partnershipId,
          code,
        }).unwrap(),
        {
          pending: 'Ajout du referral code en cours...',
          success: {
            render() {
              reset();
              showOrHiddenModal();
              return (
                <p style={{ marginBottom: 0, textAlign: 'center' }}>
                  Le referral code à bien été ajouté 🤩
                </p>
              );
            },
          },
          error: {
            render({ data }: ToastContentProps<FetchBaseQueryError>) {
              if (data?.status === 422) {
                return 'Les champs que vous avez remplis semblent être incorrects.';
              }

              return 'Une erreur est survenue';
            },
          },
        }
      );
    } else {
      console.error('partnershipId is undefined');
    }
  };

  const onError: SubmitErrorHandler<IReferralCodeForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un referral code"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Code <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : NOEL10"
              type="text"
              {...register('code', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 10,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.code}
            />
            <Form.Control.Feedback type="invalid">
              {errors.code?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreReferralCodesModal);
