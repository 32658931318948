import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import AssociationHeader from '@/components/card-headers/associations/AssociationHeader';
import UpdateAssociationCard from '@/components/updates/association/UpdateAssociationCard';

const AssociationEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <AssociationHeader id={id} isEdit />
      <UpdateAssociationCard id={id} />
    </div>
  );
};

export default AssociationEditView;
