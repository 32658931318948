import { createFormDataRequest } from '@/helpers/utils';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import PointTransaction, { IPointTransactionFormSend } from '@/interfaces/User/PointTransaction';
import api from '@/services/api';

export const pointsTransactionsApi = api.injectEndpoints({
  endpoints: build => ({
    getDriverPointsTransactions: build.query<
      IPaginateResponse<PointTransaction>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/drivers/${id}/pointsTransactions`,
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
            ...result.data.map(({ id }) => ({ type: "DriversPointsTransactions" as const, id })),
            { type: "DriversPointsTransactions", id: "LIST" },
          ]
          : [],
    }),

    storeDriverPointsTransactions: build.mutation<PointTransaction, [string, IPointTransactionFormSend]>({
      query: ([userId, body]) => ({
        url: `/drivers/${userId}/pointsTransactions`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_user, _err, [id]) => [
        { type: "DriversPointsTransactions", id: "LIST" },
        { type: "Drivers", id },
        { type: "UserLogs", id: "LIST" }
      ],
    }),
  }),
  overrideExisting: true,
});

export const { 
  useGetDriverPointsTransactionsQuery,
  useStoreDriverPointsTransactionsMutation 
} = pointsTransactionsApi;
