import UserHeader from '@/components/card-headers/users/UserHeader';
import ErrorComponent from '@/components/common/ErrorComponent';
import UserEditCard from '@/components/updates/users/UserEditCard';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

const UserEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <UserHeader id={id} isEdit />
      <UserEditCard id={id} />
    </div>
  );
};

export default UserEditView;
