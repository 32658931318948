import {
  faCake,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';

type StatsSortablesItemProps = {
  id: number;
  title: string;
  value: any;
  subValue?: string;
  percent: number | null;
  indicator?: 'up' | 'down' | 'no-changes';
};

const StatsItem: FC<StatsSortablesItemProps> = ({
  id,
  title,
  value,
  subValue,
  percent,
  indicator = 'standard',
}) => {
  return (
    <div
      className={classNames('p-3 border-end border-bottom', {
        'border-left-0': !(id % 3 === 0),
      })}
    >
      <h5 className="text-800 mb-2">{title}</h5>

      <h2>{value}</h2>

      <div className="d-flex align-items-center">
        <h6 className="fs--1 text-500 mb-0">{subValue}</h6>
        {percent && (
          <h6
            className={classNames('fs--2 ps-3 mb-0', {
              'text-success': indicator === 'up',
              'text-danger': indicator === 'down',
              'text-primary': indicator === 'no-changes',
            })}
          >
            <FontAwesomeIcon
              icon={
                indicator === 'up'
                  ? faCaretUp
                  : indicator === 'down'
                  ? faCaretDown
                  : faCake
              }
              className="me-1"
            />
            {percent}%
          </h6>
        )}
      </div>
    </div>
  );
};

export default StatsItem;
