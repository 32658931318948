import { IPaginateCardProps } from "@/interfaces/Card";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Button, Card, FormControl, InputGroup } from "react-bootstrap";
import Flex from "../common/Flex";
import TablePagination from "../common/table/TablePagination";
import classNames from "classnames";
import { IFilters } from "@/interfaces/Fetch";
import Loader from "../common/Loader";
import ErrorComponent from "../common/ErrorComponent";
import EmtpyDataComponent from "../common/EmptyDataComponent";

const PaginateCard = <F extends IFilters>({
  meta,
  children,
  title,
  card = false,
  filters,
  isLoading,
  error,
  setFilters,
}: IPaginateCardProps<F>) => {
  const nextPage = () =>
    setFilters((old) => ({ ...old, pageIndex: old.pageIndex! + 1 }));

  const previousPage = () =>
    setFilters((old) => ({ ...old, pageIndex: old.pageIndex! - 1 }));

  const setPageIndex = (pageIndex: number) =>
    setFilters((old) => ({ ...old, pageIndex }));

  const canNextPage = () => meta?.currentPage !== meta?.lastPage;

  const canPreviousPage = () => meta?.firstPage !== meta?.currentPage;

  return (
    <div
      className={classNames({
        card: card,
      })}
    >
      <div
        className={classNames({
          "card-header d-flex justify-content-between align-items-center mb-1 mb-md-0 flex-wrap":
            card,
        })}
      >
        {title && <h5>{title}</h5>}
        {!isLoading ||
          (error && (
            <InputGroup
              className={classNames("position-relative input-search-width", {
                "mb-3": !card,
              })}
            >
              <FormControl
                size="sm"
                id="search"
                type="search"
                className="shadow-none"
                placeholder="Rechercher"
                onChange={(e) =>
                  setFilters((old) => ({ ...old, query: e.target.value }))
                }
              />
              <Button
                size="sm"
                variant="outline-secondary"
                className="border-300 hover-border-secondary"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          ))}
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorComponent error={error} />
      ) : meta?.total === 0 ? (
        <EmtpyDataComponent />
      ) : (
        <>
          <Flex
            direction="column"
            className={classNames({
              "card-body p-0": card,
              "gap-3": !card,
            })}
          >
            {children}
          </Flex>
          <div
            className={classNames({
              "card-footer": card,
            })}
          >
            <TablePagination
              className={classNames({
                "mt-2": card,
                "mt-4": !card,
              })}
              nextPage={nextPage}
              pageCount={meta?.lastPage ?? 1}
              pageIndex={filters.pageIndex!}
              previousPage={previousPage}
              setPageIndex={setPageIndex}
              canNextPage={canNextPage()}
              canPreviousPage={canPreviousPage()}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaginateCard;
