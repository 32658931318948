import classNames from 'classnames';
import { FC } from 'react';
import { InfinitySpin } from 'react-loader-spinner';

interface IProps {
  className?: string;
  absolute?: boolean;
}

const Loader: FC<IProps> = ({ className, absolute = false }) => {
  return (
    <div
      className={classNames(
        'd-flex justify-content-center align-items-center',
        {
          'position-absolute top-0 start-0 end-0 bottom-0': absolute,
        },
        className
      )}
    >
      <div className="text-center">
        <InfinitySpin width="200" color="#1e1a55" />
        <p className="mt-2">Chargement en cours ...</p>
      </div>
    </div>
  );
};

export default Loader;
