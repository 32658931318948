import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import StatisticsCard from '@/components/common/StatisticsCard';
import Table from '@/components/common/table/Table';
import AllDriversReferralsHeader from '@/components/table-headers/drivers/referrals/AllDriversReferralHeader';
import {
  IDriverReferrals,
  IDriverReferralsFilters,
} from '@/interfaces/DriverReferrals';
import { useGetDriverReferralsQuery } from '@/services/drivers/referrals';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type DriverReferralsTableProps = {
  id: string;
};

const DriverReferralsTable: FC<DriverReferralsTableProps> = ({ id }) => {
  const [state, setState] = useState<
    Partial<TableState> & IDriverReferralsFilters
  >({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isError, error } = useGetDriverReferralsQuery([
    id,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const columns = useRef<ColumnDef<IDriverReferrals>[]>([
    {
      header: 'Date\nTime',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) => (
        <>
          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(createdAt).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
    {
      header: 'User',
      footer: props => props.column.id,
      accessorFn: ({ referredUser }) => (
        <Link to={`/users/${referredUser?.id}`}>{referredUser?.fullname}</Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      header: '# Completed Rides',
      footer: props => props.column.id,
      accessorFn: ({ referredUser }) => referredUser?.completedRidesCount,
      id: 'id',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  const filteredData = data?.data.filter(
    referral => referral.referredUser !== null
  );

  const totalCompletedRides = filteredData?.reduce((total, referral) => {
    return total + (referral.referredUser?.completedRidesCount || 0);
  }, 0);

  const totalUsers = data?.meta?.total || 0;

  return (
    <>
      <Row className="g-2 mb-2">
        <Col md={6} sm={6} xl={6}>
          <StatisticsCard
            title="# User Referrals"
            value={totalUsers}
            prefix="👥"
            badgeBg="success"
          />
        </Col>
        <Col md={6} sm={6} xl={6}>
          <StatisticsCard
            title="# Completed Rides"
            value={totalCompletedRides || 0}
            prefix="🚗"
            badgeBg="warning"
          />
        </Col>
      </Row>

      <Table
        HeaderComponent={AllDriversReferralsHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={filteredData}
        meta={data?.meta}
      />
    </>
  );
};

export default DriverReferralsTable;
