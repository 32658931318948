import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import { IDefaultCardProps } from '@/components/interfaces/Card';
import {
  faArrowLeft,
  faEdit,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import { FC, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateRideStatusMutation } from '@/services/rides';
import UpdateStatusRideModal, {
  UpdateStatusRideModalHandle,
} from '@/components/modals/requests/UpdateRideStatusModal';

const RideHeader: FC<IDefaultCardProps> = ({ id, isEdit }) => {
  const navigation = useNavigate();
  const UpdateStatusRideModalRef = useRef<UpdateStatusRideModalHandle>(null);
  const [updateRideStatus] = useUpdateRideStatusMutation();

  const handleStatusUpdate = async (rideId: string, newStatus: string) => {
    try {
      await updateRideStatus({ id: rideId, status: newStatus }).unwrap();
      toast.success('Ride status updated successfully');
    } catch (error) {
      toast.error('Failed to update ride status');
    }
  };

  return (
    <Card className="mb-3">
      <UpdateStatusRideModal
        ref={UpdateStatusRideModalRef}
        idRide={id}
        onUpdate={handleStatusUpdate}
      />
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigation(-1)}
          variant="nolina-default"
          size="sm"
          icon={faArrowLeft}
        />
        <Flex>
          <div
            className="bg-300 mx-3 d-xl-none"
            style={{ width: '1px', height: '29px' }}
          ></div>
          {!isEdit && (
            <IconButton
              href={`/rides/${id}/edit`}
              variant="nolina-default"
              size="sm"
              icon={faEdit}
              transform="shrink-2"
              iconAlign="middle"
              className="me-2"
            >
              <span className="d-none d-xl-inline-block ms-1">Modifier</span>
            </IconButton>
          )}
          <IconButton
            onClick={() =>
              UpdateStatusRideModalRef.current?.showOrHiddenModal()
            }
            variant="nolina-info"
            size="sm"
            icon={faToggleOn}
            transform="shrink-2"
            iconAlign="middle"
            className="me-2"
          >
            <span className="d-none d-xl-inline-block ms-1">Change Status</span>
          </IconButton>
        </Flex>
      </Card.Header>
    </Card>
  );
};

export default RideHeader;
