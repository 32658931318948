import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect } from 'react';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import { useGetAssociationQuery } from '@/services/associations';
import IAssociation from '@/interfaces/Association';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import ImageOverview from '@/components/common/ImageOverview';
import Avatar from '@/components/common/Avatar';
import UnknownBadge from '@/components/common/UnknownBadge';

const AssociationDetailsCard: FC<ICardDetailsProps<IAssociation>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetAssociationQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-3">
      <Col xs={12} md={12}>
        <Card className="font-sans-serif">
          <Card.Body>
            <Row className="gx-3">
              <Col xs={12} md={4} className="mb-3 mb-md-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Illustration banner:
                      </td>
                      <td className="p-1 text-600">
                        {data.illustrationBanner ? (
                          <ImageOverview
                            images={[data.illustrationBanner.link]}
                          >
                            {setImgIndex => (
                              <Avatar
                                size="xl"
                                src={data.illustrationBanner?.link}
                                name={data.illustrationBanner?.name}
                                onClick={() => setImgIndex(0)}
                              />
                            )}
                          </ImageOverview>
                        ) : (
                          <UnknownBadge />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Illustration link:
                      </td>
                      <td className="p-1 text-600">
                        {data.illustration ? (
                          <ImageOverview images={[data.illustration.link]}>
                            {setImgIndex => (
                              <Avatar
                                size="xl"
                                src={data.illustration?.link}
                                name={data.illustration?.name}
                                onClick={() => setImgIndex(0)}
                              />
                            )}
                          </ImageOverview>
                        ) : (
                          <UnknownBadge />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xs={12} md={4} className="mb-3 mb-md-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Id:
                      </td>
                      <td className="p-1 text-600">{data.id}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Link:
                      </td>
                      <td className="p-1 text-600">
                        <Link to={data.link}>{data.link}</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        name:
                      </td>
                      <td className="p-1 text-600">{data.name}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        title:
                      </td>
                      <td className="p-1 text-600">{data.title}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Category:
                      </td>
                      <td className="p-1 text-600">
                        <span
                          className={classNames(
                            'badge border link-secondary text-decoration-none'
                          )}
                        >
                          {data.category}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xs={12} md={4} className="mb-3 mb-sm-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        Description:
                      </td>
                      <td className="p-1 text-600">{data.description}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Donation goal:
                      </td>
                      <td className="p-1 text-600">
                        <Badge bg="info" className="text-white fs--1">
                          {data.donationGoal}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        createdAt:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.createdAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        updatedAt:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.updatedAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AssociationDetailsCard;
