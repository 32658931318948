import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import { IDefaultCardProps } from '@/components/interfaces/Card';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import StoreDriverModal from '@/components/modals/driver/StoreDriverModal';
import {
  selectCurrentUser,
  selectCurrentUserConfig,
} from '@/services/slices/authSlice';
import { useDestroyUserMutation } from '@/services/users';
import {
  faArrowLeft,
  faCab,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ElementRef, FC, useRef } from 'react';
import { Card as C } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
const Card: any = C

type UserHeaderProps = {
  showTransformDriver?: boolean;
};

const UserHeader: FC<IDefaultCardProps & UserHeaderProps> = ({
  id,
  isEdit,
  showTransformDriver,
}) => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const user = useSelector(selectCurrentUser);
  const userConfig = useSelector(selectCurrentUserConfig);
  const navigation = useNavigate();
  const [deleteUserAsync] = useDestroyUserMutation();

  const StoreUserPointTransactionModalModalRef =
    useRef<ElementRef<typeof StoreDriverModal>>(null);

  const openCreateModal = () =>
    StoreUserPointTransactionModalModalRef.current?.showOrHiddenModal();

  const onDelete = (id: string) => {
    toast.promise(deleteUserAsync(id).unwrap(), {
      pending: "Suppression de l'utilisateur en cours...",
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          navigation(-1);
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              L'utilisateur à bien été supprimée 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  return (
    <>
      {userConfig.isAdminOrSuperAdmin && (
        <StoreDriverModal
          id={id}
          ref={StoreUserPointTransactionModalModalRef}
        />
      )}
      <Card className="mb-3">
        <AreYouSurToContinueModal
          ref={DeleteModalRef}
          onContinue={onDelete}
          title="Souhaitez-vous vraiment supprimé l'utilisateur qui a pour id : {{id}} ?"
        />
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigation(-1)}
            variant="nolina-default"
            size="sm"
            icon={faArrowLeft}
          />
          <Flex>
            <div
              className="bg-300 mx-3 d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
            {!isEdit && showTransformDriver && (
              <IconButton
                variant="nolina-default"
                onClick={openCreateModal}
                size="sm"
                icon={faCab}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
              >
                <span className="d-none d-xl-inline-block ms-1">
                  Transform to driver
                </span>
              </IconButton>
            )}
            {!isEdit && (
              <IconButton
                href={`/users/${id}/edit`}
                variant="nolina-default"
                size="sm"
                icon={faEdit}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
              >
                <span className="d-none d-xl-inline-block ms-1">Modifier</span>
              </IconButton>
            )}
            {userConfig.isAdmin && user?.id !== id && (
              <IconButton
                onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
                variant="nolina-default"
                size="sm"
                icon={faTrash}
                iconAlign="middle"
                className="d-none d-sm-block me-2"
              >
                <span className="d-none d-xl-inline-block ms-1">Supprimer</span>
              </IconButton>
            )}
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
};

export default UserHeader;
