import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../../helpers/utils';
import api from '../api';
import {
  DriverDocument,
  DriverDocumentFormSend,
} from '@/interfaces/Driver/Document';

export const driversDocumentsApi = api.injectEndpoints({
  endpoints: build => ({
    getDocuments: build.query<
      IPaginateResponse<DriverDocument>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/drivers/${id}/documents`,
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'DriversDocuments' as const,
                id,
              })),
              { type: 'DriversDocuments', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getDocument: build.query<DriverDocument, [string, string]>({
      query: ([id, documentId]) => ({
        url: `/drivers/${id}/documents/${documentId}`,
      }),
      providesTags: (_result, _error, [id]) => [
        { type: 'DriversDocuments', id },
      ],
    }),
    updateDocument: build.mutation<
      DriverDocumentFormSend,
      [string, string, Partial<DriverDocumentFormSend>]
    >({
      query: ([idDriver, documentId, body]) => ({
        url: `/drivers/${idDriver}/documents/${documentId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, _error, [id]) => [
        { type: 'DriversDocuments', id: 'LIST' },
        { type: 'DriversDocuments', id },
      ],
    }),
    createDocument: build.mutation<
      DriverDocumentFormSend,
      [string, Partial<DriverDocumentFormSend>]
    >({
      query: ([idDriver, params]) => {
        const body = createFormDataRequest(params);
        return {
          url: `/drivers/${idDriver}/documents`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, _error, [id]) => [
        { type: 'DriversDocuments', id: 'LIST' },
        { type: 'DriversDocuments', id },
      ],
    }),
    deleteDocument: build.mutation<DriverDocumentFormSend, [string, string]>({
      query: ([idDriver, documentId]) => ({
        url: `/drivers/${idDriver}/documents/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, [id]) => [
        { type: 'DriversDocuments', id: 'LIST' },
        { type: 'DriversDocuments', id },
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
} = driversDocumentsApi;
