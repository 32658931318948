import { IModalMethods } from '@/interfaces/Modal';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import Modal from '@/components/common/Modal';
import { IRewardForm, REWARD_TYPES, USER_TYPES } from '@/interfaces/Reward';
import { useStoreRewardMutation } from '@/services/rewards';
import { ToastContentProps, toast } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import useLoadOptions from '@/hooks/useLoadOptions';
import { IFilters } from '@/interfaces/Fetch';

const StoreRewardModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm<IRewardForm>({
    defaultValues: {
      type: REWARD_TYPES[0],
      userType: USER_TYPES[0],
    },
  });

  const { loadPartnerships } = useLoadOptions();
  const defaultAdditionnal = useRef<IFilters>({
    pageIndex: 0,
    pageSize: 15,
  }).current;

  const [storeRewardsAsync] = useStoreRewardMutation();

  const [isPartnership, setIsPartnership] = useState(false);

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IRewardForm> = ({
    type,
    userType,
    partnership,
    ...data
  }) => {
    toast.promise(
      storeRewardsAsync({
        type: type.value,
        userType: userType.value,
        partnershipId: partnership?.id,
        ...data,
      }).unwrap(),
      {
        pending: 'Ajout du reward en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le reward à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IRewardForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un reward"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Get 10% discount"
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Reward Type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  options={REWARD_TYPES}
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    setIsPartnership(value?.value === 'partnership');
                  }}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.type?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Partnership section */}

        {isPartnership && (
          <>
            <hr></hr>

            <Col className="mb-3 fw-bold" lg={12}>
              Partnership Details
            </Col>

            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Partnership <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="partnership"
                  render={({ field }) => (
                    <SelectPaginateAsync
                      loadOptions={loadPartnerships}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={field.value}
                      onChange={field.onChange}
                      additional={defaultAdditionnal}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.partnership?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <hr></hr>
          </>
        )}

        {/* End of partnership section */}

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              User Type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="userType"
              render={({ field }) => (
                <Select
                  options={USER_TYPES}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.userType?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              {watch('userType')?.value === 'user'
                ? 'Discount Price'
                : 'Discount Commission'}{' '}
              &#40;%&#41;<span className="text-danger">*</span>:
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="ex: 10%"
                type="number"
                {...register('discountAmount', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 1,
                    message:
                      'Le discount amount doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le discount amount doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.discountAmount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.discountAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Max Discount Amount &#40;€&#41;{' '}
              <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10€"
              type="number"
              {...register('maxDiscountAmount', {
                required: 'Ce champ est requis..',
              })}
              isInvalid={!!errors.maxDiscountAmount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.maxDiscountAmount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Min Purchase Amount &#40;€&#41;{' '}
              <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('minPurchaseAmount', {
                required: 'Ce champ est requis..',
              })}
              isInvalid={!!errors.minPurchaseAmount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.minPurchaseAmount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Points <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('price', {
                required: 'Ce champ est requis..',
                minLength: {
                  value: 2,
                  message: 'Le price doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Le price doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.price}
            />
            <Form.Control.Feedback type="invalid">
              {errors.price?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Limit Per User <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('limit', {
                required: 'Ce champ est requis..',
                minLength: {
                  value: 0,
                  message: 'Le limit doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Le limitdoit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.limit}
            />
            <Form.Control.Feedback type="invalid">
              {errors.limit?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Description Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Enjoy your reward"
              type="text"
              {...register('descriptionTitle', {
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.descriptionTitle}
            />
            <Form.Control.Feedback type="invalid">
              {errors.descriptionTitle?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            as="textarea"
            type="text"
            {...register('description', {
              minLength: {
                value: 3,
                message: 'Ce champ doit contenir au moins 3 caractères.',
              },
              maxLength: {
                value: 1000,
                message: 'Ce champ doit contenir au plus 255 caractères.',
              },
            })}
            style={{ minHeight: 100 }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreRewardModal);
