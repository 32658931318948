import {
  CompanyBusinessType,
  CompanyBusinessTypeConst_,
  CompanyCountry,
  CompanyCountryConst_,
  CompanyCurrency,
  CompanyCurrencyConst_,
  CompanyPersonRole,
  CompanyPersonRoleConst_,
  CompanyStatus,
  CompanyStatusConst_,
} from '@/interfaces/Company';
import { ISelectOption } from '@/interfaces/Select';
import { getReelNameCountryBasedOnSplit } from './CountryHelper';

export const getColorFromCompanyStatus = (status: CompanyStatus) => {
  if (status === 'pending') return 'warning';
  else if (status === 'inactive') return 'danger';
  else return 'success';
};

export const getReelNameFromCompanyStatus = (status: CompanyStatus) => {
  if (status === 'pending') return 'En attente de validation';
  else if (status === 'inactive') return 'Inactif';
  else return 'Actif';
};

export const CompanyCountrySelect: ISelectOption<CompanyCountry>[] =
  CompanyCountryConst_.map(type => ({
    value: type,
    label: getReelNameCountryBasedOnSplit(type),
  }));

export const CompanyStatusSelect: ISelectOption<CompanyStatus>[] =
  CompanyStatusConst_.map(type => ({
    value: type,
    label: getReelNameFromCompanyStatus(type),
  }));

export const CompanyPersonRoleSelect: ISelectOption<CompanyPersonRole>[] =
  CompanyPersonRoleConst_.map(type => ({
    value: type,
    label: type,
  }));

export const CompanyBusinessTypeSelect: ISelectOption<CompanyBusinessType>[] =
  CompanyBusinessTypeConst_.map(type => ({
    value: type,
    label: type,
  }));

export const CompanyCurrencySelect: ISelectOption<CompanyCurrency>[] =
  CompanyCurrencyConst_.map(type => ({
    value: type,
    label: type,
  }));
