import CardHeader from '@/components/card-headers/CardHeader';
import useLoadOptions from '@/hooks/useLoadOptions';
import { FC } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { AsyncSelectComponent, CustomGoogleMenu } from '../common/Select';
import { CHAMPS_ELYSEES } from '@/constants/places';
import IconButton from '../common/IconButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { ToastContentProps, toast } from 'react-toastify';
import { INVALID_FORM_MESSAGE } from '@/constants/validatorMessages';
import { useStoreRequestMutation } from '@/services/requests';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IRequestForm } from '@/interfaces/Request';

const SimulationPriceCard: FC = () => {
  const { loadPlacesAutocomplete } = useLoadOptions();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IRequestForm>({
    defaultValues: {
      isSimulation: true,
    },
  });
  const [mutateAsync] = useStoreRequestMutation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stopPlaces',
  });

  const onSubmit: SubmitHandler<IRequestForm> = data => {
    toast.promise(
      mutateAsync({
        ...data,
        originPlaceId: data.originPlace?.place_id,
        destinationPlaceId: data.destinationPlace?.place_id,
        stopPlaceIds: data.stopPlaces?.map(stop => stop.place_id),
      }).unwrap(),
      {
        pending: 'Enregistrement de la demande en cours...',
        success: {
          render() {
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                La demande à bien été enregistrée 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IRequestForm> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Simuler des offres" className="bg-light" />
        <Card.Body>
          <Row className="gx-3">
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Adresse de départ <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="originPlace"
                  rules={{
                    required: "L'adresse de départ est obligatoire",
                  }}
                  render={({ field }) => (
                    <AsyncSelectComponent
                      loadOptions={loadPlacesAutocomplete}
                      value={field.value}
                      closeMenuOnSelect
                      isClearable
                      onChange={field.onChange}
                      components={{
                        Menu: CustomGoogleMenu,
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.place_id}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': errors.originPlace,
                  })}
                >
                  {errors.originPlace?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {fields.map((_, index) => (
              <Row key={index} className="align-items-center m-0">
                <Col lg={11}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Stop N°{index + 1} <span className="text-danger">*</span>{' '}
                      :
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`stopPlaces.${index}`}
                      rules={{
                        required: "L'adresse du stop est obligatoire",
                      }}
                      render={({ field }) => (
                        <AsyncSelectComponent
                          loadOptions={loadPlacesAutocomplete}
                          value={field.value}
                          isClearable
                          onChange={field.onChange}
                          closeMenuOnSelect
                          components={{
                            Menu: CustomGoogleMenu,
                          }}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.place_id}
                        />
                      )}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className={classNames({
                        'd-block': errors.stopPlaces?.[index],
                      })}
                    >
                      {errors.stopPlaces?.[index]?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={1} className="d-flex justify-content-end">
                  <IconButton
                    type="button"
                    variant="danger"
                    icon={faTrash}
                    className="mt-3"
                    size="sm"
                    onClick={() => remove(index)}
                  />
                </Col>
              </Row>
            ))}
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Adresse d'arrivée <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="destinationPlace"
                  rules={{
                    required: "L'adresse d'arrivée est obligatoire",
                  }}
                  render={({ field }) => (
                    <AsyncSelectComponent
                      loadOptions={loadPlacesAutocomplete}
                      value={field.value}
                      closeMenuOnSelect
                      isClearable
                      onChange={field.onChange}
                      components={{
                        Menu: CustomGoogleMenu,
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.place_id}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': errors.destinationPlace,
                  })}
                >
                  {errors.destinationPlace?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-light">
          <Button
            type="button"
            variant="primary"
            size="sm"
            onClick={() => append(undefined as any)}
          >
            Ajouter un stop
          </Button>
          <Button type="submit" variant="success" className="ms-3" size="sm">
            Simuler
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default SimulationPriceCard;
