import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import ICoupon from '@/interfaces/Coupon';
import { useGetCouponQuery } from '@/services/coupons';
import classNames from 'classnames';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromIsArchived,
  getReelArchivedName,
} from '@/helpers/UserHelpers';
import PageHeader from '@/components/common/header/PageHeader';
import ImageOverview from '@/components/common/ImageOverview';
import Avatar from '@/components/common/Avatar';
import UnknownBadge from '@/components/common/UnknownBadge';

const CouponDetailsCard: FC<ICardDetailsProps<ICoupon>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetCouponQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-3">
      <Col xs={12}>
        {/* Page header */}
        <PageHeader title={`Coupon Details: ${data.name}`}>
          <p className="fs--1 mt-1">
            {DateTime.fromISO(data?.createdAt).toFormat(
              "dd LLL yyyy 'at' HH:mm"
            )}
          </p>
          <div>
            <strong className="me-2">Status : </strong>
            <SoftBadge
              bg={getColorFromIsArchived(data.isArchived)}
              className="fs--2"
            >
              {getReelArchivedName(data.isArchived)}
            </SoftBadge>
          </div>
        </PageHeader>
        {/* end of page header */}

        {/* COUPON INFO */}

        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <Col md={6} lg={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Coupon Info</h5>
                <h6 className="mb-2">Code : {data.code}</h6>
                <p className="mb-1 fs--1">
                  <strong>Number of Uses : {data.numberOfUses}</strong>
                </p>
                <p className="mb-1 fs--1">
                  <strong>Number of Adds : {data.numberOfAdds}</strong>
                </p>
                <p className="mb-0 fs--1">
                  <strong>
                    Start at :{' '}
                    {DateTime.fromISO(data?.startAt).toFormat(
                      "dd LLL yyyy 'at' HH:mm"
                    )}
                  </strong>
                </p>
                <p className="mb-0 fs--1">
                  <strong>
                    End at :{' '}
                    {DateTime.fromISO(data?.endAt).toFormat(
                      "dd LLL yyyy 'at' HH:mm"
                    )}
                  </strong>
                </p>
              </Col>
              <Col md={6} lg={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Limits</h5>
                <h6 className="mb-2">Limit Per User : {data.limitPerUser}</h6>
                <h6 className="mb-2">Total Limit : {data.totalLimit}</h6>
              </Col>
              <Col md={6} lg={4}>
                <h5 className="mb-3 fs-0">Discount Info : </h5>
                <div className="d-flex">
                  <div className="flex-1">
                    <h6 className="mb-2">
                      Discount Type :{' '}
                      {data.discountType === 'fixed' ? '€' : '%'}
                    </h6>
                    <h6 className="mb-2">
                      Discount Amount :{' '}
                      {`${data.discountAmount}${
                        data.discountType === 'fixed' ? '€' : '%'
                      }`}
                    </h6>
                    <h6 className="mb-2">
                      Max Discount Amount &#40;€&#41; :{' '}
                      {`${data.maxDiscountAmount}€`}
                    </h6>
                    <h6 className="mb-2">
                      Min Purchase Amount : {`${data.minPurchaseAmount}€`}
                    </h6>
                    <h6 className="mb-2">
                      Min Ride Completed : {data.minRideCompleted}
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* end of coupon info */}

        {/* Start of non applicable item info section */}
        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <Col md={6} lg={12} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Non-Applicable Items:</h5>
                {data.excludeOffers && data.excludeOffers.length > 0 ? (
                  <ul className="list-unstyled d-flex flex-wrap">
                    {data.excludeOffers.map((offer, index) => (
                      <li key={index} className="me-2 mb-2">
                        {' '}
                        {/* Added margin classes */}
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip-${index}`}>
                              <div>
                                <div>
                                  <span className="p-1 text-600">
                                    {offer.avatar ? (
                                      <ImageOverview
                                        images={[offer.avatar.link]}
                                      >
                                        {setImgIndex => (
                                          <Avatar
                                            size="xl"
                                            src={offer.avatar?.link}
                                            name={offer.avatar?.name}
                                            onClick={() => setImgIndex(0)}
                                          />
                                        )}
                                      </ImageOverview>
                                    ) : (
                                      <UnknownBadge />
                                    )}
                                  </span>
                                </div>
                                <div>
                                  Active? {offer.isActive ? 'Yes' : 'No'}
                                </div>
                                <div># of Seats: {offer.numberOfSeats}</div>
                                <div className="mb-1 fs--1">
                                  <strong>{offer.name}</strong>
                                </div>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span
                            key={offer.id}
                            className={classNames(
                              'badge border link-secondary text-decoration-none',
                              {
                                'ms-1': index !== 0,
                              }
                            )}
                          >
                            {offer.name}
                          </span>
                        </OverlayTrigger>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-muted">No non-applicable items.</p>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* End of non applicable item info section */}

        {/* Description section */}

        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <Col md={6} lg={12} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Description</h5>
                <p className="mb-1 fs--1">
                  <strong>{data.description}</strong>
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* end of description section */}
      </Col>
    </Row>
  );
};

export default CouponDetailsCard;
