import { IArticleForm } from '@/interfaces/Article';
import React, { FC } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import { EditorCore } from '@react-editor-js/core/dist/src/editor-core';
import {
  ControllerRenderProps,
} from 'react-hook-form/dist/types';
import { OutputData } from '@editorjs/editorjs';
import { useEditorJsTools } from '@/hooks/useEditorJsTools';

type EditorProps = {
  field: ControllerRenderProps<IArticleForm, 'content'>;
  articleId: IArticleForm['id'];
};

const ReactEditorJS = createReactEditorJS();

const Editor: FC<EditorProps> = ({ field, articleId }) => {
  const editorCore = React.useRef<EditorCore | null>(null);
  const handleInitialize = React.useCallback((instance: EditorCore) => {
    editorCore.current = instance;
  }, []);

  const handleSave = React.useCallback(async () => {
    const savedData =
      await editorCore.current?.dangerouslyLowLevelInstance?.save();
    return savedData as OutputData;
  }, []);

  const getEditorJsTools = useEditorJsTools({
    articleId,
  });

  const editorJsTools = getEditorJsTools();

  return (
    <div className="border rounded-3">
      <ReactEditorJS
        tools={editorJsTools}
        onInitialize={handleInitialize}
        onChange={async e => {
          const savedData = await handleSave();
          field.onChange(savedData);
        }}
        defaultValue={field.value as OutputData}
      />
    </div>
  );
};

export default Editor;
