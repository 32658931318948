import { extractFileNameFromUrl } from '@/helpers/utils';

export async function getFileFromUrl(fileUrl: string) {
  console.log('Fetching file from URL:', fileUrl);
  const fileName = extractFileNameFromUrl(fileUrl);
  console.log('Extracted file name:', fileName);

  try {
    const res = await fetch(
      `/api/getFileUrl?fileUrl=${encodeURIComponent(fileUrl)}`
    ).then(async response => await response.blob());
    console.log('Fetched file blob:', res);

    return { file: new File([res], fileName), fileName };
  } catch (error) {
    console.error('Error fetching file from URL:', error);
    throw error;
  }
}
