import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import CouponHeader from '@/components/card-headers/coupons/CouponHeader';
import UpdateCouponCard from '@/components/updates/coupons/UpdateCouponCard';

const CouponEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <CouponHeader id={id} isEdit />
      <UpdateCouponCard id={id} />
    </div>
  );
};

export default CouponEditView;
