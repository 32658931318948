import PaginateCard from '@/components/cards/PaginateCard';
import Transaction from '@/components/common/Transaction';
import { IFilters } from '@/interfaces/Fetch';
import { useGetDriverPointsTransactionsQuery } from '@/services/drivers/pointsTransactions';
import { FC, useState } from 'react';

const DriverPointTransactionsCard: FC<{ id: string }> = ({ id }) => {
  const [filters, setFilters] = useState<IFilters>({
    pageIndex: 0,
    pageSize: 3,
  });

  const { data, isLoading, error } = useGetDriverPointsTransactionsQuery([
    id,
    filters,
  ]);

  return (
    <PaginateCard
      filters={filters}
      setFilters={setFilters}
      isLoading={isLoading}
      error={error}
      card
      meta={data?.meta}
      title="Point Transactions"
    >
      {data?.data.map((transaction, index) => (
        <Transaction key={transaction.id} transaction={transaction} />
      ))}
    </PaginateCard>
  );
};

export default DriverPointTransactionsCard;
