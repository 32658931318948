import Background from '@/components/common/Background';
import Flex from '@/components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import teslaBg from '@/assets/img/illustrations/tesla-ill.png';
import Notification from '@/components/cards/home/greetings/Notifications';
import { getGreetingNotifications } from '@/helpers/GreetingsHelper';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@/services/slices/authSlice';

function GreetingsInfoRides() {
  const notifications = getGreetingNotifications({
    nbrRidesRequested: 2,
    nbrRidesCancelled: 1,
  });
  const user = useSelector(selectCurrentUser);

  return (
    <Card className="overflow-hidden">
      <Card.Header className="position-relative">
        <Background
          image={teslaBg}
          className="d-none d-md-block bg-card z-index-1"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1',
          }}
        />
        <div className="position-relative z-index-2">
          <div>
            <h3 className="text-primary mb-1">
              Good Afternoon, {user?.firstname}!
            </h3>
            <p className="text-500 fs--1 ">Your results in figures (Today)</p>
          </div>
          <Flex className="py-3">
            <div className="pe-3">
              <p className="text-600 fs--1 fw-medium">Today's visit</p>
              <h4 className="text-800 mb-0">14,209</h4>
            </div>
            <div className="ps-3">
              <p className="text-600 fs--1">Today’s total sales</p>
              <h4 className="text-800 mb-0">$21,349.29</h4>
            </div>
          </Flex>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <ul className="mb-0 list-unstyled">
          {notifications.map((notification, index) => (
            <Notification
              key={notification.id}
              notification={notification}
              isLast={notifications.length - 1 === index}
            />
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
}

export default GreetingsInfoRides;
