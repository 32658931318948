import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AllEarnPointsHeader from '@/components/table-headers/earnPoints/AllEarnPointsHeader';
import {
  getColorFromCriteriaType,
  getColorFromIsArchived,
  getColorFromUserType,
  getReelArchivedName,
  getReelCriteriaType,
  getReelUserTypeName,
} from '@/helpers/UserHelpers';
import { truncate } from '@/helpers/utils';
import IEarnPoint from '@/interfaces/EarnPoint';
import { IFilters } from '@/interfaces/Fetch';
import {
  useUpdateEarnPointMutation,
  useGetEarnPointsQuery,
} from '@/services/earnPoints';
import { faArchive, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const EarnPointsView: FC = () => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetEarnPointsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [updateEarnPoint, { isLoading: isUpdateEarnPointLoading }] =
    useUpdateEarnPointMutation();

  const handleArchiveToggle = async (
    earnPointId: string,
    isArchived: boolean
  ) => {
    try {
      await updateEarnPoint([earnPointId, { isArchived: !isArchived }] as [
        string,
        Omit<Partial<IEarnPoint>, 'userId'>
      ]);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  const columns = useRef<ColumnDef<IEarnPoint>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id, isArchived }) => (
        <>
          <Link to={`/earnPoints/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link
            to={`/earnPoints/${id}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant={isArchived ? 'danger' : 'success'}
            size="sm"
            className="ms-2"
            onClick={() => handleArchiveToggle(id, isArchived)}
            disabled={isUpdateEarnPointLoading}
          >
            <FontAwesomeIcon icon={faArchive} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Title',
      footer: props => props.column.id,
      accessorFn: ({ title, id }) => (
        <Link to={`/earnPoints/${id}`}>
          <p className="mb-0">{title}</p>
        </Link>
      ),
      id: 'title',
      cell: info => info.getValue(),
    },
    {
      header: 'User Type',
      footer: props => props.column.id,
      accessorFn: ({ userType }) => (
        <SoftBadge
          bg={getColorFromUserType(userType)}
          className={'my-2 text-xl-center'}
        >
          {getReelUserTypeName(userType)}
        </SoftBadge>
      ),
      id: 'userType',
      cell: info => info.getValue(),
    },
    {
      header: 'Points',
      footer: props => props.column.id,
      accessorFn: ({ points }) => points,
      id: 'points',
      cell: info => info.getValue(),
    },
    {
      header: 'Limit',
      footer: props => props.column.id,
      accessorFn: ({ limit }) => limit,
      id: 'limit',
      cell: info => info.getValue(),
    },
    {
      header: 'Status',
      footer: props => props.column.id,
      accessorFn: ({ isArchived }) => (
        <SoftBadge
          bg={getColorFromIsArchived(isArchived)}
          className={'my-2 text-xl-center'}
        >
          {getReelArchivedName(isArchived)}
        </SoftBadge>
      ),
      id: 'archived',
      cell: info => info.getValue(),
    },
    {
      header: 'Criteria Type',
      footer: props => props.column.id,
      accessorFn: ({ earnPointCriteria }) => (
        <SoftBadge
          bg={getColorFromCriteriaType(earnPointCriteria?.type)}
          className={'my-2 text-xl-center'}
        >
          {getReelCriteriaType(earnPointCriteria?.type)}
        </SoftBadge>
      ),
      id: 'criteriaType',
      cell: info => info.getValue(),
    },
    {
      header: 'Criteria User Type',
      footer: props => props.column.id,
      accessorFn: ({ earnPointCriteria }) => {
        const userType = earnPointCriteria?.userType;

        if (userType !== null && userType !== undefined) {
          return (
            <SoftBadge
              bg={getColorFromUserType(userType)}
              className={'my-2 text-xl-center'}
            >
              {getReelUserTypeName(userType)}
            </SoftBadge>
          );
        }

        return null;
      },
      id: 'criteria.UserType',
      cell: info => info.getValue(),
    },
    {
      header: 'Criteria Value',
      footer: props => props.column.id,
      accessorFn: ({ earnPointCriteria }) => earnPointCriteria.value,
      id: 'value',
      cell: info => info.getValue(),
    },
    {
      header: 'Description Title',
      footer: props => props.column.id,
      accessorFn: ({ descriptionTitle }) => truncate(descriptionTitle, 100),
      id: 'descriptionTitle',
      cell: info => info.getValue(),
    },
    {
      header: 'Description',
      footer: props => props.column.id,
      accessorFn: ({ description }) => truncate(description, 100),
      id: 'description',
      cell: info => info.getValue(),
    },
    {
      header: 'EarnPoint ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <Link to={`/earnPoints/${id}`}>{truncate(id, 10)}</Link>
      ),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Table
      HeaderComponent={AllEarnPointsHeader}
      columns={columns}
      state={state}
      tableName="earnPoints"
      onStateChange={setState}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default EarnPointsView;
