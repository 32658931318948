import { IFilters } from '../Fetch';
import IGooglePlace from '../GooglePlace';

export type UserAddressType = 'home' | 'work' | 'bestFriends' | 'family' | 'ex';

export const USER_ADDRESS_TYPES: { [key in UserAddressType]: string } = {
  home: 'Home Address',
  work: 'Work Address',
  bestFriends: "Best Friend's Address",
  family: 'Family Address',
  ex: 'Toxic ex',
};

interface UserAddress {
  id: string;
  userId: string;
  googlePlaceId: string;
  googlePlace: IGooglePlace;
  type: UserAddressType;
  createdAt: string;
  updatedAt: string;
}

export default UserAddress;
