import { FC } from "react";
import TransactionProps from "../interfaces/ITransaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { pluralize } from "@/helpers/utils";
import Flex from "./Flex";

const Transaction: FC<TransactionProps> = ({
  flush,
  className,
  transaction: { createdAt, points, label, type, link },
}) => {
  return (
    <Link
      className={classNames(
        "notification rounded-0 border-x-0 border-300 border-bottom-0",
        { "notification-flush": flush },
        className
      )}
      to={link || "#!"}
    >
      <Flex alignItems="center">
        <div className="position-relative">
          <div className="icon-item icon-item-md rounded-7 shadow-none bg-200">
            <FontAwesomeIcon
              icon={points > 0 ? faPlus : faMinus}
              className="text-primary"
            />
          </div>
        </div>
        <div className="notification-body ms-3">
          <h5 className="fs--1 fw-bold">
            ⚡ {points} {pluralize("point", points)}
          </h5>
          <p className="mb-1">{label}</p>
          <span className="notification-time">
            {DateTime.fromISO(createdAt).toRelative()}
          </span>
        </div>
      </Flex>
    </Link>
  );
};

export default Transaction;
