import ErrorComponent from '@/components/common/ErrorComponent';
import IconButton from '@/components/common/IconButton';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import AllDrivingPreferencesHeader from '@/components/table-headers/drivingPreferences/AllDrivingPreferencesHeader';
import { splitText } from '@/helpers/utils';
import { IDrivingPreferences } from '@/interfaces/DrivingPreferences';
import { IFilters } from '@/interfaces/Fetch';
import {
  useGetDrivingPreferencesQuery,
  useRemoveDrivingPreferenceMutation,
} from '@/services/drivingPreferences';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const DrivingPreferencesView: FC = () => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const [deleteDrivingPreferenceDriverAsync] =
    useRemoveDrivingPreferenceMutation();

  const { data, isLoading, isError, error } = useGetDrivingPreferencesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const onDelete = (id: string) => {
    toast.promise(deleteDrivingPreferenceDriverAsync(id).unwrap(), {
      pending: 'Suppression de la préférence en cours...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              La preference à bien été retiré
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  const columns = useRef<ColumnDef<IDrivingPreferences>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: doc => (
        <div className="gap-2 d-flex">
          <IconButton
            href={`/drivingPreferences/${doc.id}/edit`}
            variant="nolina-default"
            size="sm"
            icon={faEdit}
            transform="shrink-2"
            iconAlign="middle"
            className="sm"
          />
          <IconButton
            variant="nolina-danger"
            size="sm"
            icon={faTrash}
            onClick={() => {
              DeleteModalRef.current?.showOrHiddenModal(doc.id);
            }}
            transform="shrink-3"
            iconAlign="middle"
          />
        </div>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'ID',
      footer: props => props.column.id,
      accessorFn: ({ id }) => splitText(id, 18),
      id: 'id',
      cell: info => info.getValue(),
    },
    {
      header: 'Name',
      footer: props => props.column.id,
      accessorFn: ({ name }) => name,
      id: 'name',
      cell: info => info.getValue(),
    },
    {
      header: 'Text Color',
      footer: props => props.column.id,
      accessorFn: ({ textColor }) => (
        <div className="d-flex align-items-center flex-column">
          <p className="text-secondary fs--2 m-0">{textColor}</p>
          <SoftBadge
            bg={textColor}
            className="border items-center justify-center w-100 p-2"
          >
            <p className="text-white m-1 px-1"></p>
          </SoftBadge>
        </div>
      ),
      id: 'textColor',
      cell: info => info.getValue(),
    },
    {
      header: 'Background color',
      footer: props => props.column.id,
      accessorFn: ({ backgroundColor }) => (
        <div className="d-flex align-items-center flex-column">
          <p className="text-secondary fs--2 m-0">{backgroundColor}</p>
          <SoftBadge
            bg={backgroundColor}
            className="border items-center justify-center w-100 p-2"
          >
            <p className="text-white m-1 px-1"></p>
          </SoftBadge>
        </div>
      ),
      id: 'backgroundColor',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'Created At',
      cell: info => info.getValue(),
    },
    {
      header: 'Updated At',
      footer: props => props.column.id,
      accessorFn: ({ updatedAt }) =>
        DateTime.fromISO(updatedAt ?? '').toFormat('dd/MM/yyyy'),
      id: 'Updated At',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment retirer cette préférence ?"
      />
      <Table
        HeaderComponent={AllDrivingPreferencesHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default DrivingPreferencesView;
