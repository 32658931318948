import { ITableHeaderComponentProps } from '@/interfaces/Table';
import { faFilter, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ElementRef, FC, useRef } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';

import IconButton from '@/components/common/IconButton';
import { useSelector } from 'react-redux';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import StoreDocumentDriverModal from '@/components/modals/driver/StoreDocumentDriverModal';
import { useParams } from 'react-router-dom';

const AllDocumentsHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {
  const { id } = useParams<{ id: string }>();

  const userConfig = useSelector(selectCurrentUserConfig);
  const StoreDocumentDriverModalRef =
    useRef<ElementRef<typeof StoreDocumentDriverModal>>(null);

  const openCreateModal = () =>
    StoreDocumentDriverModalRef.current?.showOrHiddenModal();

  return (
    <div className="d-lg-flex justify-content-between">
      {userConfig.isAdminOrSuperAdmin && (
        <StoreDocumentDriverModal
          idDriver={id!}
          ref={StoreDocumentDriverModalRef}
        />
      )}
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Documents</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filtres</span>
            </IconButton>

            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </>
        )}
        <IconButton
          variant="nolina-default"
          size="sm"
          icon={faPlus}
          onClick={openCreateModal}
          transform="shrink-3"
          iconAlign="middle"
        >
          <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
            Nouveau
          </span>
        </IconButton>
      </div>
    </div>
  );
};

export default AllDocumentsHeader;
