import StatsLiveActivities from '@/components/cards/home/Stats/StatsLiveActivities';
import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { MapCardView } from '@/components/common/map/MapViewCard/MapCardView';
import { useGetRidesQuery } from '@/services/rides';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

const LiveActivityMap: FC = () => {
  const { data, isLoading, isError, error } = useGetRidesQuery({});

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div className="flex-1 md-h-100 ">
      <Row className="g-3 mb-3 mt-2">
        <Col lg={12} xl={6}>
          <MapCardView style={{ height: 600 }} />
        </Col>
        <Col lg={12} xl={6}>
          <StatsLiveActivities />
        </Col>
      </Row>
    </div>
  );
};

export default LiveActivityMap;
