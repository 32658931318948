import { DriverVehicleDocumentStatus, DriverVehicleDocumentType } from "@/interfaces/DriverVehicleDocument";

export const getColorFromDocumentsStatus = (status?: DriverVehicleDocumentStatus) => {
  if (status === "pending") return "warning";
  else if (status === "accepted") return "success";
  else if (["expired", "rejected"].indexOf(status!) >= -1) return "danger";
  else return "danger";
};

export const getColorTypeDocument = (status?: DriverVehicleDocumentType) => {
  if (status === "greenCard") return "success";
  else if (status === "insurance") return "info";
  else if (status === "registrationCardInfo") return "secondary";
};

export const getReelStatusNameOfDocument = (status?: DriverVehicleDocumentType) => {
  if (status === "greenCard") return "Green Card";
  else if (status === "insurance") return "Insurance";
  else if (status === "registrationCardInfo") return "Registration Card";
};

export const getReelNameFromDocumentsStatus = (status?: DriverVehicleDocumentStatus) => {
  if (status === "pending") return "En attente";
  else if (status === "accepted") return "Accepté";
  else if (status === "rejected") return "Rejeté";
  else return "Expiré";
};

export const getReelNameFromDocumentsType = (status?: DriverVehicleDocumentType) => {
  if (status === "greenCard") return "Carte verte";
  else if (status === "insurance") return "Assurance";
  else return "Carte grise";
}