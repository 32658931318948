import { ITableHeaderComponentProps } from '@/interfaces/Table';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

export const DriverClubConfigurationHeader: FC<ITableHeaderComponentProps> = () => {
  return (
    <div>
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Driver club configurations</h6>
        </Col>
      </Row>
    </div>
  );
};
