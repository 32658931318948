import Loader from "@/components/common/Loader";
import { useLazyGetMyProfileQuery } from "@/services/auth";
import { selectCurrentUser } from "@/services/slices/authSlice";
import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const GuestMiddleware: FC = () => {
  const user = useSelector(selectCurrentUser);
  const [getMyProfileAsync] = useLazyGetMyProfileQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, _, removeCookie] = useCookies(["te_co"]);

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          const token = cookies.te_co;
          if (!token) {
            throw new Error("No token");
          }
          await getMyProfileAsync(token).unwrap();
        }
      } catch (e) {
        removeCookie("te_co", { path: "/" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return isLoading ? (
    <Loader className="min-vh-100" />
  ) : user ? (
    <Navigate to="/" replace />
  ) : (
    <Outlet />
  );
};

export default GuestMiddleware;
