import PaginateCard from "@/components/cards/PaginateCard";
import Log from "@/components/common/Log";
import { LogFilters } from "@/interfaces/Log";
import { useGetUserLogsQuery } from "@/services/users/logs";
import { FC, useState } from "react";

const UserActivitiesCard: FC<{ id: string }> = ({ id }) => {
  const [filters, setFilters] = useState<LogFilters>({
    pageIndex: 0,
    pageSize: 3,
    userIds: [id],
  });

  const { data, isLoading, error } = useGetUserLogsQuery(filters);

  return (
    <PaginateCard
      filters={filters}
      setFilters={setFilters}
      isLoading={isLoading}
      error={error}
      card
      meta={data?.meta}
      title="Activity Logs"
    >
      {data?.data.map((log) => (
        <Log key={log.id} {...log} />
      ))}
    </PaginateCard>
  );
};

export default UserActivitiesCard;
