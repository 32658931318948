import Flex from '@/components/common/Flex';
import createMarkup from '@/helpers/CreateMarkup';
import { NotificationsGreetings } from '@/helpers/GreetingsHelper';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type NotificationProps = {
  notification: NotificationsGreetings;
  isLast?: boolean;
};

const Notification: FC<NotificationProps> = ({
  notification: { title, linkText, type, linkTo },
  isLast = false,
}) => {
  return (
    <li
      className={classNames('alert mb-0 rounded-0 py-3 px-x1', {
        'alert-warning': type === 'warning',
        'border-top': !(type === 'warning'),
        'alert-info': type === 'info',
        'alert-success': type === 'success',
        'alert-danger': type === 'danger',
        'border-0': isLast,
        'border-x-0 border-top-0': !isLast,
      })}
    >
      <Row className="flex-between-center">
        <Col>
          <Flex>
            <FontAwesomeIcon
              icon="circle"
              className={classNames('mt-1 fs--2', {
                'text-primary': !type,
              })}
            />
            <p
              className="fs--1 ps-2 mb-0"
              dangerouslySetInnerHTML={createMarkup(title)}
            ></p>
          </Flex>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <Link to={`/${linkTo}`} className="alert-link fs--1 fw-medium">
            {linkText}
            <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs--2" />
          </Link>
        </Col>
      </Row>
    </li>
  );
};

export default Notification;
