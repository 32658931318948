'use client';
import React, { FC } from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';

type DropZoneCardProps = {
  onDrop: DropzoneProps['onDrop'];
};

const DropZoneCard: FC<DropZoneCardProps> = ({ onDrop }) => {
  return (
    <div className="flex flex-col flex-[2]">
      <h4 className="text-lg font-bold mb-2 text-start">
        Import the file{' '}
        <span className="text-red-500">(Only *.csv are allowed)</span>
      </h4>
      <Dropzone accept={{ 'text/csv': [] }} maxFiles={1} onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
          <div
            {...getRootProps()}
            className="border-2 border-dashed bg-secondary p-4 rounded-lg text-center cursor-pointer hover:bg-opacity-35 h-full
            flex flex-col justify-center items-center "
          >
            <input {...getInputProps()} />
            <p>
              {isDragAccept
                ? 'Drop the files here...'
                : isDragReject
                ? 'Only *.csv files are allowed'
                : 'Drag & drop some files here, or click to select files'}
            </p>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default DropZoneCard;
