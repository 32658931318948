import Modal from '@/components/common/Modal';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { IModalMethods } from '@/interfaces/Modal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';
import {
  CompanyBusinessTypeSelect,
  CompanyCountrySelect,
  CompanyCurrencySelect,
  CompanyStatusSelect,
} from '@/helpers/CompanyHelper';
import useLoadOptions from '@/hooks/useLoadOptions';
import Company, { CompanyFormUpdate } from '@/interfaces/Company';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useCreateAssociationMutation } from '@/services/associations';
import { IAssociationForm } from '@/interfaces/Association';

const StoreAssociationModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IAssociationForm>();

  const [createAssociationMutation] = useCreateAssociationMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();
  const navigation = useNavigate();
  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IAssociationForm> = ({ ...newData }) => {
    toast.promise(createAssociationMutation(newData).unwrap(), {
      pending: `Création de l'association en cours...`,
      success: {
        render() {
          navigation(-1);
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              {`L'association à bien été créer 🤩`}
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IAssociationForm> = error =>
    console.log(error);

  return (
    <Modal
      ref={ModalRef}
      title={'Ajouter une association'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Name <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Association 1"
              type="text"
              {...register('name', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : La paix est véridique."
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Description <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Description de mon association."
              type="text"
              as="textarea"
              rows={5}
              {...register('description', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 2000,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Donation goal <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 1000"
              type="number"
              {...register('donationGoal', {
                required: 'Ce champ est requis.',
                min: {
                  value: 0,
                  message: 'Ce champ doit contenir au minimum 0.',
                },
                max: {
                  value: 1000000,
                  message: 'Ce champ doit contenir au maximum 1000000.',
                },
              })}
              isInvalid={!!errors.donationGoal}
            />
            <Form.Control.Feedback type="invalid">
              {errors.donationGoal?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Lien <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Lien de mon association."
              type="text"
              {...register('link', {
                required: 'Ce champ est requis.',
                validate: {
                  url: value => {
                    try {
                      new URL(value);
                      return true;
                    } catch {
                      return 'Ce champ doit contenir une url valide.';
                    }
                  },
                },
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.link}
            />
            <Form.Control.Feedback type="invalid">
              {errors.link?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Illustration (Banner):
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              accept="image/png, image/gif, image/jpeg"
              type="file"
              {...register('illustrationBanner', {
                required: `Merci de sélectionner un fichier.`,
              })}
              isInvalid={!!errors.illustrationBanner}
            />
            <Form.Control.Feedback type="invalid">
              {errors.illustrationBanner?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Illustration du lien:
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              accept=".jpeg, .jpg, .png"
              type="file"
              {...register('illustration', {
                required: `Merci de sélectionner un fichier.`,
              })}
              isInvalid={!!errors.illustration}
            />
            <Form.Control.Feedback type="invalid">
              {errors.illustration?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Category <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Categorie de mon association."
              type="text"
              {...register('category', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.category}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreAssociationModal);
