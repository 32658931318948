import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import Company from '@/interfaces/Company';
import { UUID_REGEX } from '@/constants/regexs';
import CompanyDetailsCard from '@/components/cards/companies/CompanyDetailsCard';
import CompanyHeader from '@/components/card-headers/companies/CompanyHeader';

const CompanyView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Company>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {company && <CompanyHeader id={id} />}
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <CompanyDetailsCard id={id} card callBack={setCompany} />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyView;
