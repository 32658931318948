import { dot } from "@/helpers/SelectHelpers";
import classNames from "classnames";
import RSelect, { MenuProps, components } from "react-select";
import {
  AsyncPaginate,
  withAsyncPaginate,
  WithAsyncPaginateType,
} from "react-select-async-paginate";
import RSelectAsync from "react-select/async";
import RCreatable from "react-select/creatable";
import AsyncSelect from "react-select/dist/declarations/src/Async";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import GoogleLogo from "@/assets/img/google.png";
import { Fragment } from "react";

export const Select: StateManagedSelect = ({ isRtl = false, ...props }) => {
  return (
    <RSelect
      styles={{
        placeholder: (base) => ({
          ...base,
          color: "#6c757d",
          fontSize: "0.875rem",
        }),
        control: (base) => ({
          ...base,
          minHeight: "2.25rem",
          borderColor: isRtl ? "#dc3545" : "#ced4da",
          fontSize: "0.875rem",
        }),
        option: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
      }}
      className={classNames({
        "is-invalid": isRtl,
      })}
      placeholder="Choisir..."
      noOptionsMessage={() => "Pas de donnée(s) trouvée(s)"}
      loadingMessage={() => "Chargement..."}
      closeMenuOnSelect={!props.isMulti}
      {...props}
    />
  );
};

export const CustomGoogleMenu = (props: MenuProps<any, false>) => (
  <Fragment>
    <components.Menu {...props}>
      {props.children}{" "}
      <div className="p-2">
        <small>
          Powered by <img src={GoogleLogo} alt="Google" width={70} />
        </small>
      </div>
    </components.Menu>
  </Fragment>
);

export const AsyncSelectComponent: AsyncSelect = (props) => {
  return (
    <RSelectAsync
      styles={{
        option: (provided) => ({
          ...provided,
          color: "black",
        }),
      }}
      placeholder="Choisir..."
      noOptionsMessage={() => "Pas de donnée(s) trouvée(s)"}
      loadingMessage={() => "Chargement..."}
      closeMenuOnSelect={false}
      {...props}
    />
  );
};

export const CreatableSelect: RCreatable = ({ isRtl = false, ...props }) => {
  return (
    <RCreatable
      styles={{
        placeholder: (base) => ({
          ...base,
          color: "#6c757d",
          fontSize: "0.875rem",
        }),
        control: (base) => ({
          ...base,
          minHeight: "2.25rem",
          borderColor: isRtl ? "#dc3545" : "#ced4da",
          fontSize: "0.875rem",
        }),
        option: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
      }}
      className={classNames({
        "is-invalid": isRtl,
      })}
      placeholder="Choisir..."
      noOptionsMessage={() => "Pas de donnée(s) trouvée(s)"}
      loadingMessage={() => "Chargement..."}
      closeMenuOnSelect={!props.isMulti}
      {...props}
    />
  );
};

export const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

export const SelectPaginateAsync: WithAsyncPaginateType = ({
  styles,
  ...props
}) => {
  return (
    <AsyncPaginate
      styles={{
        placeholder: (base) => ({
          ...base,
          color: "#6c757d",
          fontSize: "0.875rem",
        }),
        control: (base, p) => ({
          ...base,
          ...styles?.control?.(base, p),
          minHeight: "2.25rem",
          fontSize: "0.875rem",
        }),
        option: (base, { data, isDisabled }) => ({
          ...base,
          cursor: isDisabled ? "not-allowed" : "red",
          // @ts-ignore
          color: data?.textColor ? data?.textColor : base.color,
          // @ts-ignore
          backgroundColor: data?.backgroundColor
            ? "transparent"
            : base.backgroundColor,
          ":hover": {
            ...base[":hover"],
            // @ts-ignore
            backgroundColor: data?.backgroundColor
              ? // @ts-ignore
                data?.backgroundColor
              : // @ts-ignore
                base[":hover"]?.backgroundColor,
          },
          fontSize: "0.875rem",
        }),
        multiValue: (base, { data }) => ({
          ...base,

          // @ts-ignore
          backgroundColor: data?.backgroundColor
            ? // @ts-ignore
              data?.backgroundColor
            : base.backgroundColor,
          fontSize: "0.875rem",
        }),
        multiValueLabel: (base, { data }) => ({
          ...base,
          // @ts-ignore
          color: data?.textColor ? data?.textColor : base.color,
        }),
        singleValue: (base, { data }) => ({
          ...base,
          // @ts-ignore
          ...(data.backgroundColor && dot(data.backgroundColor)),
          fontSize: "0.875rem",
        }),
      }}
      placeholder="Choisir..."
      noOptionsMessage={() => "Pas de donnée(s) trouvée(s)"}
      loadingMessage={() => "Chargement..."}
      closeMenuOnSelect={!props.isMulti}
      {...props}
    />
  );
};

export default Select;
