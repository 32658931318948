import { createFormDataRequest } from '@/helpers/utils';
import { IPaginateResponse, IFilters } from '@/interfaces/Fetch';
import IRequestOffer from '@/interfaces/RequestOffer';
import api from '@/services/api';

export const requestOffersApi = api.injectEndpoints({
  endpoints: build => ({
    getRequestOffers: build.query<
      IPaginateResponse<IRequestOffer>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/requests/${id}/offers`,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetRequestOffersQuery } = requestOffersApi;
