import classNames from "classnames";
import React, { ForwardRefRenderFunction, forwardRef } from "react";

interface IProps {
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  inline?: boolean;
  wrap?: string;
  className?: string;
  tag?: string;
  children: React.ReactNode;
  breakpoint?: string;
  direction?: string;
}

const Flex: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  {
    justifyContent,
    alignItems,
    alignContent,
    inline,
    wrap,
    className,
    children,
    breakpoint,
    direction,
    ...rest
  },
  ref
) => {
  return (
    <div
      ref={ref}
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + "-" : ""}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + "-" : ""}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap,
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default forwardRef(Flex);
