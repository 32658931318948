import { createFormDataRequest } from '@/helpers/utils';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import UserAddress from '@/interfaces/User/Address';
import api from '@/services/api';

export const userAddressesApi = api.injectEndpoints({
  endpoints: build => ({
    getUserAddresses: build.query<
      IPaginateResponse<UserAddress>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/users/${id}/addresses`,
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'UserAddresses' as const,
                id,
              })),
              { type: 'UserAddresses', id: 'LIST' },
            ]
          : [],
    }),
    deleteUserAddress: build.mutation<void, [string, string]>({
      query: ([userId, id]) => ({
        url: `/users/${userId}/addresses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'UserAddresses', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserAddressesQuery, useDeleteUserAddressMutation } =
  userAddressesApi;
