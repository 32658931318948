import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import Vehicle, {
  IVehicleFormSend,
  VehicleCategory,
} from '@/interfaces/Vehicle';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const vehiclesApi = api.injectEndpoints({
  endpoints: build => ({
    getVehicles: build.query<IPaginateResponse<Vehicle>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/vehicles',
          params,
        };
      },
      providesTags: (result, _error) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Vehicles' as const,
                id,
              })),
              { type: 'Vehicles', id: 'LIST' },
            ]
          : [],
    }),
    getAllVehiclesForCSV: build.query<IPaginateResponse<Vehicle>, void>({
      query: () => `/vehicles?pageSize=368`,
      providesTags: (result, _error) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Vehicles' as const,
                id,
              })),
              { type: 'Vehicles', id: 'LIST' },
            ]
          : [],
    }),
    getVehicle: build.query<Vehicle, string>({
      query: id => `/vehicles/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Vehicles', id: 'LIST' }],
    }),
    storeVehicules: build.mutation<Vehicle, IVehicleFormSend>({
      query: data => {
        const body = createFormDataRequest(data);
        return {
          url: `/vehicles`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Vehicles', id: 'LIST' }],
    }),
    editVehicules: build.mutation<Vehicle, [string, Partial<IVehicleFormSend>]>(
      {
        query: ([id, data]) => {
          const body = createFormDataRequest(data);
          return {
            url: `/vehicles/${id}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: [{ type: 'Vehicles', id: 'LIST' }],
      }
    ),
    destroyVehicle: build.mutation<void, string>({
      query: id => ({
        url: `/vehicles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Vehicles', id: 'LIST' }],
    }),
    getVehiclesCategories: build.query<
      IPaginateResponse<VehicleCategory>,
      void
    >({
      query: () => `/drivingPreferences`,
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetAllVehiclesForCSVQuery,
  useLazyGetVehiclesQuery,
  useStoreVehiculesMutation,
  useEditVehiculesMutation,
  useDestroyVehicleMutation,
  useGetVehicleQuery,
  useGetVehiclesCategoriesQuery,
} = vehiclesApi;
