import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { changeNoneToNullValue } from '@/helpers/utils';
import { IModalMethods } from '@/interfaces/Modal';
import {
  USER_TYPES_OPTIONS,
} from '@/interfaces/User';
import { IUserListForm, REGISTRATION_FILTER_OPTIONS, RIDES_STATUS_OPTIONS } from '@/interfaces/User/UserList';
import { useCreateUserListCriterionMutation } from '@/services/userList';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { toast, ToastContentProps } from 'react-toastify';

const UserListModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const [createUserListAsync] = useCreateUserListCriterionMutation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IUserListForm>();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  const onError: SubmitErrorHandler<IUserListForm> = () => {};

  const registration_date = watch('registration_date');

  const onSubmit: SubmitHandler<IUserListForm> = data => {
    const dataToSend = {
      ...changeNoneToNullValue(data),
      registration_date: data.registration_date
        ? new Date(data.registration_date).toISOString()
        : null,
      user_type: data.user_type?.value,
      registration_filter_type: data.registration_filter_type?.value,
      statusname: data.statusname?.value,
      referred: data.referred ? 1 : 0,
    };

    toast.promise(createUserListAsync(dataToSend).unwrap(), {
      pending: 'Creating user list...',
      success: {
        render() {
          reset();
          showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              User list has been added 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'The fields you filled in seem to be incorrect.';
          }

          return 'An error occurred.';
        },
      },
    });
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  return (
    <Modal
      ref={ModalRef}
      title="Create user list"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>User List Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter user list name"
              {...register('name', { required: 'Name is required' })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>User type</Form.Label>
            <Controller
              control={control}
              name="user_type"
              rules={{ required: 'User type is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={USER_TYPES_OPTIONS}
                  isRtl={!!errors.user_type}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.user_type?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Registration date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Enter registration date"
              {...register('registration_date')}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>Registration filter</Form.Label>
            <Controller
              control={control}
              name="registration_filter_type"
              rules={{
                required: registration_date
                  ? 'Registration filter is required when registration date is set'
                  : false,
              }}
              render={({ field }) => (
                <Select {...field} options={REGISTRATION_FILTER_OPTIONS} isRtl={!!errors.registration_filter_type} />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.registration_filter_type?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Min Rides</Form.Label>
            <Form.Control
              type="number"
              placeholder="Min rides"
              {...register('min_rides')}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Max Rides</Form.Label>
            <Form.Control
              type="number"
              placeholder="Max rides"
              {...register('max_rides')}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Min Search rides</Form.Label>
            <Form.Control
              type="number"
              placeholder="Min search rides"
              {...register('min_requests')}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Max Search rides</Form.Label>
            <Form.Control
              type="number"
              placeholder="Max search rides"
              {...register('max_requests')}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Min points</Form.Label>
            <Form.Control
              type="number"
              placeholder="Min points"
              {...register('min_points')}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Max points</Form.Label>
            <Form.Control
              type="number"
              placeholder="Max points"
              {...register('max_points')}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Status name</Form.Label>
            <Controller
              control={control}
              name="statusname"
              render={({ field }) => (
                <Select {...field} options={RIDES_STATUS_OPTIONS} />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Referred</Form.Label>
            <Form.Check
              type="checkbox"
              placeholder="Referred"
              {...register('referred')}
            />
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(UserListModal);
