import api from '../api';
import IPayment from '@/interfaces/Payment';

export const paymentsApi = api.injectEndpoints({
  endpoints: build => ({
    getRequestOfferPayments: build.query<IPayment[], [string, string]>({
      query: ([id, offerId]) => {
        return {
          url: `/requests/${id}/offers/${offerId}/payments`,
        };
      },
      providesTags: (result, _error, [id, offerId]) => {
        if (result) {
          const tags = result.map(payment => ({
            type: 'RequestOfferPayments' as const,
            id: payment.id,
          }));
          tags.push({ type: 'RequestOfferPayments', id: 'LIST' });
          return tags;
        } else {
          return [];
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetRequestOfferPaymentsQuery } = paymentsApi;
