import IFile from './IFile';
import { ISelectOption } from './Select';

export type VehicleFuelType = 'gasoline' | 'diesel' | 'electric' | 'hybrid';

export type VehicleType = 'car' | 'motorcycle';

export const VEHICULES_FUEL_TYPES: ISelectOption<VehicleFuelType>[] = [
  {
    label: 'Diesel',
    value: 'diesel',
  },
  {
    label: 'Gasoline',
    value: 'gasoline',
  },
  {
    label: 'Electric',
    value: 'electric',
  },
  {
    label: 'Hybrid',
    value: 'hybrid',
  },
];

export const VEHICULES_TYPES: ISelectOption<VehicleType>[] = [
  {
    label: 'Car',
    value: 'car',
  },
  {
    label: 'Motorcycle',
    value: 'motorcycle',
  },
];

interface Vehicle {
  id: string;
  avatar: IFile | null;
  fuelType: VehicleFuelType;
  vehicleType: VehicleType;
  numberOfSeats: number;
  brand: string;
  fullname: string;
  model: string;
  eligibleForBerline: boolean;
  eligibleForVan: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface VehicleCategory {
  id: string;
  name: string;
  backgroundColor: string;
  textColor: string;
  createdAt: string;
  updatedAt: string;
}

export interface IVehicleForm
  extends Omit<
    Vehicle,
    'createdAt' | 'updatedAt' | 'fuelType' | 'vehicleType' | 'avatar' | 'id'
  > {
  fuelType: ISelectOption<VehicleFuelType>;
  vehicleType: ISelectOption<VehicleType>;
  avatar: File;
}

export interface IVehicleFormSend
  extends Omit<IVehicleForm, 'fuelType' | 'vehicleType'> {
  fuelType: VehicleFuelType;
  vehicleType: VehicleType;
}

export default Vehicle;
