import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';

import api from './api';
import { IArticle } from '@/interfaces/Article';
import { CreateActivityAreaPickCardFormSend, UpdateActivityAreaPickCardFormSend } from '@/interfaces/ActivityAreaPick';

export const activityAreasApi = api.injectEndpoints({
  endpoints: build => ({
    getAllActivityArea: build.query<IPaginateResponse<IArticle>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/activity-area',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.data.map(({ id }) => ({
              type: 'ActivityAreas' as const,
              id,
            })),
            { type: 'ActivityAreas', id: 'LIST', ...filters },
          ]
          : [],
    }),
    storeActivityArea: build.mutation<void, CreateActivityAreaPickCardFormSend>({
      query: data => ({
        url: '/activity-area',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ActivityAreas', id: 'LIST' }],
    }),
    updateActivityArea: build.mutation<void, [string, UpdateActivityAreaPickCardFormSend]>({
      query: ([id, body]) => ({
        url: `/activity-area/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, _error, [id]) => [
        { type: 'ActivityAreas', id: 'LIST' },
        { type: 'ActivityAreas', id },
      ],
    }),
    getActivityArea: build.query<IArticle, string>({
      query: id => `/activity-area/${id}`,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllActivityAreaQuery,
  useUpdateActivityAreaMutation,
  useGetActivityAreaQuery,
  useStoreActivityAreaMutation,
} = activityAreasApi;
