import { IAssociationStatus } from "@/interfaces/Association";
import { ISelectOption } from "@/interfaces/Select";

export const ASSOCIATION_STATUSES = ['funding', 'closed', 'pending']

export const ASSOCIATION_STATUSES_SELECT: ISelectOption<IAssociationStatus>[] = ASSOCIATION_STATUSES.map((status) => ({
    value: status,
    label: status,
}));

export const getColorFromAssociationsStatus = (status: IAssociationStatus) => {
    if (status === "funding") return "success";
    else if (status === "closed") return "danger";
    else return "warning";
  };