import Modal from '@/components/common/Modal';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import useLoadOptions from '@/hooks/useLoadOptions';
import {
  DRIVER_EXPERIENCE_YEARS_TYPES_SELECT,
  DRIVER_PRIMARY_SHIFT_TYPES_SELECT,
  IDriverForm,
  WEEKLY_DRIVING_HOURS_TYPES_SELECT,
} from '@/interfaces/Driver';
import { IModalArgs, IModalMethods } from '@/interfaces/Modal';
import { IUserForm } from '@/interfaces/User';
import { useStoreDriverMutation } from '@/services/drivers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreDriverModal: ForwardRefRenderFunction<IModalMethods, IModalArgs> = (
  { id },
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDriverForm>();
  const [storeDriverAsync] = useStoreDriverMutation();
  const { loadCompaniesOptions } = useLoadOptions();
  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IDriverForm> = ({ ...data }) => {
    toast.promise(
      storeDriverAsync({
        userId: id,
        companyId: data.company?.id,
        driverExperienceYears: data.driverExperienceYears?.value,
        weeklyDrivingHours: data.weeklyDrivingHours?.value,
        primaryShift: data.primaryShift?.value,
        referralCode: data.referralCode,
      }).unwrap(),
      {
        pending: "Ajout de l'utilisateur en cours...",
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                L'utilisateur à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IUserForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Créer un driver"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Driver experience years <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="driverExperienceYears"
              render={({ field }) => (
                <Select
                  options={DRIVER_EXPERIENCE_YEARS_TYPES_SELECT}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.driverExperienceYears?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Weekly driving hours <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="weeklyDrivingHours"
              render={({ field }) => (
                <Select
                  options={WEEKLY_DRIVING_HOURS_TYPES_SELECT}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.weeklyDrivingHours?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Primary shift <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="primaryShift"
              render={({ field }) => (
                <Select
                  options={DRIVER_PRIMARY_SHIFT_TYPES_SELECT}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.primaryShift?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Company <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="company"
              render={({ field }) => (
                <SelectPaginateAsync
                  loadOptions={loadCompaniesOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.company?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreDriverModal);
