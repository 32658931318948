import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import StatsItem from './StatsSortables/StatsItem';
import { useLiveActivityStatsQuery } from '@/services/liveActivity';

function StatsLiveActivities() {
  const { data, isLoading, isError } = useLiveActivityStatsQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError || !data) {
    return <div>Error</div>;
  }

  const datas = [
    {
      title: 'Online Drivers',
      value: data.onlineDriversNumber.count,
      percent: data.onlineDriversNumber.progress,
      indicator: data.onlineDriversNumber.change_type,
      subValue: data.onlineDriversNumber.previous_count.toString(),
    },
    {
      title: 'User Views',
      value: 'N/A',
      percent: null,
      subValue: 'Not available',
    },
    {
      title: 'Searches',
      value: data.searchesNumber.count,
      percent: data.searchesNumber.progress,
      indicator: data.searchesNumber.change_type,
      subValue: data.searchesNumber.previous_count.toString(),
    },
    {
      title: 'Requests',
      value: data.rideStatsNumbers.rideRequestsNumber.count,
      percent: data.rideStatsNumbers.rideRequestsNumber.progress,
      indicator: data.rideStatsNumbers.rideRequestsNumber.change_type,
      subValue:
        data.rideStatsNumbers.rideRequestsNumber.previous_count.toString(),
    },
    {
      title: 'Rides Completed',
      value: data.rideStatsNumbers.rideRequestsCompletedNumber.count,
      percent: data.rideStatsNumbers.rideRequestsCompletedNumber.progress,
      indicator: data.rideStatsNumbers.rideRequestsCompletedNumber.change_type,
      subValue:
        data.rideStatsNumbers.rideRequestsCompletedNumber.previous_count.toString(),
    },
    {
      title: 'Rides Canceled by User',
      value: data.rideStatsNumbers.rideRequestsCancelledNumber.count,
      percent: data.rideStatsNumbers.rideRequestsCancelledNumber.progress,
      indicator: data.rideStatsNumbers.rideRequestsCancelledNumber.change_type,
      subValue:
        data.rideStatsNumbers.rideRequestsCancelledNumber.previous_count.toString(),
    },
    {
      title: 'Rides Canceled by Driver',
      value: data.rideStatsNumbers.rideRequestsDriverCancelledNumber.count,
      percent: data.rideStatsNumbers.rideRequestsDriverCancelledNumber.progress,
      indicator:
        data.rideStatsNumbers.rideRequestsDriverCancelledNumber.change_type,
      subValue:
        data.rideStatsNumbers.rideRequestsDriverCancelledNumber.previous_count.toString(),
    },
    {
      title: 'Rides No Driver Available',
      value: data.rideStatsNumbers.rideRequestsNoDriverAvailableNumber.count,
      percent:
        data.rideStatsNumbers.rideRequestsNoDriverAvailableNumber.progress,
      indicator:
        data.rideStatsNumbers.rideRequestsNoDriverAvailableNumber.change_type,
      subValue:
        data.rideStatsNumbers.rideRequestsNoDriverAvailableNumber.previous_count.toString(),
    },
  ];

  return (
    <Card>
      <Card.Header>
        <h5 className="mb-0">
          Live Stats{' '}
          <span className="fs--1 text-500 mb-0">(Last 30 minutes)</span>
        </h5>
      </Card.Header>
      <Row className="px-3 rounded-2 overflow-hidden">
        {datas.map((data, i) => (
          <Col xs={6} lg={4} key={data.title} className="p-0">
            <StatsItem
              {...data}
              indicator={
                data.indicator as 'up' | 'down' | 'no-changes' | undefined
              }
              id={i}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default StatsLiveActivities;
