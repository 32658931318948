import RideHeader from '@/components/card-headers/ride/RideHeader';
import { RideMapCard } from '@/components/common/map/RideMapCard/RideMapCard';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import UserRatingCard from '@/components/cards/requests/UserRatingCard';
import DriverRatingCard from '@/components/cards/requests/DriverRatingCard';
import RideDetailsCard from '@/components/cards/requests/RideDetailCard';
import DriverNotificationCard from '@/components/cards/requests/DriverNotificationCard';

const RideView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }
  return (
    <div>
      <RideHeader id={id} />
      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <RideDetailsCard id={id} card />
        </Col>
        <Col>
          <RideMapCard />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <DriverNotificationCard id={id} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={6} md={6}>
          <UserRatingCard id={id} card />
        </Col>
        <Col xxl={6} lg={6} md={6}>
          <DriverRatingCard id={id} card />
        </Col>

        {/* <Col xxl={6} lg={6} md={6}>
          <RideUserItemDetail />
        </Col>
        <Col xxl={6} lg={6} md={6}>
          <RideUserItemDetail />
        </Col> */}
      </Row>
    </div>
  );
};

export default RideView;
