import GoogleMap from '@/components/common/map/GoogleMaps';
import LegendMapCard from './LegendMapCard';
import { FC, useState } from 'react';
import PinDriverVehicle from '@/assets/img/map-view-illustration/pin-driver-vehicle.svg';
import Loader from '../../Loader';
import ErrorComponent from '../../ErrorComponent';
import { useLiveActivityDatasQuery } from '@/services/liveActivity';
import { Marker } from '@react-google-maps/api';

type RideMapCardProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const MapCardView: FC<RideMapCardProps> = ({
  className = '',
  style,
}) => {
  const [coor, setCoordinates] = useState<{ lat: number; lng: number }>({
    lat: 48.8608685,
    lng: 2.34,
  });

  const {
    data: liveActivityData,
    isLoading: isGodViewInfosLoading,
    isError: isGodViewInfosError,
    error,
  } = useLiveActivityDatasQuery(
    {
      coordinates: {
        lat: coor.lat.toString() || '',
        lng: coor.lng.toString() || '',
      },
    },
    {
      pollingInterval: 3000,
    }
  );

  if (isGodViewInfosLoading) {
    return <Loader absolute />;
  } else if (isGodViewInfosError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  const driversAroundFormatted = liveActivityData?.driversAroundFormatted ?? [];

  

  return (
    <div
      style={{
        borderRadius: 10,
        overflow: 'hidden',
        height: '100%',
        minHeight: '30vh',
        ...style,
      }}
      className={`${className} position-relative`}
    >
      <GoogleMap
        initialCenter={coor}
        positions={isLoaded =>
          !isLoaded
            ? []
            : [
                ...driversAroundFormatted.map(driver => (
                  <Marker
                    key={driver.latitude}
                    position={{
                      lat: driver.latitude,
                      lng: driver.longitude,
                    }}
                    icon={{
                      url: PinDriverVehicle,
                      scaledSize: new google.maps.Size(50, 50),
                      strokeColor: '#FFFFFF',
                      fillColor: '#0000FF',
                      fillOpacity: 1,
                      rotation: 215,
                      anchor: new google.maps.Point(30, 36)
                    }}
                  />
                )),
              ]
        }
        onDragEnd={setCoordinates}
        options={{
          zoomControlOptions: {
            position: 0,
          },
          center: coor,
          disableDefaultUI: true,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
      />
      <LegendMapCard />
    </div>
  );
};
