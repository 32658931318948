import { FC } from 'react';
import { IArticle } from '@/interfaces/Article';
import { Card } from 'react-bootstrap';
import { useEditorJsTools } from '@/hooks/useEditorJsTools';
import { createReactEditorJS } from 'react-editor-js';

const ReactEditorJS = createReactEditorJS();

const ArticleEditorCard: FC<Pick<IArticle, 'content'>> = ({ content }) => {
  const getEditorJsTools = useEditorJsTools({});
  const editorJsTools = getEditorJsTools();
  return (
    <Card className="mt-3">
      <Card.Body>
        {!content.blocks.length ? (
          <Card.Header className="text-center">No content</Card.Header>
        ) : (
          <ReactEditorJS
            readOnly
            tools={editorJsTools}
            defaultValue={content}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default ArticleEditorCard;
