import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ReferralCodesHeader from '@/components/card-headers/partnerships/refferalCodes/ReferralCodesHeader';
import UpdateReferralCodeCard from '@/components/updates/partnerships/referralCodes/UpdateReferralCodesCard';

const PartnershipReferralCodeEditView: FC = () => {
  const { id, referralCodesId } = useParams<{
    id: string;
    referralCodesId: string;
  }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <ReferralCodesHeader id={id} isEdit />
      <UpdateReferralCodeCard
        partnershipId={id}
        referralCodeId={referralCodesId || ''}
      />
    </div>
  );
};

export default PartnershipReferralCodeEditView;
