import IEarnPoint, { IEarnPointFormSend } from '@/interfaces/EarnPoint';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const earnPointsApi = api.injectEndpoints({
  endpoints: build => ({
    getEarnPoints: build.query<IPaginateResponse<IEarnPoint>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/earnPoints',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'EarnPoints' as const,
                id,
              })),
              { type: 'EarnPoints', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getEarnPoint: build.query<IEarnPoint, string>({
      query: id => `/earnPoints/${id}`,
      providesTags: (_coupon, _err) => [{ type: 'EarnPoints', id: 'LIST' }],
    }),
    updateEarnPoint: build.mutation<
      IEarnPoint,
      [string, Partial<IEarnPointFormSend>]
      >({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/earnPoints/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'EarnPoints', id: 'LIST' }],
    }),
    storeEarnPoint: build.mutation<IEarnPoint, IEarnPointFormSend>({
      query: data => ({
        url: '/earnPoints',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'EarnPoints', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetEarnPointsQuery,
  useGetEarnPointQuery,
  useStoreEarnPointMutation,
  useUpdateEarnPointMutation,
} = earnPointsApi;
