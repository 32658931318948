import { getReelNameFromDriverBlockedStatus, getReelNameFromDriverBlockedType } from "@/helpers/DriverBlockHelpers";
import { ISelectOption } from "./Select";
import IUser from "./User";
import { IFilters } from "./Fetch";

const DriverBlockedType_ = [
  "documents_expired"
] as const;

const DriverBlockedStatus_ = [
  "blocked",
  "unblocked",
  "reviewing",
  "soon",
] as const;

export const DriverBlockedTypeSelect: ISelectOption<DriverBlockedType>[] = DriverBlockedType_.map((type) => ({
  value: type,
  label: getReelNameFromDriverBlockedType(type),
}));

export const DriverBlockedStatusSelect: ISelectOption<DriverBlockedStatus>[] = DriverBlockedStatus_
  .map((status) => ({
    value: status,
    label: getReelNameFromDriverBlockedStatus(status),
  }));

export type DriverBlockedStatus = typeof DriverBlockedStatus_[number];

export type DriverBlockedType = typeof DriverBlockedType_[number];

export type DriverBlockedBy = Omit<IUser,
  "driverProfile" | "points" | "requestsCount" | "completedRidesCount" | "referredUsersCount" | "totalSpent" | "governanceVotesCount" | "cancelRate" | "averageRating" | "referrer"
>

export interface IDriverBlocked {
  id: string;
  blockedById: string;
  unblockedById: string | null;
  blockedBy: DriverBlockedBy;
  unblockedBy: DriverBlockedBy;
  driverProfileId: string;
  status: DriverBlockedStatus;
  type: DriverBlockedType;
  message: string;
  createdAt: string;
  updatedAt: string;
}


export interface IDriverBlockForm extends Pick<IDriverBlocked, "message"> {
  type: ISelectOption<DriverBlockedType>;
}

export interface IDriverBlockFormSend extends Omit<IDriverBlockForm, "type"> {
  type: DriverBlockedType;
}


export interface IDriverUpdateStatusForm {
  status: ISelectOption<DriverBlockedStatus>;
}

export interface IDriverUpdateStatusFormSend extends Omit<IDriverUpdateStatusForm, "status"> {
  status: DriverBlockedStatus;
}

export interface IDriverBlocksFilters extends IFilters {}

export default IDriverBlocked;
