import { DriverBlockedStatus, DriverBlockedType } from "@/interfaces/DriverBlocked";

export const getReelNameFromDriverBlockedType = (exp?: DriverBlockedType) => {
    switch (exp) {
      case "documents_expired":
        return "Documents Expired";
      default:
        return "None";
    }
  };

export const getReelNameFromDriverBlockedStatus = (status: DriverBlockedStatus) => {
    switch (status) {
      case "blocked":
        return "Blocked";
      case "unblocked":
        return "Unblocked";
      case "reviewing":
        return "Reviewing";
      case "soon":
        return "Soon";
      default:
        return "None";
    }
}
  