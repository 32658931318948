import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromIsActive,
  getReelIsActiveName,
} from '@/helpers/UserHelpers';
import { IOfferForm } from '@/interfaces/Offer';
import { useGetOfferQuery, useUpdateOfferMutation } from '@/services/offers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

interface UpdateOfferCardProps {
  id: string;
}

const UpdateOfferCard: FC<UpdateOfferCardProps> = ({ id }) => {
  const { data, isLoading } = useGetOfferQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IOfferForm>>({});

  const [updateOfferMutation] = useUpdateOfferMutation();
  const navigation = useNavigate();

  const [isActive, setIsActive] = useState<boolean>(data?.isActive || false);

  const onSubmit: SubmitHandler<Partial<IOfferForm>> = ({ ...data }) => {
    toast.promise(
      updateOfferMutation([
        id,
        {
          ...data,
        },
      ]).unwrap(),
      {
        pending: `Modification du offer en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le offer à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IOfferForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        avatar: undefined,
      });
    }
  }, [data]);

  const handleIsActiveToggle = async () => {
    try {
      setIsActive(prevIsActive => !prevIsActive);
      await updateOfferMutation([id, { isActive: !isActive }]);
      navigation(-1);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Edit an Offer</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Active :</Form.Label>
                <SoftBadge
                  bg={getColorFromIsActive(data?.isActive || false)}
                  className={'m-3 text-xl-center'}
                >
                  {getReelIsActiveName(data?.isActive || false)}
                </SoftBadge>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Berline"
                  type="text"
                  {...register('name', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Number of Seats <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 3"
                  type="number"
                  {...register('numberOfSeats', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 2,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.numberOfSeats}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numberOfSeats?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Avatar :</Form.Label>
                <Form.Control type="file" {...register('avatar')} />
                <Form.Control.Feedback type="invalid">
                  {errors.avatar?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Eligible Van ? <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  {...register('isForVanOnly')}
                  isInvalid={!!errors.isForVanOnly}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.isForVanOnly?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Eligible Berlin ? <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  {...register('isForBerlineOnly')}
                  isInvalid={!!errors.isForBerlineOnly?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.isForBerlineOnly?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3 me-2"
          >
            Confirmer
          </Button>
          <Button
            variant={isActive ? 'info' : 'danger'}
            disabled={isLoading}
            onClick={handleIsActiveToggle}
            className="mt-3"
          >
            {isActive ? 'Activate' : 'Disable'}
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateOfferCard;
