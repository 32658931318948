import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import IEarnPoint from '@/interfaces/EarnPoint';
import EarnPointHeader from '@/components/card-headers/earnPoints/EarnPointHeader';
import EarnPointsDetailsCard from '@/components/cards/earnPoints/EarnPointsDetailsCard';
import EarnPointsCriteriaDetailsCard from '@/components/cards/earnPoints/EarnPointsCriteriaDetailsCard';

const EarnPointView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <EarnPointHeader id={id} />
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <EarnPointsDetailsCard id={id} card />
        </Col>
        <Col xxl={6}>
          <EarnPointsCriteriaDetailsCard id={id} card />
        </Col>
      </Row>
    </div>
  );
};

export default EarnPointView;
