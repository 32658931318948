import { createFormDataRequest } from "@/helpers/utils";
import IDriverBlocked from "@/interfaces/DriverBlocked";
import { IFilters, IPaginateResponse } from "@/interfaces/Fetch";
import api from "@/services/api";

export const driverBlocksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDriverBlocks: build.query<IPaginateResponse<IDriverBlocked>, [string, IFilters]>({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/drivers/${id}/blocks`,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetDriverBlocksQuery } = driverBlocksApi;
