import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../../helpers/utils';
import api from '../api';
import IReferralCode, {
  IReferralCodeFilters,
  IReferralCodeForm,
} from '@/interfaces/Partnership/ReferralCode';

export const referralCodesApi = api.injectEndpoints({
  endpoints: build => ({
    getPartnershipRefferalCodes: build.query<
      IPaginateResponse<IReferralCode>,
      IReferralCodeFilters
    >({
      query: ({ partnershipId, ...data }) => {
        const params = createFormDataRequest(data);
        return {
          url: `/partnerships/${partnershipId}/referralCodes`,
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'ReferralCodes' as const,
                id,
              })),
              { type: 'ReferralCodes', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getPartnershipReferralCode: build.query<IReferralCode, [string, string]>({
      query: ([referralCodeId, partnershipId]) => {
        return {
          url: `/partnerships/${partnershipId}/referralCodes/${referralCodeId}`,
        };
      },
    }),

    storePartnershipReferralCode: build.mutation<
      IReferralCode,
      IReferralCodeForm
    >({
      query: ({ partnershipId, ...data }) => {
        return {
          url: `/partnerships/${partnershipId}/referralCodes`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [{ type: 'ReferralCodes', id: 'LIST' }],
    }),

    updatePartnershipReferralCode: build.mutation<
      IReferralCode,
      {
        partnershipId: string;
        referralCodeId: string;
        data: Partial<IReferralCodeForm>;
      }
    >({
      query: ({ partnershipId, referralCodeId, data }) => {
        const body = createFormDataRequest(data);
        return {
          url: `/partnerships/${partnershipId}/referralCodes/${referralCodeId}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'ReferralCodes', id: 'LIST' }],
    }),
    destroyPartnershipReferralCode: build.mutation<
      void,
      { partnershipId: string; referralCodeId: string }
    >({
      query: ({ partnershipId, referralCodeId }) => ({
        url: `/partnerships/${partnershipId}/referralCodes/${referralCodeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ReferralCodes', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPartnershipRefferalCodesQuery,
  useStorePartnershipReferralCodeMutation,
  useUpdatePartnershipReferralCodeMutation,
  useDestroyPartnershipReferralCodeMutation,
  useGetPartnershipReferralCodeQuery,
} = referralCodesApi;
