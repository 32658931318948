import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';
import Company, {
  CompanyFormCreateSend,
  CompanyFormUpdateSend,
} from '@/interfaces/Company';

export const companiesApi = api.injectEndpoints({
  endpoints: build => ({
    getCompanies: build.query<IPaginateResponse<Company>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/companies',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Companies' as const,
                id,
              })),
              { type: 'Companies', id: 'LIST', ...filters },
            ]
          : [],
    }),
    destroyCompany: build.mutation<void, string>({
      query: id => ({
        url: `/companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Companies', id: 'LIST' }],
    }),
    getCompany: build.query<Company, string>({
      query: id => `/companies/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Companies', id }],
    }),
    updateCompany: build.mutation<
      Company,
      [string, Partial<CompanyFormUpdateSend>]
    >({
      query: ([id, company]) => ({
        url: `/companies/${id}`,
        method: 'PUT',
        body: company,
      }),
      invalidatesTags: (result, _error, [id]) => [{ type: 'Companies', id }],
    }),
    createCompany: build.mutation<Company, CompanyFormCreateSend>({
      query: company => ({
        url: '/companies',
        method: 'POST',
        body: company,
      }),
      invalidatesTags: [{ type: 'Companies', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useCreateCompanyMutation,
  useLazyGetCompaniesQuery,
  useDestroyCompanyMutation,
} = companiesApi;
