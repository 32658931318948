import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import RevenueChart from './RevenueChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '@/components/common/Flex';

type NavItemProps = {
  item: string;
};

const NavItem: FC<NavItemProps> = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase()}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const Revenue = () => {
  const navItems = ['Revenue', 'Users', 'Deals', 'Profit'];
  return (
    <Card className="mt-3">
      <Tab.Container id="audience-tab" defaultActiveKey="users">
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
          >
            {navItems.map(item => (
              <NavItem item={item} key={item} />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body>
          <Row className="g-1">
            <Col xxl={3}>
              <Row className="g-0 my-2">
                <Col md={6} xxl={12}>
                  <div className="border-xxl-bottom border-xxl-200 mb-2">
                    <h2 className="text-primary">$37,950</h2>
                    <p className="fs--2 text-500 fw-semi-bold mb-0">
                      <FontAwesomeIcon
                        icon="circle"
                        className="text-primary me-2"
                      />
                      Closed Amount
                    </p>
                    <p className="fs--2 text-500 fw-semi-bold">
                      <FontAwesomeIcon
                        icon="circle"
                        className="text-warning me-2"
                      />
                      Revenue Goal
                    </p>
                  </div>
                </Col>
                <Col md={6} xxl={12} className="py-2">
                  <Row className="mx-0">
                    <Col xs={6} className="border-end border-bottom py-3">
                      <h5 className="fw-normal text-600">$4.2k</h5>
                      <h6 className="text-500 mb-0">Email</h6>
                    </Col>
                    <Col xs={6} className="border-bottom py-3">
                      <h5 className="fw-normal text-600">$5.6k</h5>
                      <h6 className="text-500 mb-0">Social</h6>
                    </Col>
                    <Col xs={6} className="border-end py-3">
                      <h5 className="fw-normal text-600">$6.7k</h5>
                      <h6 className="text-500 mb-0">Call</h6>
                    </Col>
                    <Col xs={6} className="py-3">
                      <h5 className="fw-normal text-600">$2.3k</h5>
                      <h6 className="text-500 mb-0">Other</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xxl={9}>
              <Tab.Content>
                <Tab.Pane unmountOnExit eventKey="revenue">
                  <RevenueChart data={revenueChartData.dataset.revenue} />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="users">
                  <RevenueChart data={revenueChartData.dataset.users} />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="deals">
                  <RevenueChart data={revenueChartData.dataset.deals} />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="profit">
                  <RevenueChart data={revenueChartData.dataset.profit} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

NavItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default Revenue;

const revenueChartData = {
  dates: [new Date('5-6-2019'), new Date('5-6-2021'), 1000 * 60 * 60 * 24 * 30],
  dataset: {
    revenue: [
      [
        645, 500, 550, 550, 473, 405, 286, 601, 743, 450, 604, 815, 855, 722,
        700, 896, 866, 952, 719, 558, 737, 885, 972, 650, 600,
      ],
      [
        440, 250, 270, 400, 175, 180, 200, 400, 600, 380, 340, 550, 650, 450,
        400, 688, 650, 721, 500, 300, 445, 680, 568, 400, 371,
      ],
    ],
    users: [
      [
        545, 500, 650, 727, 773, 705, 686, 501, 643, 580, 604, 615, 755, 722,
        727, 816, 836, 952, 719, 758, 937, 785, 872, 850, 800,
      ],
      [
        340, 360, 230, 250, 410, 430, 450, 200, 220, 540, 500, 250, 355, 320,
        500, 630, 680, 500, 520, 550, 750, 720, 700, 780, 750,
      ],
    ],
    deals: [
      [
        545, 400, 450, 627, 473, 450, 460, 780, 770, 800, 504, 550, 500, 530,
        727, 716, 736, 820, 719, 758, 737, 885, 872, 850, 800,
      ],
      [
        245, 300, 450, 427, 273, 250, 260, 580, 570, 500, 402, 450, 400, 330,
        527, 516, 536, 620, 519, 558, 537, 483, 472, 250, 300,
      ],
    ],
    profit: [
      [
        545, 400, 450, 627, 673, 605, 686, 501, 843, 518, 504, 715, 955, 622,
        627, 716, 736, 952, 619, 558, 937, 785, 872, 550, 400,
      ],
      [
        340, 360, 330, 300, 410, 380, 450, 400, 420, 240, 200, 250, 355, 320,
        500, 630, 680, 400, 420, 450, 650, 620, 700, 450, 340,
      ],
    ],
  },
};
