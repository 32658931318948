import { ILog } from '@/components/interfaces/Log';
import createMarkup from '@/helpers/CreateMarkup';
import ClassNames from 'classnames';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import { Sizes } from '@/interfaces/Theme';

interface IProps extends ILog {
  classNames?: {
    wrapper?: string;
    avatar?: string;
    body?: string;
  };
  flush?: boolean;
  sizeAvatar?: Sizes;
}

const Log: FC<IProps> = ({
  classNames,
  emoji,
  createdAt,
  action,
  flush,
  link,
  sizeAvatar = '2xl',
}) => {
  const DynamicTag = link ? Link : 'div';
  return (
    <DynamicTag
      className={ClassNames(
        'notification rounded-0 border-x-0 border-300 border-bottom-0',
        { 'notification-flush': flush },
        classNames?.wrapper
      )}
      to={link || '#!'}
    >
      {emoji && (
        <div className="notification-avatar">
          <Avatar
            emoji={emoji}
            size={sizeAvatar as Sizes}
            className={`me-3 ${classNames?.avatar}`}
          />
        </div>
      )}
      <div className={`notification-body ${classNames?.body ?? ''}`}>
        <div className="mb-1" dangerouslySetInnerHTML={createMarkup(action)} />
        <span className="notification-time">
          {DateTime.fromISO(createdAt).toRelative()}
        </span>
      </div>
    </DynamicTag>
  );
};

export default Log;
