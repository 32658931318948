interface IErrorType {
  title: string;
  message: string;
}

const errors: Record<number, IErrorType> = {
  400: {
    title: "Une erreur est survenue lors de la requête",
    message:
      "Soyez sûr que l'adresse est correcte et que le serveur est en ligne.",
  },
  401: {
    title: "Vous n'êtes pas autorisé à accéder à cette ressource",
    message:
      "Veuillez vous connecter avec un compte autorisé pour accéder à cette ressource.",
  },
  403: {
    title: "Vous n'êtes pas autorisé à accéder à cette ressource",
    message:
      "Veuillez vous connecter avec un compte autorisé pour accéder à cette ressource.",
  },
  404: {
    title: "La ressource demandée n'a pas été trouvée",
    message:
      "Veuillez vérifier l'adresse et réessayer. Si le problème persiste, veuillez contacter l'administrateur.",
  },
  422: {
    title: "Une erreur est survenue lors de l'accès à la ressource",
    message: "Veuillez vérifier les données dans le lien et réessayer.",
  },
  500: {
    title: "Une erreur est survenue lors de la requête",
    message:
      "Soyez sûr que l'adresse est correcte et que le serveur est en ligne.",
  },
};

export default errors;
