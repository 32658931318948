import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import IconButton from '@/components/common/IconButton';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import StoreVehiculesModal from '@/components/modals/vehicules/StoreVehiculesModal';
import AllDriversHeader from '@/components/table-headers/drivers/AllDriversHeader';
import AllVehiculesHeader from '@/components/table-headers/vehicules/AllVehiculesHeader';
import { capitalize, truncate } from '@/helpers/utils';
import { IFilters } from '@/interfaces/Fetch';
import Vehicle from '@/interfaces/Vehicle';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import {
  useDestroyVehicleMutation,
  useGetVehiclesQuery,
} from '@/services/vehicles';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { ElementRef, FC, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const VehiclesView: FC = () => {
  const userConfig = useSelector(selectCurrentUserConfig);

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [deleteVehicleAsync] = useDestroyVehicleMutation();

  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetVehiclesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const totalCompanies = data?.meta?.total;

  const columns: ColumnDef<Vehicle>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <div className="gap-2 d-flex">
            <Link to={`/vehicles/${id}/edit`}>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faEdit}
                transform="shrink-3"
                iconAlign="middle"
              />
            </Link>
            {userConfig.isAdminOrSuperAdmin && (
              <IconButton
                variant="outline-danger"
                size="sm"
                icon={faTrash}
                onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
                transform="shrink-3"
                iconAlign="middle"
              />
            )}
          </div>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        id: 'number',
        cell: info => {
          if (totalCompanies === undefined) {
            return 'Loading...';
          }
          const pageIndex = info.table.getState().pagination.pageIndex;
          const pageSize = info.table.getState().pagination.pageSize;
          const rowIndex = info.row.index;
          const recordNumber =
            totalCompanies - (pageIndex * pageSize + rowIndex);
          return recordNumber;
        },
      },
      {
        header: 'Vehicle ID',
        footer: props => props.column.id,
        accessorFn: ({ id }) => truncate(id, 10),
        id: 'id',
        cell: info => info.getValue(),
      },
      {
        header: 'Avatar',
        footer: props => props.column.id,
        accessorFn: ({ avatar }) =>
          avatar ? (
            <ImageOverview images={[avatar.link]}>
              {setImgIndex => (
                <Avatar
                  size="xl"
                  src={avatar.link}
                  name={avatar.name}
                  onClick={() => setImgIndex(0)}
                />
              )}
            </ImageOverview>
          ) : (
            <UnknownBadge />
          ),
        id: 'avatar',
        cell: info => info.getValue(),
      },
      {
        header: 'Brand',
        footer: props => props.column.id,
        accessorFn: ({ brand }) => capitalize(brand),
        id: 'brand',
        cell: info => info.getValue(),
      },
      {
        header: 'Model',
        footer: props => props.column.id,
        accessorFn: ({ model }) => model.toUpperCase(),
        id: 'model',
        cell: info => info.getValue(),
      },
      {
        header: '# Seats',
        footer: props => props.column.id,
        accessorFn: ({ numberOfSeats }) => numberOfSeats,
        id: 'numberOfSeats',
        cell: info => info.getValue(),
      },
      {
        header: 'Fuel type',
        footer: props => props.column.id,
        accessorFn: ({ fuelType }) => capitalize(fuelType),
        id: 'fuelType',
        cell: info => info.getValue(),
      },
      {
        header: 'Vehicule type',
        footer: props => props.column.id,
        accessorFn: ({ vehicleType }) => capitalize(vehicleType),
        id: 'vehiculeType',
        cell: info => info.getValue(),
      },
      {
        header: 'Eligible Van',
        footer: props => props.column.id,
        accessorFn: ({ eligibleForVan }) => (eligibleForVan ? 'YES' : 'NO'),
        id: 'eligibleForVan',
        cell: info => info.getValue(),
      },
      {
        header: 'Eligible Berline',
        footer: props => props.column.id,
        accessorFn: ({ eligibleForBerline }) =>
          eligibleForBerline ? 'YES' : 'NO',
        id: 'eligibleForBerline',
        cell: info => info.getValue(),
      },
    ],
    [totalCompanies, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  const onDelete = (id: string) => {
    toast.promise(deleteVehicleAsync(id).unwrap(), {
      pending: 'Suppression du véhicule en cours...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              Le véhicule à bien été supprimée 🚙🚙
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le vehicule  : {{id}} ?"
      />
      <Table
        HeaderComponent={AllVehiculesHeader}
        columns={columns}
        state={state}
        tableName="vehicles"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default VehiclesView;
