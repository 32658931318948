import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import Driver from '@/interfaces/Driver';
import {
  DriverDocument,
  DriverDocumentFormUpdate,
} from '@/interfaces/Driver/Document';
import { DriverDocumentVehicleStatusSelect } from '@/interfaces/DriverVehicleDocument';
import { IUserForm } from '@/interfaces/User';
import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from '@/services/drivers/documents';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

type UpdateStatusDriverModalProps = {
  idDriver: string;
};

type IModalMethods = {
  showOrHiddenModal: (document: DriverDocument) => void;
};

const UpdateDocumentDriverModal: ForwardRefRenderFunction<
  IModalMethods,
  UpdateStatusDriverModalProps
> = ({ idDriver }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DriverDocumentFormUpdate>();
  const [changeDriverDocument] = useUpdateDocumentMutation();
  const [document, setDocument] = useState<DriverDocument | undefined>();

  const showOrHiddenModal = (doc?: DriverDocument) => {
    if (doc) setDocument(doc);
    ModalRef.current?.showOrHiddenModal();
  };

  useEffect(() => {
    if (document) {
      reset({
        status: DriverDocumentVehicleStatusSelect.find(
          v => v.value === document.status
        ),
      });
    }
  }, [document]);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<DriverDocumentFormUpdate> = ({ status }) => {
    if (document === undefined) return;
    toast.promise(
      changeDriverDocument([
        idDriver,
        document.id,
        {
          status: status.value,
        },
      ]).unwrap(),
      {
        pending: 'Changement du status du chauffeur...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le status du chauffeur à été remplacé par {status.label} 🚙🚙
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IUserForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Modifier le document du chauffeur"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Status <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="status"
              rules={{
                required: `Merci d'indiquer le futur status.`,
              }}
              render={({ field }) => (
                <Select
                  options={DriverDocumentVehicleStatusSelect}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.status,
              })}
            >
              {errors.status?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(UpdateDocumentDriverModal);
