import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';
import { IArticle, IArticleForm, IArticleFormSend } from '@/interfaces/Article';
import IFile from '@/interfaces/IFile';

export const articlesApi = api.injectEndpoints({
  endpoints: build => ({
    getArticles: build.query<IPaginateResponse<IArticle>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/articles',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.data.map(({ id }) => ({
              type: 'Articles' as const,
              id,
            })),
            { type: 'Articles', id: 'LIST', ...filters },
          ]
          : [],
    }),
    getArticle: build.query<IArticle, string>({
      query: id => `/articles/${id}`,
      providesTags: (_article, _err) => [{ type: 'Articles', id: 'LIST' }],
    }),
    storeArticle: build.mutation<IArticle, IArticleFormSend>({
      query: data => {
        const body = createFormDataRequest(data);
        return {
          url: '/articles',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),
    updateArticle: build.mutation<IArticle, [string, Partial<IArticleFormSend>]>({
      query: ([id, data]) => {
        if (!(data.illustration instanceof FileList)) delete data.illustration;
        const body = createFormDataRequest(data);
        return {
          url: `/articles/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),
    addArticleFile: build.mutation<IFile, [string, File]>({
      query: ([id, file]) => {
        const body = createFormDataRequest({ file });
        return {
          url: `/articles/${id}/files`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),
    destroyArticle: build.mutation<void, string>({
      query: id => ({
        url: `/articles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  useAddArticleFileMutation,
  useStoreArticleMutation,
  useDestroyArticleMutation,
  useUpdateArticleMutation,
} = articlesApi;
