import ErrorComponent from "@/components/common/ErrorComponent";
import { UUID_REGEX } from "@/constants/regexs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import GovernanceHeader from "@/components/card-headers/governances/GovernanceHeader";
import UpdateGovernanceCard from "@/components/updates/governances/UpdateGovernanceCard";

const GovernanceEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
        <GovernanceHeader id={id} isEdit />
        <UpdateGovernanceCard id={id} />
    </div>
  );
};

export default GovernanceEditView;