import { CellContext } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  cellProps: CellContext<any, unknown>;
  type?: string;
  onUpdate: (rowIndex: string, columnId: string, value: any) => void;
}

const UpdatableCell: FC<IProps> = ({
  cellProps: {
    getValue,
    row: {
      original: { id: index },
    },
    column: { id },
  },
  type = 'text',
  onUpdate,
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(type === 'number' ? Number(e.target.value) : e.target.value);

  const _onBlur = () => {
    if (initialValue !== value) {
      onUpdate(index.toString(), id, value);
    }
  };

  useEffect(() => {
    if(type === 'date') {
      setValue(DateTime.fromISO(value as string).toFormat('yyyy-MM-dd'))
    }
  }, [type]);

  return (
    <Form.Control
      value={value as any}
      onChange={_onChange}
      size="sm"
      style={{ minWidth: 80, paddingRight: 0 }}
      onBlur={_onBlur}
      step={type === 'number' ? 0.01 : undefined}
      type={type}
    />
  );
};

export default UpdatableCell;
