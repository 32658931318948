import { createFormDataRequest } from "@/helpers/utils";
import { DriverService, DriverServiceFilters } from "@/interfaces/Driver/Service";
import { IPaginateResponse } from "@/interfaces/Fetch";
import api from "@/services/api";

export const driverServicesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDriverServices: build.query<IPaginateResponse<DriverService>, DriverServiceFilters>({
            query: ({ id, ...data }) => {
              const params = createFormDataRequest(data);
              return {
                url: `/drivers/${id}/services`,
                params,
              };
            },
          }),
    }),
    overrideExisting: true,
});

export const { useGetDriverServicesQuery } = driverServicesApi;
