import { FC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

type IInputColorProps = Pick<ControllerRenderProps, 'onChange' | 'value'>;

const InputColor: FC<IInputColorProps> = ({ value, onChange, ...props }) => {
  return (
    <div>
      <input
        type="color"
        onChange={e => onChange(e.target.value)}
        className="form-control"
        value={value}
        style={{
          width: '100%',
          height: '40px',
        }}
        {...props}
      />
    </div>
  );
};

export default InputColor;
