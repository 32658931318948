import {
  IAskEmailOTP,
  ICheckEmailOTP,
  ILoginResponse,
} from "@/interfaces/Auth";
import api from "./api";
import { resetCredentials, setCredentials } from "./slices/authSlice";
import IUser from "@/interfaces/User";
import { EmailOTP } from "@/interfaces/EmailOTP";
import { createFormDataRequest } from "@/helpers/utils";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendEmailOTP: build.mutation<EmailOTP, IAskEmailOTP>({
      query: (body) => ({
        url: `/sendEmailOTP`,
        method: "POST",
        body,
      }),
    }),
    checkEmailOTP: build.mutation<ILoginResponse, ICheckEmailOTP>({
      query: (body) => ({
        url: `/checkEmailOTP`,
        method: "POST",
        body: {
          ...body,
          roles: ["superadmin", "admin"],
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const { data } = await queryFulfilled;
        dispatch(setCredentials(data));
      },
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: `/logout`,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(resetCredentials());
        dispatch(authApi.util.resetApiState());
      },
    }),
    getMyProfile: build.query<IUser, string>({
      query: (token) => {
        const params = createFormDataRequest({
          roles: ["superadmin", "admin"],
        });
        return {
          url: `/getMyProfile`,
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(token, { queryFulfilled, dispatch }) {
        const { data: user } = await queryFulfilled;
        dispatch(setCredentials({ user, token }));
      },
      providesTags: ["Me"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useSendEmailOTPMutation,
  useCheckEmailOTPMutation,
  useLogoutMutation,
  useLazyGetMyProfileQuery,
} = authApi;
