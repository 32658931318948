import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { FeatureGroup as FeatureGroupElement } from 'react-leaflet';
import { EditControl, EditControlProps } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import LeafletMap from './LeafletMap';
import L, { FeatureGroup } from 'leaflet';
import { LeafletMapDrawerShape } from '@/interfaces/ActivityAreaPick';

type LeafletMapDrawerProps = {
  onShape?: (shape: LeafletMapDrawerShape) => void;
  color?: string;
};

export type LeafletMapDrawerParams = {
  setShape: (shape: LeafletMapDrawerShape) => void;
};

const LeafletMapDrawer: ForwardRefRenderFunction<
  LeafletMapDrawerParams,
  LeafletMapDrawerProps
> = ({ onShape, color = 'blue' }, ref) => {
  const featureGroupRef = useRef<FeatureGroup>(null);

  useEffect(() => {
    if (featureGroupRef.current) {
      // change color of the rectangle
      featureGroupRef.current.eachLayer((layer: any) => {
        layer.setStyle({ color });
      });
    }
  }, [color]);

  // Use forwardRef to expose internal functions
  useImperativeHandle(ref, () => ({
    setShape: (shape: LeafletMapDrawerShape) => {
      if (featureGroupRef.current) {
        // Remove existing layers before adding a new one
        featureGroupRef.current.clearLayers();
      }

      const bounds: L.LatLngBoundsExpression = [
        [shape.southWest.lat, shape.southWest.lng], // Bottom-left corner (SW)
        [shape.northEast.lat, shape.northEast.lng], // Top-right corner (NE)
      ];
      const layer = L.rectangle(bounds);

      if (onShape) {
        onShape(shape);
      }
      featureGroupRef.current?.addLayer(layer);
    },
  }));

  const onCreated: Required<EditControlProps>['onEdited'] = e => {
    const { layerType, layer } = e;
    console.log('inside onCreated', color);

    if (featureGroupRef.current) {
      // Remove existing layers before adding a new one
      featureGroupRef.current.clearLayers();
    }

    if (layerType === 'rectangle') {
      const rectangleData = layer.toGeoJSON();
      if (onShape) {
        // Extracting corners from the rectangle data
        const coordinates = rectangleData.geometry.coordinates[0];
        onShape({
          southWest: {
            lat: coordinates[0][1],
            lng: coordinates[0][0],
          },
          northWest: {
            lat: coordinates[1][1],
            lng: coordinates[1][0],
          },
          northEast: {
            lat: coordinates[2][1],
            lng: coordinates[2][0],
          },
          southEast: {
            lat: coordinates[3][1],
            lng: coordinates[3][0],
          },
        });
      }
      // Add the new layer to the feature group
      featureGroupRef.current?.addLayer(layer);
    }
  };

  const onEdited: Required<EditControlProps>['onEdited'] = e => {
    const { layers } = e;
    layers.eachLayer((layer: any) => {
      const editedData = layer.toGeoJSON();
      console.log('inside onEdited', color);

      layer.setStyle({ color }); // Update color on edit
      if (onShape) {
        // Extracting corners from the edited rectangle data
        const coordinates = editedData.geometry.coordinates[0];
        onShape({
          southWest: {
            lat: coordinates[0][1],
            lng: coordinates[0][0],
          },
          northWest: {
            lat: coordinates[1][1],
            lng: coordinates[1][0],
          },
          northEast: {
            lat: coordinates[2][1],
            lng: coordinates[2][0],
          },
          southEast: {
            lat: coordinates[3][1],
            lng: coordinates[3][0],
          },
        });
      }
    });
  };

  return (
    <LeafletMap style={{ height: '400px', width: '100%' }}>
      <FeatureGroupElement ref={featureGroupRef}>
        <EditControl
          position="topright"
          onCreated={onCreated}
          onEdited={onEdited} // Handle when the shape is edited
          draw={{
            rectangle: true,
            circle: false,
            circlemarker: false,
            polyline: false,
            marker: false,
            polygon: false,
          }}
        />
      </FeatureGroupElement>
    </LeafletMap>
  );
};

export default forwardRef(LeafletMapDrawer);
