import { AnyObject } from '@/interfaces/Fetch';

const getDifferenceBetweenTwoObjects = <T>(
  objectA: AnyObject | undefined | null,
  objectB: AnyObject
) => {
  if (objectA) {
    const result: AnyObject = {};
    Object.entries(objectB).forEach(([key, element]) => {
      if (key !== 'id' && objectA[key] === element) return;
      else if (
        typeof objectA[key] === 'object' &&
        element !== null &&
        typeof element === 'object'
      ) {
        const elements = getDifferenceBetweenTwoObjects<AnyObject>(
          objectA[key],
          element
        );
        if (Object.keys(elements).length !== 0) {
          result[key] = elements;
        }
        return;
      }
      result[key] = element === '' ? null : element;
    });
    return result as T;
  }

  return objectB as T;
};

export default getDifferenceBetweenTwoObjects;
