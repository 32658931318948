import { useState, useEffect } from 'react';
import { useGetUsersQuery } from '@/services/users';
import IUser from '@/interfaces/User';

const useFetchUsersInBatches = (pageSize: number) => {
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const {
    data,
    error: queryError,
    isFetching,
  } = useGetUsersQuery({
    pageSize,
    pageIndex,
  });

  useEffect(() => {
    if (queryError) {
      setError(queryError as Error);
      setLoading(false);
      return;
    }
    if (data) {
      setAllUsers(prevUsers => [...prevUsers, ...data.data]);
      setLastPage(data.meta.lastPage);
      setLoading(isFetching);
      if (pageIndex < data.meta.lastPage) {
        setPageIndex(prevIndex => prevIndex + 1);
      }
    }
  }, [data, queryError, isFetching]);

  return { allUsers, loading, error };
};

export default useFetchUsersInBatches;
