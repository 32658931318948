import IconButton from "@/components/common/IconButton";
import StoreEarnPointsModal from "@/components/modals/earnPoints/StoreEarnPointsModal";
import StoreRewardModal from "@/components/modals/reward/StoreRewardModal";
import { ITableHeaderComponentProps } from "@/interfaces/Table";
import { faFilter, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $CombinedState } from "@reduxjs/toolkit";
import { ElementRef, FC, useRef, useState } from "react";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";

const AllEarnPointsHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {
  const StoreEarnPointModalRef = useRef<ElementRef<typeof StoreEarnPointsModal>>(null);

  const openCreateModal = () =>
  StoreEarnPointModalRef.current?.showOrHiddenModal();

  return (
    <div className="d-lg-flex justify-content-between">
      <StoreEarnPointsModal ref={StoreEarnPointModalRef} />
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Earn Points</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={(e) => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filtres</span>
            </IconButton>
            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: "1px", height: "29px" }}
            ></div>
          </>
        )}

        <div id="orders-actions">
          <IconButton
            variant="nolina-default"
            size="sm"
            icon={faPlus}
            onClick={openCreateModal}
            transform="shrink-3"
            iconAlign="middle"
            >
              <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                Nouveau
              </span>
            </IconButton>
        </div>
      </div>
    </div>
  );
};

export default AllEarnPointsHeader;