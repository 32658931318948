import IPartnership from './Partnership/Partnership';
import { ISelectOption } from './Select';
import { UserType } from './User';

export type RewardType = 'discount' | 'partnership';

export const REWARD_TYPES: ISelectOption<RewardType>[] = [
  {
    label: 'discount',
    value: 'discount',
  },
  {
    label: 'partnership',
    value: 'partnership',
  },
];

export const USER_TYPES: ISelectOption<UserType>[] = [
  {
    label: 'user',
    value: 'user',
  },
  {
    label: 'driver',
    value: 'driver',
  },
];

interface IReward {
  isArchived: boolean;
  id: string;
  partnershipId: string | null;
  type: RewardType;
  userType: UserType;
  title: string;
  discountAmount: number | null;
  maxDiscountAmount: number | null;
  minPurchaseAmount: number | null;
  price: number;
  limit: number | null;
  descriptionTitle: string;
  description: string;
  partnership?: IPartnership;
  updatedAt: string;
  createdAt: string;
}

export interface IRewardForm {
  type: ISelectOption<RewardType>;
  userType: ISelectOption<UserType>;
  title: string;
  discountAmount: number | null;
  maxDiscountAmount: number | null;
  minPurchaseAmount: number | null;
  price: number;
  limit: number | null;
  descriptionTitle: string;
  description: string;
  partnership?: IPartnership;
  isArchived: boolean;
}

export interface IRewardFormSend
  extends Omit<IRewardForm, 'type' | 'userType' | 'partnership'> {
  type: RewardType;
  userType: UserType;
  partnershipId?: string;
}

export default IReward;
