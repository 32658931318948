import DriverService from '@/interfaces/DriverService';
import { DateTime } from 'luxon';

export const getTextDriverService = (v: DriverService) => {
  return ` 
  ${`<p class="m-0 fw-light me-1">- <strong class="me-1"> ON </strong > 🟢 : <span class="text-secondary fw-medium font-family">${DateTime.fromISO(
    v.createdAt
  ).toFormat('dd/MM/yyyy, HH:mm')},</span> Paris 75020</p>`}
  ${
    v.completedAt !== null
      ? `<p class="m-0 fw-light">- <strong> OFF 🔴 </strong >: <span class="text-secondary fw-medium font-family">${DateTime.fromISO(
          v.completedAt
        ).toFormat('dd/MM/yyyy, HH:mm')},</span> Paris 75020</p>`
      : ''
  }
  ${v.completedAt === null ? ' (Currently active 🟢)<br/>' : ''}`
};
