import DayFactorTable from '@/components/table/dayAndTimeFactor/dayFactor';
import TimeFactorTable from '@/components/table/dayAndTimeFactor/timeFactor';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

const DayAndTimeFactorView: FC = () => {
  return (
    <div>
      <Row className="g-3 mb-3">
        <Col xxl={6} xl={6} lg={6}>
          <TimeFactorTable />
        </Col>
        <Col xxl={6} xl={6} lg={6}>
          <DayFactorTable />
        </Col>
      </Row>
    </div>
  );
};

export default DayAndTimeFactorView;
