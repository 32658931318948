import React, { useState } from 'react';
import DropZoneCard from '@/components/cards/DropZoneCard';
import { useSendDrivers } from '@/hooks/useSendDrivers';

const ImportDriversPage = () => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [importSummary, setImportSummary] = useState<{
    successCount: number;
    failCount: number;
    failedImports: Array<{
      phone: string;
      email: string;
      name: string;
      error: string;
    }>;
  } | null>({ successCount: 0, failCount: 0, failedImports: [] });

  const { throttledSendDriver, isSending } = useSendDrivers(setImportSummary);

  const handleDrop = async (files: File[]) => {
    const file = files[0];
    try {
      const response = await throttledSendDriver(file);
      if (response.success) {
        setCsvData(prevData => [...prevData, file]);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <main className="flex flex-col items-center">
      <div className="w-full max-w-full lg:max-w-4xl p-10">
        <div className="w-full">
          <div className="flex flex-row gap-2 h-64">
            <DropZoneCard onDrop={handleDrop} />
          </div>

          {/* Rectangle Screen for Displaying Data */}
          <div className="mt-4 p-4 border border-gray-200 rounded-lg bg-white shadow-md h-64 overflow-y-auto">
            <h4 className="text-lg font-bold mb-2">Import Summary</h4>
            <p>{importSummary?.successCount} drivers imported successfully</p>
            <p>{importSummary?.failCount} imports failed</p>
            {importSummary && importSummary.failedImports?.length > 0 && (
              <div>
                <h5 className="text-md font-semibold mb-1">
                  Details of failed imports:
                </h5>
                <ul className="list-disc list-inside">
                  {importSummary.failedImports.map((fail, index) => (
                    <li key={index}>
                      {`${fail.phone} / ${fail.email} / ${fail.name} - ${fail.error}`}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ImportDriversPage;
