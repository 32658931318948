import { FC, useState, useEffect } from 'react';
import DatePickerReact, { ReactDatePickerProps } from 'react-datepicker';
import {} from 'luxon';

export const DatePicker: FC<ReactDatePickerProps> = ({ ...props }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);

  useEffect(() => {
    if (props.value) {
      setStartDate(new Date(props.value));
    }
  }, [props.value]);

  console.log(startDate);

  return (
    <DatePickerReact
      selected={startDate}
      onChange={(date, evt) => {
        props.onChange(date, evt);
        setStartDate(date);
      }}
      formatWeekDay={day => day.slice(0, 3)}
      className="form-control"
      placeholderText="Select Date"
      timeIntervals={5}
      dateFormat="dd/MM/yyyy"
      fixedHeight
    />
  );
};

export default DatePicker;
