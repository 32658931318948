import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../../helpers/utils';
import api from '../api';
import {
  IRequest,
  IRequestFilters,
  IRequestFormSend,
} from '@/interfaces/Request';

export const requestsApi = api.injectEndpoints({
  endpoints: build => ({
    getRequests: build.query<IPaginateResponse<IRequest>, IRequestFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/requests',
          params,
        };
      },
    }),
    storeRequest: build.mutation<IRequest, IRequestFormSend>({
      query: data => ({
        url: '/requests',
        method: 'POST',
        body: data,
      }),
    }),
    getRequest: build.query<IRequest, string>({
      query: id => `/requests/${id}`,
      providesTags: (result, _error, id) =>
        result ? [{ type: 'Requests', id: 'LIST' }] : [],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetRequestsQuery,
  useStoreRequestMutation,
  useGetRequestQuery,
} = requestsApi;
