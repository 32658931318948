import { useBreakpoints } from "@/hooks/useBreakpoints";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC, MouseEventHandler } from "react";
import {
  Button,
  ButtonProps,
  OverlayTrigger,
  OverlayTriggerProps,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface IProps extends ButtonProps {
  icon: IconDefinition;
  iconAlign?: "left" | "right" | "middle";
  iconClassName?: string;
  transform?: string;
  children?: React.ReactNode;
  overlayTitle?: string;
  overlayPlacement?: OverlayTriggerProps["placement"];
}

const IconButton: FC<IProps> = ({
  children,
  iconAlign = "left",
  iconClassName,
  icon,
  transform,
  overlayTitle,
  href,
  onClick,
  overlayPlacement = "top",
  ...rest
}) => {
  const { breakpoints } = useBreakpoints();
  const navigation = useNavigate();

  const onClickTrigger = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (href) {
      e.preventDefault();
      if (/^(tel|mailto):/i.test(href)) {
        document.location.href = href;
      } else {
        navigation(href);
      }
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <OverlayTrigger
      placement={overlayPlacement}
      show={breakpoints.down("xl") && !!overlayTitle ? undefined : false}
      overlay={<Tooltip>{overlayTitle}</Tooltip>}
    >
      <Button onClick={onClickTrigger} {...rest}>
        {iconAlign === "right" && children}
        <FontAwesomeIcon
          icon={icon}
          className={classNames(iconClassName, {
            "me-1": children && iconAlign === "left",
            "ms-1": children && iconAlign === "right",
          })}
          transform={transform}
        />
        {iconAlign === "left" || iconAlign === "middle" ? children : false}
      </Button>
    </OverlayTrigger>
  );
};

export default IconButton;
