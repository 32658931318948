import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import UpdateCouponCard from '@/components/updates/coupons/UpdateCouponCard';
import CompanyHeader from '@/components/card-headers/companies/CompanyHeader';
import UpdateCompanyCard from '@/components/updates/companies/UpdateCompanyCard';

const CompanyEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <CompanyHeader id={id} isEdit />
      <UpdateCompanyCard id={id} />
    </div>
  );
};

export default CompanyEditView;
