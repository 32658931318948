// @ts-ignore
import ActivityAreaPickHeader from '@/components/card-headers/activityAreaPick/ActivityAreaPickHeader';
import { ActivityAreaPickMap } from '@/components/common/map/ActivityAreaPickMap';
import { FC } from 'react';

const ActivityAreaPick: FC = () => {
  return (
    <div className="flex-1 md-h-100 ">
      <ActivityAreaPickHeader id="" />
      <div style={{ height: '70vh' }}>
        <ActivityAreaPickMap />
      </div>
    </div>
  );
};

export default ActivityAreaPick;
