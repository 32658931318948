import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import StarRating from '@/components/common/StarRating';
import UnknownBadge from '@/components/common/UnknownBadge';
import { ICardDetailsProps } from '@/interfaces/Card';
import IRide from '@/interfaces/Ride';
import { useGetRideQuery } from '@/services/rides';
import { FC, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DriverRatingCard: FC<ICardDetailsProps<IRide>> = ({ id, callBack }) => {
  const { data, isLoading, isError, error } = useGetRideQuery(id);

  useEffect(() => {
    if (data) {
      callBack?.(data);
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  const driverService = data.driverRequest?.driverService;
  const driverProfile = driverService?.driverProfile;
  const driverVehicle = driverService?.driverVehicle;
  const user = driverProfile?.user;

  return (
    <Row className="g-2">
      <Col xs={12}>
        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <h5 className="mb-3 fs-0" style={{ textAlign: 'center' }}>
                Driver Rating
              </h5>
            </Row>
            <Row className="mt-3">
              <Col md={6} lg={3} className="mb-4 mb-lg-0 text-center">
                {user?.avatar ? (
                  <ImageOverview images={[user.avatar.link]}>
                    {setImgIndex => (
                      <Avatar
                        size="xl"
                        src={user.avatar.link}
                        name={user.avatar?.name}
                        onClick={() => setImgIndex(0)}
                      />
                    )}
                  </ImageOverview>
                ) : (
                  <UnknownBadge />
                )}
              </Col>
              <Col lg={2} className="text-left">
                {data.offer?.driverPrice}€
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                <Link to={`/drivers/${driverProfile?.id}`}>
                  {user?.fullname}
                </Link>
              </Col>
              <Col lg={2} className="text-left mt-3"></Col>
              <Col lg={4} className="text-center mt-3">
                Car Brand
              </Col>
              <Col lg={3} className="text-left mt-3 fw-bold">
                {driverVehicle?.vehicle?.brand} {driverVehicle?.vehicle?.model}
              </Col>
            </Row>
            <Row className="mb-5">
              <Col
                md={6}
                lg={3}
                className="mb-4 mb-lg-0 mt-2 text-center fw-bold"
              >
                {driverProfile?.referralCode}
              </Col>
              <Col lg={2} className="text-left mt-2"></Col>
              <Col lg={4} className="text-center mt-2">
                Car Plate
              </Col>
              <Col lg={2} className="text-left mt-2">
                {driverVehicle?.licensePlate}
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                Rating:
              </Col>
              <Col lg={2} className="text-left mt-2">
                {data.driverRideRating?.rating !== null &&
                data.driverRideRating?.rating !== undefined ? (
                  <StarRating initialValue={data.driverRideRating.rating} />
                ) : (
                  'No rating yet'
                )}
              </Col>
              <Col lg={5} className="text-center mt-3">
                # Rides Completed:
              </Col>
              <Col lg={2} className="text-left mt-3">
                {driverProfile?.completedRidesCount}
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                Feedback:{' '}
              </Col>
              <Col lg={2} className="text-left mt-3">
                {data.driverRideRating?.feedback || 'No Feedback'}
              </Col>
              <Col lg={5} className="text-center mt-3">
                Total Earned:
              </Col>
              <Col lg={2} className="text-left mt-3">
                {driverService?.totalEarned ?? 0}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DriverRatingCard;
