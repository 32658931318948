import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import IPriceController, {
  IPriceControllerForm,
} from '@/interfaces/PriceController';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const priceControllerApi = api.injectEndpoints({
  endpoints: build => ({
    getPriceControllers: build.query<
      IPaginateResponse<IPriceController>,
      IFilters
    >({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/priceControllers',
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'PriceControllers' as const,
                id,
              })),
              { type: 'PriceControllers', id: 'LIST' },
            ]
          : [],
    }),
    updatePriceController: build.mutation<
      IPriceController,
      IPriceControllerForm
    >({
      query: body => ({
        url: `/priceControllers`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'PriceControllers', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetPriceControllersQuery, useUpdatePriceControllerMutation } =
  priceControllerApi;
