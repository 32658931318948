import classNames from "classnames";
import React, { FC } from "react";
import { Container } from "react-bootstrap";
import Background from "./Background";

interface IProps {
  fluid?: boolean;
  bg?: string;
  image?: string;
  overlay?: boolean | string;
  position?: string | { x: string; y: string };
  video?: string[];
  bgClassName?: string;
  className?: string;
  children?: React.ReactNode;
}

const Section: FC<IProps> = ({
  fluid,
  bg,
  image,
  overlay,
  position,
  video,
  bgClassName,
  className,
  children,
  ...rest
}) => {
  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
      {image && (
        <Background
          image={image}
          overlay={overlay}
          position={position}
          video={video}
          className={bgClassName}
        />
      )}
      <Container fluid={fluid}>{children}</Container>
    </section>
  );
};

export default Section;
