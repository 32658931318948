import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import api from './api';
import { DriverClub } from '@/interfaces/DriverClub';
import { createFormDataRequest } from '@/helpers/utils';

export const driverClubsApi = api.injectEndpoints({
  endpoints: build => ({
    getDriverClubs: build.query<IPaginateResponse<DriverClub>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/driverClubs',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'DriverClubs' as const,
                id,
              })),
              { type: 'DriverClubs', id: 'LIST', ...filters },
            ]
          : [],
    }),
  }),
});

export const { useGetDriverClubsQuery } = driverClubsApi;
