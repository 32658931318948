import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import PartnershipHeader from '@/components/card-headers/partnerships/PartnershipHeader';
import PartnershipDetailsCard from '@/components/cards/partnerships/PartnershipDetailsCard';
import PartnershipReferralCodesTable from '@/components/cards/partnerships/PartnershipReferralCodesTable';
import PartnershipPageHeaderCard from '@/components/cards/partnerships/PartnershipPageHeaderCard';

const PartnershipView: FC = () => {
  const { id: partnershipId } = useParams<{
    id: string;
  }>();

  if (!partnershipId) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <PartnershipHeader id={partnershipId} />
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <PartnershipPageHeaderCard id={partnershipId} card />
        </Col>
        <Col xxl={6}>
          <PartnershipDetailsCard id={partnershipId} card />
        </Col>
        <Row className="g-3 mb-3">
          <Col lg={6} xxl={12}>
            <PartnershipReferralCodesTable partnershipId={partnershipId} />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default PartnershipView;
