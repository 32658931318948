import { createFormDataRequest } from '@/helpers/utils';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { ITimePriceControllerForm } from '@/interfaces/TimePriceController';
import api from './api';
import ITimePriceController from '@/interfaces/TimePriceController';

export const timeFactorApi = api.injectEndpoints({
  endpoints: build => ({
    getTimePriceControllers: build.query<
      IPaginateResponse<ITimePriceController>,
      IFilters
    >({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/timePriceControllers',
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'TimePriceControllers' as const,
                id,
              })),
              { type: 'TimePriceControllers', id: 'LIST' },
            ]
          : [],
    }),
    updateTimePriceControllers: build.mutation<
      ITimePriceController,
      ITimePriceControllerForm
    >({
      query: body => ({
        url: `/timePriceControllers`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'TimePriceControllers', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTimePriceControllersQuery,
  useUpdateTimePriceControllersMutation,
} = timeFactorApi;
