import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Flex from "./Flex";
import IconButton from "./IconButton";

interface IProps {
  absolute?: boolean;
  returnButton?: boolean;
  className?: string;
}

const EmtpyDataComponent: FC<IProps> = ({
  absolute = false,
  returnButton = false,
  className,
}) => {
  const navigation = useNavigate();

  return (
    <Flex
      className={classNames(
        `text-center`,
        {
          "position-absolute top-0 start-0 end-0 bottom-0": absolute,
        },
        className
      )}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <div className="display-1 text-300">EMPTY</div>
      <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
        No Data Found
      </p>
      <hr />
      <p>
        It seems we couldn't find any matching information. Please try again
        with different criteria.
      </p>
      {returnButton && (
        <IconButton
          className="btn btn-primary btn-sm mt-3"
          icon={faChevronLeft}
          iconAlign="left"
          onClick={() => navigation(-1)}
        >
          Retour
        </IconButton>
      )}
    </Flex>
  );
};

export default EmtpyDataComponent;
