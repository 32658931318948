import Background from '@/components/common/Background';
import SoftBadge from '@/components/common/SoftBadge';
import { getBackgroundFromColor } from '@/helpers/StatisticHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC } from 'react';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Link, useNavigate } from 'react-router-dom';
import StatisticsCardProps from '../interfaces/StatisticsCard';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const StatisticsCard: FC<StatisticsCardProps> = ({
  title,
  value,
  decimal,
  suffix,
  prefix,
  valueClassName,
  linkText,
  link,
  badgeText,
  onClick,
  badgeBg,
  className,
}) => {
  const navigation = useNavigate();

  const _onClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    if (link) {
      return navigation(link);
    } else if (onClick) {
      return onClick();
    }
  };

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Background image={getBackgroundFromColor(badgeBg)} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>
          {title}
          {badgeText && (
            <SoftBadge bg={badgeBg} pill className="ms-2">
              {badgeText}
            </SoftBadge>
          )}
        </h6>
        <div
          className={classNames(
            valueClassName,
            'display-4 fs-4 mb-2 fw-normal font-sans-serif'
          )}
        >
          <CountUp
            start={0}
            end={value}
            duration={2.75}
            suffix={suffix}
            prefix={prefix}
            separator=","
            decimals={decimal ? 2 : 0}
            decimal="."
          />
        </div>
        {linkText && (
          <Link
            to={link ?? '#!'}
            onClick={_onClick}
            className="fw-semi-bold fs--1 text-nowrap"
          >
            {linkText}
            <FontAwesomeIcon
              icon={faAngleRight}
              className="ms-1"
              transform="down-1"
            />
          </Link>
        )}
      </Card.Body>
    </Card>
  );
};

export default StatisticsCard;
