import React from 'react';
import StatsItem from './StatsItem';
import { Card, Col, Row } from 'react-bootstrap';

function StatsSortables() {
  const datas = [
    {
      title: 'Rides completed',
      value: 233,
      subValue: '45.12',
      percent: 3.5,
    },
    {
      title: 'Rides cancelled',
      value: 80,
      subValue: '2.233',
    },
    {
      title: 'Rides requested',
      value: 2039,
      subValue: '1.283',
    },
    {
      title: 'User signups',
      value: 123,
      subValue: '182',
    },
    {
      title: 'Driver signups',
      value: 20,
      subValue: '450',
    },
    {
      title: 'Uninstalls',
      value: 2039,
      subValue: '25,300 (Downloads)',
    },
    {
      title: 'Avg Service Duration',
      value: 30,
    },
    {
      title: 'Users sessions',
      value: 123,
    },
    {
      title: 'Drivers sessions',
      value: 20,
    },
    {
      title: 'GBV',
      value: 2300,
      subValue: '23,000€',
    },
    {
      title: 'Revenues',
      value: 2300,
      subValue: '23,000€',
    },
    {
      title: 'Driver Rem',
      value: 2300,
      subValue: '23,000€',
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h5 className="mb-0">Stats</h5>
      </Card.Header>
      <Row className="px-3 rounded-2 overflow-hidden">
        {datas.map((data, i) => (
          <Col xs={6} lg={4} key={data.title} className="p-0">
            <StatsItem {...data} id={i} />
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default StatsSortables;
