import RequestHeader from '@/components/card-headers/requests/RequestHeader';
import RequestDetailsCard from '@/components/cards/searches/RequestDetailsCard';
import RequestOffersCard from '@/components/cards/searches/RequestOffersCard';
import RequestStopsCard from '@/components/cards/searches/RequestStopsCard';
import UserDetailsCard from '@/components/cards/users/UserDetailsCard';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { IRequest } from '@/interfaces/Request';
import IRequestOffer from '@/interfaces/RequestOffer';
import {
  faCreditCard,
  faGifts,
  faStop,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

const RequestView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [request, setRequest] = useState<IRequest>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <RequestHeader id={id} />
      <Row className="g-3">
        <Col xxl={4} xl={5}>
          <RequestDetailsCard id={id} card callBack={setRequest} />
        </Col>
        {request && (
          <Col xxl={8} xl={7}>
            <Card>
              <Tab.Container defaultActiveKey={'author'}>
                <SimpleBar>
                  <Card.Header className="p-0 border-bottom">
                    <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="author"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1">Auteur</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="offers"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon={faGifts}
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1">Offres</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="stops"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon={faStop}
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1">Stops</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="payment"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon={faCreditCard}
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1">Payment</h6>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                </SimpleBar>
                <Card.Body className="bg-light">
                  <Tab.Content>
                    <Tab.Pane eventKey="author">
                      <UserDetailsCard id={request.authorId.toString()} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="offers">
                      <RequestOffersCard id={request.id} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="stops">
                      <RequestStopsCard stops={request.stops ?? []} />
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RequestView;
