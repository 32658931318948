import { TemplateContext } from "@/hooks/useTemplate";
import classNames from "classnames";
import { FC, useContext, useRef } from "react";
import { Nav, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import Logo from "@/components/common/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const NavbarTop: FC = () => {
  const { isDark, switchTheme, triggerBurgerMenu, isNavbarVerticalCollapsed } =
    useContext(TemplateContext);
  const burgerMenuRef = useRef<HTMLDivElement>(null);

  return (
    <Navbar
      className={classNames("navbar-glass fs--1 navbar-top sticky-kit", {
        "navbar-glass": isNavbarVerticalCollapsed,
      })}
      expand
    >
      <Navbar.Toggle
        ref={burgerMenuRef}
        as="div"
        className={`toggle-icon-wrapper me-md-3 me-2 d-xl-none`}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          id="burgerMenu"
          onClick={triggerBurgerMenu}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line"></span>
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" />

      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
      >
        <Nav.Item as="li">
          <Nav.Link className="px-2 theme-control-toggle" onClick={switchTheme}>
            <OverlayTrigger
              key="left"
              placement="left"
              overlay={
                <Tooltip id="ThemeColor">
                  {isDark ? "Thème clair" : "Thème sombre"}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label">
                {isDark ? (
                  <FontAwesomeIcon icon={faMoon} size={"xl"} />
                ) : (
                  <FontAwesomeIcon icon={faSun} size={"xl"} />
                )}
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
        {/* <NotificationDropdown />
        <ProfileDropdown /> */}
      </Nav>
    </Navbar>
  );
};

export default NavbarTop;
