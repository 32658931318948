import Modal from '@/components/common/Modal';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { IModalMethods } from '@/interfaces/Modal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { addDays, format } from 'date-fns';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';
import {
  CompanyBusinessTypeSelect,
  CompanyCountrySelect,
  CompanyCurrencySelect,
  CompanyStatusSelect,
} from '@/helpers/CompanyHelper';
import useLoadOptions from '@/hooks/useLoadOptions';
import Company, { CompanyFormCreate } from '@/interfaces/Company';
import { useCreateCompanyMutation } from '@/services/companies';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import { validateAge, validateEmail } from '@/helpers/utils';
import DatePicker from '@/components/common/DatePicker';
import { GENDERS } from '@/interfaces/User';

const StoreCompanyModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CompanyFormCreate>({
    defaultValues: {
      status: CompanyStatusSelect[0],
    },
  });

  const { loadPlacesAutocomplete } = useLoadOptions();
  const [createCompanyMutation] = useCreateCompanyMutation();
  const navigation = useNavigate();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<CompanyFormCreate> = ({ ...newData }) => {
    toast.promise(
      createCompanyMutation({
        ...newData,
        addressId: (newData?.address as unknown as Company['address'])?.placeId,
        phone: parsePhoneNumber(newData.phone, 'FR').format('E.164'),
        supportPhone: newData.supportPhone
          ? parsePhoneNumber(newData.supportPhone, 'FR').format('E.164')
          : null,
        supportAddressId: newData?.supportAddress?.place_id,
        bankAccountCountry: newData.bankAccountCountry?.value,
        bankAccountCurrency: newData.bankAccountCurrency?.value,
        defaultCurrency: newData.defaultCurrency?.value,
        status: newData.status?.value,
        businessType: newData.businessType?.value,
        country: newData.country?.value,
        owner: {
          phone: parsePhoneNumber(newData.owner.phone, 'FR').format('E.164'),
          addressId: (newData.owner.addressId as unknown as Company['address'])
            ?.placeId,
          role: "owner",
          gender: newData.owner.gender?.value,
          nationality: newData.owner.nationality?.value,
          firstname: newData.owner.firstname,
          lastname: newData.owner.lastname,
          email: newData.owner.email,
          percentOwnership: newData.owner.percentOwnership,
          title: newData.owner.title,
          birthdate: format(newData.owner.birthdate, 'dd-MM-yyyy'),
        },
      }).unwrap(),
      {
        pending: `Création de la compagnie en cours...`,
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`La Création à bien été créer 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<CompanyFormCreate> = error =>
    console.log(error);

  return (
    <Modal
      ref={ModalRef}
      title={'Ajouter une compagnie'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Col>
        <Row className="gx-3">
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Name <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : NOEL10"
                type="text"
                {...register('name', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Ce champ doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Ce champ doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Adresse<span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: "L'adresse est obligatoire",
                }}
                render={({ field }) => (
                  <AsyncSelectComponent
                    loadOptions={loadPlacesAutocomplete}
                    value={field.value}
                    closeMenuOnSelect
                    isClearable
                    onChange={field.onChange}
                    components={{
                      Menu: CustomGoogleMenu,
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.place_id}
                  />
                )}
              />
              <Form.Control.Feedback
                type="invalid"
                className={classNames({
                  'd-block': errors.address,
                })}
              >
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Phone <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : 0618109832"
                type="tel"
                {...register('phone', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message:
                      'Le téléphone doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 20,
                    message:
                      'Le téléphone name doit contenir au maximum 12 caractères.',
                  },
                })}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Country <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <Select
                    options={CompanyCountrySelect}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Email <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : contact@wearecomin.com"
                type="email"
                {...register('email', {
                  validate: value => validateEmail(value),
                })}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Default Currency <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="defaultCurrency"
                render={({ field }) => (
                  <Select
                    options={CompanyCurrencySelect}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.defaultCurrency?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Bank Account Country <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="bankAccountCountry"
                render={({ field }) => (
                  <Select
                    options={CompanyCountrySelect}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bankAccountCountry?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Status <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="status"
                disabled
                render={({ field }) => (
                  <Select
                    isDisabled
                    options={CompanyStatusSelect}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.status?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Business Type <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="businessType"
                render={({ field }) => (
                  <Select
                    options={CompanyBusinessTypeSelect}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.businessType?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Bank Account Currency <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="bankAccountCurrency"
                render={({ field }) => (
                  <Select
                    options={CompanyCurrencySelect}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bankAccountCurrency?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Bank Account Holder Name <span className="text-danger">*</span>{' '}
                :
              </Form.Label>
              <Form.Control
                placeholder="ex : John Doe"
                type="text"
                {...register('bankAccountHolderName', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message:
                      'Le bank account holder name doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le bank account holder name doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.bankAccountHolderName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bankAccountHolderName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Bank Account Number <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : 123456789"
                type="text"
                {...register('bankAccountNumber', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message:
                      'Le bank account number doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le bank account number doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.bankAccountNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bankAccountNumber?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>Support address :</Form.Label>
              <Form.Control
                placeholder="ex : 12 rue de la paix"
                type="text"
                {...register('supportAddress', {
                  minLength: {
                    value: 2,
                    message:
                      'Le champs supportAddress doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le champs supportAddress doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.supportAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.supportAddress?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>Support email :</Form.Label>
              <Form.Control
                placeholder="ex : contact@comin.com"
                type="text"
                {...register('supportEmail', {
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  minLength: {
                    value: 2,
                    message:
                      'Le support email doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le support email doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.supportEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.supportEmail?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>Support phone :</Form.Label>
              <Form.Control
                placeholder="ex : +330617628275"
                type="phone"
                {...register('supportPhone', {
                  minLength: {
                    value: 2,
                    message:
                      'Le support Phone doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 20,
                    message:
                      'Le bank name doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.supportPhone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.supportPhone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Tax ID <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : SIREN Number"
                type="text"
                {...register('taxId', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Le tax ID doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 15,
                    message:
                      'Le tax ID doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.taxId}
              />
              <Form.Control.Feedback type="invalid">
                {errors.taxId?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Vat ID <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : TVA Number"
                type="text"
                {...register('vatId', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Le vatId doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Le vatId doit contenir au maximum 15 caractères.',
                  },
                })}
                isInvalid={!!errors.vatId}
              />
              <Form.Control.Feedback type="invalid">
                {errors.vatId?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="bg-100 py-3 px-2 ">
          <h3 className="text-lg">Owner</h3>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Gender <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="owner.gender"
                render={({ field }) => (
                  <Select
                    options={GENDERS}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.gender?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Lastname <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : Bernard"
                type="text"
                {...register('owner.lastname', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Ce champ doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Ce champ doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors['owner']?.lastname}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.lastname?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Firstname <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : Leonard"
                type="text"
                {...register('owner.firstname', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Ce champ doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Ce champ doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors['owner']?.firstname}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.firstname?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Nationality <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="owner.nationality"
                render={({ field }) => (
                  <Select
                    options={CompanyCountrySelect}
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.nationality?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Adresse<span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="owner.addressId"
                rules={{
                  required: "L'adresse est obligatoire",
                }}
                render={({ field }) => (
                  <AsyncSelectComponent
                    loadOptions={loadPlacesAutocomplete}
                    value={field.value}
                    closeMenuOnSelect
                    isClearable
                    required
                    onChange={field.onChange}
                    components={{
                      Menu: CustomGoogleMenu,
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.place_id}
                  />
                )}
              />
              <Form.Control.Feedback
                type="invalid"
                className={classNames({
                  'd-block': errors.address,
                })}
              >
                {errors['owner']?.addressId?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Title <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : Leonard"
                type="text"
                {...register('owner.title', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Ce champ doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Ce champ doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors['owner']?.title}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.title?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Email <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : connexion@me.com"
                type="text"
                {...register('owner.email', {
                  pattern: /^\S+@\S+$/i,
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message: 'Ce champ doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Ce champ doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors['owner']?.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Phone <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : 0618109832"
                type="tel"
                {...register('owner.phone', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message:
                      'Le téléphone doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 20,
                    message:
                      'Le téléphone name doit contenir au maximum 12 caractères.',
                  },
                })}
                isInvalid={!!errors['owner']?.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Percent ownership <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : 0"
                type="number"
                min={0}
                max={100}
                {...register('owner.percentOwnership', {
                  required: 'Ce champ est requis.',
                  min: 0,
                  max: 100,
                })}
                isInvalid={!!errors['owner']?.percentOwnership?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors['owner']?.percentOwnership?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3 d-flex flex-column">
              <Form.Label>
                Birthdate <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="owner.birthdate"
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={field.onChange}
                    required
                  />
                )}
                rules={{
                  validate: value =>
                    !validateAge(value)
                      ? "L'owner doit avoir au moins 18 ans."
                      : undefined,
                  required: 'Ce champ est requis.',
                }}
              />

              <Form.Control.Feedback type="invalid">
                {errors['owner']?.birthdate?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};

export default forwardRef(StoreCompanyModal);
