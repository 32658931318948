import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromGovStatusType,
  getReelGovStatusName,
} from '@/helpers/UserHelpers';
import IGovernance from '@/interfaces/Governance';
import { useGetGovernanceQuery } from '@/services/governances';
import { DateTime } from 'luxon';
import PageHeader from '@/components/common/header/PageHeader';

const GovernanceDetailsCard: FC<ICardDetailsProps<IGovernance>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetGovernanceQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-2">
      <Col xs={12}>
        <Card className="font-sans-serif ">
          <Card.Body>
            <Row className="gx-3 gy-3">
              <Col className="mb-3 mb-sm-0">
                <div className="overflow-hidden">
                  <Tab.Container defaultActiveKey="description">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="description"
                          className="ps-0 cursor-pointer outline-none"
                        >
                          Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="context"
                          className="px-2 px-md-3 cursor-pointer outline-none"
                        >
                          Context
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="outcome"
                          className="px-2 px-md-3 cursor-pointer outline-none"
                        >
                          Outcome
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="description">
                        <div className="mt-3">{data.description}</div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="context">
                        <div className="mt-3">{data.context}</div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="outcome">
                        {/* <div className='mt-3'>{data.outcome}</div> */}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default GovernanceDetailsCard;
