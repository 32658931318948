import Modal from '@/components/common/Modal';
import { IModalArgs, IModalMethods } from '@/interfaces/Modal';
import { IUserForm } from '@/interfaces/User';
import { IPointTransactionFormSend } from '@/interfaces/User/PointTransaction';
import { useStoreUserPointsTransactionsMutation } from '@/services/users/pointsTransactions';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreUserPointTransactionModal: ForwardRefRenderFunction<
  IModalMethods,
  IModalArgs
> = ({ id }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IPointTransactionFormSend>();
  const [storeUserPointsTransactions] = useStoreUserPointsTransactionsMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IPointTransactionFormSend> = ({ ...data }) => {
    toast.promise(storeUserPointsTransactions([id, data]).unwrap(), {
      pending: "Ajout du point en cours...",
      success: {
        render() {
          reset();
          showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              Point attribué avec succès 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IUserForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un point de transaction"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Label <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Transaction de test"
              type="text"
              {...register('label', {
                required: 'Le label est requis.',
                minLength: {
                  value: 2,
                  message: 'Le label doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Le label doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.label}
            />
            <Form.Control.Feedback type="invalid">
              {errors.label?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Points <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 300"
              type="number"
              {...register('points', {
                required: 'Les points sont requis.',
                max: {
                  value: 9999,
                  message: 'Le nombre de points doit être inférieur à 9999.',
                },
              })}
              isInvalid={!!errors.points}
            />
            <Form.Control.Feedback type="invalid">
              {errors.points?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreUserPointTransactionModal);
