import Modal from '@/components/common/Modal';
import { IModalMethods } from '@/interfaces/Modal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IAskEmailOTP } from '@/interfaces/Auth';
import { useCreateEmailOtpMutation } from '@/services/emailOtps';

const StoreEmailOtpModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IAskEmailOTP>();

  const [createEmailOtpMutation] = useCreateEmailOtpMutation();
  const navigation = useNavigate();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IAskEmailOTP> = ({ ...newData }) => {
    toast.promise(createEmailOtpMutation(newData).unwrap(), {
      pending: `Création et envoie de l'OTP en cours...`,
      success: {
        render() {
          navigation(-1);
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              {`L'OTP à bien été crée avec succès 🤩`}
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'L\'addresse email n\'existe pas.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<IAskEmailOTP> = error => console.log(error);

  return (
    <Modal
      ref={ModalRef}
      title={'Créer un Email OTP'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Email <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : contact@wearecomin.com"
              type="text"
              {...register('email', {
                required: 'Ce champ est requis.',
                validate: {
                  email: value =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                    'Veuillez entrer une adresse email valide.',
                },
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreEmailOtpModal);
