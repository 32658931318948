import SoftBadge from '@/components/common/SoftBadge';
import { FC, ReactNode, useRef, ElementRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from '@/components/common/Flex';
import { capitalize, formatPlate, splitText } from '@/helpers/utils';
import CardHeader from '@/components/card-headers/CardHeader';
import { getColorFromVehicleStatus } from '@/helpers/DriverVehiculeHelpers';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  useDestroyDriverVehicleMutation,
  useGetDriverVehicleQuery,
} from '@/services/drivers/vehicles';
import Loader from '@/components/common/Loader';
import ErrorComponent from '@/components/common/ErrorComponent';
import { VEHICULES_FUEL_TYPES } from '@/interfaces/Vehicle';
import IconButton from '@/components/common/IconButton';
import { faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import UpdateDriverVehicleModal from '@/components/modals/driver/vehicle/UpdateDriverVehicleModal';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import { toast, ToastContentProps } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

type DriverVehicleInfoCardProps = {
  driverId: string;
  idVehicle: string;
  isLink?: boolean;
};

const DriverVehicleInfoCard: FC<DriverVehicleInfoCardProps> = ({
  driverId,
  idVehicle,
  isLink = true,
}) => {
  const { data, isLoading, error, isError } = useGetDriverVehicleQuery([
    driverId,
    idVehicle,
  ]);

  const ModalRef = useRef<ElementRef<typeof UpdateDriverVehicleModal>>(null);

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [deleteDriverVehicleAsync] = useDestroyDriverVehicleMutation();

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  // const navigate = useNavigate();

  const onDelete = () => {
    toast.promise(deleteDriverVehicleAsync([driverId, idVehicle]).unwrap(), {
      pending: 'Suppression du véhicule en cours...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          // navigate(-1);
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              Le véhicule a bien été retiré.
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }

          return 'Une erreur est survenue';
        },
      },
    });
  };

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé la vehucule : {{id}} ?"
      />
      <UpdateDriverVehicleModal idDriver={driverId} ref={ModalRef} />
      <Card className="relative">
        {isLink && (
          <Link
            to={`/drivers/${driverId}/vehicles/${idVehicle}`}
            className="position-absolute h-100 w-100"
          />
        )}
        <Card.Header className="p-0 d-flex justify-content-between align-items-center">
          <CardHeader
            title="Informations"
            className="mt-0 mb-0 pb-0"
            titleClassName="fs-1 fw-bold"
          />
          <IconButton
            variant="outline-danger"
            size="sm"
            icon={faTrash}
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(idVehicle)}
            className="ms-3"
            transform="shrink-3"
            iconAlign="middle"
          />
          <IconButton
            variant="nolina-default"
            size="sm"
            icon={faToggleOn}
            className="me-3"
            onClick={() => {
              ModalRef.current?.showOrHiddenModal({
                vehicle: data,
              });
            }}
            transform="shrink-3"
            iconAlign="middle"
          />
        </Card.Header>
        <Card.Body className="py-0 mt-0">
          <RowDriverVehicleInfoCard label="Selected">
            <p className=" m-0" style={{ fontSize: 12 }}>
              <SoftBadge bg={data.default ? 'success' : 'info'}>
                {data.default ? 'Selected' : 'Not Selected'}
              </SoftBadge>
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Vehicle Id">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {splitText(data.id, 15)}
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Status">
            <SoftBadge bg={getColorFromVehicleStatus(data.status)}>
              {data.status}
            </SoftBadge>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Brand">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {data.vehicle.brand}
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Model">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {data.vehicle.model}
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Fuel Type">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {
                VEHICULES_FUEL_TYPES.find((v, i) => data.vehicle.fuelType)
                  ?.label
              }
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Type Vehicle">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {capitalize(data.vehicle.vehicleType)}
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Color">
            <div className="d-flex items-center gap-2" style={{ height: 20 }}>
              <SoftBadge
                bg={data.color}
                className="border items-center justify-center"
              >
                <p className="text-secondary m-2 px-2"></p>
              </SoftBadge>
              <p className="fs--1">{data.color}</p>
            </div>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Seats">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {data.vehicle.numberOfSeats}
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="License Plate">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {formatPlate(data.licensePlate)}
            </p>
          </RowDriverVehicleInfoCard>

          <RowDriverVehicleInfoCard label="Year">
            <p className=" m-0" style={{ fontSize: 12 }}>
              {data.year}
            </p>
          </RowDriverVehicleInfoCard>
        </Card.Body>
      </Card>
    </>
  );
};

const RowDriverVehicleInfoCard: FC<{ label: string; children: ReactNode }> = ({
  label,
  children,
}) => (
  <Row
    className={classNames(
      'align-items-center py-2',
      'border-bottom border-200 '
    )}
    md={7}
  >
    <Col className="py-1">
      <Flex className="position-relative">
        <Flex tag="h6" className="mb-0">
          <h6 className="fs--1 fw-semi-bold stretched-link mb-0">
            {capitalize(label)}
          </h6>
        </Flex>
      </Flex>
    </Col>
    <Col>
      <Row className="justify-content-end align-items-center">
        <Col xs="auto" className="pe-x1 pt-1">
          {children}
        </Col>
      </Row>
    </Col>
  </Row>
);

export default DriverVehicleInfoCard;
