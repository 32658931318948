import ErrorComponent from "@/components/common/ErrorComponent";
import { UUID_REGEX } from "@/constants/regexs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import RewardHeader from "@/components/card-headers/rewards/RewardHeader";
import UpdateRewardCard from "@/components/updates/rewards/UpdateRewardCard";

const RewardEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
        <RewardHeader id={id} isEdit />
        <UpdateRewardCard id={id} />
    </div>
  );
};

export default RewardEditView;