import { PlaceAutocompleteResult } from "@/interfaces/PlaceAutocomplete";

export const GOOGLEMAPSWEBLINK = "https://www.google.com/maps/place/?q=place_id:";

export const GOOGLEMAPS_COORD_LINK = (lat: number, lon: number) => `https://www.google.com/maps?q=${lat},${lon}`;

export const CHAMPS_ELYSEES = {
  name: "Avenue des Champs-Élysées, Paris, France",
  matched_substrings: [
    {
      length: 17,
      offset: 0,
    },
  ],
  place_id:
    "EipBdmVudWUgZGVzIENoYW1wcy3DiWx5c8OpZXMsIFBhcmlzLCBGcmFuY2UiLiosChQKEglReAD4xG_mRxH2ZI_zh6ehWhIUChIJD7fiBh9u5kcRYJSMaMOCCwQ",
  reference:
    "EipBdmVudWUgZGVzIENoYW1wcy3DiWx5c8OpZXMsIFBhcmlzLCBGcmFuY2UiLiosChQKEglReAD4xG_mRxH2ZI_zh6ehWhIUChIJD7fiBh9u5kcRYJSMaMOCCwQ",
  structured_formatting: {
    main_text: "Avenue des Champs-Élysées",
    main_text_matched_substrings: [
      {
        length: 17,
        offset: 0,
      },
    ],
    secondary_text: "Paris, France",
  },
  terms: [
    {
      offset: 0,
      value: "Avenue des Champs-Élysées",
    },
    {
      offset: 27,
      value: "Paris",
    },
    {
      offset: 34,
      value: "France",
    },
  ],
  types: ["route", "geocode"],
} as PlaceAutocompleteResult;
