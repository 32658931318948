import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC } from "react";
import { Button } from "react-bootstrap";
import Flex from "../Flex";
import { ITablePaginationProps } from "@/interfaces/Table";

export const TablePagination: FC<ITablePaginationProps> = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  className,
  pageIndex,
  setPageIndex,
}) => {
  return (
    <Flex alignItems="center" justifyContent="center" className={className}>
      <Button
        size="sm"
        variant="nolina-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      <ul className="pagination mb-0 mx-2">
        {[...Array(pageCount)].map((_, page) => {
          const isCurrent = pageIndex === page;
          const isInRange =
            page >= Math.max(0, pageIndex - 2) &&
            page <= Math.min(pageCount - 1, pageIndex + 2);
          const isEllipsis =
            (page === 1 && pageIndex >= 5) ||
            (page === pageCount - 2 && pageIndex <= pageCount - 6);
          if (isInRange || isCurrent || isEllipsis) {
            return (
              <li key={page} className={classNames({ active: isCurrent })}>
                <Button
                  size="sm"
                  variant="nolina-default"
                  className={classNames("page", {
                    "me-2": page + 1 !== pageCount,
                    disabled: isCurrent || isEllipsis,
                  })}
                  onClick={() => setPageIndex(page)}
                >
                  {isEllipsis ? "..." : page + 1}
                </Button>
              </li>
            );
          }
          return null;
        })}
      </ul>
      <Button
        size="sm"
        variant="nolina-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Flex>
  );
};

export default TablePagination;
