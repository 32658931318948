import {
  DRIVER_CANCELLATION_REASONS,
  RIDE_CANCELLATION_REASONS,
} from '@/interfaces/Ride';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface UpdateStatusRideModalProps {
  idRide: string;
  onUpdate: (
    rideId: string,
    newStatus: string,
    cancellationReason?: string,
    cancellationReasonMessage?: string
  ) => void;
}

export interface UpdateStatusRideModalHandle {
  showOrHiddenModal: () => void;
}

const UpdateStatusRideModal = forwardRef<
  UpdateStatusRideModalHandle,
  UpdateStatusRideModalProps
>(({ idRide, onUpdate }, ref) => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationReasonMessage, setCancellationReasonMessage] =
    useState('');

  useImperativeHandle(ref, () => ({
    showOrHiddenModal: () => setShow(!show),
  }));

  const handleUpdate = () => {
    onUpdate(idRide, status, cancellationReason, cancellationReasonMessage);
    setShow(false);
  };

  const shouldShowCancellationReasons = [
    'driver_cancelled',
    'cancelled',
  ].includes(status);
  const shouldShowCancellationReasonMessage = cancellationReason === 'other';

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Change Ride Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="statusSelect">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={e => {
                setStatus(e.target.value);
                setCancellationReason(''); // Reset the reason when status changes
                setCancellationReasonMessage(''); // Reset the message when status changes
              }}
            >
              <option value="">Select status</option>
              <option value="requesting">Requesting</option>
              <option value="accepted">Accepted</option>
              <option value="driver_arrived">Driver Arrived</option>
              <option value="started">Started</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
              <option value="driver_cancelled">Driver Cancelled</option>
              <option value="no_drivers_available">No Drivers Available</option>
              <option value="internal_error">Internal Error</option>
            </Form.Control>
          </Form.Group>

          {shouldShowCancellationReasons && (
            <>
              <Form.Group controlId="cancellationReasonSelect">
                <Form.Label>Cancellation Reason</Form.Label>
                <Form.Control
                  as="select"
                  value={cancellationReason}
                  onChange={e => setCancellationReason(e.target.value)}
                >
                  <option value="">Select reason</option>
                  {status === 'driver_cancelled' &&
                    DRIVER_CANCELLATION_REASONS.map(reason => (
                      <option key={reason} value={reason}>
                        {reason}
                      </option>
                    ))}
                  {status === 'cancelled' &&
                    RIDE_CANCELLATION_REASONS.map(reason => (
                      <option key={reason} value={reason}>
                        {reason}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>

              {shouldShowCancellationReasonMessage && (
                <Form.Group controlId="cancellationReasonMessage">
                  <Form.Label>Cancellation Reason Message</Form.Label>
                  <Form.Control
                    type="text"
                    value={cancellationReasonMessage}
                    onChange={e => setCancellationReasonMessage(e.target.value)}
                  />
                </Form.Group>
              )}
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default UpdateStatusRideModal;
