import PaginateCard from '@/components/cards/PaginateCard';
import Log from '@/components/common/Log';
import { ILog } from '@/components/interfaces/Log';
import { getTextDriverService } from '@/helpers/DriverServiceHelpers';
import { LogFilters } from '@/interfaces/Log';
import { useGetDriverServicesQuery } from '@/services/drivers/service';
import { FC, useState } from 'react';

const DriverServiceCard: FC<{ id: string }> = ({ id }) => {
  const [filters, setFilters] = useState<LogFilters>({
    pageIndex: 0,
    pageSize: 3,
  });

  const { data, isLoading, error } = useGetDriverServicesQuery({
    ...filters,
    id,
  });

  return (
    <PaginateCard
      filters={filters}
      setFilters={setFilters}
      isLoading={isLoading}
      error={error}
      card
      meta={data?.meta}
      title="Services"
    >
      {data?.data
        .map((v, i) => {
          return {
            action: getTextDriverService(v),
            id: v.id,
          } as ILog;
        })
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .map(log => (
          <Log
            key={log.id}
            {...log}
            sizeAvatar="s"
            classNames={{
              avatar: 'me-4',
              wrapper: 'align-items-center',
              body: 'm-0',
            }}
          />
        ))}
    </PaginateCard>
  );
};

export default DriverServiceCard;
