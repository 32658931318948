import Editor from '@/components/common/Editor';
import Modal from '@/components/common/Modal';
import { IArticleForm } from '@/interfaces/Article';
import { IModalMethods } from '@/interfaces/Modal';
import {
  useStoreArticleMutation,
  useUpdateArticleMutation,
} from '@/services/articles';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  Controller,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';
import classNames from 'classnames';
import Select from '@/components/common/Select';
import { UserTypeSelect } from '@/helpers/UserHelpers';

const StoreArticleModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IArticleForm>({});

  const [storeArticleAsync, { reset: resetForm }] = useStoreArticleMutation();
  const [updateArticleAsync] = useUpdateArticleMutation();

  const showOrHiddenModal = () => {
    reset();
    ModalRef.current?.showOrHiddenModal();
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  useEffect(() => {
    reset();
    return () => {
      resetForm();
    };
  }, []);

  const articleId = getValues('id');

  const onSubmit: SubmitHandler<IArticleForm> = ({ ...data }) => {
    const isUpdate = !!articleId;

    toast
      .promise(
        articleId
          ? updateArticleAsync([
              articleId,
              {
                ...data,
                userType: data.userType?.value,
                content: JSON.stringify(data.content),
              },
            ]).unwrap()
          : storeArticleAsync({
              ...data,
              userType: data.userType?.value,
              content: JSON.stringify(data.content),
            }).unwrap(),
        {
          pending: `${
            isUpdate ? 'Modification' : 'Création'
          } de l'article en cours...`,
          success: {
            render() {
              if (isUpdate) {
                reset({});
                showOrHiddenModal();
                return (
                  <p style={{ marginBottom: 0, textAlign: 'center' }}>
                    L'article à bien été ajouté 🤩
                  </p>
                );
              }
              return (
                <p style={{ marginBottom: 0, textAlign: 'center' }}>
                  L'article à bien été créer maintenant, merci d'ajouter du
                  contenu.
                </p>
              );
            },
          },
          error: {
            render({ data }: ToastContentProps<FetchBaseQueryError>) {
              if (data?.status === 422) {
                return 'Les champs que vous avez remplis semblent être incorrects.';
              }
              return 'Une erreur est survenue';
            },
          },
        }
      )
      .then(resp => {
        reset({
          ...getValues(),
          illustration: undefined,
          id: resp.id,
        });
      });
  };

  const onError: SubmitErrorHandler<IArticleForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un article"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Mon titre"
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Link <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : www.wearecomin.com"
              type="text"
              {...register('link', {
                required: 'Ce champ est requis..',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères',
                },
              })}
              isInvalid={!!errors.link}
            />
            <Form.Control.Feedback type="invalid">
              {errors.link?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Illustration <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              type="file"
              {...register('illustration', {
                validate: {
                  isFile: value => {
                    const files = value as unknown as FileList;
                    if (!!articleId) return true;
                    return (
                      !!files.length &&
                      (files[0].type === 'image/jpeg' ||
                        files[0].type === 'image/png')
                    );
                  },
                },
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.illustration?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Type d'utilisateur :</Form.Label>
            <Controller
              control={control}
              name="userType"
              render={({ field }) => (
                <Select
                  options={UserTypeSelect}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.userType,
              })}
            >
              {errors.userType?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {articleId && (
        <Form.Group className="mb-3">
          <Form.Label>
            Contenu <span className="text-danger">*</span> :
          </Form.Label>
          <Controller
            control={control}
            name="content"
            rules={{
              required: 'Contenu requis',
            }}
            render={({ field }) => (
              <Editor field={field} articleId={articleId} />
            )}
          />
          <Form.Control.Feedback
            type="invalid"
            className={classNames({
              'd-block': errors.content,
            })}
          >
            {errors.content?.message}
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </Modal>
  );
};

export default forwardRef(StoreArticleModal);
