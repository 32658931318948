import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC, useCallback } from "react";
import Flex from "./Flex";
import { Sizes } from "@/interfaces/Theme";

interface IProps {
  size?: Sizes;
  rounded?: string;
  src?: string | null;
  alt?: string;
  name?: string;
  emoji?: string;
  className?: string;
  mediaClass?: string;
  isExact?: boolean;
  icon?: IconProp;
  onClick?: () => void;
}

const Avatar: FC<IProps> = ({
  size = "xl",
  src,
  rounded = "circle",
  alt = "",
  emoji,
  className,
  mediaClass,
  name,
  isExact,
  icon,
  onClick,
}) => {
  const classNames = ["avatar", `avatar-${size}`, className].join(" ");
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : "rounded",
    mediaClass,
  ].join(" ");

  const GetAvatar: FC = useCallback(() => {
    if (src) {
      return (
        <img
          alt={alt}
          onClick={onClick}
          src={src}
          className={`${
            rounded
              ? `rounded-${rounded} ${mediaClass}`
              : `rounded ${mediaClass}`
          } ${onClick ? "cursor-pointer" : ""}`}
        />
      );
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g)?.join("")}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    if (emoji) {
      return (
        <div className={`avatar-emoji ${mediaClasses}`}>
          <span role="img" aria-label="Emoji">
            {emoji}
          </span>
        </div>
      );
    }

    return (
      <div className={`avatar-name ${mediaClasses}`}>
        <span>{alt.match(/\b\w/g)?.join("")}</span>
      </div>
    );
  }, [
    alt,
    icon,
    isExact,
    mediaClass,
    mediaClasses,
    name,
    rounded,
    src,
    onClick,
  ]);

  return (
    <div className={classNames}>
      <GetAvatar />
    </div>
  );
};

interface IAvatarGroupProps {
  children: React.ReactNode;
  dense?: boolean;
  className?: string;
}

export const AvatarGroup: FC<IAvatarGroupProps> = ({
  children,
  dense,
  className,
}) => {
  return (
    <div
      className={classNames(className, "avatar-group", {
        "avatar-group-dense": dense,
      })}
    >
      {children}
    </div>
  );
};

export default Avatar;
